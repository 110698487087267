import React, { Fragment, useState } from 'react';
import Header from '../../components/ui/Header';
import Navigation from '../../components/ui/Navigation';
import FindCharacteristic from './FindCharacteristic';
import FindMaterial from './FindMaterial';
import './index.scss';

const PAGES = [{ key: 'recherche-de-caracteristique', label: 'Recherche de caractéristique' }, { key: 'recherche-de-materiau', label: 'Recherche de matériau' }];

const Library = () => {
    const [page, setPage] = useState<string | null>(null);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Bibliothèque' }]} />
            <Navigation pages={PAGES} setPage={setPage} />
            {page === PAGES[0].key && <FindCharacteristic />}
            {page === PAGES[1].key && <FindMaterial />}
        </Fragment>
    )
}

export default Library;
