import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useOperationContext } from '../../../../context/OperationProvider';
import { Lot } from '../../../../models/lot';
import Canvas from '../../../../synoptique/Canvas.class';
import { Direction, LayerColors } from '../../../../synoptique/Synoptique.class';
import { IdentityCardLot } from '../../../../components/entities/IdentityCard';

interface LotWithCoordinates extends Lot {
    index: number;
    min: number;
    max: number;
    x: number;
    y: number;
    width: number;
    height: number;
    cumulatedThickness: number;
}

interface LotCanvasProps {
    layers: Lot[];
    currentDirection: Direction;
    onSelectLayer: (l: Lot) => void;
}

const LotCanvas = ({
    layers,
    currentDirection,
    onSelectLayer,
}: LotCanvasProps) => {
    const { isRoadOperation } = useOperationContext();
    const canvasDomRef = useRef<HTMLCanvasElement>(null);
    const canvas = useRef<Canvas | null>(null);
    const [layersWithCoordinates, setLayersWithCoordinates] = useState<LotWithCoordinates[]>([]);
    const [hoverLayer, setHoverLayer] = useState<Lot | null>(null);

    const drawLayers = () => {
        if (!canvas.current) return;
        const maxThicknessPerPosition: { [k: number]: number } = {};

        const _layersWithCoordinates: LotWithCoordinates[] = layers
            .sort((l1, l2) => {
                if (l1.position === l2.position) {
                    return l1.date < l2.date ? -1 : 1;
                }
                return l1.position < l2.position ? -1 : 1;
            })
            .map((l, index) => {
                maxThicknessPerPosition[l.position] = l.thickness > (maxThicknessPerPosition[l.position] ?? 0) ? l.thickness : (maxThicknessPerPosition[l.position] ?? 0);

                return {
                    ...l,
                    index,
                    min: isRoadOperation ? l.zone.prStart! : Math.min(...l.zone.polygonXY?.map(xy => xy.x) ?? []),
                    max: isRoadOperation ? l.zone.prEnd! : Math.max(...l.zone.polygonXY?.map(xy => xy.x) ?? []),
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    cumulatedThickness: 0
                };
            });

        // Get canvas size
        const { width, height } = canvas.current?.setCanvasSize();

        // Clear canvas
        canvas.current?.render();

        // Layer values
        const thickness = Object.keys(maxThicknessPerPosition).reduce((sum, position) => maxThicknessPerPosition[Number(position)] + sum, 0);
        const min = Math.min(..._layersWithCoordinates.map(l => l.min));
        const max = Math.max(..._layersWithCoordinates.map(l => l.max));
        const length = max - min;

        // Canvas values
        const marginRight = 10;
        const marginLeft = 80;
        const marginTop = 25;
        const marginBottom = 10;
        const heightPerCm = (height - marginTop - marginBottom) / thickness;
        const widthPerHm = (width - marginLeft - marginRight) / (length * 10);
        const hmOffset = 0;

        // Draw grid
        canvas.current.setStyle({ strokeStyle: '#f0f0f5', lineWidth: 1 });
        for (let i = 0; i < Math.ceil((height - marginTop) / heightPerCm); i++) {
            canvas.current.drawLine(marginLeft - 25, marginTop + i * heightPerCm, width, marginTop + i * heightPerCm);
        }

        // Draw Pr if road operation
        if (isRoadOperation) {
            canvas.current.setStyle({ strokeStyle: '#bec1cc', lineWidth: 1, fillStyle: '#828999', font: 'bold 10px Roboto' });
            for (let i = 0; i < hmOffset + length * 10; i++) {
                const currentPr = (i - hmOffset) / 10 + min;
                if ((Math.floor(currentPr * 10) / 10) % 1 === 0) {
                    const text = Math.floor(currentPr) + ' km';
                    canvas.current.drawText(marginLeft + (i - hmOffset) * widthPerHm - canvas.current.ctx.measureText(text).width / 2, marginTop - 13, text);
                    canvas.current.drawLine(marginLeft + (i - hmOffset) * widthPerHm, marginTop - 10, marginLeft + (i - hmOffset) * widthPerHm, height);
                } else {
                    canvas.current.drawLine(marginLeft + (i - hmOffset) * widthPerHm, marginTop - 10, marginLeft + (i - hmOffset) * widthPerHm, height);
                }
            }
        }

        // Draw layers
        canvas.current.setStyle({ strokeStyle: '#bec1cc', lineWidth: 1, fillStyle: '#828999', font: 'bold 10px Roboto' });
        const text = Number.parseFloat('0').toFixed(2) + ' cm';
        canvas.current.drawText((marginLeft - 25 - canvas.current.ctx.measureText(text).width) - 10, marginTop + 6, text);
        canvas.current.drawLine(marginLeft - 25, marginTop, width, marginTop);

        // List of thickness ticks
        const thicknessTicks = [];

        for (let i = 0; i < _layersWithCoordinates.length; i++) {
            _layersWithCoordinates[i].x = marginLeft + (_layersWithCoordinates[i].min - min) * widthPerHm * 10;
            _layersWithCoordinates[i].y = marginTop;
            _layersWithCoordinates[i].width = (_layersWithCoordinates[i].max - _layersWithCoordinates[i].min) * widthPerHm * 10;
            _layersWithCoordinates[i].height = _layersWithCoordinates[i].thickness * heightPerCm;
            _layersWithCoordinates[i].cumulatedThickness = _layersWithCoordinates[i].thickness;

            // Find if layer overwrite part of another one
            const overwrittenLayer = _layersWithCoordinates
                .find(l =>
                    l.index < i &&
                    l.position === _layersWithCoordinates[i].position &&
                    (
                        (l.min >= _layersWithCoordinates[i].min && l.min <= _layersWithCoordinates[i].max)
                        || (l.max >= _layersWithCoordinates[i].min && l.max <= _layersWithCoordinates[i].max)
                    )
                );
            if (overwrittenLayer) {
                _layersWithCoordinates[i].y = overwrittenLayer.y;
                _layersWithCoordinates[i].cumulatedThickness = overwrittenLayer.cumulatedThickness - overwrittenLayer.thickness + _layersWithCoordinates[i].thickness;
            } else {
                // Find if a layer already drawn above this one
                const aboveLayer = _layersWithCoordinates
                    .find(l =>
                        l.index < i &&
                        l.position < _layersWithCoordinates[i].position &&
                        (
                            (l.min >= _layersWithCoordinates[i].min && l.min <= _layersWithCoordinates[i].max)
                            || (l.max >= _layersWithCoordinates[i].min && l.max <= _layersWithCoordinates[i].max)
                        )
                    );

                if (aboveLayer) {
                    _layersWithCoordinates[i].y = aboveLayer.y + aboveLayer.height;
                    _layersWithCoordinates[i].cumulatedThickness = aboveLayer.cumulatedThickness + _layersWithCoordinates[i].thickness;
                }
            }
            /*           TODO why ?
                        if (!thicknessTicks.includes(_layersWithCoordinates[i].cumulatedThickness)) {
                            // Draw thickness tick
                            canvas.current.setStyle({ strokeStyle: '#bec1cc', lineWidth: 1, fillStyle: '#828999', font: 'bold 10px Roboto' });
                            const text = formatNumberToFixedDecimal(_layersWithCoordinates[i].cumulatedThickness) + ' cm';
                            canvas.current.drawLine(marginLeft - 25, _layersWithCoordinates[i].y + _layersWithCoordinates[i].height - 1, width, _layersWithCoordinates[i].y + _layersWithCoordinates[i].height - 1);
                            canvas.current.drawText((marginLeft - 25 - canvas.current.ctx.measureText(text).width) - 10, _layersWithCoordinates[i].y + _layersWithCoordinates[i].height + 4, text);
                        } */

            // Layer rectangle
            const color = LayerColors[_layersWithCoordinates[i].index] ?? '#d94032';
            const r = parseInt(color.substring(1, 3), 16);
            const g = parseInt(color.substring(3, 5), 16);
            const b = parseInt(color.substring(5, 7), 16);

            canvas.current?.setStyle({ strokeStyle: color, fillStyle: `rgba(${r}, ${g}, ${b}, 0.9)`, lineWidth: 1 });
            canvas.current?.drawRect(_layersWithCoordinates[i].x, _layersWithCoordinates[i].y, _layersWithCoordinates[i].width, _layersWithCoordinates[i].height, true, true);
        }
        setLayersWithCoordinates(_layersWithCoordinates.sort((l1, l2) => l1.date > l2.date ? -1 : 1));
    }

    useEffect(() => {
        window.addEventListener('resize', drawLayers);

        return () => window.removeEventListener('resize', drawLayers);
    }, []);

    useEffect(() => {
        if (canvasDomRef?.current) {
            canvas.current = new Canvas(canvasDomRef.current);
            drawLayers();
        }
    }, [canvasDomRef]);

    useEffect(() => {
        if (canvas?.current) {
            drawLayers();
        }
    }, [layers, currentDirection]);

    const onMouseMove = useCallback((event: MouseEvent<HTMLCanvasElement>) => {
        if (canvas?.current) {
            const mouse = canvas?.current.getCursorPosFromXY({ x: event.clientX, y: event.clientY });
            setHoverLayer(layersWithCoordinates.find(l => canvas?.current?.containsClick(mouse.x, mouse.y, l.x, l.y, l.width, l.height)) ?? null);
        }
    }, []);

    const onClick = useCallback((event: MouseEvent<HTMLCanvasElement>) => {
        if (canvas?.current) {
            const mouse = canvas?.current.getCursorPosFromXY({ x: event.clientX, y: event.clientY });
            const _selectedLayer = layersWithCoordinates.find(l => canvas?.current?.containsClick(mouse.x, mouse.y, l.x, l.y, l.width, l.height));

            if (_selectedLayer) onSelectLayer(_selectedLayer);
            setHoverLayer(null);
        }
    }, [layersWithCoordinates]);

    return (
        <div className="lot-canvas-container">
            <canvas className="lot-canvas" ref={canvasDomRef} onMouseMove={onMouseMove} onClick={onClick} />
            {hoverLayer &&
                <div className="lot-modal md-hide">
                    <IdentityCardLot entity={hoverLayer} />
                </div>
            }
        </div>
    )
}

export default LotCanvas;