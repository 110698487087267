import { Marker, Polygon } from '@react-google-maps/api';
import { useRef, useState } from 'react';
import Map, { MapAction, getCoordinatesFromPolygon } from './Map';
import { Modal } from '../../ui/Modal';
import './index.scss';
import { Coordinates } from '../../../models/location';

interface MapModalProps {
    title: string;
    action: MapAction;
    marker?: Coordinates;
    polygon?: Coordinates[];
    zone?: Coordinates[];
    center?: Coordinates;
    onSubmit: (data: { marker?: Coordinates, polygon?: Coordinates[] }) => void;
    onCancel?: () => void;
}

const MapModal = ({
    title,
    action = MapAction.MARKER,
    center,
    zone,
    marker: markerFromProps,
    polygon: polygonFromProps,
    onSubmit,
    onCancel
}: MapModalProps) => {
    const [marker, setMarker] = useState<Coordinates | null>(action === MapAction.MARKER && markerFromProps ? markerFromProps : null);
    const [polygon, setPolygon] = useState<Coordinates[] | null>(action === MapAction.POLYGON && polygonFromProps ? polygonFromProps : null);
    const polygonRef = useRef<google.maps.Polygon | null>(null);

    const handleActionComplete = (a: MapAction, marker: Coordinates, polygon?: Coordinates[] | undefined) => {
        if (a === MapAction.MARKER) {
            setMarker(marker);
        } else if (a === MapAction.POLYGON && polygon) {
            setPolygon(polygon);
        }
    }

    const handleEditPolygon = () => {
        if (!polygonRef.current) return;
        setPolygon(getCoordinatesFromPolygon(polygonRef.current));
    }

    return (
        <Modal
            title={title}
            className="map-modal"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onCancel },
                { label: 'Confirmer', onClick: () => onSubmit({ marker: marker ?? undefined, polygon: polygon ?? undefined }) },
            ]}
        >
            <Map
                center={center}
                onActionComplete={handleActionComplete}
                initialAction={action === MapAction.POLYGON && polygonFromProps ? undefined : action}
                availableActions={[action]}
                withSearch
                withZone
            >
                {!!marker?.longitude && !!marker?.latitude && <Marker position={{ lng: marker.longitude, lat: marker.latitude }} />}
                {zone && (
                    <Polygon
                        paths={zone.map(c => ({ lat: c.latitude, lng: c.longitude }))}
                        options={{
                            strokeWeight: 2,
                            strokeColor: '#7bb1c1',
                            fillColor: '#7bb1c1',
                            fillOpacity: 0.3,
                            clickable: false,
                        }}
                    />
                )}
                {polygon && (
                    <Polygon
                        onLoad={(polygon) => polygonRef.current = polygon}
                        onMouseUp={() => handleEditPolygon()}
                        paths={polygon.map(c => ({ lat: c.latitude, lng: c.longitude }))}
                        options={{
                            editable: true,
                            strokeWeight: 2,
                            strokeColor: '#7bb1c1',
                            fillColor: '#7bb1c1',
                            fillOpacity: 0.3,
                        }}
                    />
                )}
            </Map>
        </Modal>
    )
}

export default MapModal;