
import { BubbleDataPoint, Point } from 'chart.js';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import Button from '../../../../components/ui/Button';
import Expandable from '../../../../components/ui/Expandable';
import FakeData from '../../../../components/ui/FakeData';
import FakeThicknessChart from '../../../../components/ui/FakeData/FakeThicknessChart';
import IconLink from '../../../../components/ui/IconLink';
import { Modal } from '../../../../components/ui/Modal';
import Printable from '../../../../components/ui/Printable';
import { useAuthContext } from '../../../../context/AuthProvider';
import { useOperationContext } from '../../../../context/OperationProvider';
import { PhaseLabel, Road, RoadPositionLabel } from '../../../../models/operation';
import { ControleLabel, Sample } from '../../../../models/sample';
import { Permission } from '../../../../models/user';
import { Direction, LayerColors, SampleForSynoptique } from '../../../../synoptique/Synoptique.class';
import { floatToPrString } from '../../../../utils/pr';
import SampleCard from './SampleCard';
import { ModalBottom } from '../../../../components/ui/Modal/ModalBottom';

const COMMON_DATASET_OPTIONS = {
    borderWidth: 1,
    pointRadius: 2,
    spanGaps: true,
}

interface SampleChartData {
    labels: string[];
    rawData: { [k: string]: string | undefined }[];
    datasets: any[];
}

const getChartData = (samples: Sample[], isRoadOperation: boolean, roadsObj: { [k: string]: Road }, withPhases: boolean): SampleChartData => {
    const labels: string[] = [];
    const datasets: { [k: number]: { x: string; y: number; collage?: boolean }[] } = {};
    const rawData: { [k: string]: string | undefined }[] = [];
    for (const sample of samples) {
        const rawDatum = isRoadOperation
            ? {
                phase: PhaseLabel[sample.phase],
                way: `Sens ${sample.location.way ?? 1}`,
                road: sample.location.road ? roadsObj?.[sample.location.road]?.name ?? '' : '',
                roadPosition: sample.location.roadPosition ? RoadPositionLabel[sample.location.roadPosition] ?? '' : '',
                pr: floatToPrString(sample.location.pr),
                controle: ControleLabel[sample.controle]
            }
            : {
                phase: PhaseLabel[sample.phase],
                name: sample.name,
                controle: ControleLabel[sample.controle]
            };

        const label = isRoadOperation
            ? `${withPhases ? rawDatum.phase + ' : ' : ''}${rawDatum.way} - ${rawDatum.road} ${rawDatum.roadPosition} ${rawDatum.pr} (${rawDatum.controle})`
            : `${withPhases ? rawDatum.phase + ' : ' : ''}${sample.name} (${rawDatum.controle})`;

        labels.push(label);

        for (const layer of sample.layers ?? []) {
            const field = layer.order ?? 0;
            if (!datasets[field]) {
                datasets[field] = [];
            }
            datasets[field].push({
                x: label,
                y: layer.thickness,
                collage: layer.collage,
            });
        }
        rawData.push(rawDatum);
    }

    return {
        labels,
        rawData,
        datasets: Object.keys(datasets).map(key => ({
            ...COMMON_DATASET_OPTIONS,
            label: 'Couche ' + (Number(key) + 1),
            data: datasets[Number(key)],
            backgroundColor: LayerColors[Number(key)],
            borderColor: 'white',
            borderWidth: (context: any) => ({
                top: 0,
                bottom: context?.raw?.collage ? 0 : 2,
                left: 0,
                right: 0,
            }),
        }))
    };
}

interface SampleViewProps {
    samples: SampleForSynoptique[];
    onSelectElement: (_id: string) => void;
    selectedElement?: string;
    currentDirection: Direction;
    onClose: () => void;
}

const SampleView = ({
    samples,
    onSelectElement,
    selectedElement,
    currentDirection,
    onClose
}: SampleViewProps) => {
    const { operation, isRoadOperation, availablePhases, operationPermissions } = useOperationContext();
    const [thickness, setThickness] = useState<number>(0);
    const [selectedSample, setSelectedSample] = useState<SampleForSynoptique | null>(null);
    const [chartData, setChartData] = useState<{ min: number; max: number; data: SampleChartData } | null>(null);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const chartRef = useRef<ChartJSOrUndefined<"bar", (number | [number, number] | Point | BubbleDataPoint | null)[], unknown>>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [sortedSamples, setSortedSamples] = useState<Sample[] | null>(null);

    const sortSamples = (_samples: SampleForSynoptique[]) => isRoadOperation
        ? sortSamplesByRoadAndPr(_samples)
        : sortSamplesByCoordinates(_samples);

    const sortSamplesByRoadAndPr = (_samples: SampleForSynoptique[]) => {
        const samplesSorted: { [k: number]: SampleForSynoptique } = {};
        for (const sample of _samples) {
            if (sample.location.pr === undefined) continue;
            switch (currentDirection) {
                case Direction.LEFT:
                    // Road position ascending and show only lowest PR on same road position
                    if (!samplesSorted[sample.roadPositionIndex]) {
                        samplesSorted[sample.roadPositionIndex] = sample;
                    } else if (sample.location.pr < samplesSorted[sample.roadPositionIndex].location.pr!) {
                        samplesSorted[sample.roadPositionIndex] = sample;
                    } else if (
                        sample.location.pr === samplesSorted[sample.roadPositionIndex].location.pr
                        && sample.date >= samplesSorted[sample.roadPositionIndex].date
                    ) {
                        samplesSorted[sample.roadPositionIndex] = sample;
                    }
                    break;
                case Direction.RIGHT:
                    // Road position descending and show only highest PR on same road position
                    if (!samplesSorted[sample.roadPositionIndex]) {
                        samplesSorted[sample.roadPositionIndex] = sample;
                    } else if (sample.location.pr > samplesSorted[sample.roadPositionIndex].location.pr!) {
                        samplesSorted[sample.roadPositionIndex] = sample;
                    } else if (
                        sample.location.pr === samplesSorted[sample.roadPositionIndex].location.pr
                        && sample.date >= samplesSorted[sample.roadPositionIndex].date
                    ) {
                        samplesSorted[sample.roadPositionIndex] = sample;
                    }
                    break;
                case Direction.TOP:
                    // PR descending and show only lowest road position on same PR
                    if (!samplesSorted[sample.location.pr]) {
                        samplesSorted[sample.location.pr] = sample;
                    } else if (sample.roadPositionIndex < samplesSorted[sample.location.pr].roadPositionIndex) {
                        samplesSorted[sample.location.pr] = sample;
                    } else if (
                        sample.roadPositionIndex === samplesSorted[sample.location.pr].roadPositionIndex
                        && sample.date >= samplesSorted[sample.location.pr].date
                    ) {
                        samplesSorted[sample.location.pr] = sample;
                    }
                    break;
                default:
                    // PR ascending and show only highest road position on same PR
                    if (!samplesSorted[sample.location.pr]) {
                        samplesSorted[sample.location.pr] = sample;
                    } else if (sample.roadPositionIndex > samplesSorted[sample.location.pr].roadPositionIndex) {
                        samplesSorted[sample.location.pr] = sample;
                    } else if (
                        sample.roadPositionIndex === samplesSorted[sample.location.pr].roadPositionIndex
                        && sample.date >= samplesSorted[sample.location.pr].date
                    ) {
                        samplesSorted[sample.location.pr] = sample;
                    }
                    break;
            }
        }
        return Object.keys(samplesSorted)
            .sort((k1, k2) => {
                if (currentDirection === Direction.BOTTOM || currentDirection === Direction.LEFT) {
                    return Number(k1) > Number(k2) ? 1 : -1
                } else {
                    return Number(k1) > Number(k2) ? -1 : 1
                }
            })
            .map(key => samplesSorted[Number(key)]);
    }

    const sortSamplesByCoordinates = (_samples: SampleForSynoptique[]) => {
        const GRID = 10; // Grid in meters to hide element
        let minX = 0;
        let maxX = 0;
        let minY = 0;
        let maxY = 0;

        for (const sample of _samples) {
            if (sample.location.coordinatesXY === undefined) continue;

            minX = sample.location.coordinatesXY.x < minX ? sample.location.coordinatesXY.x : minX;
            maxX = sample.location.coordinatesXY.x > maxX ? sample.location.coordinatesXY.x : maxX;
            minY = sample.location.coordinatesXY.y < minY ? sample.location.coordinatesXY.y : minY;
            maxY = sample.location.coordinatesXY.y > maxY ? sample.location.coordinatesXY.y : maxY;
        }

        const samplesSorted = [];
        switch (currentDirection) {
            case Direction.LEFT:
                // Y descending and show only lowest X on same Y grid
                for (let y = maxY; y >= minY; y -= GRID) {
                    const samplesOnGrid = _samples
                        .filter(s => s.location?.coordinatesXY && s.location?.coordinatesXY.y <= y && s.location?.coordinatesXY.y > y - GRID)
                        .sort((s1, s2) => s1.location?.coordinatesXY!.x < s2.location?.coordinatesXY!.x ? -1 : 1);

                    if (samplesOnGrid?.length) {
                        samplesSorted.push(samplesOnGrid[0]);
                    }
                }
                break;
            case Direction.RIGHT:
                // Y ascending and show only highest X on same Y grid
                for (let y = minY; y <= maxY; y += GRID) {
                    const samplesOnGrid = _samples
                        .filter(s => s.location?.coordinatesXY && s.location?.coordinatesXY.y >= y && s.location?.coordinatesXY.y < y + GRID)
                        .sort((s1, s2) => s1.location?.coordinatesXY!.x < s2.location?.coordinatesXY!.x ? 1 : -1);

                    if (samplesOnGrid?.length) {
                        samplesSorted.push(samplesOnGrid[0]);
                    }
                }
                break;
            case Direction.TOP:
                // X descending and show only highest Y on same X grid
                for (let x = maxX; x >= minX; x -= GRID) {
                    const samplesOnGrid = _samples
                        .filter(s => s.location?.coordinatesXY && s.location?.coordinatesXY.x <= x && s.location?.coordinatesXY.x > x - GRID)
                        .sort((s1, s2) => s1.location?.coordinatesXY!.y < s2.location?.coordinatesXY!.y ? 1 : -1);

                    if (samplesOnGrid?.length) {
                        samplesSorted.push(samplesOnGrid[0]);
                    }
                }
                break;
            default:
                // X ascending and show only lowest Y on same X grid
                for (let x = minX; x <= maxX; x += GRID) {
                    const samplesOnGrid = _samples
                        .filter(s => s.location?.coordinatesXY && s.location?.coordinatesXY.x >= x && s.location?.coordinatesXY.x < x + GRID)
                        .sort((s1, s2) => s1.location?.coordinatesXY!.y < s2.location?.coordinatesXY!.y ? -1 : 1);

                    if (samplesOnGrid?.length) {
                        samplesSorted.push(samplesOnGrid[0]);
                    }
                }
                break;
        }
        return samplesSorted;
    }

    useEffect(() => {
        if (selectedSample) {
            onSelectElement(selectedSample._id);
        }
    }, [selectedSample]);

    useEffect(() => {
        const _sortedSamples = sortSamples(samples ?? []);
        const _thickness = Math.max(...(_sortedSamples ?? []).map(s => s.layers.reduce((sum, layer) => (layer.thickness ?? 0) + sum, 0)));
        setThickness(_thickness);
        setChartData({
            min: 0,
            max: Math.ceil(_thickness + 1),
            data: getChartData(_sortedSamples, isRoadOperation, operation.roadsObj, !!availablePhases?.length),
        });

        if (_sortedSamples?.length === 1) {
            onSelectElement(_sortedSamples[0]._id);
        }
        setSortedSamples(_sortedSamples);
        setSelectedSample(null);
    }, [samples, currentDirection]);

    if (!sortedSamples?.length) {
        return (null);
    }

    return (
        <ModalBottom visible={true} onClose={onClose} title="Echantillons sélectionnés">
            <div id="sample-view-container">
                <div className="sample-view-cards-container">
                    {sortedSamples.map(s =>
                        <SampleCard
                            key={s._id}
                            onSelectSample={onSelectElement}
                            sample={s}
                            thickness={thickness}
                            active={s._id === selectedElement}
                        />
                    )}
                </div>
                <IconLink type="chart" label="Afficher les données d'épaisseur" onClick={() => setModalVisible(true)} />
                {chartData && isModalVisible && (
                    <Modal
                        title="Graphique d'épaisseur"
                        className="sample-view-modal-chart"
                        actions={[
                            { label: 'Fermer', color: 'secondary', onClick: () => setModalVisible(false) }
                        ]}
                    >
                        <Printable title="Graphique d'épaisseur" subtitle={operation.name} filename="epaisseurs" disposition="landscape">
                            {operationPermissions.synoptiqueData ? (
                                <div className="data-table">
                                    <div className="data-chart">
                                        <div className="depth-input no-print">
                                            <div className="depth-input-form">
                                                <span>Interface : </span>
                                                <input type="number" ref={inputRef} />
                                            </div>
                                            <Button className="no-print" onClick={() => chartRef?.current?.render()} label="Mettre à jour" />
                                        </div>
                                        <Expandable>
                                            <Bar
                                                data={chartData.data}
                                                ref={chartRef}
                                                plugins={[{
                                                    id: "tooltipLine",
                                                    afterDraw: (chart) => {
                                                        const lineY = inputRef?.current?.value;
                                                        if (lineY !== undefined && Number(lineY) <= chart.scales.y.max && Number(lineY) >= chart.scales.y.min) {
                                                            const { ctx } = chart;
                                                            const y = chart.scales.y.paddingBottom + (Number(lineY) * chart.scales.y.height) / chart.scales.y.max;
                                                            ctx.beginPath();
                                                            ctx.moveTo(chart.scales.x.left - 25, y);
                                                            ctx.lineTo(chart.scales.x.right, y);
                                                            ctx.lineWidth = 1;
                                                            ctx.strokeStyle = 'red';
                                                            ctx.stroke();
                                                            ctx.fillStyle = 'red';
                                                            ctx.font = '12px Roboto';
                                                            ctx.textAlign = 'end';
                                                            ctx.fillText(lineY, chart.scales.x.left - 30, y);
                                                        }
                                                    }
                                                }]}
                                                options={{
                                                    plugins: {
                                                        legend: {
                                                            display: true,
                                                            position: 'bottom'
                                                        },
                                                        tooltip: {
                                                            callbacks: {
                                                                label: (item) => `${item.dataset?.label} : ${item.formattedValue}${!(item?.raw as any)?.collage ? ' (collage manquant)' : ''}`
                                                            }
                                                        }
                                                    },
                                                    maintainAspectRatio: false,
                                                    scales: {
                                                        x: {
                                                            stacked: true,
                                                        },
                                                        y: {
                                                            reverse: true,
                                                            stacked: true,
                                                            max: chartData?.max,
                                                            min: chartData?.min,
                                                        }
                                                    },
                                                }}
                                            />
                                        </Expandable>
                                    </div>
                                    <h3>Données brutes</h3>
                                    <table>
                                        <thead>
                                            <tr>
                                                {isRoadOperation ? (
                                                    <Fragment>
                                                        <td>Phase</td>
                                                        <td>Sens</td>
                                                        <td>Voie</td>
                                                        <td>Position</td>
                                                        <td>PR</td>
                                                        <td>Contrôle</td>
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <td>Phase</td>
                                                        <td>Nom de l'échantillon</td>
                                                        <td>Contrôle</td>
                                                    </Fragment>
                                                )}
                                                {(chartData.data?.datasets ?? []).map(dataset => (
                                                    <td key={dataset.label}>{dataset.label}</td>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(chartData.data?.rawData ?? []).map((rawDatum, index) => (
                                                <tr key={index}>
                                                    {isRoadOperation ? (
                                                        <Fragment>
                                                            <td>{rawDatum.phase}</td>
                                                            <td>{rawDatum.way}</td>
                                                            <td>{rawDatum.road}</td>
                                                            <td>{rawDatum.roadPosition}</td>
                                                            <td>{rawDatum.pr}</td>
                                                            <td>{rawDatum.controle}</td>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <td>{rawDatum.phase}</td>
                                                            <td>{rawDatum.name}</td>
                                                            <td>{rawDatum.controle}</td>
                                                        </Fragment>
                                                    )}
                                                    {(chartData.data?.datasets ?? []).map(dataset => (
                                                        <td key={dataset.label}>{dataset.data[index]?.y}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <FakeData permission={Permission.SYNOPTIQUE_DATA}><FakeThicknessChart /></FakeData>
                            )}
                        </Printable>
                    </Modal>
                )}
            </div>
        </ModalBottom>
    )
}

export default SampleView;