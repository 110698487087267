import { useCallback, useState } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';
import { ReactComponent as CommentOffIcon } from "../../assets/icons/comment-off.svg";
import { ReactComponent as CommentOnIcon } from "../../assets/icons/comment-on.svg";
import { Textarea } from './Textarea';
import { FormBaseProps } from '../../hooks/useForm';

interface CommentPopupProps extends FormBaseProps<string> {
    withLabel?: boolean;
}

const CommentPopup = ({
    value,
    onChange,
    withLabel = false
}: CommentPopupProps) => {
    const [isTextareaVisible, setTextareaVisible] = useState(false);
    const ref = useOutsideClick(useCallback(() => setTextareaVisible(false), []));

    return (
        <div className="comment" ref={ref}>
            {withLabel && <span onClick={() => setTextareaVisible(!isTextareaVisible)}>Commentaire</span>}
            {value
                ? <CommentOnIcon onClick={() => setTextareaVisible(!isTextareaVisible)} />
                : <CommentOffIcon onClick={() => setTextareaVisible(!isTextareaVisible)} />
            }
            {isTextareaVisible && (
                <Textarea
                    id="comment"
                    value={value ?? ''}
                    onChange={onChange}
                />
            )}
        </div>
    );
}

export default CommentPopup;