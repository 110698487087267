export { ReactComponent as AddBlackIcon } from './add-black.svg';
export { ReactComponent as AddRedIcon } from './add-red.svg';
export { ReactComponent as AnalysisIcon } from './analysis.svg';
export { ReactComponent as ArrowDownIcon } from './arrow-down.svg';
export { ReactComponent as ArrowLeftIcon } from './arrow-left.svg';
export { ReactComponent as ArrowRightIcon } from './arrow-right.svg';
export { ReactComponent as ArrowUpIcon } from './arrow-up.svg';
export { ReactComponent as BugIcon } from './bug.svg';
export { ReactComponent as CalendarIcon } from './calendar.svg';
export { ReactComponent as CarretIcon } from './carret.svg';
export { ReactComponent as ChartIcon } from './chart.svg';
export { ReactComponent as CloseCircleIcon } from './close-circle.svg';
export { ReactComponent as CloseOIcon } from './close-o.svg';
export { ReactComponent as CloseIcon } from './close.svg';
export { ReactComponent as CommentOffIcon } from './comment-off.svg';
export { ReactComponent as CommentOnIcon } from './comment-on.svg';
export { ReactComponent as CompanyIcon } from './company.svg';
export { ReactComponent as CopyIcon } from './copy.svg';
export { ReactComponent as CrosshairIcon } from './crosshair.svg';
export { ReactComponent as CylinderIcon } from './cylinder.svg';
export { ReactComponent as DashboardIcon } from './dashboard.svg';
export { ReactComponent as DownloadIcon } from './download.svg';
export { ReactComponent as EditIcon } from './edit.svg';
export { ReactComponent as ErrorIcon } from './error.svg';
export { ReactComponent as EyeIcon } from './eye.svg';
export { ReactComponent as FilePdfIcon } from './file-pdf.svg';
export { ReactComponent as FileIcon } from './file.svg';
export { ReactComponent as HomeIcon } from './home.svg';
export { ReactComponent as InfoSquareIcon } from './info-square.svg';
export { ReactComponent as LayerIcon } from './layer.svg';
export { ReactComponent as LicenseIcon } from './license.svg';
export { ReactComponent as LogoutIcon } from './logout.svg';
export { ReactComponent as MelangeIcon } from './melange.svg';
export { ReactComponent as MenuIcon } from './menu.svg';
export { ReactComponent as MessageIcon } from './message.svg';
export { ReactComponent as NotificationIcon } from './notification.svg';
export { ReactComponent as OpenIcon } from './open.svg';
export { ReactComponent as OptionsIcon } from './options.svg';
export { ReactComponent as OrderAscIcon } from './order-asc.svg';
export { ReactComponent as OrderDescIcon } from './order-desc.svg';
export { ReactComponent as OrderIcon } from './order.svg';
export { ReactComponent as PasswordHideIcon } from './password-hide.svg';
export { ReactComponent as PasswordShowIcon } from './password-show.svg';
export { ReactComponent as PermissionIcon } from './permission.svg';
export { ReactComponent as PolygonIcon } from './polygon.svg';
export { ReactComponent as PositionIcon } from './position.svg';
export { ReactComponent as PrintIcon } from './print.svg';
export { ReactComponent as UserIcon } from './profile.svg';
export { ReactComponent as RockIcon } from './rock.svg';
export { ReactComponent as SaveIcon } from './save.svg';
export { ReactComponent as SearchIcon } from './search.svg';
export { ReactComponent as OperationIcon } from './operation.svg';
export { ReactComponent as SelectIcon } from './select.svg';
export { ReactComponent as SendIcon } from './send.svg';
export { ReactComponent as SortHandleIcon } from './sort-handle.svg';
export { ReactComponent as SuccessIcon } from './success.svg';
export { ReactComponent as SynoptiqueIcon } from './synoptique.svg';
export { ReactComponent as TimelineIcon } from './timeline.svg';
export { ReactComponent as TrashIcon } from './trash.svg';
export { ReactComponent as TubeIcon } from './tube.svg';
export { ReactComponent as UploadIcon } from './upload.svg';
export { ReactComponent as WarningIcon } from './warning.svg';
export { ReactComponent as XRedIcon } from './x-red.svg';
export { ReactComponent as ZoomMinusIcon } from './zoom-minus.svg';
export { ReactComponent as ZoomPlusIcon } from './zoom-plus.svg';
export { ReactComponent as ZoomIcon } from './zoom.svg';

