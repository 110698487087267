import { Outlet } from 'react-router-dom';
import { useGlobalContext } from '../context/GlobalProvider';
import { useOperationContext } from '../context/OperationProvider';
import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';
import { useAuthContext } from '../context/AuthProvider';
import { Role } from '../models/user';

const Private = () => {
    const { isMenuCollapsed } = useGlobalContext();
    const { isOperationLoaded } = useOperationContext();
    const { user } = useAuthContext();

    return (
        <div id="app" className={`${isMenuCollapsed ? 'menu-collapsed' : ''}`}>
            <Header />
            <div id="main">
                {user.role !== Role.SUBCONTRACTOR && <Sidebar />}
                <div id="main-container">
                    {isOperationLoaded && <Outlet />}
                </div>
            </div>
        </div>
    );
}

export default Private;
