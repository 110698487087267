import { Link, useNavigate } from 'react-router-dom';
import { LicenseIcon, LogoutIcon, UserIcon } from '../assets/icons';
import { ReactComponent as OperationIcon } from '../assets/icons/navigation/operations.svg';
import { ReactComponent as Hamburger } from '../assets/icons/navigation/menu.svg';
import logo from '../assets/images/logo.png';
import Tooltip from '../components/ui/Tooltip';
import { useAuthContext } from '../context/AuthProvider';
import { useGlobalContext } from '../context/GlobalProvider';
import { useOperationContext } from '../context/OperationProvider';
import Notifications from './Notifications';

const Header = () => {
    const { toggleMenu } = useGlobalContext();
    const { user, logout } = useAuthContext();
    const { operation } = useOperationContext();
    const navigate = useNavigate();

    return (
        <header>
            <div id="header-left">
                <Hamburger onClick={toggleMenu} />
                <Link to="/"><img src={logo} alt="OSO Numérique" id="logo" /></Link>
            </div>
            <div id="header-right">
                {operation && (
                    <div id="header-operation" className="md-hide lg-hide">
                        <Tooltip text={operation.name} position="right"><OperationIcon /></Tooltip>
                    </div>
                )}
                <Notifications />
                {!!user && (
                    <div id="header-profile">
                        <span className="sm-hide">{user.fullName}</span>
                        <UserIcon />
                        <div id="my-profile">
                            <div onClick={() => navigate('/mon-profil')}>
                                <UserIcon />
                                <span>Profil</span>
                            </div>
                            {!!user.managedLicense && (
                                <div onClick={() => navigate('/ma-licence')}>
                                    <LicenseIcon />
                                    <span>Licence</span>
                                </div>
                            )}
                            <div onClick={logout}>
                                <LogoutIcon />
                                <span>Déconnexion</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </header>
    );
}

export default Header;
