import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SampleMasseAnalysis from '../../../components/Analysis/SampleMasseAnalysis';
import Header from '../../../components/ui/Header';
import { IdentityBlockLot } from '../../../components/entities/IdentityBlock';
import './index.scss';
import Button from '../../../components/ui/Button';
import { useOperationContext } from '../../../context/OperationProvider';
import { SampleLabels, SampleType, SampleTypeLabel } from '../../../models/sample';
import { Lot } from '../../../models/lot';
import useRequest from '../../../hooks/useRequest';
import ScrollableContent from '../../../components/ui/ScrollableContent';

const EditMultiple = () => {
    const { operation } = useOperationContext();
    const { lotId, sampleType } = useParams();
    const request = useRequest();
    const navigate = useNavigate();
    const [lot, setLot] = useState<Lot | null>(null)

    useEffect(() => {
        request.get<Lot>(`/lot/${lotId}`, { errorMessage: 'Une erreur est survenue lors de la récupération du lot.', loader: true })
            .then(setLot)
            .catch(() => navigate(-1));
    }, []);

    if (!lot) {
        return (null);
    }

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: operation?.name, href: `/operation/${operation?._id}` },
                    { label: 'Travaux', href: `/operation/${operation?._id}/travaux` },
                    { label: lot?._id ? 'Lot ' + lot?.fullLot : 'Lot', href: `/operation/${operation?._id}/travaux/lot/${lot?._id}/detail` },
                    { label: SampleLabels[sampleType as SampleType].masse }
                ]}
            >
                <Button label="Fermer" onClick={() => navigate(-1)} />
            </Header>
            <IdentityBlockLot lot={lot} />
            <ScrollableContent noPadding>
                <SampleMasseAnalysis
                    roads={operation.roads.filter(r => lot?.roads.includes(r.key))}
                    lot={lot}
                    type={sampleType as SampleType}
                />
            </ScrollableContent>
        </Fragment>
    );
}

export default EditMultiple;