
import { MATERIAL_FORM_PARAMETERS, Material } from '../../models/material';
import Charac from '../Characteristics';
import { IdentityBlockMaterial } from '../entities/IdentityBlock';
import { Modal } from '../ui/Modal';
import './index.scss';

interface ModalMaterialProps {
    material: Material;
    onClose: () => void;
}

const ModalMaterial = ({
    material,
    onClose,
}: ModalMaterialProps) => {

    return (
        <Modal
            title="Détail du matériau"
            className="modal-material"
            actions={[{ label: 'Fermer', color: 'primary', onClick: onClose }]}
        >
            <IdentityBlockMaterial material={material} />
            <Charac
                material={material}
                form={MATERIAL_FORM_PARAMETERS[material.type].characteristics}
                onChange={() => null}
            />
        </Modal>
    )
}

export default ModalMaterial;
