import { Fragment, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChartIcon, CompanyIcon, DashboardIcon, LicenseIcon, RockIcon, SendIcon, SynoptiqueIcon, TimelineIcon, TubeIcon } from '../assets/icons';
import { ReactComponent as CatalogIcon } from '../assets/icons/navigation/catalogue.svg';
import { ReactComponent as CompaniesIcon } from '../assets/icons/navigation/companies.svg';
import { ReactComponent as HomeIcon } from '../assets/icons/navigation/home.svg';
import { ReactComponent as LibraryIcon } from '../assets/icons/navigation/library.svg';
import { ReactComponent as MapIcon } from '../assets/icons/navigation/map.svg';
import { ReactComponent as OperationIcon } from '../assets/icons/navigation/operations.svg';
import { ReactComponent as AdministrationIcon } from '../assets/icons/navigation/settings.svg';
import Collapse from '../components/ui/Collapse';
import { useAuthContext } from '../context/AuthProvider';
import { useGlobalContext } from '../context/GlobalProvider';
import { useOperationContext } from '../context/OperationProvider';
import { Phase } from '../models/operation';
import { Role } from '../models/user';

const Sidebar = () => {
    const location = useLocation();
    const { operation, availablePhases, hasTravaux, operationPermissions } = useOperationContext();
    const { closeMenu } = useGlobalContext();
    const { user, permissions } = useAuthContext();

    const operationNav = useMemo(() => {
        if (!operation._id) return null;

        return (
            <div className="navigation-block">
                <Collapse
                    header={
                        <div className="navigation-item">
                            <OperationIcon className="stroke" />
                            <span>Opération</span>
                        </div>
                    }
                    collapsed={false}
                >
                    {operationPermissions.read && (
                        <Fragment>
                            <Link
                                to={`/operation/${operation._id}/synoptique`}
                                onClick={closeMenu}
                                className={location.pathname.startsWith(`/operation/${operation._id}/synoptique`) || location.pathname === `/operation/${operation._id}` ? 'active' : ''}
                            >
                                <SynoptiqueIcon />
                                <span>Synoptique</span>
                            </Link>
                            <Link
                                to={`/operation/${operation._id}/carte`}
                                onClick={closeMenu}
                                className={location.pathname.startsWith(`/operation/${operation._id}/carte`) ? 'active' : ''}
                            >
                                <MapIcon />
                                <span>Carte</span>
                            </Link>
                        </Fragment>
                    )}
                    {operationPermissions.write && (
                        <Fragment>
                            <Link
                                to={`/operation/${operation._id}/tableau-de-bord`}
                                onClick={closeMenu}
                                className={location.pathname.startsWith(`/operation/${operation._id}/tableau-de-bord`) ? 'active' : ''}
                            >
                                <DashboardIcon />
                                <span>Tableau de bord</span>
                            </Link>
                            <Collapse
                                header={
                                    <div className="navigation-item">
                                        <TimelineIcon />
                                        <span>Phases</span>
                                    </div>
                                }
                            >
                                {availablePhases?.map(p => (
                                    <Link
                                        key={p.key}
                                        to={`/operation/${operation._id}/${p.key}`}
                                        onClick={closeMenu}
                                        className={location.pathname.startsWith(`/operation/${operation._id}/${p.key}`) ? 'active' : ''}
                                    >
                                        <span>{p.label}</span>
                                    </Link>
                                ))}
                            </Collapse>
                        </Fragment>
                    )}
                    {operationPermissions.administrate && hasTravaux && (
                        <Link
                            to={`/operation/${operation._id}/exigences`}
                            onClick={closeMenu}
                            className={location.pathname.startsWith(`/operation/${operation._id}/exigences`) ? 'active' : ''}
                        >
                            <RockIcon />
                            <span>Exigences</span>
                        </Link>
                    )}
                    {operationPermissions.read && (
                        <Collapse
                            header={
                                <div className="navigation-item">
                                    <ChartIcon />
                                    <span>Résultats</span>
                                </div>
                            }
                        >
                            <Link
                                to={`/operation/${operation._id}/resultats/localisation-et-phase`}
                                onClick={closeMenu}
                                className={location.pathname.startsWith(`/operation/${operation._id}/resultats/localisation-et-phase`) ? 'active' : ''}
                            >
                                <span>Par localisation et phase</span>
                            </Link>
                            {(operation?.phases?.includes(Phase.ETUDE) || operation?.phases?.includes(Phase.PREPARATOIRE)) && (
                                <Link
                                    to={`/operation/${operation._id}/resultats/melanges`}
                                    onClick={closeMenu}
                                    className={location.pathname.startsWith(`/operation/${operation._id}/resultats/melanges`) ? 'active' : ''}
                                >
                                    <span>Mélanges</span>
                                </Link>
                            )}
                            {hasTravaux && (
                                <Fragment>
                                    <Link
                                        to={`/operation/${operation._id}/resultats/materiaux`}
                                        onClick={closeMenu}
                                        className={location.pathname.startsWith(`/operation/${operation._id}/resultats/materiau`) ? 'active' : ''}
                                    >
                                        <span>Matériaux</span>
                                    </Link>
                                    <Link
                                        to={`/operation/${operation._id}/resultats/bilan-travaux`}
                                        onClick={closeMenu}
                                        className={location.pathname.startsWith(`/operation/${operation._id}/resultats/bilan-travaux`) ? 'active' : ''}
                                    >
                                        <span>Bilan de travaux</span>
                                    </Link>
                                </Fragment>
                            )}
                        </Collapse>
                    )}
                    {operationPermissions.read && (
                        <Link
                            to={`/operation/${operation._id}/boite-aux-lettres`}
                            onClick={closeMenu}
                            className={location.pathname.startsWith(`/operation/${operation._id}/boite-aux-lettres`) ? 'active' : ''}
                        >
                            <SendIcon />
                            <span>Boîte aux lettres</span>
                        </Link>
                    )}
                </Collapse>
            </div>
        );
    }, [operation, location, operationPermissions, availablePhases, closeMenu, hasTravaux]);

    return (
        <nav id="navigation">
            <div className="navigation-block">
                <Link
                    to="/accueil"
                    onClick={closeMenu}
                    className={location.pathname === '/' || location.pathname === '/accueil' ? 'active' : ''}
                >
                    <HomeIcon />
                    <span>Accueil</span>
                </Link>
            </div>
            {operationNav}
            {permissions.library && (
                <div className="navigation-block">
                    <Collapse
                        header={
                            <div className="navigation-item">
                                <LibraryIcon />
                                <span>Bibliothèque</span>
                            </div>
                        }
                    >
                        <Link
                            to="/bibliotheque/recherche-de-caracteristique"
                            onClick={closeMenu}
                            className={location.pathname === '/bibliotheque/recherche-de-caracteristique' ? 'active' : ''}
                        >
                            <TubeIcon />
                            <span>Caractéristiques</span>
                        </Link>
                        <Link
                            to="/bibliotheque/recherche-de-materiau"
                            onClick={closeMenu}
                            className={location.pathname === '/bibliotheque/recherche-de-materiau' ? 'active' : ''}
                        >
                            <RockIcon />
                            <span>Matériaux</span>
                        </Link>
                    </Collapse>
                    <Link
                        to="/cartographie-du-reseau"
                        onClick={closeMenu}
                        className={location.pathname.startsWith('/cartographie-du-reseau') ? 'active' : ''}
                    >
                        <MapIcon />
                        <span>Cartographie</span>
                    </Link>
                </div>
            )}
            {permissions.administrate && (
                <div className="navigation-block">
                    <Link
                        to="/catalogue"
                        onClick={closeMenu}
                        className={location.pathname.startsWith('/catalogue') ? 'active' : ''}
                    >
                        <CatalogIcon />
                        <span>Catalogue</span>
                    </Link>
                    <Link
                        to="/annuaire"
                        onClick={closeMenu}
                        className={location.pathname.startsWith('/annuaire') ? 'active' : ''}
                    >
                        <CompanyIcon />
                        <span>Annuaire</span>
                    </Link>
                </div>
            )}
            {[Role.SUPER_ADMIN].includes(user.role) && (
                <div className="navigation-block">
                    <Collapse
                        header={
                            <div className="navigation-item">
                                <AdministrationIcon />
                                <span>Administration</span>
                            </div>
                        }
                    >
                        {[Role.SUPER_ADMIN].includes(user.role) && (
                            <Link
                                to="/licences"
                                onClick={closeMenu}
                                className={location.pathname.startsWith('/licenses') ? 'active' : ''}
                            >
                                <LicenseIcon />
                                <span>Licences</span>
                            </Link>
                        )}
                        <Link
                            to="/utilisateurs"
                            onClick={closeMenu}
                            className={location.pathname.startsWith('/utilisateurs') ? 'active' : ''}
                        >
                            <CompaniesIcon />
                            <span>Utilisateurs</span>
                        </Link>
                    </Collapse>
                </div>
            )}
        </nav >
    );
}

export default Sidebar;