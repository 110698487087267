import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List, { FilterType, ListColumns } from '../../../components/data/List';
import Header from '../../../components/ui/Header';
import Navigation from '../../../components/ui/Navigation';
import Tag from '../../../components/ui/Tag';
import { useOperationContext } from '../../../context/OperationProvider';
import { CoucheTypeLabel, CoucheTypes, Lot, Shifts } from '../../../models/lot';
import { Phase } from '../../../models/operation';
import { SampleType } from '../../../models/sample';
import { RevisionStatus, RevisionStatusList } from '../../../models/shared';
import { formatDate } from '../../../utils/format';
import { floatToPrString } from '../../../utils/pr';
import MarkerList from '../Map/MarkerList';
import PopulationList from '../Population/PopulationList';
import SampleList from '../Sample/SampleList';
import ComponentsAnalysisList from '../Components/ComponentAnalysisList';

const PAGES = [
    { key: 'lot', label: 'Lots' },
    { key: 'prelevement', label: 'Prélèvements' },
    { key: 'carotte', label: 'Carottes' },
    { key: 'sondage', label: 'Sondages' },
    { key: 'population', label: 'Populations' },
    { key: 'point-d-interet', label: 'Point d\'intérêt' },
    { key: 'suivi-de-constituants', label: 'Suivi de constituants' }
];

const WorkFollowUp = () => {
    const { operation, isRoadOperation, operationPermissions } = useOperationContext();
    const navigate = useNavigate();
    const [page, setPage] = useState<string | null>(null);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: operation.name, href: `/operation/${operation._id}` }, { label: 'Travaux' }]} />
            <Navigation pages={PAGES} setPage={setPage} />
            {!!page && ['prelevement', 'carotte', 'sondage'].includes(page) && <SampleList type={page as SampleType} phase={Phase.TRAVAUX} />}
            {page === 'population' && <PopulationList />}
            {page === 'lot' && (
                <List<Lot>
                    columns={[
                        { key: 'fullLot', label: 'Lot' },
                        { key: 'status', label: 'Statut', mapper: (element) => <Tag value={element.status} items={RevisionStatusList} /> },
                        ...(isRoadOperation
                            ? [
                                { key: 'way', label: 'Sens', mapper: (element) => operation.waysObj[element.way]?.label },
                                { key: 'roads', label: 'Voie(s)', mapper: (element) => (element.roads ?? []).map(roadId => operation.roads.find(r => r._id === roadId)?.name).filter(r => !!r).join(', ') },
                                { key: 'materialPopulated.name', label: 'Materiau' },
                                { key: 'zone.prStart', label: 'PR début', mapper: (element) => floatToPrString(element.zone?.prStart) },
                                { key: 'zone.prEnd', label: 'PR fin', mapper: (element) => floatToPrString(element.zone?.prEnd) },
                            ] : [
                                { key: 'zone.location', label: 'Localisation' },
                            ]) as ListColumns<Lot>[],
                        { key: 'type', label: 'Type de couche', mapper: (element) => CoucheTypeLabel[element.type] ?? '' },
                        { key: 'thickness', label: 'Epaisseur' },
                        { key: 'date', label: 'Date', mapper: (element) => formatDate(element.date) },
                    ]}
                    dataEndpoint={`/lot/operation/${operation._id}`}
                    crudEndpoint="/lot"
                    baseUrl={`/operation/${operation._id}/travaux/lot`}
                    withSearch
                    actions={{
                        comment: operationPermissions.write,
                        edit: (e) => operationPermissions.write && (operationPermissions.validate || e.status !== RevisionStatus.VALIDATED),
                        activate: operationPermissions.administrate,
                        duplicate: operationPermissions.write,
                        delete: operationPermissions.administrate
                    }}
                    initialPagination={{ sortBy: 'lot', sortDirection: -1 }}
                    onView={(l) => navigate(`/operation/${operation._id}/travaux/lot/${l._id}/detail`)}
                    filters={[
                        { field: 'active', label: 'Actif' },
                        { field: 'status', label: 'Statut', type: FilterType.MULTIPLE_SELECT, items: RevisionStatusList },
                        { field: 'fullLot', label: 'Lot', type: FilterType.MULTIPLE_SELECT, endpoint: `/lot/distinct/${operation._id}` },
                        ...(isRoadOperation
                            ? [
                                { field: 'prMin', label: 'PR minimum', type: FilterType.PR },
                                { field: 'prMax', label: 'PR maximum', type: FilterType.PR },
                                { field: 'roads', label: 'Voies', type: FilterType.MULTIPLE_SELECT, items: operation.roads },
                            ] : []),
                        { field: 'type', label: 'Type de couche', type: FilterType.MULTIPLE_SELECT, items: CoucheTypes },
                        { field: 'material', label: 'Materiau', type: FilterType.MULTIPLE_SELECT, endpoint: `/material/list/product/${operation._id}` },
                        { field: 'shift', label: 'Shift', type: FilterType.SWITCH, items: Shifts },
                    ]}
                    warningBeforeDelete
                    buttons={[{ label: 'Créer un lot', onClick: () => navigate(`/operation/${operation._id}/travaux/lot/creer`) }]}
                />
            )}
            {page === 'point-d-interet' && <MarkerList phase={Phase.TRAVAUX} />}
            {page === 'suivi-de-constituants' && <ComponentsAnalysisList />}
        </Fragment>
    )
}

export default WorkFollowUp;