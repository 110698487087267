import { useCallback, useEffect, useState } from 'react';
import { FormBaseProps } from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { toggleInArray } from '../../utils/objects';
import Checkbox from './Checkbox';

interface CheckboxListItem { key: string; label: string };

interface CheckboxListProps extends FormBaseProps<string[]> {
    items?: CheckboxListItem[];
    disabledItems?: string[];
    endpoint?: string;
}

const CheckboxList = ({
    id,
    value,
    items,
    disabledItems,
    endpoint,
    onChange,
    errors
}: CheckboxListProps) => {
    const [itemsOrData, setItemsOrData] = useState<CheckboxListItem[]>([]);
    const request = useRequest();

    const handleChange = useCallback((item: CheckboxListItem) => {
        const _value = toggleInArray(item.key, value ?? []);

        onChange(_value);
    }, [value, onChange, itemsOrData]);

    useEffect(() => {
        if (endpoint) {
            request.get<CheckboxListItem[]>(endpoint).then(setItemsOrData).catch(() => setItemsOrData([]));
        } else {
            setItemsOrData(items ?? []);
        }
    }, [endpoint, items]);

    return (
        <div className="checkbox-list">
            {!!itemsOrData?.length && itemsOrData.map(item => (
                <Checkbox
                    key={item.key}
                    id={`${id}_${item.key}`}
                    value={!!value?.includes(item.key)}
                    label={item.label}
                    onChange={() => handleChange(item)}
                    disabled={!!disabledItems?.includes(item.key)}
                />
            ))}
            {!!errors?.length && <div className="form-error">{errors.join('. ')}</div>}
        </div>
    )
}

export default CheckboxList;