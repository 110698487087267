import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from '../../../context/AuthProvider';
import { Role } from '../../../models/user';
import './index.scss';

export type NavigationPage = { key: string, label: string, role?: Role };

interface NavigationProps {
    pages: NavigationPage[];
    setPage?: (p: string) => void;
    className?: string;
}

const Navigation = ({
    pages,
    setPage,
    className
}: NavigationProps) => {
    const { page } = useParams();
    const { user } = useAuthContext();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchHistory, setSearchHistory] = useState<any>({});

    const handleClick = (newPage: string) => {
        if (newPage !== page && page) {
            // Keep search history between pages
            const _searchHistory = Object.assign({}, searchHistory);
            _searchHistory[page] = location.search;
            setSearchHistory(_searchHistory);

            const path = location.pathname.split('/');
            path.pop();
            if (setPage) setPage(newPage);
            navigate(`${path.join('/')}/${newPage}${searchHistory[newPage] ?? ''}`, { replace: true });
        }
    }

    useEffect(() => {
        if (!page) {
            if (setPage) setPage(pages[0].key);
            navigate(`${location.pathname}/${pages[0].key}`, { replace: true });
        } else if (!pages?.some(p => p.key === page)) {
            navigate('/erreur/404');
        } else {
            if (setPage) setPage(page);
        }
    }, [page]);

    return (
        <nav className={`navigation ${className ?? ''}`}>
            {pages.filter(p => !p.role || p.role === user.role).map(p => (
                <div key={p.key} className={page === p.key ? 'active' : ''} onClick={() => handleClick(p.key)}>
                    {p.label}
                </div>
            ))}
        </nav>
    )
}

export default Navigation;