import { MinMaxAverage } from "./analysis";
import { Location } from "./location";
import { Lot } from "./lot";
import { RevisionEntity } from "./shared";

export type Population = RevisionEntity & {
    type: PopulationType;
    lot: string;
    lotPopulated?: Lot;
    fullLot: string;
    material: string;
    depth?: number;
    statistics?: MinMaxAverage;
    controle: PopulationControle;
    problematicDataCount: number;
    operation: string;
};

export const isPopulation = (o: any) =>
    o.hasOwnProperty("problematicDataCount");

export type PopulationSynoptique = {
    type: PopulationType;
    fullLot: string;
    lot: string;
    material: string;
    location: Location;
    problematic: boolean;
    value: number;
};

export type Data = {
    location: Location;
    value: number;
    problematic: boolean;
};

export type PopulationData = {
    laboratory?: string;
    date?: Date;
    statistics?: MinMaxAverage;
    compliance95?: boolean;
    compliance100?: boolean;
    problematicDataCount: number;
    data?: Data[];
};

export type PopulationControle = {
    externe?: PopulationData;
    exterieur?: PopulationData;
    autre?: PopulationData;
};

export enum PopulationType {
    PMT = "pmt",
    PERMEABILITE = "permeabilite",
    VIDE = "vide",
}

export const PopulationTypeLabel = {
    [PopulationType.VIDE]: "Pourcentage de vide",
    [PopulationType.PERMEABILITE]: "Perméabilité",
    [PopulationType.PMT]: "PMT",
};

export const PopulationColor = {
    [PopulationType.VIDE]: "#d5e5a1",
    [PopulationType.PERMEABILITE]: "#d3eaf0",
    [PopulationType.PMT]: "#f2c8a2",
};

export const PopulationDataHeader = {
    [PopulationType.VIDE]: "% vide",
    [PopulationType.PERMEABILITE]: "VP (cm/s)",
    [PopulationType.PMT]: "PMT",
};

export const PopulationTypes = [
    {
        key: PopulationType.VIDE,
        label: PopulationTypeLabel[PopulationType.VIDE],
    },
    {
        key: PopulationType.PERMEABILITE,
        label: PopulationTypeLabel[PopulationType.PERMEABILITE],
    },
    { key: PopulationType.PMT, label: PopulationTypeLabel[PopulationType.PMT] },
];
