import { Fragment } from 'react';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning.svg';
import Tooltip from '../../../../components/ui/Tooltip';
import { useOperationContext } from '../../../../context/OperationProvider';
import { PhaseLabel, Phases, RoadPositionLabel } from '../../../../models/operation';
import { ControleLabel, Controles, Sample, SampleTypeLabel } from '../../../../models/sample';
import { LayerColors } from '../../../../synoptique/Synoptique.class';
import { formatDate, formatNumberToFixedDecimal } from '../../../../utils/format';
import { floatToPrString } from '../../../../utils/pr';
import { sampleTypeIcon } from '../../../../components/entities/IdentityCard';
import Tag from '../../../../components/ui/Tag';

interface SampleCardProps {
    sample: Sample;
    onSelectSample: (_id: string) => void;
    thickness: number;
    active: boolean;
}

const SampleCard = ({
    sample,
    onSelectSample,
    thickness,
    active
}: SampleCardProps) => {
    const { operation, isRoadOperation } = useOperationContext();

    return (
        <div className={`sample-card ${active ? 'active' : ''}`} onClick={() => onSelectSample(sample._id)}>
            <div className="sample-layers">
                {!!sample.layers?.length && sample.layers.map((layer, i) => (
                    <div key={i} style={!!layer.thickness ? { backgroundColor: LayerColors[i], height: Math.floor(layer.thickness * 100 / thickness) + '%' } : { backgroundColor: '#c2c3c9', height: '100%' }}>{!!layer.problematic && <WarningIcon />}</div>
                ))}
                {!!sample.thickness && <span className="sample-thickness">{formatNumberToFixedDecimal(sample.thickness)} cm</span>}
            </div>
            <div className="sample-details">
                <div className="sample-name"><Tooltip text={SampleTypeLabel[sample.type]}>{sampleTypeIcon(sample)}</Tooltip>{sample.name}</div>
                <div className="sample-date">{formatDate(sample.date)}</div>
                <Tag value={sample.phase} items={Phases} />
                <Tag value={sample.controle} items={Controles} />
                {isRoadOperation
                    ? (
                        <Fragment>
                            <div className="sample-detail-group">
                                <label>PR</label>
                                <div>{floatToPrString(sample.location.pr)}</div>
                            </div>
                            <div className="sample-detail-group">
                                <label>Voie</label>
                                <div>{sample.location.road ? operation.roadsObj?.[sample.location.road]?.name : ''}</div>
                            </div>
                            <div className="sample-detail-group">
                                <label>Empl.</label>
                                <div>{sample.location.roadPosition ? RoadPositionLabel[sample.location.roadPosition] : ''}</div>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="sample-detail-group">
                                <label>Repère kilom.</label>
                                <div>{sample.location.pr ? floatToPrString(sample.location.pr) : ''}</div>
                            </div>
                            <div className="sample-detail-group">
                                <label>Localisation</label>
                                <div>{sample.location.location ?? ''}</div>
                            </div>
                        </Fragment>
                    )}
                {sample.fullLot &&
                    <div className="sample-detail-group">
                        <label>Lot</label>
                        <div>{sample.fullLot}</div>
                    </div>
                }
            </div>
        </div>
    );
}

export default SampleCard;