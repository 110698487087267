import React, { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/ui/Header';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/ui/Button';
import { useGlobalContext } from '../../context/GlobalProvider';
import useForm, { FormComparator } from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { UserProfile } from '../../models/user';
import Password from '../../components/inputs/Password';
import { useAuthContext } from '../../context/AuthProvider';
import Card from '../../components/ui/Card';
import ScrollableContent from '../../components/ui/ScrollableContent';
import SelectAutocomplete from '../../components/inputs/SelectAutocomplete';

const VALIDATION = {
    firstName: [{ comparator: FormComparator.REQUIRED }],
    lastName: [{ comparator: FormComparator.REQUIRED }],
    email: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.EMAIL }],
};

const Profile = () => {
    const { setToastError } = useGlobalContext();
    const { user, getMe } = useAuthContext();
    const request = useRequest();
    const { attachInput, validate } = useForm<UserProfile>(user);
    const navigate = useNavigate();

    const handleSubmit = useCallback(() => {
        const entity = validate(VALIDATION);

        if (!entity) return;

        if (entity.newPassword && entity.newPassword !== entity.newPasswordRepeat) {
            setToastError('Les mots de passe ne correspondent pas');
        } else if (
            entity.newPassword
            && (
                !entity.newPassword.match(/[A-Z]/)
                || !entity.newPassword.match(/[a-z]/)
                || !entity.newPassword.match(/[0-9]/)
                || !entity.newPassword.match(/.{8,32}/)
            )
        ) {
            setToastError('Le mot de passe n\'est pas au bon format');
        } else {
            request.put('/user/me', entity, { successMessage: 'Mise à jour du profil effectuée avec succès', loader: true })
                .then(() => getMe())
                .then(() => navigate(-1))
                .catch((e) => {
                    if ((e as any)?.error?.status === 409) {
                        setToastError('Cet email est déjà utilisée par un utilisateur');
                    } else if ((e as any)?.error?.status === 401) {
                        setToastError('Le mot de passe est incorrect');
                    } else {
                        setToastError('Une erreur est survenue lors de l\'enregistrement.', e);
                    }
                })
        }
    }, [validate, getMe]);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Mon compte' }]} >
                <Button
                    label="Enregistrer"
                    onClick={handleSubmit}
                />
            </Header>

            <ScrollableContent>
                <Card title="Identification">

                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="firstName">Prénom *</label>
                            <TextInput {...attachInput('firstName')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="firstName">Nom *</label>
                            <TextInput {...attachInput('lastName')} />
                        </div>
                    </div>
                    <div className="input-column">
                        <label htmlFor="email">Email *</label>
                        <TextInput {...attachInput('email')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="business">Entreprise *</label>
                        <SelectAutocomplete endpoint="/business/list" {...attachInput('business')} />
                    </div>
                </Card>
                <Card title="Mot de passe">
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="password">Mot de passe actuel *</label>
                            <Password {...attachInput('password')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="newPassword">Nouveau mot de passe *</label>
                            <Password withProgressBar withWarnings {...attachInput('newPassword')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="newPasswordRepeat">Répéter le mot de passe *</label>
                            <Password {...attachInput('newPasswordRepeat')} />
                        </div>
                    </div>
                </Card>
            </ScrollableContent>
        </Fragment >
    )
}

export default Profile;
