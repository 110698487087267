import { useEffect, useMemo, useState } from 'react';
import { useOperationContext } from '../../context/OperationProvider';
import useRequest from '../../hooks/useRequest';
import { Lot } from '../../models/lot';
import { floatToPrString } from '../../utils/pr';
import { IdentityCardLot } from '../entities/IdentityCard';
import SearchInput from '../inputs/SearchInput';
import { Modal } from '../ui/Modal';
import './index.scss';

interface ModalSearchLotProps {
    onSubmit: (lot: Lot) => void;
    onClose: () => void;
}

const ModalSearchLot = ({
    onSubmit,
    onClose,
}: ModalSearchLotProps) => {
    const { operation } = useOperationContext();
    const request = useRequest();
    const [lots, setLots] = useState<(Lot & { searchToken: string })[]>([]);
    const [selectedLot, setSelectedLot] = useState<Lot | null>(null);
    const [filter, setFilter] = useState<string | undefined>();

    const lotsFiltered = useMemo(() => {
        if (!filter) {
            return lots;
        } else {
            const regex = new RegExp('.*' + filter.replace('+', ' ').split(' ').join('.*') + '.*', 'gi');
            return lots.filter(l => regex.test(l.searchToken));
        }
    }, [lots, filter]);

    useEffect(() => {
        request.get<Lot[]>(`/lot/all/operation/${operation._id}`, { loader: true })
            .then((lots) => {
                setLots(lots.map(l => ({ ...l, searchToken: l.fullLot + l.materialPopulated?.name + floatToPrString(l.zone.prStart) + floatToPrString(l.zone.prEnd) })));
            })
            .catch(() => onClose());
    }, []);

    return (
        <Modal
            title="Sélection de lot"
            className="modal-search"
            size="small"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', disabled: !selectedLot, onClick: selectedLot ? () => onSubmit(selectedLot) : undefined },
            ]}
        >
            <SearchInput
                id="lot-search-input"
                placeholder="Rechercher un lot..."
                value={filter}
                onChange={setFilter}
            />
            <div className="modal-search-list">
                {lotsFiltered?.map(l => (
                    <div key={l._id} className={l._id === selectedLot?._id ? 'active' : ''} onClick={() => setSelectedLot(l)}>
                        <IdentityCardLot key={l._id} entity={l} />
                    </div>
                ))}
            </div>
        </Modal>
    )
}

export default ModalSearchLot;
