import { MouseEvent, useCallback, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import './index.scss';
import { MenuIcon } from '../../../assets/icons';

interface OptionsMenuProps {
    onEdit?: () => void;
    onDelete?: () => void;
    onDuplicate?: () => void;
}

const OptionsMenu = ({
    onDelete,
    onDuplicate,
    onEdit,
}: OptionsMenuProps) => {
    const [isOpened, setOpened] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => setOpened(false), []));

    const handleClick = (event: MouseEvent<HTMLDivElement>, callback: () => void) => {
        event.stopPropagation();
        callback();
        setOpened(false);
    }

    return (
        <div className="options-menu">
            <MenuIcon onClick={(event) => { event.stopPropagation(); setOpened(true); }} />
            <div className={`options-menu-content ${isOpened ? 'visible' : ''}`} ref={ref}>
                {!!onEdit && <div onClick={(event) => handleClick(event, onEdit)}>Editer</div>}
                {!!onDuplicate && <div onClick={(event) => handleClick(event, onDuplicate)}>Dupliquer</div>}
                {!!onDelete && <div onClick={(event) => handleClick(event, onDelete)}>Supprimer</div>}
            </div>
        </div>
    );
}

export default OptionsMenu;