import { Marker, Polygon } from '@react-google-maps/api';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OptionsIcon } from '../../../assets/icons';
import carotteMarker from '../../../assets/icons/map/carotteMarker.png';
import carotteMarkerProblematic from '../../../assets/icons/map/carotteMarkerProblematic.png';
import markerMarker from '../../../assets/icons/map/markerMarker.png';
import populationMarker from '../../../assets/icons/map/populationMarker.png';
import populationMarkerProblematic from '../../../assets/icons/map/populationMarkerProblematic.png';
import prelevementMarker from '../../../assets/icons/map/prelevementMarker.png';
import prelevementMarkerProblematic from '../../../assets/icons/map/prelevementMarkerProblematic.png';
import sondageMarker from '../../../assets/icons/map/sondageMarker.png';
import sondageMarkerProblematic from '../../../assets/icons/map/sondageMarkerProblematic.png';
import SampleDetail from '../../../components/SampleDetail';
import { ListFilters } from '../../../components/data/List';
import Map, { ANCHOR, MapAction, SCALED_SIZE } from '../../../components/data/Map/Map';
import IdentityCard from '../../../components/entities/IdentityCard';
import Button from '../../../components/ui/Button';
import Header from '../../../components/ui/Header';
import { ModalRight } from '../../../components/ui/Modal/ModalRight';
import { useOperationContext } from '../../../context/OperationProvider';
import useDebounce from '../../../hooks/useDebounce';
import useRequest from '../../../hooks/useRequest';
import { Coordinates } from '../../../models/location';
import { Lot } from '../../../models/lot';
import { Phase } from '../../../models/operation';
import { PopulationSynoptique } from '../../../models/population';
import { Sample, SampleType } from '../../../models/sample';
import { SynoptiqueElements } from '../../../synoptique/Synoptique.class';
import { filtersToQueryParam } from '../../../utils/format';
import { SynoptiqueFilters } from '../Synoptique';
import Filters from '../Synoptique/components/Filters';
import MarkerDetail from './MarkerDetail';
import './index.scss';

const INITIAL_FILTERS_TYPE_1: ListFilters = {
    populationDisplay: true,
    sampleDisplay: true,
    populationProblematic: true,
    sampleProblematic: true,
};

const INITIAL_FILTERS_TYPE_2: ListFilters = {
    populationDisplay: true,
    sampleDisplay: true,
    populationProblematic: true,
};

const OperationMap = () => {
    const { operation, isRoadOperation, availablePhases } = useOperationContext();
    const request = useRequest();
    const [elements, setElements] = useState<SynoptiqueElements>({});
    const [isPanelVisible, setPanelVisible] = useState<boolean>(false);
    const [activeFilters, setActiveFilters] = useState<ListFilters>({});
    const [hoverElement, setHoverElement] = useState<Sample | Lot | PopulationSynoptique | null>(null);
    const [selectedSample, setSelectedSample] = useState<string | null>(null);
    const [selectedMarker, setSelectedMarker] = useState<string | null>(null);
    useDebounce(() => getElements(), 600, [activeFilters]);
    const navigate = useNavigate();

    const getElements = useCallback(async () => {
        request.get<SynoptiqueElements>(`/operation/${operation?._id}/elements`, {
            params: { ...filtersToQueryParam({ ...activeFilters }) },
            errorMessage: 'Une erreur est survenue lors de la récupération des éléments',
            loader: true
        })
            .then(setElements)
            .catch(() => {
                setElements({});
            });
    }, [activeFilters]);

    const handleActionComplete = (a: MapAction, marker: Coordinates) => {
        if (a === MapAction.MARKER) {
            navigate(`/operation/${operation._id}/${operation.phases[0]}/point-d-interet/creer?latitude=${marker.latitude}&longitude=${marker.longitude}`);
        }
    }

    useEffect(() => {
        setActiveFilters(isRoadOperation ? INITIAL_FILTERS_TYPE_1 : INITIAL_FILTERS_TYPE_2);
    }, [operation]);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: operation.name, href: `/operation/${operation._id}` }, { label: 'Carte' }]}>
                <Button
                    color="black"
                    onClick={() => setPanelVisible(!isPanelVisible)}
                    icon={<OptionsIcon />}
                    label="Filtrer"
                />
            </Header>
            <div className="container" id="operation-map">
                <MapWithElements
                    elements={elements}
                    onAction={handleActionComplete}
                    setSelectedSample={setSelectedSample}
                    setSelectedMarker={setSelectedMarker}
                    setHoverElement={setHoverElement}
                    onPhaseChange={(phase) => setActiveFilters({ ...activeFilters, phase })}
                    phases={Array.isArray(activeFilters?.phase) ? activeFilters.phase.map(p => p as Phase) : []}
                />
                {!!hoverElement && (
                    <div id="hover-element-container">
                        <IdentityCard entity={hoverElement} />
                    </div>
                )}
                <SampleDetail id={selectedSample ?? undefined} onClose={() => setSelectedSample(null)} />
                <MarkerDetail id={selectedMarker ?? undefined} onClose={() => setSelectedMarker(null)} />
                <ModalRight
                    visible={!!isPanelVisible}
                    className="synoptique-filters-panel"
                    actions={[
                        { label: 'Réinitialiser', color: 'secondary', onClick: () => setActiveFilters(isRoadOperation ? { ...INITIAL_FILTERS_TYPE_1 } : { ...INITIAL_FILTERS_TYPE_2 }) },
                        { label: 'Fermer', onClick: () => setPanelVisible(false) },
                    ]}
                >
                    <Filters
                        filters={activeFilters as SynoptiqueFilters}
                        withProblematic={!isRoadOperation}
                        onChange={(filters) => setActiveFilters(filters as ListFilters)}
                    />
                </ModalRight >
            </div>
        </Fragment>
    )
}

interface MapWithElementsProps {
    elements: SynoptiqueElements;
    onAction: (a: MapAction, marker: Coordinates, polygon?: Coordinates[] | undefined) => void;
    setSelectedSample: (id: string) => void;
    setSelectedMarker: (id: string) => void;
    setHoverElement: (e: Lot | Sample | PopulationSynoptique | null) => void;
    phases: Phase[];
    onPhaseChange: (p: Phase[]) => void;
}

const MapWithElements = ({
    elements,
    onAction,
    setSelectedSample,
    setSelectedMarker,
    setHoverElement,
    phases,
    onPhaseChange
}: MapWithElementsProps) => {

    const markers = useMemo(() => {
        const sampleMarkers = (elements.samples ?? [])
            .filter(s => s.location?.coordinates?.latitude && s.location?.coordinates?.longitude)
            .map(s => (
                <Marker
                    key={'sample' + s._id}
                    icon={{
                        url: s.problematic
                            ? s.type === SampleType.CAROTTE ? carotteMarkerProblematic : (s.type === SampleType.PRELEVEMENT ? prelevementMarkerProblematic : sondageMarkerProblematic)
                            : s.type === SampleType.CAROTTE ? carotteMarker : (s.type === SampleType.PRELEVEMENT ? prelevementMarker : sondageMarker),
                        scaledSize: SCALED_SIZE,
                        anchor: ANCHOR
                    }}
                    onClick={() => setSelectedSample(s._id)}
                    onMouseOver={() => setHoverElement(s)}
                    onMouseOut={() => setHoverElement(null)}
                    position={{ lat: s.location?.coordinates!.latitude, lng: s.location?.coordinates!.longitude }}
                />
            ));

        const markerMarkers = (elements.markers ?? [])
            .filter(s => s.location?.coordinates?.latitude && s.location?.coordinates?.longitude)
            .map(s => (
                <Marker
                    key={'marker' + s._id}
                    icon={{
                        url: markerMarker,
                        scaledSize: SCALED_SIZE,
                        anchor: ANCHOR
                    }}
                    onClick={() => setSelectedMarker(s._id)}
                    position={{ lat: s.location?.coordinates!.latitude, lng: s.location?.coordinates!.longitude }}
                />
            ));

        const populationMarkers = (elements.populations ?? [])
            .filter(s => s.location?.coordinates?.latitude && s.location?.coordinates?.longitude)
            .map((s, i) => (
                <Marker
                    key={'population' + i}
                    icon={{
                        url: s.problematic ? populationMarkerProblematic : populationMarker,
                        scaledSize: SCALED_SIZE,
                        anchor: ANCHOR
                    }}
                    onMouseOver={() => setHoverElement(s)}
                    onMouseOut={() => setHoverElement(null)}
                    position={{ lat: s.location?.coordinates!.latitude, lng: s.location?.coordinates!.longitude }}
                />
            ));

        const lotPolygons = (elements.lots ?? [])
            .filter(s => !!s.zone?.polygon)
            .map((s) => (
                <Polygon
                    key={'lot' + s._id}
                    paths={s.zone?.polygon!.map(c => ({ lat: c.latitude, lng: c.longitude }))}
                    onMouseOver={() => setHoverElement(s)}
                    onMouseOut={() => setHoverElement(null)}
                    options={{
                        strokeWeight: 2,
                        strokeColor: '#7bb1c1',
                        fillColor: '#7bb1c1',
                        fillOpacity: 0.3,
                        clickable: true,
                    }}
                />
            ));
        return [...sampleMarkers, ...markerMarkers, ...populationMarkers, ...lotPolygons];
    }, [elements]);

    return (
        <div id="map">
            <Map
                withUserPosition
                withZone
                availableActions={[MapAction.MARKER, MapAction.POSITION]}
                onActionComplete={onAction}
                phases={phases}
                onPhaseChange={onPhaseChange}
            >
                {markers}
            </Map>
        </div>
    );
}

export default OperationMap;