import { ReactNode, useCallback, useState } from "react";
import { InfoSquareIcon } from "../../../assets/icons";
import { Modal } from "../Modal";
import './index.scss';
import { useGlobalContext } from "../../../context/GlobalProvider";


interface ModalHelperProps {
    text: string;
}

const ModalHelper = ({ text }: ModalHelperProps) => {
    const { setHelper } = useGlobalContext();

    return (
        <div className="modal-helper-icon">
            <InfoSquareIcon onClick={() => setHelper(text)} />
        </div>
    );
}

interface ModalHelperModalProps {
    text?: string;
    onClose: () => void;
}

export const ModalHelperModal = ({ text, onClose }: ModalHelperModalProps) => !!text ? (
    <Modal title="Informations" actions={[{ label: "Fermer", color: "secondary", onClick: onClose }]} size="small">
        <div id="modal-helper-text">{text}</div>
    </Modal>
) : (null);

export default ModalHelper;