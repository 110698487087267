import { useCallback, useEffect } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { CloseIcon, UploadIcon } from '../../../assets/icons';
import useRequest from '../../../hooks/useRequest';
import { StorageFile } from '../../../models/storage';
import './index.scss';

interface DropZoneProps {
    direction?: 'row' | 'column';
    files: StorageFile[];
    onChange: (files: StorageFile[]) => void;
    maxFiles?: number;
}

const DropZone = ({
    direction = 'row',
    files,
    maxFiles,
    onChange
}: DropZoneProps) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ maxFiles: maxFiles ?? 1 });
    const request = useRequest();

    const handleUpload = useCallback(async (filesWithPath: FileWithPath[]) => {
        if (!filesWithPath?.length) return;
        let data = new FormData();
        for (const file of filesWithPath) {
            data.append('files[]', file);
        }

        request.post<StorageFile[]>('/storage', data, { headers: { 'content-type': 'multipart/form-data' }, loader: true, errorMessage: 'Une erreur est survenue pendant le téléversement' })
            .then((data) => onChange([...files, ...data]))
            .catch(() => null);
    }, [onChange, files]);

    const handleDelete = useCallback(async (file: StorageFile) => {
        request.delete<StorageFile>(`/storage/${file._id}`, { loader: true, errorMessage: 'Une erreur est survenue pendant la suppression' })
            .then(() => onChange(files.filter(s => s._id !== file._id)))
            .catch(() => null);
    }, [onChange, files]);

    useEffect(() => {
        if (maxFiles === undefined || files.length < maxFiles) {
            handleUpload(acceptedFiles);
        }
    }, [acceptedFiles]);

    return (
        <div className="dropzone-container">
            {(maxFiles === undefined || files.length < maxFiles) && (
                <div {...getRootProps({ className: `dropzone ${direction}` })}>
                    <input {...getInputProps()} />
                    <div><UploadIcon />Glissez votre fichier ici</div>
                    <span>ou</span>
                    <span>recherchez un fichier</span>
                </div>
            )}
            {!!files.length && (
                <div className="dropzone-files">
                    {files.map(s => <span key={s._id} className="dropzone-file">{s.name}<CloseIcon onClick={() => handleDelete(s)} /></span>)}
                </div>
            )}
        </div>
    )
}

export default DropZone;