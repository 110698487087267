import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddBlackIcon, SaveIcon } from "../../assets/icons";
import SmallList, { SmallListAction } from "../../components/data/SmallList";
import { IdentityBlockLicense, IdentityBlockOperation } from "../../components/entities/IdentityBlock";
import UserSearchModal from "../../components/entities/UserSearchModal";
import Button from "../../components/ui/Button";
import Card from "../../components/ui/Card";
import Header from "../../components/ui/Header";
import Menu from "../../components/ui/Menu";
import ScrollableContent from "../../components/ui/ScrollableContent";
import { useAuthContext } from "../../context/AuthProvider";
import { useGlobalContext } from "../../context/GlobalProvider";
import useForm from "../../hooks/useForm";
import useRequest from "../../hooks/useRequest";
import { License, PermissionActive, QuotaCurrent } from "../../models/license";
import { Permissions, User } from "../../models/user";
import './index.scss';
import { IdentityCardOperation } from "../../components/entities/IdentityCard";

interface LicenseForUserProps {

}

const LicenseForUser = ({ }: LicenseForUserProps) => {
    const { getMe } = useAuthContext();
    const request = useRequest();
    const navigate = useNavigate();
    const { entity, onChangeMultiple, setChanged, setEntity, hasChanged } = useForm<License>({ active: true });
    const [isSearchModalVisible, setSearchModalVisible] = useState<boolean>(false);

    const permissions: PermissionActive[] = useMemo(() => Permissions.map(p => ({ _id: p.label, active: !!entity.permissions?.includes(p.key) })), [entity]);
    const quotas: QuotaCurrent[] = useMemo(() => [
        { _id: 'Operations', quota: entity?.operationsQuota ?? 0 },
        { _id: 'Utilisateurs par opération', quota: entity?.usersPerOperationQuota ?? 0 },
    ], [entity]);

    const handleSubmit = useCallback(async (close?: boolean) => {
        request.post<License>('/license/users', { _id: entity._id, users: entity.users }, {
            successMessage: 'Licence mise à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then(() => {
                // refresh user and license
                getMe();
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                }
            })
            .catch(() => null);
    }, [entity]);

    useEffect(() => {
        request.get<License>('/license/me', { errorMessage: 'Une erreur est survenue lors de la récupération de la license', loader: true })
            .then(setEntity)
            .catch(() => navigate(-1))
    }, []);

    if (!entity) return (null);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Ma licence' }]}>
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={<SaveIcon />}
                            onClick={handleSubmit}
                        />
                        <Button
                            label="Enregistrer et fermer"
                            icon={<SaveIcon />}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            <IdentityBlockLicense license={entity} />
            <ScrollableContent
                side={
                    <Card title="Permissions">
                        {!!entity.permissions?.length && (
                            <div className="input-column">
                                <label htmlFor="permissions">Permissions</label>
                                <SmallList<PermissionActive>
                                    data={permissions}
                                    columns={[{ key: '_id', label: 'Permission' }, { key: 'active', label: '', mapper: (e) => e.active ? 'Oui' : 'Non' }]}
                                />
                            </div>
                        )}
                        <div className="input-column">
                            <label htmlFor="permissions">Quotas</label>
                            <SmallList<QuotaCurrent>
                                data={quotas}
                                columns={[{ key: '_id', label: 'Permission' }, { key: 'quota', label: 'Limite' }]}
                            />
                        </div>
                    </Card>
                }
            >
                {!!entity.operationsPopulated?.length && (
                    <Card title="Opérations">
                        {entity.operationsPopulated?.map(o => <IdentityBlockOperation key={o._id} operation={o} onView={() => navigate(`/operation/${o._id}`)} />)}
                    </Card>
                )}
            </ScrollableContent>
        </Fragment >
    )
}

export default LicenseForUser;