import { CoordinatesXY } from "../models/location";
import { Lot } from "../models/lot";
import { Marker } from "../models/marker";
import { Melange, MelangeType } from "../models/melange";
import { Exit, Operation } from "../models/operation";
import { PopulationSynoptique } from "../models/population";
import { Sample } from "../models/sample";
import { isType } from "../utils/objects";
import Canvas, { CanvasStyle } from "./Canvas.class";

export enum SynoptiqueAction {
    SELECT_ONE = "select_one",
    SELECT_ONE_BY_ONE = "select_one_by_one",
    SELECT_MELANGE = "select_melange",
    SELECT_MULTIPLE_MOVE = "select_multiple_move",
    SELECT_MULTIPLE = "select_multiple",
    SELECT_LAYER = "select_layer",
    SELECT_EXIT = "select_exit",
    HOVER_ELEMENTS = "hover_elements",
    INIT_ELEMENTS = "init_elements",
    ZOOM_IN = "zoom_in",
    ZOOM_OUT = "zoom_out",
    PRINT = "print",
}

export enum Direction {
    TOP = "top",
    RIGHT = "right",
    BOTTOM = "bottom",
    LEFT = "left",
}

export enum SynoptiqueMarker {
    MALADIE = "maladie",
    REPERAGE = "reperage",
}

export const LayerColors = [
    "#010508",
    "#397d92",
    "#d94032",
    "#faa97a",
    "#f2c8a2",
    "#d3eaf0",
    "#84a31f",
    "#d5e5a1",
    "#a30e09",
    "#f0c6c5",
];

export type ActionDoneElements = (
    | SampleForSynoptique
    | MarkerForSynoptique
    | LotForSynoptique
    | PopulationForSynoptique
    | MelangeForSynoptique
    | ExitForSynoptique
)[];

export interface ActionDoneResult {
    elements?: ActionDoneElements;
    firstPosition?: CoordinatesXY;
    selectionDiv?: {
        top: number;
        left: number;
        width: number;
        height: number;
    };
}

export type ActionDoneType = (
    a: SynoptiqueAction,
    result: ActionDoneResult
) => void;

export type SampleForSynoptique = Sample & {
    x: number;
    y: number;
    roadName: string;
    roadPositionIndex: number;
};
export type MarkerForSynoptique = Marker & {
    x: number;
    y: number;
    roadName: string;
    roadPositionIndex: number;
};
export type PopulationForSynoptique = PopulationSynoptique & {
    x: number;
    y: number;
    roadName: string;
    roadPositionIndex: number;
};
export type LotForSynoptique = Lot & {
    x: number;
    y: number;
    width: number;
    height: number;
    polygon: CoordinatesXY[];
};
export type MelangeForSynoptique = Melange & { polygon: CoordinatesXY[] };

export interface SynoptiqueElements {
    populations?: PopulationSynoptique[];
    samples?: Sample[];
    lots?: Lot[];
    markers?: Marker[];
    melanges?: Melange[];
}
interface SynoptiqueElementsLocalized {
    populations?: PopulationForSynoptique[];
    samples?: SampleForSynoptique[];
    lots?: LotForSynoptique[];
    markers?: MarkerForSynoptique[];
    melanges?: MelangeForSynoptique[];
}

export type ExitForSynoptique = Exit & {
    x: number;
    y: number;
    width: number;
    height: number;
};

export interface SelectionDiv {
    isMoving?: boolean;
    startX: number;
    startY: number;
    x: number;
    y: number;
    width: number;
    height: number;
}

class Synoptique extends Canvas {
    baseStyle: { [key: string]: Partial<CanvasStyle> } = {
        grid: { strokeStyle: "#f0f0f5", lineWidth: 1 },
        legend: {
            strokeStyle: "#000000",
            fillStyle: "#000000",
            font: "bold 12px Roboto",
            lineWidth: 1,
        },
        sample: {
            strokeStyle: "#000000",
            fillStyle: "#000000",
            lineWidth: 2,
            lineCap: "butt",
        },
        sampleHighlighted: {
            strokeStyle: "#397d92",
            fillStyle: "#397d92",
            lineWidth: 2,
            lineCap: "butt",
        },
        sampleProblematic: {
            strokeStyle: "#d94032",
            fillStyle: "#d94032",
            lineWidth: 2,
            lineCap: "butt",
        },
        sampleProblematicHighlighted: {
            strokeStyle: "#faa97a",
            fillStyle: "#faa97a",
            lineWidth: 2,
            lineCap: "butt",
        },
        melange: {
            strokeStyle: "#000000",
            fillStyle: "#000000",
            lineWidth: 1,
            lineCap: "butt",
            lineDash: [],
        },
        melangeProblematic: {
            strokeStyle: "#d94032",
            fillStyle: "#d94032",
            lineWidth: 1,
            lineCap: "butt",
            lineDash: [],
        },
        scrollBar: {
            strokeStyle: "#dfdfe5",
            fillStyle: "#dfdfe5",
            lineWidth: 3,
            lineCap: "round",
        },
        scrollCursor: {
            strokeStyle: "#0c2c40",
            fillStyle: "#0c2c40",
            lineWidth: 3,
            lineCap: "round",
        },
        lot: {
            strokeStyle: "#7bb1c1",
            fillStyle: "rgba(57, 125, 146, 0.2)",
            lineWidth: 1,
        },
        lotSelected: {
            strokeStyle: "#d94032",
            fillStyle: "rgba(217, 64, 50, 0.2)",
            lineWidth: 1,
        },
        lotTextRect: {
            strokeStyle: "#7bb1c1",
            fillStyle: "rgba(255, 255, 255, 0.8)",
            font: "12px Roboto",
            lineWidth: 1,
        },
        lotText: { font: "bold 12px Roboto", fillStyle: "#7bb1c1" },
        lotSelectedText: { font: "12px Roboto", fillStyle: "#d94032" },
    };

    // Grid square dimensions
    grid = { width: 18, height: 18 };

    // Icons size for samples
    sampleIconSize = 4;

    // Melange closeness limit
    melangeCloseness = 10;

    // Current action
    action: SynoptiqueAction | null = SynoptiqueAction.SELECT_ONE;
    onActionDone: ActionDoneType;

    // Elements to display and filters
    elements: SynoptiqueElementsLocalized = {};
    selectedSamples: (SampleForSynoptique | MarkerForSynoptique)[] = [];
    selectedLayers: Lot[] = [];
    highlightedSample: string | null = null;

    // Position of first element to move synoptique to on init
    firstElementPosition: CoordinatesXY | null = null;

    // Synoptique dimensions (without margins or zoom)
    synoptique = { width: 0, height: 0 };

    // Margins
    margin = { top: 40, right: 40, bottom: 40, left: 40 };

    // Zoom
    zoom = {
        x: 1,
        y: 1,
    };
    zoomStep = 1.3;

    // Zoom limit (browser canvas size limit)
    zoomLimit = 62000;

    // Exists
    exits: ExitForSynoptique[] = [];

    // Selection div
    selectionDiv: SelectionDiv | null = null;

    protected operation: Operation;

    constructor(
        canvasRef: HTMLCanvasElement,
        operation: Operation,
        onActionDone: ActionDoneType
    ) {
        super(canvasRef);

        this.operation = operation;
        this.onActionDone = onActionDone;

        this.canvasRef.addEventListener(
            "mousedown",
            (e) => this.handleClickStart(e),
            false
        );
        this.canvasRef.addEventListener(
            "touchstart",
            (e) => this.handleClickStart(e),
            false
        );
        this.canvasRef.addEventListener(
            "mouseup",
            (e) => this.handleClickEnd(e),
            false
        );
        this.canvasRef.addEventListener(
            "touchend",
            (e) => this.handleClickEnd(e),
            false
        );
        this.canvasRef.addEventListener(
            "mousemove",
            (e) => this.handleClickMove(e),
            false
        );
        this.canvasRef.addEventListener(
            "touchmove",
            (e) => this.handleClickMove(e),
            false
        );
    }

    // Get cursor in synoptique coordinates (without margin and zoom)
    protected getCursorRealPosFromEvent(event: MouseEvent | TouchEvent) {
        const pos = this.getCursorPosFromEvent(event);
        return {
            x: (pos.x - this.margin.left) / this.zoom.x,
            y: (pos.y - this.margin.top) / this.zoom.y,
        };
    }

    protected handleClickStart(event: MouseEvent | TouchEvent) {
        const cursor = this.getCursorRealPosFromEvent(event);
        let selectedSample:
            | SampleForSynoptique
            | MarkerForSynoptique
            | undefined;
        switch (this.action) {
            case SynoptiqueAction.SELECT_MULTIPLE:
                event.preventDefault();
                this.startSelectionDiv(event);
                if (this.selectionDiv) {
                    this.onActionDone(SynoptiqueAction.SELECT_MULTIPLE_MOVE, {
                        selectionDiv: {
                            top: this.withZoomAndMarginY(this.selectionDiv.y),
                            left: this.withZoomAndMarginX(this.selectionDiv.x),
                            width: this.withZoomX(this.selectionDiv.width),
                            height: this.withZoomY(this.selectionDiv.height),
                        },
                    });
                }
                break;
            case SynoptiqueAction.SELECT_ONE:
                event.preventDefault();
                const filteredSamples = [
                    ...(this.elements.samples ?? []),
                    ...(this.elements.markers ?? []),
                ].filter((e) =>
                    this.isAroundClick(
                        cursor.x,
                        cursor.y,
                        e.x,
                        e.y,
                        this.sampleIconSize / this.zoom.x,
                        this.sampleIconSize / this.zoom.y
                    )
                );
                if (filteredSamples?.length > 1) {
                    // Loop through samples on top of each other if multiple found
                    let indexSelectedSample = filteredSamples.findIndex(
                        (s) => s._id === this.selectedSamples?.[0]?._id
                    );
                    if (indexSelectedSample === filteredSamples.length - 1) {
                        indexSelectedSample = -1;
                    }

                    if (indexSelectedSample < filteredSamples.length - 1) {
                        selectedSample =
                            filteredSamples[indexSelectedSample + 1];
                        this.onActionDone(SynoptiqueAction.SELECT_ONE, {
                            elements: [selectedSample],
                        });
                        this.selectedSamples = [selectedSample];
                        this.render();
                    }
                } else if (filteredSamples?.length === 1) {
                    selectedSample = filteredSamples[0];
                    this.onActionDone(SynoptiqueAction.SELECT_ONE, {
                        elements: [selectedSample],
                    });
                    this.selectedSamples = [selectedSample];
                    this.render();
                }
                break;
            case SynoptiqueAction.SELECT_ONE_BY_ONE:
                event.preventDefault();
                selectedSample = this.elements.samples?.find((e) =>
                    this.isAroundClick(
                        cursor.x,
                        cursor.y,
                        e.x,
                        e.y,
                        this.sampleIconSize / this.zoom.x,
                        this.sampleIconSize / this.zoom.y
                    )
                );
                if (selectedSample) {
                    const index = this.selectedSamples?.findIndex(
                        (s) => s._id === selectedSample!._id
                    );
                    if (index >= 0) {
                        this.selectedSamples.splice(index, 1);
                    } else {
                        this.selectedSamples.push(selectedSample);
                    }
                    this.onActionDone(SynoptiqueAction.SELECT_ONE_BY_ONE, {
                        elements: this.selectedSamples,
                    });
                    this.render();
                }
                break;
            case SynoptiqueAction.SELECT_MELANGE:
                event.preventDefault();
                selectedSample = [
                    ...(this.elements.samples ?? []),
                    ...(this.elements.markers ?? []),
                ].find((e) =>
                    this.isAroundClick(
                        cursor.x,
                        cursor.y,
                        e.x,
                        e.y,
                        this.sampleIconSize / this.zoom.x,
                        this.sampleIconSize / this.zoom.y
                    )
                );
                if (
                    selectedSample &&
                    isType<SampleForSynoptique>(selectedSample, (s) =>
                        s.hasOwnProperty("layers")
                    )
                ) {
                    // Find if one layer has a melange
                    const layer = selectedSample.layers?.find((l) => l.melange);
                    if (layer) {
                        const melangeId = layer.melange;
                        this.selectedSamples =
                            this.elements.samples?.filter((e) =>
                                e.layers?.some((l) => l.melange === melangeId)
                            ) ?? [];
                        this.onActionDone(SynoptiqueAction.SELECT_MELANGE, {
                            elements: this.selectedSamples,
                        });
                        this.render();
                    }
                }
                break;
            case SynoptiqueAction.SELECT_LAYER:
                event.preventDefault();
                const selectedLayers = this.elements.lots?.filter((l) =>
                    l.zone.polygonXY
                        ? this.isInsidePolygon(cursor, l.zone.polygonXY)
                        : this.containsClick(
                              cursor.x,
                              cursor.y,
                              l.x,
                              l.y,
                              l.width,
                              l.height
                          )
                );

                if (selectedLayers) {
                    this.onActionDone(SynoptiqueAction.SELECT_LAYER, {
                        elements: selectedLayers,
                    });
                    this.selectedLayers = selectedLayers;
                    this.render();
                }
                break;
            default:
        }
    }

    protected handleClickEnd(event: MouseEvent | TouchEvent) {
        switch (this.action) {
            case SynoptiqueAction.SELECT_MULTIPLE:
                if (this.selectionDiv?.isMoving) {
                    event.preventDefault();
                    this.selectedSamples =
                        this.elements.samples?.filter((e) =>
                            this.isInSelectionDiv(e.x, e.y)
                        ) ?? [];
                    this.onActionDone(SynoptiqueAction.SELECT_MULTIPLE, {
                        elements: this.selectedSamples,
                    });
                    this.render();
                    this.selectionDiv.isMoving = false;
                }
                break;
            default:
                const realPos = this.getCursorPosFromEvent(event);
                const selectedExit = this.exits.find((e) =>
                    this.containsClick(
                        realPos.x,
                        realPos.y,
                        e.x,
                        e.y - 16,
                        e.width,
                        e.height
                    )
                );

                if (selectedExit?.operation) {
                    this.onActionDone(SynoptiqueAction.SELECT_EXIT, {
                        elements: [selectedExit],
                    });
                }
        }
    }

    protected handleClickMove(event: MouseEvent | TouchEvent) {
        const cursor = this.getCursorRealPosFromEvent(event);
        switch (this.action) {
            case SynoptiqueAction.SELECT_MULTIPLE:
                if (this.selectionDiv?.isMoving) {
                    event.preventDefault();
                    this.moveSelectionDiv(event);
                    this.onActionDone(SynoptiqueAction.SELECT_MULTIPLE_MOVE, {
                        selectionDiv: {
                            top: this.withZoomAndMarginY(this.selectionDiv.y),
                            left: this.withZoomAndMarginX(this.selectionDiv.x),
                            width: this.withZoomX(this.selectionDiv.width),
                            height: this.withZoomY(this.selectionDiv.height),
                        },
                    });
                }
                break;
            case SynoptiqueAction.SELECT_ONE:
            case SynoptiqueAction.SELECT_ONE_BY_ONE:
                const samples = [
                    ...(this.elements.samples ?? []),
                    ...(this.elements.markers ?? []),
                ].filter((e) =>
                    this.isAroundClick(
                        cursor.x,
                        cursor.y,
                        e.x,
                        e.y,
                        this.sampleIconSize / this.zoom.x,
                        this.sampleIconSize / this.zoom.y
                    )
                );
                this.onActionDone(SynoptiqueAction.HOVER_ELEMENTS, {
                    elements: samples,
                });
                break;
            case SynoptiqueAction.SELECT_LAYER:
                const lots = this.elements.lots?.filter((e) =>
                    e.zone.polygonXY
                        ? this.isInsidePolygon(cursor, e.zone.polygonXY)
                        : this.containsClick(
                              cursor.x,
                              cursor.y,
                              e.x,
                              e.y,
                              e.width,
                              e.height
                          )
                );
                this.onActionDone(SynoptiqueAction.HOVER_ELEMENTS, {
                    elements: lots,
                });
                break;
            default:
                if (!this.action) {
                    const hoverSamples = [
                        ...(this.elements.samples ?? []),
                        ...(this.elements.markers ?? []),
                    ].filter((e) =>
                        this.isAroundClick(
                            cursor.x,
                            cursor.y,
                            e.x,
                            e.y,
                            this.sampleIconSize / this.zoom.x,
                            this.sampleIconSize / this.zoom.y
                        )
                    );
                    const hoverLots = (this.elements.lots ?? []).filter((e) =>
                        e.zone.polygonXY
                            ? this.isInsidePolygon(cursor, e.zone.polygonXY)
                            : this.containsClick(
                                  cursor.x,
                                  cursor.y,
                                  e.x,
                                  e.y,
                                  e.width,
                                  e.height
                              )
                    );
                    this.onActionDone(SynoptiqueAction.HOVER_ELEMENTS, {
                        elements: [...hoverSamples, ...hoverLots],
                    });
                }
        }
    }

    render() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.sizeSynoptique();
    }

    protected sizeSynoptique() {
        this.canvas = {
            width: Math.floor(
                this.withZoomX(this.synoptique.width) +
                    this.margin.left +
                    this.margin.right
            ),
            height: Math.floor(
                this.withZoomY(this.synoptique.height) +
                    this.margin.top +
                    this.margin.bottom
            ),
        };

        // Set synoptique DOM dimensions
        this.canvasRef.style.height = this.canvas.height + "px";
        this.canvasRef.style.width = this.canvas.width + "px";

        super.sizeCanvas();
    }

    protected startSelectionDiv(event: MouseEvent | TouchEvent) {
        const realCursorPos = this.getCursorRealPosFromEvent(event);
        this.selectionDiv = {
            isMoving: true,
            startX: realCursorPos.x,
            startY: realCursorPos.y,
            x: realCursorPos.x,
            y: realCursorPos.y,
            width: 0,
            height: 0,
        };
    }

    protected moveSelectionDiv(event: MouseEvent | TouchEvent) {
        const realCursorPos = this.getCursorRealPosFromEvent(event);

        if (
            this.selectionDiv?.startX !== undefined &&
            this.selectionDiv.startY !== undefined
        ) {
            this.selectionDiv = {
                ...this.selectionDiv,
                x:
                    realCursorPos.x < this.selectionDiv.startX
                        ? realCursorPos.x
                        : this.selectionDiv.startX,
                y:
                    realCursorPos.y < this.selectionDiv.startY
                        ? realCursorPos.y
                        : this.selectionDiv.startY,
                width: Math.abs(realCursorPos.x - this.selectionDiv.startX),
                height: Math.abs(realCursorPos.y - this.selectionDiv.startY),
            };
        }
    }

    public clearSelections() {
        this.onActionDone(SynoptiqueAction.SELECT_MULTIPLE, { elements: [] });

        this.selectionDiv = null;
        this.selectedSamples = [];
        this.selectedLayers = [];
        this.highlightedSample = null;
    }

    public setElements(elements: SynoptiqueElements) {
        this.localizeElements(elements);
        this.render();
        this.onActionDone(SynoptiqueAction.INIT_ELEMENTS, {
            firstPosition: this.firstElementPosition ?? undefined,
        });
    }

    public getScrollFromPr(pr: number): number {
        return 0;
    }

    protected localizeElements(elements: SynoptiqueElements) {}

    public highlightElement(_id: string) {
        this.highlightedSample = _id;
        this.render();
    }

    public setAction(action: SynoptiqueAction | null) {
        this.action = action;

        if (action === SynoptiqueAction.ZOOM_IN) {
            this.zoomIn();
        } else if (action === SynoptiqueAction.ZOOM_OUT) {
            this.zoomOut();
        } else {
            this.clearSelections();
        }

        this.render();
    }

    protected isInSelectionDiv(x: number, y: number) {
        if (this.selectionDiv) {
            return (
                x >= this.selectionDiv.x &&
                y >= this.selectionDiv.y &&
                x <= this.selectionDiv.x + this.selectionDiv.width &&
                y <= this.selectionDiv.y + this.selectionDiv.height
            );
        }
        return false;
    }

    protected zoomIn() {
        this.zoom.x *= this.zoomStep;
        this.zoom.y *= this.zoomStep;

        const newCanvas = {
            width: Math.floor(
                this.withZoomX(this.synoptique.width) +
                    this.margin.left +
                    this.margin.right
            ),
            height: Math.floor(
                this.withZoomY(this.synoptique.height) +
                    this.margin.top +
                    this.margin.bottom
            ),
        };

        if (
            newCanvas.height > this.zoomLimit ||
            newCanvas.width > this.zoomLimit
        ) {
            this.zoom.x /= this.zoomStep;
            this.zoom.y /= this.zoomStep;
        }
    }

    protected zoomOut() {
        this.zoom.x /= this.zoomStep;
        this.zoom.y /= this.zoomStep;
    }

    protected withZoomX(x: number) {
        return x * this.zoom.x;
    }

    protected withZoomY(y: number) {
        return y * this.zoom.y;
    }

    protected withZoomXY({ x, y }: CoordinatesXY) {
        return {
            x: this.withZoomX(x),
            y: this.withZoomX(y),
        };
    }

    protected withZoomAndMarginX(x: number) {
        return this.margin.left + this.withZoomX(x);
    }

    protected withZoomAndMarginY(y: number) {
        return this.margin.top + this.withZoomY(y);
    }

    protected withZoomAndMarginXY({ x, y }: CoordinatesXY) {
        return {
            x: this.withZoomAndMarginX(x),
            y: this.withZoomAndMarginY(y),
        };
    }

    protected drawSample(sample: SampleForSynoptique) {
        const selected = this.selectedSamples?.some(
            (s) => s._id === sample._id
        );
        if (sample.type === "carotte") {
            this.drawCarotte(
                sample.x,
                sample.y,
                selected,
                sample._id === this.highlightedSample,
                sample.problematic
            );
        } else if (sample.type === "prelevement") {
            this.drawPrelevement(
                sample.x,
                sample.y,
                selected,
                sample._id === this.highlightedSample,
                sample.problematic
            );
        } else if (sample.type === "sondage") {
            this.drawSondage(
                sample.x,
                sample.y,
                selected,
                sample._id === this.highlightedSample,
                sample.problematic
            );
        }
    }

    protected drawCarotte(
        x: number,
        y: number,
        selected: boolean = false,
        highlighted: boolean = false,
        problematic: boolean = false
    ) {
        this.setStyle(
            this.baseStyle[
                `sample${problematic ? "Problematic" : ""}${
                    highlighted ? "Highlighted" : ""
                }`
            ]
        );

        this.drawRect(
            this.withZoomAndMarginX(x) - this.sampleIconSize,
            this.withZoomAndMarginY(y) - this.sampleIconSize,
            this.sampleIconSize * 2,
            this.sampleIconSize * 2,
            true,
            selected || highlighted
        );
    }

    protected drawSondage(
        x: number,
        y: number,
        selected: boolean = false,
        highlighted: boolean = false,
        problematic: boolean = false
    ) {
        this.setStyle(
            this.baseStyle[
                `sample${problematic ? "Problematic" : ""}${
                    highlighted ? "Highlighted" : ""
                }`
            ]
        );
        this.drawCircle(
            this.withZoomAndMarginX(x),
            this.withZoomAndMarginY(y),
            this.sampleIconSize,
            true,
            selected || highlighted
        );
    }

    protected drawPrelevement(
        x: number,
        y: number,
        selected: boolean = false,
        highlighted: boolean = false,
        problematic: boolean = false
    ) {
        const realX = this.withZoomAndMarginX(x);
        const realY = this.withZoomAndMarginY(y);
        this.setStyle(
            this.baseStyle[
                `sample${problematic ? "Problematic" : ""}${
                    highlighted ? "Highlighted" : ""
                }`
            ]
        );
        this.drawPolygon(
            [
                { x: realX, y: realY - this.sampleIconSize },
                {
                    x: realX - this.sampleIconSize,
                    y: realY + this.sampleIconSize,
                },
                {
                    x: realX + this.sampleIconSize,
                    y: realY + this.sampleIconSize,
                },
            ],
            true,
            selected || highlighted
        );
    }

    protected drawMarker(
        x: number,
        y: number,
        selected: boolean = false,
        highlighted: boolean = false,
        problematic: boolean = false
    ) {
        const realX = this.withZoomAndMarginX(x);
        const realY = this.withZoomAndMarginX(y);
        this.setStyle(
            this.baseStyle[
                `sample${problematic ? "Problematic" : ""}${
                    highlighted ? "Highlighted" : ""
                }`
            ]
        );
        this.drawCircle(
            realX,
            realY,
            this.sampleIconSize,
            true,
            selected || highlighted
        );
        this.drawLine(
            realX,
            realY - this.sampleIconSize - 2,
            realX,
            realY + this.sampleIconSize + 2
        );
        this.drawLine(
            realX + this.sampleIconSize + 2,
            realY,
            realX - this.sampleIconSize - 2,
            realY
        );
    }

    protected drawMelange(
        polygon: CoordinatesXY[],
        problematic: boolean = false,
        type: MelangeType
    ) {
        if (polygon?.length) {
            this.setStyle(
                this.baseStyle[`melange${problematic ? "Problematic" : ""}`]
            );
            let offset = -3;

            if (type === "melange") {
                this.ctx.setLineDash([4, 4]);
                offset = -1;
            } else if (type === "liant") {
                this.ctx.setLineDash([4, 4, 4]);
                offset = 1;
            } else if (type === "itsr") {
                this.ctx.setLineDash([12, 3, 3]);
                offset = 3;
            }

            for (let i = 1; i < polygon.length; i++) {
                const point1 =
                    polygon[i - 1].x < polygon[i].x
                        ? polygon[i - 1]
                        : polygon[i];
                const point2 =
                    polygon[i - 1].x < polygon[i].x
                        ? polygon[i]
                        : polygon[i - 1];

                // If samples to close to each other, draw a circle
                if (
                    point2.x - point1.x < this.melangeCloseness / this.zoom.x &&
                    Math.abs(point2.y - point1.y) <
                        this.melangeCloseness / this.zoom.y
                ) {
                    this.drawCircle(
                        this.withZoomAndMarginX(
                            point1.x + (point2.x - point1.x) / 2
                        ),
                        this.withZoomAndMarginY(
                            point1.y < point2.y
                                ? point1.y + (point2.y - point1.y) / 2
                                : point2.y + (point1.y - point2.y) / 2
                        ),
                        this.sampleIconSize * 2.5,
                        true
                    );
                    continue;
                }

                this.drawLine(
                    this.withZoomAndMarginX(point1.x) + this.sampleIconSize,
                    this.withZoomAndMarginY(point1.y) + offset,
                    this.withZoomAndMarginX(point2.x) - this.sampleIconSize,
                    this.withZoomAndMarginY(point2.y) + offset
                );
            }
        }
    }

    protected drawPopulation(x: number, y: number, problematic?: boolean) {
        const realX = this.withZoomAndMarginX(x);
        const realY = this.withZoomAndMarginY(y);
        this.setStyle(
            problematic
                ? this.baseStyle.sampleProblematic
                : this.baseStyle.sample
        );
        this.drawLine(
            realX - this.sampleIconSize,
            realY - this.sampleIconSize,
            realX + this.sampleIconSize,
            realY + this.sampleIconSize
        );
        this.drawLine(
            realX + this.sampleIconSize,
            realY - this.sampleIconSize,
            realX - this.sampleIconSize,
            realY + this.sampleIconSize
        );
    }
}

export default Synoptique;
