import {
    FilterType,
    ListFilter,
    ListFilterSetting,
    ListFilters,
} from "../components/data/List";
import { RoadPosition } from "../models/operation";
import { Pagination } from "../models/pagination";
import { User } from "../models/user";
import { SynoptiqueFilterMinMaxLayer } from "../pages/Operation/Synoptique";
import { isArray } from "./objects";

export const formatTimestamp = (
    timestamp: number,
    separator: string = "/",
    format: string = "dd/mm/yyyy"
) => formatDate(new Date(timestamp), separator, format);

export const formatTimestampToLocalString = (timestamp: number): string =>
    new Date(timestamp).toLocaleString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
export const formatTimestampToFullLocalString = (timestamp: number): string =>
    new Date(timestamp).toLocaleString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });

export const formatDate = (
    date: Date,
    separator: string = "/",
    format: string = "dd/mm/yyyy"
) => {
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    const year = String(date.getFullYear());
    month = month.length < 2 ? (month = "0" + month) : month;
    day = day.length < 2 ? (day = "0" + day) : day;

    if (format === "yyyy/mm/dd") {
        return `${year}${separator}${month}${separator}${day}`;
    } else if (format === "full") {
        return date.toLocaleDateString("fr", {
            minute: "2-digit",
            hour: "2-digit",
        });
    } else {
        return `${day}${separator}${month}${separator}${year}`;
    }
};

export const formatStringDate = (
    dateString: string,
    separator: string = "/",
    format: string = "dd/mm/yyyy"
) => {
    const date = new Date(dateString);
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    const year = String(date.getFullYear());
    month = month.length < 2 ? (month = "0" + month) : month;
    day = day.length < 2 ? (day = "0" + day) : day;

    if (format === "yyyy/mm/dd") {
        return `${year}${separator}${month}${separator}${day}`;
    } else {
        return `${day}${separator}${month}${separator}${year}`;
    }
};

/**
 * Formatted date by dd/mm/yyyy
 * @param isoString
 */
export const isoStringToDate = (isoString?: string) => {
    if (!isoString) return;

    let isoStringSplit = isoString.split(/\D+/);
    return new Date(
        Date.UTC(
            Number(isoStringSplit[0]),
            Number(isoStringSplit[1]) - 1,
            Number(isoStringSplit[2]),
            Number(isoStringSplit[3]),
            Number(isoStringSplit[4]),
            Number(isoStringSplit[5]),
            Number(isoStringSplit[6])
        )
    );
};

export const stringDateToDate = (stringDate: string) => {
    const stringDateSplit = stringDate.split("/");
    return new Date(
        Number(stringDateSplit[2]),
        Number(stringDateSplit[1]) - 1,
        Number(stringDateSplit[0])
    );
};
export const addToDate = (
    date: Date,
    unit: "year" | "month" | "day",
    n: number
) =>
    new Date(
        unit === "year" ? date.getFullYear() + n : date.getFullYear(),
        unit === "month" ? date.getMonth() + n : date.getMonth(),
        1
    );

export const getMonthInfo = (
    y: number,
    m: number
): { year: number; month: number; nbDays: number; firstDay: number } => {
    let infos = { year: y, month: m };
    if (m > 11) {
        infos.year = y + 1;
        infos.month = 0;
    } else if (m < 0) {
        infos.year = y - 1;
        infos.month = 11;
    }
    return {
        ...infos,
        nbDays: 40 - new Date(infos.year, infos.month, 40).getDate(),
        firstDay: (new Date(infos.year, infos.month).getDay() + 6) % 7,
    };
};

export const formatNumberToFixedDecimal = (
    num?: number,
    decimals: number = 2
): string =>
    num !== null && num !== undefined && !isNaN(num)
        ? num.toFixed(decimals)
        : "--";

export const getInitials = (user?: User) =>
    user
        ? `${user.firstName?.charAt(0) ?? ""}${user.lastName?.charAt(0) ?? ""}`
        : "";

export const getFullName = (user?: User) =>
    user
        ? user.fullName ??
          `${user.firstName ? user.firstName + " " : ""}${user.lastName ?? ""}`
        : "Utilisateur supprimé";

export const formatNumber = (value: any) => {
    if (!value || !isNaN(value)) return Number(value);

    return Number(("0" + value).replace(",", "."));
};

export const formatRoadPosition = (value: string) => {
    if (!value || value in RoadPosition) {
        return value;
    }

    const valueLower = value.toLowerCase();
    if (valueLower.includes("dr")) {
        return RoadPosition.ROUE_DROITE;
    } else if (valueLower.includes("ga")) {
        return RoadPosition.ROUE_GAUCHE;
    } else if (valueLower.includes("ax")) {
        return RoadPosition.AXE;
    } else {
        return "VALEUR INCORRECTE";
    }
};

export const buildSearchParams = (params: { [k: string]: any }): string => {
    const searchParams = new URLSearchParams();

    for (const key in params) {
        if (Array.isArray(params[key])) {
            params[key].forEach((p: any) =>
                searchParams.append(key, String(p))
            );
        } else {
            searchParams.append(key, String(params[key]));
        }
    }
    return searchParams.toString();
};

export const filtersFromSearchParams = (
    URLSearchParams: URLSearchParams,
    filtersSettings: ListFilterSetting[] | undefined
): ListFilters => {
    const filters: ListFilters = { active: true };
    for (const filter of filtersSettings ?? []) {
        if (filter.field === "active" || filter.type === FilterType.SWITCH) {
            const value = URLSearchParams.get(filter.field);

            if (filter.field === "active" && !value) continue;
            if (value === null) continue;

            filters[filter.field] =
                value === "true" ? true : value === "false" ? false : undefined;
        } else if (filter.type === FilterType.MULTIPLE_SELECT) {
            const value = URLSearchParams.getAll(filter.field);
            if (value === null || !value.length) continue;

            filters[filter.field] = value;
        } else {
            const value = URLSearchParams.get(filter.field);

            if (value === null) continue;

            filters[filter.field] =
                value !== null && !isNaN(Number(value))
                    ? Number(value)
                    : undefined;
        }
    }
    return filters;
};

export const paginationFromSearchParams = (
    URLSearchParams: URLSearchParams
): Partial<Pagination> => {
    const pagination: Partial<Pagination> = {};

    if (
        URLSearchParams.get("page") !== null &&
        !isNaN(Number(URLSearchParams.get("page")))
    ) {
        pagination.page = Number(URLSearchParams.get("page"));
    }
    if (
        URLSearchParams.get("perPage") !== null &&
        !isNaN(Number(URLSearchParams.get("perPage")))
    ) {
        pagination.perPage = Number(URLSearchParams.get("perPage"));
    }
    if (URLSearchParams.get("sortBy")) {
        pagination.sortBy = URLSearchParams.get("sortBy")!;
    }
    if (
        URLSearchParams.get("sortDirection") !== null &&
        !isNaN(Number(URLSearchParams.get("sortDirection")))
    ) {
        pagination.sortDirection = Number(URLSearchParams.get("sortDirection"));
    }

    return pagination;
};

export const filtersToQueryParam = (filters: ListFilters) => {
    const params: {
        [field: string]:
            | undefined
            | string
            | number
            | boolean
            | string[]
            | SynoptiqueFilterMinMaxLayer;
    } = { active: true };
    for (const field in filters) {
        const filter = filters[field];
        params[field] = filter === "indifferent" ? undefined : filter;
    }
    return params;
};

export const getStringValue = (obj?: any): string => {
    if (obj === undefined || obj === null) return "";
    if (obj.hasOwnProperty("toString")) return obj.toString();
    if (Array.isArray(obj)) return obj.map(getStringValue).join(",");

    try {
        return String(obj);
    } catch {
        return "Err!";
    }
};
