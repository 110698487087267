import { useEffect, useState } from 'react';
import { ModalBottom } from '../../../components/ui/Modal/ModalBottom';
import Select from '../../../components/inputs/Select';
import TextInput from '../../../components/inputs/TextInput';
import Button from '../../../components/ui/Button';
import IconLink from '../../../components/ui/IconLink';
import { FormComparator, validateAll } from '../../../hooks/useForm';
import { Road, RoadDto, RoadType, RoadTypes, Way, WayDto } from '../../../models/operation';
import { changeFieldValue } from '../../../utils/objects';
import PrInput from '../../../components/inputs/PrInput';

const INITIAL_ROAD = {
    active: true,
    type: RoadType.VOIE,
};

export type RoadWithSections = { order: number, sections: Partial<RoadDto>[] }

interface RoadFormProps {
    road: RoadWithSections;
    way: WayDto;
    onClose: () => void;
    onSubmit: (r: RoadWithSections) => void;
}

const RoadForm = ({
    road,
    way,
    onClose,
    onSubmit
}: RoadFormProps) => {
    const [sections, setSections] = useState<Partial<Road>[]>([]);
    const [errors, setErrors] = useState<{ [k: string]: string[] } | null>(null);
    const roadPrRules = [
        { comparator: FormComparator.REQUIRED },
        { comparator: FormComparator.PR },
        { comparator: FormComparator.PR_INSIDE, compareTo: { min: way?.prStart, max: way?.prEnd }, message: 'Le PR est hors des limites du sens' }
    ];

    const handleChange = (id: string, value: any, index: number) => {
        if (index >= 0 && index < sections?.length) {
            const _sections = [...sections];
            _sections[index] = changeFieldValue(_sections[index], id, value);
            setSections(_sections);
        }
    }

    const handleSubmit = () => {
        const _errors: { [k: string]: string[] } = {};
        let _hasError = false;

        for (let i = 0; i < sections?.length ?? 0; i++) {
            let [sectionErrors, sectionHasError] = validateAll(sections[i], {
                name: [{ comparator: FormComparator.REQUIRED }],
                type: [{ comparator: FormComparator.REQUIRED }],
                prStart: roadPrRules,
                prEnd: roadPrRules,
            });

            _errors['name' + i] = sectionErrors.name;
            _errors['type' + i] = sectionErrors.type;
            _errors['prStart' + i] = sectionErrors.prStart;
            _errors['prEnd' + i] = sectionErrors.prEnd;
            _hasError = _hasError || !!sectionHasError;

            if (
                !!sections[i].name
                && (
                    way.roads?.some(r => r.order !== road.order && r.name === sections[i].name)
                    || sections.some((s, index) => index !== i && s.name === sections[i].name)
                )
            ) {
                _errors['name' + i] = ['Une voie avec ce nom existe déjà'];
                _hasError = true;
            }
        }

        if (!_hasError) {
            onSubmit({ order: road.order, sections });
        }
        setErrors(_errors);
    }

    useEffect(() => {
        setSections(road?.sections?.length
            ? [...road.sections]
            : [{ ...INITIAL_ROAD, prStart: way?.prStart, prEnd: way?.prEnd }]
        );
    }, [road]);

    return (
        <ModalBottom
            visible={road !== null}
            title={road?.order === -1 ? 'Créer une nouvelle voie' : 'Modifier la voie'}
            className="modal-road"
            onClose={onClose}
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', onClick: handleSubmit },
            ]}
        >
            {sections.map((section, index) => (
                <div className="row" key={index}>
                    <div className="input-column">
                        <label htmlFor={'name' + index}>Nom *</label>
                        <TextInput
                            id={'name' + index}
                            value={section.name ?? ''}
                            placeholder="Nom de la voie"
                            onChange={(value) => handleChange('name', value, index)}
                            errors={errors?.['name' + index]}
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor={'type' + index}>Type de voie</label>
                        <Select
                            id={'type' + index}
                            value={section.type ?? ''}
                            items={RoadTypes}
                            onChange={(value) => handleChange('type', value, index)}
                            errors={errors?.['type' + index]}
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor={'prStart' + index}>PR de début *</label>
                        <PrInput
                            id={'prStart' + index}
                            value={section.prStart}
                            onBlurValidationError={roadPrRules}
                            onBlurValidationWarning={[{ comparator: FormComparator.PR_EQUAL, compareTo: way?.prStart, message: 'Le PR est différent de celui du sens' }]}
                            onChange={(value) => handleChange('prStart', value, index)}
                            errors={errors?.['prStart' + index]}
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor={'prEnd' + index}>PR de fin *</label>
                        <PrInput
                            id={'prEnd' + index}
                            value={section.prEnd}
                            onBlurValidationError={roadPrRules}
                            onBlurValidationWarning={[{ comparator: FormComparator.PR_EQUAL, compareTo: way?.prEnd, message: 'Le PR est différent de celui du sens' }]}
                            onChange={(value) => handleChange('prEnd', value, index)}
                            errors={errors?.['prEnd' + index]}
                        />
                    </div>
                </div>
            ))}
            <IconLink type="add" label="Ajouter une section" onClick={() => setSections([...sections, { ...INITIAL_ROAD }])} />
        </ModalBottom >
    );
}

export default RoadForm;
