import { Zone } from "./location";
import { Material } from "./material";
import { RevisionEntity } from "./shared";

export type Lot = RevisionEntity & {
    lot: number;
    subLot?: number;
    fullLot: string;
    type: CoucheType;
    material: string;
    materialPopulated?: Material;
    operation: string;
    position: number;
    date: Date;
    shift?: Shift;
    zone: Zone;
    thickness: number;
    way: number;
    roads: string[];
};

export const isLot = (o: any) => o.hasOwnProperty("position");

export enum CoucheType {
    ROULEMENT = "roulement",
    LIAISON = "liaison",
    ASSISE = "assise",
}

export const CoucheTypeLabel: Record<CoucheType, string> = {
    [CoucheType.ROULEMENT]: "Roulement",
    [CoucheType.LIAISON]: "Liaison",
    [CoucheType.ASSISE]: "Assise",
};

export const CoucheTypes = [
    { key: CoucheType.ROULEMENT, label: CoucheTypeLabel[CoucheType.ROULEMENT] },
    { key: CoucheType.LIAISON, label: CoucheTypeLabel[CoucheType.LIAISON] },
    { key: CoucheType.ASSISE, label: CoucheTypeLabel[CoucheType.ASSISE] },
];

export enum Shift {
    JOUR = "jour",
    NUIT = "nuit",
}

export const ShiftLabel: { [key in Shift]: string } = {
    [Shift.JOUR]: "Jour",
    [Shift.NUIT]: "Nuit",
};

export const Shifts = [
    { key: Shift.JOUR, label: ShiftLabel[Shift.JOUR] },
    { key: Shift.NUIT, label: ShiftLabel[Shift.NUIT] },
];
