import { ReactNode } from 'react';
import { CloseIcon } from '../../../assets/icons';
import Button, { ButtonProps } from '../Button';

interface ModalBottomProps {
    visible?: boolean;
    children: ReactNode;
    title?: string;
    className?: string;
    onClose?: () => void;
    actions?: ButtonProps[];
}

export const ModalBottom = ({
    visible,
    children,
    title,
    className,
    actions,
    onClose
}: ModalBottomProps) => (
    <div className={`modal-bottom-backdrop ${visible ? 'visible' : ''}`}>
        <div className={`modal-bottom ${className ?? ''}`}>
            {!!onClose && <CloseIcon onClick={onClose} />}
            {title && <div className="modal-bottom-title">{title}</div>}
            <div className="modal-bottom-content">
                {children}
            </div>
            {!!actions?.length && (
                <div className="modal-bottom-footer">
                    {actions?.map(a => <Button key={a.label} {...a} />)}
                </div>
            )}
        </div>
    </div>
);
