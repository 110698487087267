import { useCallback, useState } from 'react';
import { ReactComponent as RemoveIcon } from '../../assets/icons/close-o.svg';
import { MaterialCharacteristic, MaterialCharacteristicElement } from '../../models/material';
import NumberInput from '../inputs/NumberInput';
import Dropdown from '../ui/Dropdown';
import { Modal } from '../ui/Modal';
import './index.scss';

export interface CharacSearchParams {
    min?: number;
    max?: number;
    seuil?: number;
    tolerance?: number
}

export interface SelectedMaterialCharacteristicElement extends MaterialCharacteristicElement {
    searchParams: CharacSearchParams;
    groupIndex: number;
    elementIndex: number;
}

interface CharacAddRemoveProps {
    availableCharacs: MaterialCharacteristic[];
    setAvailableCharacs: (a: MaterialCharacteristic[]) => void;
    selectedCharacs: SelectedMaterialCharacteristicElement[];
    setSelectedCharacs: (s: SelectedMaterialCharacteristicElement[]) => void;
    withTolerance?: boolean;
}

const CharacAddRemove = ({
    availableCharacs,
    setAvailableCharacs,
    selectedCharacs,
    setSelectedCharacs,
    withTolerance = false
}: CharacAddRemoveProps) => {
    const [selectedElement, setSelectedElement] = useState<SelectedMaterialCharacteristicElement | null>(null);
    const [searchParams, setSearchParams] = useState<CharacSearchParams>({});
    const [error, setError] = useState<string | null>(null);

    const handleChange = useCallback((key: 'min' | 'max' | 'seuil' | 'tolerance', v?: number) => {
        setSearchParams(searchParams => ({ ...searchParams, [key]: v }));
    }, []);

    const handleSelectElement = () => {
        if (searchParams?.min === undefined && searchParams?.max === undefined) {
            setError('Au moins une limite doit être renseignée');
            return;
        } else {
            setError(null);
        }

        if (!selectedElement) return;

        setSelectedCharacs([...selectedCharacs, { ...selectedElement, searchParams }]);
        if (selectedElement.groupIndex < availableCharacs.length && selectedElement.elementIndex < availableCharacs[selectedElement.groupIndex].elements?.length) {
            const _availableCharacs = availableCharacs.map(group => ({ ...group, elements: [...group.elements] }));
            _availableCharacs[selectedElement.groupIndex].elements[selectedElement.elementIndex] = { ..._availableCharacs[selectedElement.groupIndex].elements[selectedElement.elementIndex], selected: true };
            setAvailableCharacs(_availableCharacs);
        }
        setSelectedElement(null);
        setSearchParams({});
    }

    const handleRemoveElement = (element: SelectedMaterialCharacteristicElement) => {
        if (element.groupIndex < availableCharacs.length && element.elementIndex < availableCharacs[element.groupIndex].elements?.length) {
            const _availableCharacs = availableCharacs.map(group => ({ ...group, elements: [...group.elements] }));
            _availableCharacs[element.groupIndex].elements[element.elementIndex] = { ..._availableCharacs[element.groupIndex].elements[element.elementIndex], selected: false };
            setAvailableCharacs(_availableCharacs);
        }
        setSelectedCharacs(selectedCharacs.filter(e => e.key !== element.key));
    }

    return (
        <div className="add-remove-container">
            <div className="available-items">
                {availableCharacs.map((c, groupIndex) => (
                    <Dropdown
                        key={c.key}
                        title={c.label}
                        opened={true}
                    >
                        {(c.elements ?? []).filter(element => !element.hideInSample).map((element, elementIndex) => (
                            <span
                                key={element.key}
                                onClick={() => !element.selected ? setSelectedElement({ ...element, groupIndex, elementIndex, searchParams: {} }) : undefined}
                                className={element.selected ? 'selected' : ''}
                            >
                                {element.label}
                            </span>
                        ))}
                    </Dropdown>
                ))}
            </div>
            <div className="selected-elements">
                <table>
                    <thead>
                        <tr>
                            <th>Caractéristique</th>
                            <th>Seuil</th>
                            <th>Minimum</th>
                            <th>Maximum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!!selectedCharacs?.length && selectedCharacs.map(element => (
                            <tr key={element.key}>
                                <td>
                                    <div>
                                        <RemoveIcon onClick={() => handleRemoveElement(element)} />
                                        <span>{element.label}</span>
                                    </div>
                                </td>
                                <td>{element.searchParams.seuil ?? ''}</td>
                                <td>{element.searchParams.min ?? ''}</td>
                                <td>{element.searchParams.max ?? ''}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {selectedElement && (
                <Modal
                    title={selectedElement.label}
                    className="modal-add-remove"
                    actions={[
                        { label: 'Annuler', color: 'secondary', onClick: () => { setSelectedElement(null); setSearchParams({}); } },
                        { label: 'Confirmer', onClick: handleSelectElement },
                    ]}
                >
                    {selectedElement.seuil && (
                        <div className="input-column">
                            <label htmlFor="max">{selectedElement.seuil}{selectedElement.seuilUnit ? ` (${selectedElement.seuilUnit})` : ''}</label>
                            <NumberInput
                                id="seuil"
                                value={searchParams?.seuil}
                                onChange={(v) => handleChange('seuil', v)}
                            />
                        </div>
                    )}
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="min">Valeur minimum</label>
                            <NumberInput
                                id="min"
                                value={searchParams?.min}
                                onChange={(v) => handleChange('min', v)}
                                errors={error ? [error] : undefined}
                            />
                        </div>
                        <div className="input-column">
                            <label htmlFor="max">Valeur maximum</label>
                            <NumberInput
                                id="max"
                                value={searchParams?.max}
                                onChange={(v) => handleChange('max', v)}
                                errors={error ? [error] : undefined}
                            />
                        </div>
                    </div>
                    {withTolerance && (
                        <div className="tolerance">
                            <span>Autoriser les résultats ayant</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={searchParams?.tolerance ?? 100} onChange={(e) => handleChange('tolerance', e.target.value ? Number(e.target.value) : 100)} />
                            <span>% de valeurs vérifiant cette condition</span>
                        </div>
                    )}
                </Modal>
            )}

        </div>
    )
}

export default CharacAddRemove;