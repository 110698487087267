// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** Colors */
/** Sample layers */
/** Dimensions */
#component-detail {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
#component-detail #component-detail-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/styles/variables.scss","webpack://./src/pages/Operation/Components/index.scss"],"names":[],"mappings":"AAAA,YAAA;AAqCA,mBAAA;AAYA,gBAAA;AC/CA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAEJ;AAAI;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;AAER","sourcesContent":["/** Colors */\n\n$white: #fff;\n$white-transparent: rgba(255, 255, 255, 0.95);\n$black: #000;\n$black-oso: #010508;\n$red: #cd222c;\n$dark-red: #a30e09;\n$red-oso: #d94032;\n$red-disabled: #f0c6c5;\n$blue-oso: #0c2c40;\n$blue-oso-duck: #397d92;\n$blue-oso-duck-disable: #d3eaf0;\n$blue-oso-duck-hover: #2c6070;\n$pale-grey: #f8f8fa;\n$dark-blue: #2b2e42;\n$light-grey: #c2c3c9;\n$very-light-grey: #f0f0f5;\n$silver: #e1e1e4;\n$steel: #868793;\n$gunmetal: #494c5d;\n$gunmetal2: #eeeef0;\n$green-disabled: #b7e5a1;\n$green: #2ad344;\n$peach: #faa97a;\n$peach-light: #f2c8a2;\n$controle-exterieur: #ffffff;\n$controle-externe: #fffced;\n$controle-autre: #ebe6f7;\n$controle-externe-contrast: #fdf5ce;\n$controle-autre-contrast: #d8ccf5;\n$silver-three: #bec1cc;\n\n$success: #31b225;\n$warning: #ddbb5b;\n$error: #c72626;\n\n/** Sample layers */\n$layer0: #010508;\n$layer1: #397d92;\n$layer2: #d94032;\n$layer3: #faa97a;\n$layer4: #f2c8a2;\n$layer5: #d3eaf0;\n$layer6: #84a31f;\n$layer7: #d5e5a1;\n$layer8: #a30e09;\n$layer9: #f0c6c5;\n\n/** Dimensions */\n$header-height: 60px;\n$sidebar-width: 250px;\n$sidebar-width-compact: 72px;\n","@import \"../../../styles/variables.scss\";\n\n#component-detail {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n\n    & #component-detail-header {\n        display: flex;\n        flex-direction: column;\n        align-items: flex-end;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
