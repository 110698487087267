import { Fragment, useCallback, useEffect, useState } from 'react';
import { ReactComponent as CollapseIcon } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { FormBaseProps } from '../../hooks/useForm';
import useOutsideClick from '../../hooks/useOutsideClick';
import useRequest from '../../hooks/useRequest';

const MAX_HEIGHT = 306;

type SelectItem<T> = { key: T, label: string };

interface SelectProps<T> extends FormBaseProps<T> {
    items?: SelectItem<T>[];
    endpoint?: string;
    placeholder?: string;
}

const Select = <T,>({
    id,
    value,
    items,
    endpoint,
    placeholder,
    disabled,
    onChange,
    errors
}: SelectProps<T>) => {
    const request = useRequest();
    const [itemsOrData, setItemsOrData] = useState<SelectItem<T>[]>([]);
    const [isOpened, setOpened] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => setOpened(false), []));

    useEffect(() => {
        if (ref.current) {
            const bound = ref.current.getBoundingClientRect();
            if (!isOpened) {
                ref.current.style.maxHeight = "100%";
            } else {
                if (bound.top + MAX_HEIGHT > window.innerHeight) {
                    ref.current.style.bottom = '0';
                    ref.current.style.top = "auto";
                } else {
                    ref.current.style.top = '0';
                    ref.current.style.bottom = "auto";
                }
                ref.current.style.maxHeight = MAX_HEIGHT + "px";
            }
        }
    }, [isOpened, ref]);

    useEffect(() => {
        if (endpoint) {
            request.get<SelectItem<T>[]>(endpoint).then(setItemsOrData).catch(() => setItemsOrData([]));
        } else {
            setItemsOrData(items ?? []);
        }
    }, [endpoint, items]);

    return (
        <div className="select">
            <div className={`dropdown border-input${isOpened ? ' opened' : ''}${!!errors?.length ? ' input-error' : ''}`} ref={ref}>
                <input
                    type="text"
                    readOnly
                    value={itemsOrData?.find(item => item.key === value)?.label ?? ''}
                    onClick={() => !disabled ? setOpened(!isOpened) : undefined}
                    placeholder={placeholder ?? ''}
                />
                {!disabled && (
                    <Fragment>
                        <div className="icon-container" onClick={() => setOpened(!isOpened)}>
                            <CollapseIcon onClick={() => setOpened(false)} />
                        </div>
                        {!!itemsOrData?.length && (
                            <div className="dropdown-scroll">
                                {isOpened && <CloseIcon className="sm-show close-icon-form" onClick={() => setOpened(false)} />}
                                {itemsOrData.map((item) => {
                                    const active = value === item.key;

                                    return (
                                        <div
                                            key={String(item.key)}
                                            className={`select-item ${active ? 'active' : ''}`}
                                            onClick={() => { onChange(item.key, item.label); setOpened(false) }}
                                        >
                                            {item.label}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
            {!!errors?.length && <div className="form-error">{errors.join('. ')}</div>}
        </div>
    )
}

export default Select;