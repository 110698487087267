import { Fragment, useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as AeroportIcon } from "../../assets/icons/operation/aeroport.svg";
import { ReactComponent as AireIcon } from "../../assets/icons/operation/aire.svg";
import { ReactComponent as FerroviaireIcon } from "../../assets/icons/operation/ferroviaire.svg";
import { ReactComponent as MetroIcon } from "../../assets/icons/operation/metro.svg";
import { ReactComponent as PlateformeIcon } from "../../assets/icons/operation/plateforme.svg";
import { ReactComponent as PortIcon } from "../../assets/icons/operation/port.svg";
import { ReactComponent as RouteIcon } from "../../assets/icons/operation/route.svg";
import { ReactComponent as SportIcon } from "../../assets/icons/operation/sport.svg";
import List, { FilterType } from '../../components/data/List';
import Header from '../../components/ui/Header';
import { Modal } from '../../components/ui/Modal';
import { useAuthContext } from '../../context/AuthProvider';
import { useGlobalContext } from '../../context/GlobalProvider';
import { Operation, OperationRole, OperationSubType, OperationSubTypes } from '../../models/operation';
import { Role } from '../../models/user';
import { formatDate } from '../../utils/format';
import './index.scss';

const Home = () => {
    const { user } = useAuthContext();
    const { setToastError } = useGlobalContext();
    const [isCreateOperationMenuVisible, setCreateOperationMenuVisible] = useState(false);
    const navigate = useNavigate();

    const handleNewOperation = useCallback(() => {
        if (user.role === Role.SUPER_ADMIN || (user.managedLicense && (user.managedLicense?.operationsCount ?? 0) < user.managedLicense.operationsQuota)) {
            setCreateOperationMenuVisible(true);
        } else {
            setToastError('Le nombre maximum d\'opérations a été atteint');
        }
    }, [user]);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Opérations' }]} />
            <List<Operation>
                columns={[
                    { key: 'name', label: 'Nom de l\'opération' },
                    { key: 'project', label: 'Projet' },
                    { key: 'zone.location', label: 'Localisation' },
                    { key: 'type', label: 'Type', mapper: (datum) => OperationSubType[datum.type]?.label ?? '' },
                    { key: 'date', label: 'Date de lancement', mapper: (datum) => formatDate(datum.date) }
                ]}
                dataEndpoint="/operation"
                crudEndpoint="/operation"
                baseUrl="/operation"
                onView={(o) => navigate(`/operation/${o._id}`)}
                actions={{
                    edit: user.role === Role.SUPER_ADMIN || ((e) => e.licensePopulated?.manager === user._id || e.users?.some(u => u.user === user._id && u.role === OperationRole.ADMIN)),
                    activate: user.role === Role.SUPER_ADMIN || ((e) => e.licensePopulated?.manager === user._id || e.users?.some(u => u.user === user._id && u.role === OperationRole.ADMIN)),
                    delete: user.role === Role.SUPER_ADMIN
                }}
                filters={[
                    { field: 'active', label: 'Actif' },
                    { field: 'types', label: 'Type d\'opération', type: FilterType.MULTIPLE_SELECT, items: OperationSubTypes },
                    { field: 'businesses', label: 'Entreprise', type: FilterType.MULTIPLE_SELECT, endpoint: '/business/list' }
                ]}
                buttons={(user.role === Role.SUPER_ADMIN || user.managedLicense) ? [{
                    className: "md-hide",
                    label: "Nouvelle opération",
                    onClick: handleNewOperation
                }] : []}
                initialPagination={{ sortBy: 'date', sortDirection: -1 }}
                warningBeforeDelete
            />
            {isCreateOperationMenuVisible && (
                <Modal
                    title="Sélectionner le type d'opération"
                    className="operation-create"
                    size="small"
                    actions={[{ label: 'Annuler', color: 'secondary', onClick: () => setCreateOperationMenuVisible(false) }]}
                >
                    <Link to={'/operation/creer/' + OperationSubType.route.key}><RouteIcon />{OperationSubType.route.label}</Link>
                    <Link to={'/operation/creer/' + OperationSubType.aire.key}><AireIcon />{OperationSubType.aire.label}</Link>
                    <Link to={'/operation/creer/' + OperationSubType.ferroviaire.key}><FerroviaireIcon />{OperationSubType.ferroviaire.label}</Link>
                    <Link to={'/operation/creer/' + OperationSubType.metro.key}><MetroIcon />{OperationSubType.metro.label}</Link>
                    <Link to={'/operation/creer/' + OperationSubType.aeroport.key}><AeroportIcon />{OperationSubType.aeroport.label}</Link>
                    <Link to={'/operation/creer/' + OperationSubType.port.key}><PortIcon />{OperationSubType.port.label}</Link>
                    <Link to={'/operation/creer/' + OperationSubType.plateforme.key}><PlateformeIcon />{OperationSubType.plateforme.label}</Link>
                    <Link to={'/operation/creer/' + OperationSubType.sport.key}><SportIcon />{OperationSubType.sport.label}</Link>
                </Modal>
            )}
        </Fragment>
    )
}

export default Home;