import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import useRequest from "../hooks/useRequest";
import { Permission, Role, User } from "../models/user";

export interface GlobalPermissions {
    superAdministrate: boolean;
    administrate: boolean;
    print: boolean;
    filter: boolean;
    library: boolean;
    localizedResults: boolean;
    materialResults: boolean;
    synoptiqueData: boolean;
}

export interface ProtectedPage {
    canAccess: (p: GlobalPermissions) => boolean;
}

const DEFAULT_PERMISSIONS = {
    superAdministrate: false,
    administrate: false,
    print: false,
    filter: false,
    library: false,
    localizedResults: false,
    materialResults: false,
    synoptiqueData: false,
}

const DEFAULT_USER: User = {
    _id: "",
    firstName: '',
    lastName: '',
    email: '',
    business: '',
    role: Role.USER,
    active: false,
    connectedAt: 0
} as User

type AuthContextType = {
    user: User;
    getMe: () => void,
    login: (token: string) => void,
    logout: () => void,
    permissions: GlobalPermissions,
};

const AuthContext = createContext<AuthContextType>({
    user: DEFAULT_USER,
    getMe: () => null,
    login: () => null,
    logout: () => null,
    permissions: DEFAULT_PERMISSIONS,
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [isInit, setInit] = useState<boolean>(false);
    const [user, setUser] = useState<User>(DEFAULT_USER);
    const request = useRequest();
    const logout = useCallback(() => {
        setUser(DEFAULT_USER);
        localStorage.removeItem('token');
    }, []);

    const permissions = useMemo(() => user._id ? {
        superAdministrate: user.role === Role.SUPER_ADMIN,
        administrate: user.role === Role.SUPER_ADMIN || !!user.managedLicense,
        print: user.role === Role.SUPER_ADMIN || !!user.managedLicense?.permissions?.includes(Permission.PRINT),
        filter: user.role === Role.SUPER_ADMIN || !!user.managedLicense?.permissions?.includes(Permission.FILTER),
        library: user.role === Role.SUPER_ADMIN || !!user.managedLicense?.permissions?.includes(Permission.LIBRARY),
        localizedResults: user.role === Role.SUPER_ADMIN || !!user.managedLicense?.permissions?.includes(Permission.LOCALIZED_RESULTS),
        materialResults: user.role === Role.SUPER_ADMIN || !!user.managedLicense?.permissions?.includes(Permission.MATERIAL_RESULTS),
        synoptiqueData: user.role === Role.SUPER_ADMIN || !!user.managedLicense?.permissions?.includes(Permission.SYNOPTIQUE_DATA)
    } : DEFAULT_PERMISSIONS, [user]);

    const getMe = useCallback(async (): Promise<void> => {
        request.get<User>('/user/me')
            .then((data) => {
                if (data.token) {
                    localStorage.setItem('token', data.token);
                }
                setUser(data);
            })
            .catch(() => logout())
            .finally(() => setInit(true));
    }, [logout]);

    const login = useCallback((token: string) => {
        localStorage.setItem('token', token);
        getMe();
    }, [getMe]);

    useEffect(() => {
        getMe();
    }, []);

    if (!isInit) {
        return (null)
    }

    return (
        <AuthContext.Provider value={{
            user,
            getMe,
            login,
            logout,
            permissions,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuthContext = () => useContext<AuthContextType>(AuthContext);

export { AuthProvider, useAuthContext };
export default AuthContext;