import { Fragment, useCallback, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalProvider';
import useDebounce from '../../../hooks/useDebounce';
import useForm, { FormComparator } from '../../../hooks/useForm';
import useRequest from '../../../hooks/useRequest';
import { Role, Roles, User } from '../../../models/user';
import SmallList from '../../data/SmallList';
import SearchInput from '../../inputs/SearchInput';
import Select from '../../inputs/Select';
import TextInput from '../../inputs/TextInput';
import Card from '../../ui/Card';
import IconLink from '../../ui/IconLink';
import { Modal } from '../../ui/Modal';
import './index.scss';
import { useAuthContext } from '../../../context/AuthProvider';
import SelectAutocomplete from '../../inputs/SelectAutocomplete';

const VALIDATION = {
    firstName: [{ comparator: FormComparator.REQUIRED }],
    lastName: [{ comparator: FormComparator.REQUIRED }],
    email: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.EMAIL }],
    role: [{ comparator: FormComparator.REQUIRED }],
    business: [{ comparator: FormComparator.REQUIRED }],
};

interface UserSearchModalProps {
    mode?: 'user' | 'subcontractor';
    disabledUsers?: string[];
    onSubmit: (u: User[]) => void;
    onClose: () => void;
}

const UserSearchModal = ({
    mode,
    onSubmit,
    onClose,
}: UserSearchModalProps) => {
    const request = useRequest();
    const { setToastError } = useGlobalContext();
    const { user } = useAuthContext();
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User[]>([]);
    const [keyword, setKeyword] = useState<string>('');
    const [createMode, setCreateMode] = useState<boolean>(false);
    const { attachInput, validate } = useForm<User>({ active: true, role: mode === 'subcontractor' ? Role.SUBCONTRACTOR : Role.USER });

    const handleSubmit = useCallback(async () => {
        const entity = validate(VALIDATION)
        if (!entity) return;

        const create = !entity?._id;
        const requestMethod = create ? request.post : request.put;

        requestMethod<User>('/user', entity, {
            successMessage: create ? 'Utilisateur créé avec succès' : 'Utilisateur mis à jour avec succès',
            loader: true
        })
            .then((data) => onSubmit([data]))
            .catch((e) => {
                if ((e as any)?.error?.status === 409) {
                    setToastError('Cet email est déjà utilisée par un utilisateur');
                } else {
                    setToastError('Une erreur est survenue lors de l\'enregistrement', e);
                }
            });

    }, [validate, onSubmit]);

    useDebounce(useCallback(() => {
        if (keyword.length > 1) {
            request.get<User[]>(`/user/search${mode === 'subcontractor' ? '/subcontractor' : ''}`, { params: { keyword }, loader: true })
                .then(setUsers)
                .catch(() => null);
        }
    }, [keyword, mode]), 500, [keyword]);


    return (
        <Modal
            title={mode === 'subcontractor' ? ' Rechercher un prestataire' : 'Rechercher un utilisateur'}
            className="user-search-modal"
            size="small"
            actions={createMode ? [
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', onClick: handleSubmit },
            ] : [
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', disabled: !selectedUser, onClick: () => selectedUser && onSubmit(selectedUser) },
            ]}
        >
            {createMode ? (
                <Fragment>
                    <Card title="Informations générales">
                        <div className="row">
                            <div className="input-column">
                                <label htmlFor="firstName">Prénom *</label>
                                <TextInput {...attachInput('firstName')} />
                            </div>
                            <div className="input-column">
                                <label htmlFor="lastName">Nom *</label>
                                <TextInput {...attachInput('lastName')} />
                            </div>
                        </div>
                        <div className="input-column">
                            <label htmlFor="email">Email *</label>
                            <TextInput {...attachInput('email')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="business">Entreprise *</label>
                            <SelectAutocomplete endpoint="/business/list" {...attachInput('business')} />
                        </div>
                    </Card>
                    <Card title="Permissions">
                        <div className="input-column">
                            <label htmlFor="role">Role *</label>
                            <Select<Role> items={user.role === Role.SUPER_ADMIN ? Roles : Roles.filter(r => r.key !== Role.SUPER_ADMIN)} {...attachInput('role')} />
                        </div>
                    </Card>
                </Fragment>
            ) : (
                <Fragment>
                    <SearchInput
                        id="user-search-modal-input"
                        placeholder="Rechercher un utilisateur..."
                        value={keyword}
                        onChange={(v) => setKeyword(v ?? '')}
                    />
                    <SmallList<User>
                        data={users}
                        columns={[
                            { key: 'firstName', label: 'Nom', mapper: (element) => `${element.firstName ? element.firstName + ' ' : ''}${element.lastName ?? ' '}` },
                            { key: 'business', label: 'Entreprise', mapper: (element) => element.businessPopulated?.name ?? '' },
                        ]}
                        onSelectMultiple={setSelectedUser}
                    />
                    <IconLink type="add" label="Créer un utilisateur" onClick={() => setCreateMode(true)} />
                </Fragment>
            )}
        </Modal>
    )
}

export default UserSearchModal;
