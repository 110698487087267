import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveIcon } from '../../assets/icons';
import Button from '../../components/ui/Button';
import Header from '../../components/ui/Header';
import { ModalRight } from '../../components/ui/Modal/ModalRight';
import Navigation from '../../components/ui/Navigation';
import UsersPills from '../../components/ui/UsersPills';
import useForm, { FormComparator } from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { OperationDto, OperationRole, OperationSubType, OperationSynoptiqueType, OperationType } from '../../models/operation';
import General from './Edit/General';
import UsersPanel from './Edit/UsersPanel';
import Way from './Edit/Way';
import Zone from './Edit/Zone';
import './index.scss';
import { useAuthContext } from '../../context/AuthProvider';
import { useOperationContext } from '../../context/OperationProvider';

const PAGES_1 = [{ key: 'general', label: 'Général' }, { key: 'sens1', label: 'Sens 1' }, { key: 'sens2', label: 'Sens 2' }];
const PAGES_2 = [{ key: 'general', label: 'Général' }, { key: 'zone', label: 'Zone' }];
const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }],
    date: [{ comparator: FormComparator.REQUIRED }]
};
const VALIDATION_WAY_1 = {
    'way1.name': [{ comparator: FormComparator.REQUIRED }],
    'way1.prStart': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.PR }],
    'way1.prEnd': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.PR }],
};
const VALIDATION_WAY_2 = {
    'way2.name': [{ comparator: FormComparator.REQUIRED }],
    'way2.prStart': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.PR }],
    'way2.prEnd': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.PR }],
}
const VALIDATION_TYPE_2 = {};

const Edit = () => {
    const request = useRequest();
    const { operationId, type } = useParams();
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const { entity, attachInput, validate, setEntity, setChanged, onChange, hasChanged, errors } = useForm<OperationDto>({});
    const [page, setPage] = useState<string | null>(null);
    const { operation, getOperation } = useOperationContext();
    const [pages, setPages] = useState([{ key: 'general', label: 'Général' }]);
    const [isUserPanelVisible, setUserPanelVisible] = useState<boolean>(false);

    const handleSubmit = useCallback(async (type: number, withWay2: boolean, close?: boolean) => {
        const entity = validate({
            ...VALIDATION,
            ...(type === 1 ? {
                ...VALIDATION_WAY_1,
                ...(withWay2 ? VALIDATION_WAY_2 : {})
            } : VALIDATION_TYPE_2)
        })
        if (!entity) return;

        const create = !entity?._id;
        const requestMethod = create ? request.post : request.put;

        requestMethod<OperationDto>('/operation', entity, {
            successMessage: create ? 'Opération créée avec succès' : 'Opération mise à jour avec succès',
            errorMessage: "Une erreur est survenue lors de l'enregistrement",
            loader: true
        })
            .then((data) => {
                if (data._id === operation._id) {
                    getOperation(data._id);
                }
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                    setEntity(data);
                }
            })
            .catch(() => null);
    }, [validate, setEntity, operation]);

    useEffect(() => {
        if (type) {
            setEntity({
                type: type as OperationType,
                synoptique: OperationSubType[type as OperationType]?.type === OperationSynoptiqueType.LINEAR ? 1 : 2,
                license: user.managedLicense?._id, licensePopulated: user.managedLicense,
                users: [],
                active: true
            });
            setPages(OperationSubType[type as OperationType]?.type === OperationSynoptiqueType.LINEAR ? PAGES_1 : PAGES_2);
        } else if (operationId) {
            request.get<OperationDto>(`/operation/edit/${operationId}`, {
                errorMessage: 'Une erreur est survenue lors de la récupération de l\'opération.',
                loader: true
            })
                .then((data) => {
                    setEntity(data);
                    setPages(data.synoptique === 1 ? PAGES_1 : PAGES_2);
                })
                .catch(() => navigate('/accueil'))
        } else {
            navigate('/accueil');
        }
    }, []);

    if (!entity.synoptique || entity._id !== operationId) {
        return (null);
    }

    return (
        <Fragment>
            <Header
                breadcrumbs={[{ label: 'Opérations', href: '/accueil' }, { label: entity?.name ? entity.name : 'Nouvelle opération' }]}
            >
                <UsersPills users={entity.users} onClick={() => setUserPanelVisible(true)} />
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={<SaveIcon />}
                            onClick={() => handleSubmit(entity.synoptique ?? 1, !!entity.way2)}
                        />
                        <Button
                            label="Enregistrer et fermer"
                            icon={<SaveIcon />}
                            onClick={() => handleSubmit(entity.synoptique ?? 1, !!entity.way2, true)}
                        />
                    </Fragment>
                )}
            </Header>
            <Navigation pages={pages} setPage={setPage} />
            {page === 'general' && <General entity={entity} onChange={onChange} attachInput={attachInput} errors={errors} />}
            {page === 'sens1' && <Way entity={entity} onChange={onChange} attachInput={attachInput} way={1} />}
            {page === 'sens2' && <Way entity={entity} onChange={onChange} attachInput={attachInput} way={2} />}
            {page === 'zone' && <Zone entity={entity} onChange={onChange} attachInput={attachInput} />}
            <ModalRight
                visible={!!isUserPanelVisible}
                title="Utilisateurs"
                className="users-panel"
                actions={[
                    { label: 'Fermer', onClick: () => setUserPanelVisible(false) }
                ]}
            >
                <UsersPanel operation={entity} onChange={(users) => onChange('users', users)} />
            </ModalRight>
        </Fragment >
    )
}

export default Edit;