import { memo } from 'react';
import { OperationUser } from '../../../models/operation';
import { getFullName, getInitials } from '../../../utils/format';
import Tooltip from '../Tooltip';
import './index.scss';
import { EyeIcon } from '../../../assets/icons';

interface UsersPillsProps {
    users?: OperationUser[];
    onClick: () => void;
}

const UsersPills = ({
    users,
    onClick
}: UsersPillsProps) => (
    <div className="users-pills">
        {!users?.length
            ? <div className="pill-no-user">0 utilisateurs</div>
            : (
                <div className={`pill-container ${users.length > 3 ? 'pill-collapsed' : ''}`}>
                    {users.slice(0, 3).map(user => (
                        <Tooltip key={user.user} text={getFullName(user.userPopulated)}>
                            <div className="pill">{getInitials(user.userPopulated)}</div>
                        </Tooltip>
                    ))}
                    {users.length > 3 && (
                        <Tooltip text={users.slice(3).map(u => getFullName(u.userPopulated)).join(', ')}>
                            <div className="pill">+{users.length - 3}</div>
                        </Tooltip>
                    )}
                </div>
            )
        }
        {typeof onClick === 'function' && <EyeIcon onClick={onClick} />}
    </div>
)

export default memo(UsersPills);