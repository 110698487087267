import { useCallback } from "react";
import { useGlobalContext } from "../context/GlobalProvider";
import AxiosInstance from "../services/AxiosInstance";
import { ResponseType } from "axios";

interface RequestOptions {
    params: any;
    loader: boolean;
    successMessage: string;
    errorMessage: string;
    responseType: ResponseType;
    timeout: number;
    headers: Record<string, string>;
}

interface UseRequestReturn {
    get: <T>(url: string, options?: Partial<RequestOptions>) => Promise<T>;
    post: <T>(
        url: string,
        body: any,
        options?: Partial<RequestOptions>
    ) => Promise<T>;
    put: <T>(
        url: string,
        body: any,
        options?: Partial<RequestOptions>
    ) => Promise<T>;
    patch: <T>(
        url: string,
        body: any,
        options?: Partial<RequestOptions>
    ) => Promise<T>;
    delete: <T>(url: string, options?: Partial<RequestOptions>) => Promise<T>;
}

const useRequest = (): UseRequestReturn => {
    const { setToastError, setToastSuccess, setSpinnerVisible } =
        useGlobalContext();

    const request = useCallback(
        async <T>(
            method: "get" | "post" | "put" | "patch" | "delete",
            url: string,
            body: any,
            options?: Partial<RequestOptions>
        ): Promise<T> => {
            if (options?.loader) {
                setSpinnerVisible(true);
            }

            try {
                let data: T;
                const _options = {
                    params: options?.params,
                    responseType: options?.responseType,
                    timeout: options?.timeout,
                    headers: options?.headers,
                };

                switch (method) {
                    case "get":
                        const getResponse = await AxiosInstance.get<T>(
                            url,
                            _options
                        );
                        data = getResponse.data;
                        break;
                    case "post":
                        const postResponse = await AxiosInstance.post<T>(
                            url,
                            body,
                            _options
                        );
                        data = postResponse.data;
                        break;
                    case "put":
                        const putResponse = await AxiosInstance.put<T>(
                            url,
                            body,
                            _options
                        );
                        data = putResponse.data;
                        break;
                    case "patch":
                        const patchResponse = await AxiosInstance.patch<T>(
                            url,
                            body,
                            _options
                        );
                        data = patchResponse.data;
                        break;
                    default:
                        const deleteResponse = await AxiosInstance.delete<T>(
                            url,
                            _options
                        );
                        data = deleteResponse.data;
                        break;
                }

                if (options?.successMessage) {
                    setToastSuccess(options.successMessage);
                }

                return data;
            } catch (e) {
                if (options?.errorMessage) {
                    const status = (e as any)?.error.status;
                    if (status === 429) {
                        setToastError(
                            "Le nombre de requêtes autorisé a été atteint. Pour des raisons de sécurité, merci de patienter quelques minutes avant de réessayer.",
                            e
                        );
                    } else if (status === 403 || status === 404) {
                        setToastError(
                            "La donnée demandée n'a pas été trouvée ou vous ne disposez pas des authorisations nécessaire pour y accéder.",
                            e
                        );
                    } else {
                        setToastError(options.errorMessage, e);
                    }
                }

                throw e;
            } finally {
                if (options?.loader) {
                    setSpinnerVisible(false);
                }
            }
        },
        []
    );

    return {
        get: <T>(url: string, options?: Partial<RequestOptions>) =>
            request<T>("get", url, {}, options),
        post: <T>(url: string, body: any, options?: Partial<RequestOptions>) =>
            request<T>("post", url, body, options),
        put: <T>(url: string, body: any, options?: Partial<RequestOptions>) =>
            request<T>("put", url, body, options),
        patch: <T>(url: string, body: any, options?: Partial<RequestOptions>) =>
            request<T>("patch", url, body, options),
        delete: <T>(url: string, options?: Partial<RequestOptions>) =>
            request<T>("delete", url, {}, options),
    };
};

export default useRequest;
