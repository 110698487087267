import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, ArrowRightIcon, OptionsIcon, SendIcon } from '../../../assets/icons';
import MessageModal from '../../../components/MessageModal';
import SampleDetail from '../../../components/SampleDetail';
import IdentityCard from '../../../components/entities/IdentityCard';
import Button from '../../../components/ui/Button';
import Header from '../../../components/ui/Header';
import { ModalRight } from '../../../components/ui/Modal/ModalRight';
import Printable from '../../../components/ui/Printable';
import { useOperationContext } from '../../../context/OperationProvider';
import useDebounce from '../../../hooks/useDebounce';
import useRequest from '../../../hooks/useRequest';
import { CoucheType, isLot } from '../../../models/lot';
import { MelangeType, isMelange } from '../../../models/melange';
import { MessageType } from '../../../models/message';
import { Operation, Phase, RoadPosition } from '../../../models/operation';
import { PopulationType } from '../../../models/population';
import { SampleType, isSample } from '../../../models/sample';
import LinearSynoptique from '../../../synoptique/LinearSynoptique.class';
import SynoptiqueClass, { ActionDoneElements, ActionDoneResult, Direction, LotForSynoptique, MarkerForSynoptique, SampleForSynoptique, SynoptiqueAction, SynoptiqueElements } from '../../../synoptique/Synoptique.class';
import ZoneSynoptique from '../../../synoptique/ZoneSynoptique.class';
import { isType } from '../../../utils/objects';
import MarkerDetail from '../Map/MarkerDetail';
import Filters from './components/Filters';
import Legend from './components/Legend';
import LotView from './components/LotView';
import SynoptiqueMenu from './components/Menu';
import SampleView from './components/SampleView';
import './index.scss';

export interface SynoptiqueFilterMinMaxLayer {
    key?: string;
    min?: number;
    max?: number;
    layer?: number;
}

export interface SynoptiqueAdvancedFilters {
    sampleValue?: SynoptiqueFilterMinMaxLayer;
    sampleThickness?: SynoptiqueFilterMinMaxLayer;
    populationValue?: SynoptiqueFilterMinMaxLayer;
}

export interface SynoptiqueFilters {
    realtime?: boolean | undefined;
    phase?: Phase[] | undefined;
    fullLot?: string[] | undefined;
    material?: string[] | undefined;
    coucheType?: CoucheType[] | undefined;
    sampleDiameter?: number | undefined;
    road?: string[] | undefined;
    roadPosition?: RoadPosition[] | undefined;
    lotDisplay?: boolean | undefined;
    sampleType?: SampleType[] | undefined;
    sampleAnalysis?: boolean | undefined;
    sampleProblematic?: boolean | undefined;
    sampleDisplay?: boolean | undefined;
    previousSampleDisplay?: boolean | undefined;
    populationType?: PopulationType[] | undefined;
    populationProblematic?: boolean | undefined;
    populationDisplay?: boolean | undefined;
    markerDisplay?: boolean | undefined;
    melangeType?: MelangeType[] | undefined;
    melangeDisplay?: boolean | undefined;
    melangeProblematic?: boolean | undefined;
}

const INITIAL_FILTERS = {
    populationProblematic: true,
};

const FILTERS_LOCALSTORAGE_KEY = 'synoptique-filters';

const getFiltersInLocalStorage = () => JSON.parse(localStorage.getItem(FILTERS_LOCALSTORAGE_KEY) || "{}");

const setFiltersInLocalStorage = (operation: Operation, filters: any) => {
    const storedFilters = getFiltersInLocalStorage();
    storedFilters[operation._id] = filters;
    localStorage.setItem(FILTERS_LOCALSTORAGE_KEY, JSON.stringify(storedFilters));
}

const Synoptique = () => {
    const { operation, isRoadOperation, operationPermissions } = useOperationContext();
    const request = useRequest();
    const canvasDomRef = useRef<HTMLCanvasElement>(null);
    const canvasScrollRef = useRef<HTMLDivElement>(null);
    const synoptiqueRef = useRef<SynoptiqueClass | null>(null);
    const [currentAction, setCurrentAction] = useState<SynoptiqueAction | null>(SynoptiqueAction.SELECT_ONE);
    const [currentDirection, setCurrentDirection] = useState<Direction>(Direction.BOTTOM);
    const [selectedSampleId, setSelectedSampleId] = useState<string | null>(null);
    const [selectedMarkerId, setSelectedMarkerId] = useState<string | null>(null);
    const [selectedElements, setSelectedElements] = useState<ActionDoneElements>([]);
    const [selectedElement, setSelectedElement] = useState<string | null>(null);
    const [selectionDiv, setSelectionDiv] = useState<{ top: number; left: number; width: number; height: number; } | null>(null);
    const [hoverElements, setHoverElements] = useState<ActionDoneElements>([]);
    const [activeFilters, setActiveFilters] = useState<SynoptiqueFilters>({ ...INITIAL_FILTERS });
    const [advancedFilters, setAdvancedFilters] = useState<SynoptiqueAdvancedFilters>({});
    const [isPanelVisible, setPanelVisible] = useState(false);
    const [isSynoptiqueInitialized, setSynoptiqueInitialized] = useState(false);
    const [isNotifyModalVisible, setNotifyModalVisible] = useState(false);
    useDebounce(() => getElements(), 600, [activeFilters, advancedFilters]);
    const navigate = useNavigate();

    const onActionChange = useCallback((action: SynoptiqueAction) => {
        const _action = action === currentAction ? null : action;
        if (action !== SynoptiqueAction.ZOOM_IN && action !== SynoptiqueAction.ZOOM_OUT) {
            setCurrentAction(_action);
        }
        synoptiqueRef?.current?.setAction(_action);
    }, [currentAction]);

    const handleSelectSample = useCallback((_id: string) => {
        setSelectedElement(_id);
        setSelectedSampleId(_id);
        synoptiqueRef?.current?.highlightElement(_id);
    }, []);

    const onActionDone = useCallback((action: SynoptiqueAction, result: ActionDoneResult) => {
        switch (action) {
            case SynoptiqueAction.SELECT_ONE:
                if (result.elements?.length) {
                    const element = result.elements[0];
                    if (isType<SampleForSynoptique>(element, isSample)) {
                        setSelectedSampleId(element._id);
                        setSelectedMarkerId(null);
                    } else if (isType<MarkerForSynoptique>(element, isMelange)) {
                        setSelectedMarkerId(element._id);
                        setSelectedSampleId(null);
                    }
                }
                break;
            case SynoptiqueAction.SELECT_MULTIPLE_MOVE:
                const bbox = canvasDomRef.current?.getBoundingClientRect();
                const parentBbox = canvasDomRef.current?.parentElement?.getBoundingClientRect();
                const parentScroll = canvasDomRef.current?.parentElement?.scrollTop ?? 0;

                if (!bbox || !result.selectionDiv || !parentBbox) return;

                setSelectionDiv({
                    ...result.selectionDiv,
                    top: result.selectionDiv.top + bbox.top - parentBbox.top + parentScroll,
                } ?? null);
                break;
            case SynoptiqueAction.HOVER_ELEMENTS:
                setHoverElements(result.elements ?? []);
                break;
            case SynoptiqueAction.INIT_ELEMENTS:
                if (result && isRoadOperation && canvasScrollRef?.current && synoptiqueRef?.current && !isSynoptiqueInitialized) {
                    const firstElementScroll = (result.firstPosition?.x ?? 0) + 100 - (canvasScrollRef.current.offsetWidth / 2);
                    if ((canvasScrollRef.current.scrollLeft ?? 0) <= firstElementScroll) {
                        canvasScrollRef.current.scrollLeft = firstElementScroll;
                    }
                }
                break;
            case SynoptiqueAction.SELECT_EXIT:
                if (result?.elements?.length) {
                    const operation = (result.elements[0] as any).operation;

                    if (operation) {
                        navigate(`/operation/${operation}/synoptique`);
                    }
                }
                break;
            default:
                setSelectedElements([...(result.elements ?? [])]);
        }
        setSelectedElement(null);
    }, [isRoadOperation]);

    const getElements = useCallback(async () => {
        setFiltersInLocalStorage(operation, activeFilters);

        request.get<SynoptiqueElements>(`/operation/${operation?._id}/elements`, {
            params: { ...activeFilters, ...(advancedFilters ?? {}) },
            errorMessage: 'Une erreur est survenue lors de la récupération des éléments',
            loader: true
        })
            .then((e) => synoptiqueRef.current?.setElements(e))
            .catch(() => null);
    }, [activeFilters, operation, advancedFilters]);

    const startSynoptique = async () => {
        if (canvasDomRef?.current) {
            synoptiqueRef.current = isRoadOperation
                ? new LinearSynoptique(
                    canvasDomRef.current,
                    operation,
                    onActionDone
                )
                : new ZoneSynoptique(
                    canvasDomRef.current,
                    operation,
                    onActionDone
                );

            // Get stored filters
            const storedFilters = getFiltersInLocalStorage();

            setActiveFilters(storedFilters[operation._id] ?? { ...INITIAL_FILTERS, phase: operation.phases?.length ? [operation.phases[operation.phases.length - 1]] : [] });
            setSynoptiqueInitialized(true);
        }
    }

    const handleGoToPr = (pr: number) => {
        if (canvasScrollRef?.current && synoptiqueRef?.current) {
            canvasScrollRef.current.scrollLeft = (synoptiqueRef.current.getScrollFromPr(pr) ?? 0) - (canvasScrollRef.current.offsetWidth / 2);
        }
    }

    const handleCloseBottomPanel = useCallback(() => {
        setSelectedElements([]);
        synoptiqueRef?.current?.clearSelections();
        synoptiqueRef?.current?.render();
        setSelectionDiv(null);
    }, []);

    const bottomPanel = useMemo(() => {
        if (!selectedElements?.length) return null;

        if (isType<SampleForSynoptique[]>(selectedElements, (o) => isSample(o[0]))) {
            return (
                <SampleView
                    onSelectElement={handleSelectSample}
                    samples={selectedElements}
                    selectedElement={selectedElement ?? undefined}
                    currentDirection={currentDirection}
                    onClose={handleCloseBottomPanel}
                />
            )
        }

        if (isType<LotForSynoptique[]>(selectedElements, (o) => isLot(o[0]))) {
            return (
                <LotView
                    layers={selectedElements}
                    onSelectLayer={(l) => setSelectedElement(l?._id ?? null)}
                    selectedLayer={selectedElement ?? undefined}
                    onClose={handleCloseBottomPanel}
                    currentDirection={currentDirection}
                />
            )
        }

        return null;
    }, [handleSelectSample, handleCloseBottomPanel, currentDirection, selectedElement, selectedElements]);

    useEffect(() => {
        if (canvasDomRef?.current && !isSynoptiqueInitialized) {
            startSynoptique();
        }
    }, [canvasDomRef.current, isSynoptiqueInitialized]);

    return (
        <Fragment>
            <Header breadcrumbs={[
                { label: operation.name, href: `/operation/${operation._id}` },
                { label: 'Synoptique' }
            ]}>
                {operationPermissions.notify && (
                    <Button
                        color="primary"
                        label="Notifier les utilisateurs"
                        icon={<SendIcon />}
                        onClick={() => setNotifyModalVisible(true)}
                    />
                )}
                <Button
                    color="black"
                    onClick={() => setPanelVisible(!isPanelVisible)}
                    icon={<OptionsIcon />}
                    label="Filtrer"
                />
            </Header>
            <div id="synoptique">
                <SynoptiqueMenu
                    currentAction={currentAction ?? undefined}
                    onActionChange={onActionChange}
                    onChange={setActiveFilters}
                    onGoToPr={handleGoToPr}
                    filters={activeFilters}
                    advancedFilters={advancedFilters}
                    onAdvancedFiltersSubmit={setAdvancedFilters}
                />
                <Printable
                    title="Synoptique"
                    subtitle={operation.name}
                    filename="Synoptique"
                    disposition="landscape"
                    flow="both"
                    hideBanner
                    togglePrint={currentAction === SynoptiqueAction.PRINT}
                    onPrintEnd={() => setCurrentAction(null)}
                >
                    <div
                        id="canvas-container"
                        className={currentAction === SynoptiqueAction.PRINT ? 'printing' : ''}
                        style={currentAction === SynoptiqueAction.PRINT
                            ? {
                                width: canvasScrollRef.current?.getBoundingClientRect().height + 'px',
                            }
                            : undefined
                        }
                    >
                        <div id="canvas-gradient" className="no-print" />
                        <div
                            id="canvas-scroll-container"
                            className={!isRoadOperation ? ' type-2' : ''}
                            ref={canvasScrollRef}
                        >
                            {isRoadOperation && !!operation.way2 && (
                                <div id="way2">
                                    <div><ArrowLeftIcon /><div><span>Sens 2</span><span>{operation.way2.name}</span></div></div>
                                    <div><div><span>Sens 2</span><span>{operation.way1.name}</span></div><ArrowRightIcon /></div>
                                </div>
                            )}
                            {currentAction === SynoptiqueAction.SELECT_MULTIPLE && selectionDiv && (
                                <div
                                    id="canvas-selection-div"
                                    style={{
                                        width: selectionDiv.width,
                                        height: selectionDiv.height,
                                        left: selectionDiv.left,
                                        top: selectionDiv.top
                                    }}
                                />
                            )}
                            <canvas
                                id="canvas"
                                className={`canvas-${currentAction ?? ''}`}
                                ref={canvasDomRef}
                            />
                            {isRoadOperation && (
                                <div id="way1">
                                    <div>{!!operation.way2 && <Fragment><ArrowLeftIcon /><div><span>Sens 1</span><span>{operation.way2.name}</span></div></Fragment>}</div>
                                    <div><div><span>Sens 1</span><span>{operation.way1.name}</span></div><ArrowRightIcon /></div>
                                </div>
                            )}
                        </div>
                        <Legend />
                    </div >
                </Printable>
                {bottomPanel}
            </div>
            {!!hoverElements.length && (
                <div id="hover-elements-modal" className="md-hide">
                    {hoverElements.map((e, index) => <IdentityCard key={index} entity={e} />)}
                </div>
            )}
            <SampleDetail id={selectedSampleId ?? undefined} onClose={() => setSelectedSampleId(null)} compiled={!!activeFilters?.realtime} />
            <MarkerDetail id={selectedMarkerId ?? undefined} onClose={() => setSelectedMarkerId(null)} />
            <ModalRight
                visible={!!isPanelVisible}
                className="synoptique-filters-panel"
                actions={[
                    { label: 'Réinitialiser', color: 'secondary', onClick: () => setActiveFilters({ ...INITIAL_FILTERS }) },
                    { label: 'Fermer', onClick: () => setPanelVisible(false) },
                ]}
            >
                <Filters
                    filters={activeFilters}
                    withProblematic
                    isSynoptique
                    onChange={setActiveFilters}
                />
            </ModalRight >
            {isNotifyModalVisible && <MessageModal type={MessageType.OPERATION_UPDATE} message={`${operation.name} : synoptique mis à jour`} onClose={() => setNotifyModalVisible(false)} onSubmit={() => setNotifyModalVisible(false)} />}
        </Fragment >
    )
}

export default Synoptique;