import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CloseIcon, EditIcon, EyeIcon, TrashIcon } from '../../../assets/icons';
import { getStringValue } from '../../../utils/format';
import { getNestedField, toggleInArray } from '../../../utils/objects';
import Checkbox from '../../inputs/Checkbox';
import NoResult from '../../ui/NoResult';
import './index.scss';

export enum SmallListAction {
    VIEW = 'view',
    EDIT = 'edit',
    ACTIVATE = 'activate',
    DELETE = 'delete',
}

export const SmallListActionLabel: { [key in SmallListAction]: string } = {
    [SmallListAction.VIEW]: 'Voir',
    [SmallListAction.EDIT]: 'Modifier',
    [SmallListAction.ACTIVATE]: 'Active',
    [SmallListAction.DELETE]: 'Supprimer'
}

export interface SmallListColumn<T> {
    key: string,
    label: string,
    mapper?: (e: T) => ReactNode
}

export interface SmallListProps<T> {
    data?: T[];
    columns: SmallListColumn<T>[];
    actions?: SmallListAction[];
    onEdit?: (e: T) => void;
    onView?: (e: T) => void;
    onSelect?: (e: T) => void;
    onSelectMultiple?: (e: T[]) => void;
    onDelete?: (e: T) => void;
}

const SmallList = <T extends { _id: string }>({
    data,
    columns,
    actions,
    onEdit,
    onView,
    onSelect,
    onSelectMultiple,
    onDelete,
}: SmallListProps<T>) => {
    const [selectedElements, setSelectedElements] = useState<T[]>([]);

    const onAction = useCallback(async (action: SmallListAction, e: T) => {
        switch (action) {
            case SmallListAction.VIEW:
                if (onView) {
                    onView(e);
                }
                break;
            case SmallListAction.EDIT:
                if (onEdit) {
                    onEdit(e);
                }
                break;
            case SmallListAction.DELETE:
                if (onDelete) {
                    onDelete(e);
                }
                break;
            default:
        }
    }, [onView, onEdit, onDelete]);

    const handleSelect = useCallback((e: T) => {
        if (onSelect) {
            setSelectedElements([e]);
            onSelect(e);
        } else if (onSelectMultiple) {
            const _selectedElements = toggleInArray(e, selectedElements, (e1, e2) => e1._id === e2._id);
            setSelectedElements(_selectedElements);
            onSelectMultiple(_selectedElements);
        }
    }, [onSelect, onSelectMultiple, selectedElements]);

    const handleClick = useCallback((e: T) => {
        if (onView) {
            onView(e);
        } if (onSelect || onSelectMultiple) {
            handleSelect(e);
        }
    }, [onView, onSelect, onSelectMultiple, handleSelect]);

    const headers = useMemo(() => {
        return (
            <tr>
                {(!!onSelect || !!onSelectMultiple) && <th className="small-list-action" />}
                {!!columns?.length && columns.map(c => (
                    <th key={c.key}>{c.label}</th>
                ))}
                {!!actions?.includes(SmallListAction.VIEW) && <th className="small-list-action" />}
                {!!actions?.includes(SmallListAction.EDIT) && <th className="small-list-action" />}
                {!!actions?.includes(SmallListAction.DELETE) && <th className="small-list-action" />}
            </tr>
        )
    }, [actions, columns, onSelect, onSelectMultiple]);

    const rows = useMemo(() => data?.map(entity => (
        <tr
            key={entity._id}
            onClick={(e) => { e.stopPropagation(); handleClick(entity); }}
            className={onView || onSelect || onSelectMultiple ? 'pointer' : ''}
        >
            {(!!onSelect || !!onSelectMultiple) && (
                <td className="small-list-action">
                    <Checkbox
                        id={`select.${entity._id}`}
                        value={!!selectedElements?.some(s => s._id === entity._id)}
                        onChange={() => handleSelect(entity)}
                    />
                </td>
            )}
            {columns.map(c => (
                <td className={`list-td ${getNestedField(entity, c.key) === undefined ? 'no-content' : ''}`} key={`${entity._id}_${c.key}`}>
                    {c.mapper && typeof c.mapper === 'function'
                        ? c.mapper(entity)
                        : getStringValue(getNestedField(entity, c.key))
                    }
                </td>
            ))}
            {!!actions?.includes(SmallListAction.VIEW) && <td className="small-list-action" onClick={(e) => { e.stopPropagation(); onAction(SmallListAction.VIEW, entity); }}><EyeIcon /></td>}
            {!!actions?.includes(SmallListAction.EDIT) && <td className="small-list-action" onClick={(e) => { e.stopPropagation(); onAction(SmallListAction.EDIT, entity); }}><EditIcon /></td>}
            {!!actions?.includes(SmallListAction.DELETE) && <td className="small-list-action" onClick={(e) => { e.stopPropagation(); onAction(SmallListAction.DELETE, entity); }}><CloseIcon /></td>}
        </tr>
    ))
        , [data, actions, selectedElements, columns, onAction, handleSelect, onSelect, onSelectMultiple]);

    return (
        <div className="small-list">
            <table>
                <thead>
                    {headers}
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            {!data?.length && <NoResult text="Aucune donnée" />}
        </div>
    )
}

export default SmallList;