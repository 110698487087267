import { MouseEvent, ReactNode, useCallback } from "react";
import './index.scss';

export interface ButtonProps {
    type?: 'submit' | 'button',
    label?: string,
    color?: 'primary' | 'secondary' | 'disabled' | 'black' | 'success' | 'error',
    icon?: ReactNode,
    className?: string,
    onClick?: () => void,
    disabled?: boolean
}

const Button = ({
    type = 'button',
    label,
    color = 'primary',
    icon,
    className,
    onClick,
    disabled
}: ButtonProps) => {

    const handleClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (!disabled && onClick) onClick();
    }, [onClick, disabled]);

    return (
        <button
            type={type}
            onClick={handleClick}
            className={`button button-${color} ${className ?? ''} ${!label ? 'button-no-label' : ''}`}
            disabled={disabled}
        >
            {icon}
            {label && <span>{label}</span>}
        </button>
    );
}

export default Button;
