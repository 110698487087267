import { useCallback, useMemo } from "react";
import { SendIcon } from "../../assets/icons";
import { useOperationContext } from "../../context/OperationProvider";
import useForm, { FormComparator } from "../../hooks/useForm";
import useRequest from "../../hooks/useRequest";
import { MessageType } from "../../models/message";
import { StorageFile } from "../../models/storage";
import { getFullName } from "../../utils/format";
import CheckboxList from "../inputs/CheckboxList";
import { Textarea } from "../inputs/Textarea";
import Toggle from "../inputs/Toggle";
import Card from "../ui/Card";
import { Modal } from "../ui/Modal";
import './index.scss';
import { useAuthContext } from "../../context/AuthProvider";

interface MessageInterface {
    type: MessageType;
    message: string;
    email: boolean;
    private: boolean;
    sentTo: string[];
    file?: string;
}

const DEFAULT_MESSAGE: Partial<MessageInterface> = {
    type: MessageType.MESSAGE,
    email: true,
    private: false,
    sentTo: [],
}

const VALIDATION = {
    message: [{ comparator: FormComparator.REQUIRED }],
};

interface MessageModalProps {
    type: MessageType;
    associatedFile?: StorageFile;
    message?: string;
    onClose: () => void;
    onSubmit: () => void;
}

const MessageModal = ({
    type,
    associatedFile,
    message,
    onClose,
    onSubmit
}: MessageModalProps) => {
    const { user } = useAuthContext();
    const { operation } = useOperationContext();
    const request = useRequest();
    const { entity, attachInput, validate } = useForm<MessageInterface>({ ...DEFAULT_MESSAGE, message, type, file: associatedFile?._id });

    const handleSubmit = useCallback(async () => {
        const entity = validate(VALIDATION);
        if (!entity) return;

        request.post(`/operation/${operation._id}/message`, entity, {
            successMessage: 'Envoyé avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'envoi',
            loader: true
        })
            .then(onSubmit)
            .catch(() => null);
    }, [validate, operation, onSubmit]);

    const usersList = useMemo(() => (operation.users?.map(u => ({ key: u.user, label: getFullName(u.userPopulated) })) ?? []).filter(u => u.key !== user._id), [operation]);

    return (
        <Modal
            title="Envoyer un message"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Envoyer', onClick: handleSubmit, icon: <SendIcon /> },
            ]}
            id="message-modal"
        >
            <div id="message-modal-message">
                <Card title="Message">
                    {!!associatedFile && <span><strong>Fichier concerné :</strong> {associatedFile.name}</span>}
                    <Textarea rows={6} placeholder="Message à joindre à l'email" {...attachInput('message')} />
                </Card>
            </div>
            <div id="message-modal-options">
                <Card>
                    <Toggle label="Notifier par email" {...attachInput('email')} />
                    <Toggle label="Message privé" {...attachInput('private')} />
                    {entity.private && (
                        <div className="form-group">
                            <label htmlFor="sentTo">Utilisateurs à notifier</label>
                            <CheckboxList items={usersList} {...attachInput('sentTo')} />
                        </div>
                    )}
                </Card>
            </div>
        </Modal>
    )
}

export default MessageModal;