import { useEffect, useState } from 'react';
import { FormBaseProps } from '../../../hooks/useForm';
import { PasswordHideIcon, PasswordShowIcon } from '../../../assets/icons';
import './index.scss';
import TextInput from '../TextInput';

const regex = { upper: /[A-Z]/, lower: /[a-z]/, digit: /[0-9]/, min: /.{8,32}/ };

interface PasswordProps extends FormBaseProps<string> {
    placeholder?: string;
    withProgressBar?: boolean;
    withWarnings?: boolean;
}

const Password = ({
    id,
    value,
    placeholder,
    onChange,
    withProgressBar,
    withWarnings,
    errors
}: PasswordProps) => {
    const [errorsToDisplay, setErrorsToDisplay] = useState<string[]>([]);
    const [warning, setWarning] = useState<string | undefined>();
    const [securityLevel, setSecurityLevel] = useState<number>(0);
    const [isProgressBarVisible, setProgressBarVisible] = useState<boolean>(false);
    const [isPasswordShown, setPasswordShown] = useState<boolean>(false);

    const handleChange = (value?: string) => {
        if (!value || !withWarnings) {
            setSecurityLevel(0);
        } else {
            let _warnings = [];
            if (!value.match(regex.min)) {
                _warnings.push('entre 8 et 32 caractères');
            }
            if (!value.match(regex.upper)) {
                _warnings.push('1 majuscule');
            }
            if (!value.match(regex.lower)) {
                _warnings.push('1 minuscule');
            }
            if (!value.match(regex.digit)) {
                _warnings.push('1 chiffre');
            }

            setSecurityLevel(4 - _warnings.length);
            if (_warnings.length) {
                setWarning('Doit comporter ' + _warnings.join(', '));
                setErrorsToDisplay([]);
            } else {
                setWarning(undefined);
                setErrorsToDisplay([]);
            }
        }

        onChange(value);
    }

    useEffect(() => {
        setErrorsToDisplay(errors ?? []);
    }, [errors]);

    return (
        <div className="form-password">
            <TextInput
                autoComplete={false}
                id={id}
                type={isPasswordShown ? "text" : "password"}
                onChange={handleChange}
                onFocus={() => setProgressBarVisible(true)}
                onBlur={() => setProgressBarVisible(false)}
                value={value ?? ''}
                placeholder={placeholder ?? ''}
                errors={(!withProgressBar || !isProgressBarVisible) ? errorsToDisplay : []}
                warnings={(!withProgressBar || !isProgressBarVisible) && warning ? [warning] : []}
                icon={isPasswordShown
                    ? <PasswordShowIcon onClick={() => setPasswordShown(false)} />
                    : <PasswordHideIcon onClick={() => setPasswordShown(true)} />
                }
                onIconClick={() => setPasswordShown(!isPasswordShown)}
            />
            {withProgressBar && isProgressBarVisible && (
                <div className="progress-bar"><div className={`progress security${securityLevel}`} /></div>
            )}
        </div>
    );
}

export default Password;