import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BugIcon, FileIcon, MessageIcon, NotificationIcon, OperationIcon, PermissionIcon } from "../assets/icons";
import PaginationComponent from "../components/data/Pagination";
import useRequest from "../hooks/useRequest";
import { Notification, NotificationType } from "../models/notification";
import { PaginatedResults, Pagination } from "../models/pagination";
import { formatTimestampToLocalString, getFullName } from "../utils/format";

interface NotificationsProps {

}

const Notifications = ({ }: NotificationsProps) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [pagination, setPagination] = useState<Pagination | null>(null);
    const [hasUnread, setHasUnread] = useState<boolean>(false);
    const navigate = useNavigate();
    const trackingRefresh = useRef<NodeJS.Timeout | null>(null);
    const request = useRequest();

    const getNotifications = async (page?: number) => {
        request.get<PaginatedResults<Notification>>('/notification', { params: { page: page ?? 1, perPage: 6 } })
            .then((data) => {
                setNotifications(data.data);
                setPagination(data.pagination);
            })
            .catch(() => null);
    }

    const notificationsComponents = useMemo(() => notifications.map(notification => {
        switch (notification.type) {
            case NotificationType.FILE:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/operation/${notification.operation}/boite-aux-lettres`)}
                    >
                        <FileIcon />
                        <div>
                            <span>Un nouveau fichier <strong>{notification.filePopulated?.name ?? ''}</strong> a été publié dans l'opération <strong>{notification.operationPopulated?.name ?? ''}</strong></span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.MESSAGE:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/operation/${notification.operation}/boite-aux-lettres`)}
                    >
                        <MessageIcon />
                        <div>
                            <span>Nouveau message de <strong>{getFullName(notification.createdByPopulated)}</strong>{notification.operationPopulated ? <span> concernant l'opération <strong>{notification.operationPopulated?.name ?? ''}</strong></span> : ''}</span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.INCIDENT:
                // TODO page
                return (
                    <div
                        key={notification._id}
                        className="notification"
                    >
                        <BugIcon />
                        <div>
                            <span>Demande d'étude d'incident {notification.data?.loggingId ?? ''} par <strong>{getFullName(notification.createdByPopulated)}</strong> : {notification.data?.message}</span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.PERMISSION:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/utilisateurs/${notification.createdBy}/editer`)}
                    >
                        <PermissionIcon />
                        <div>
                            <span>Demande d'accès à la permission <strong>{notification.data}</strong> par {getFullName(notification.createdByPopulated)}</span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.OPERATION_UPDATE:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/operation/${notification.operation}/synoptique`)}
                    >
                        <OperationIcon className="stroke" />
                        <div>
                            <span>Le synoptique de l'opération <strong>{notification.operationPopulated?.name ?? ''}</strong> a été mis à jour par {getFullName(notification.createdByPopulated)}</span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.OPERATION_DATA:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/operation/${notification.operation}/tableau-de-bord`)}
                    >
                        <OperationIcon className="stroke" />
                        <div>
                            <span><strong>{getFullName(notification.createdByPopulated)}</strong> a rédigé de nouvelles données en attente de validation dans l'opération <strong>{notification.operationPopulated?.name ?? ''}</strong></span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
            case NotificationType.OPERATION_ADD:
                return (
                    <div
                        key={notification._id}
                        className="notification"
                        onClick={() => navigate(`/operation/${notification.operation}`)}
                    >
                        <OperationIcon className="stroke" />
                        <div>
                            <span><strong>{getFullName(notification.createdByPopulated)}</strong> vous a invité a collaborer à l'opération <strong>{notification.operationPopulated?.name ?? ''}</strong></span>
                            <span>{formatTimestampToLocalString(notification.createdAt)}</span>
                        </div>
                    </div>
                );
        }
    })
        , [notifications]);

    useEffect(() => {
        getNotifications();
        trackingRefresh.current = setInterval(getNotifications, 300000);

        return () => {
            trackingRefresh.current && clearInterval(trackingRefresh.current);
        }
    }, []);

    return (
        <div id="header-notifications" className={hasUnread ? 'unread' : ''}>
            <span className="sm-hide">Notifications</span>
            <NotificationIcon />
            {!!notifications?.length && (
                <div id="notifications-container">
                    <div id="notifications">
                        {notificationsComponents}
                    </div>
                    <PaginationComponent pagination={pagination} onPageChange={(page) => getNotifications(page)} />
                </div>
            )}
        </div>
    )
}

export default Notifications;