import { ReactNode } from 'react';
import Breadcrumbs, { Breadcrumb } from '../Breadcrumb';
import './index.scss';

interface HeaderProps {
    children?: ReactNode;
    breadcrumbs: Breadcrumb[];
}

const Header = ({
    children,
    breadcrumbs
}: HeaderProps) => (
    <div id="header">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {children}
    </div>
);

export default Header;