import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from "../../../assets/icons/back-arrow.svg";
import './index.scss';
import { Fragment } from 'react';
import { HomeIcon } from '../../../assets/icons';

export type Breadcrumb = { label: string, href?: string };

interface BreadcrumbsProps {
    breadcrumbs: Breadcrumb[];
}

const Breadcrumbs = ({
    breadcrumbs
}: BreadcrumbsProps) => {
    const navigate = useNavigate()

    return (
        <div id="breadcrumbs" className="sm-hide">
            <BackIcon id="breadcrumb-back-icon" onClick={() => navigate(-1)} />
            <HomeIcon id="breadcrumb-home-icon" onClick={() => navigate('/')} />
            <div className="breadcrumb-delimiter" />
            {breadcrumbs.map((breadcrumb, i) => i === breadcrumbs.length - 1
                ? <span key={i}>{breadcrumb.label}</span>
                : (
                    <Fragment key={i}>
                        <Link to={breadcrumb.href ?? '.'} key={i}>{breadcrumb.label}</Link>
                        <div className="breadcrumb-delimiter" />
                    </Fragment>
                )
            )}
        </div>
    );
}

export default Breadcrumbs;