import Lottie from "lottie-react";
import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import spinnerJson from "../assets/spinner.json";
import { ModalHelperModal } from "../components/ui/ModalHelper";
import Toast, { ToastData, ToastType } from "../components/ui/Toast";

type GlobalContextType = {
    setToast: (t: ToastData) => void,
    setToastError: (message: string, error?: any) => void,
    setToastSuccess: (message: string) => void,
    isMenuCollapsed: boolean,
    toggleMenu: () => void,
    closeMenu: () => void,
    setSpinnerVisible: (b: boolean) => void,
    setHelper: (t: string | null) => void,
};

const GlobalContext = createContext<GlobalContextType>({
    setToast: () => null,
    setToastError: () => null,
    setToastSuccess: () => null,
    isMenuCollapsed: false,
    toggleMenu: () => null,
    closeMenu: () => null,
    setSpinnerVisible: (b: boolean) => null,
    setHelper: (t: string | null) => null,
});

const GlobalProvider = ({ children }: { children: ReactNode }) => {
    const [isMenuCollapsed, setMenuCollapsed] = useState<boolean>(false);
    const [isSpinnerVisible, setSpinnerVisible] = useState<boolean>(false);
    const [toast, setToast] = useState<ToastData | null>(null);
    const [helper, setHelper] = useState<string | null>(null);

    const setToastError = useCallback((message: string, error?: any) => setToast({ type: ToastType.ERROR, message, error }), []);
    const setToastSuccess = useCallback((message: string) => setToast({ type: ToastType.SUCCESS, message }), []);

    const toggleMenu = useCallback(() => setMenuCollapsed(!isMenuCollapsed), [isMenuCollapsed]);

    return (
        <GlobalContext.Provider value={{
            setToast,
            setToastError,
            setToastSuccess,
            isMenuCollapsed,
            toggleMenu,
            closeMenu: () => setMenuCollapsed(false),
            setSpinnerVisible,
            setHelper
        }}>
            {children}
            <Toast toast={toast} onClose={() => setToast(null)} />
            <div id="spinner-container" className={isSpinnerVisible ? 'visible' : ''}>
                <Lottie animationData={spinnerJson} />
            </div>
            <ModalHelperModal text={helper ?? undefined} onClose={() => setHelper(null)} />
        </GlobalContext.Provider>
    );
};

const useGlobalContext = () => useContext<GlobalContextType>(GlobalContext);

export { GlobalProvider, useGlobalContext };
export default GlobalContext;