import { Coordinates } from "../models/location";

export const average = (array: number[]) =>
    Array.isArray(array) && array.length
        ? array.reduce((sum, a) => Number(a) + sum, 0) / array.length
        : NaN;

export const roundDecimals = (num: number, decimals: number = 2) =>
    !isNaN(num) && num !== null
        ? Math.round((num + Number.EPSILON) * Math.pow(10, decimals)) /
          Math.pow(10, decimals)
        : "";

export const isPointOnSegment = (
    map: google.maps.Map,
    gpsPoint1: { lat: number; lng: number },
    gpsPoint2: { lat: number; lng: number },
    gpsPoint: { lat: number; lng: number }
) => {
    var p1 = map.getProjection()?.fromLatLngToPoint(gpsPoint1);
    var p2 = map.getProjection()?.fromLatLngToPoint(gpsPoint2);
    var p = map.getProjection()?.fromLatLngToPoint(gpsPoint);

    if (!p1 || !p2 || !p) return;

    var t_x;
    var t_y;
    //Parametric form of line equation is:
    //--------------------------------
    //      x = x1 + t(x2-x1)
    //      y = y1 + t(y2-y1)
    //--------------------------------
    //'p' is on [p1,p2] segment,if 't' is number from [0,1]
    //-----Case 1----
    //      x = x1
    //      y = y1
    //---------------
    if (p2.x - p1.x == 0 && p2.y - p1.y == 0) {
        return p.x == p1.x && p.y == p1.y;
    }
    //-----Case 2----
    //      x = x1
    //      y = y1 + t(y2-y1)
    //---------------
    else if (p2.x - p1.x == 0 && p2.y - p1.y != 0) {
        t_y = (p.y - p1.y) / (p2.y - p1.y);
        return p.x == p1.x && t_y >= 0 && t_y <= 1;
    }
    //-----Case 3----
    //      x = x1 + t(x2-x1)
    //      y = y1
    //---------------
    else if (p2.x - p1.x != 0 && p2.y - p1.y == 0) {
        t_x = (p.x - p1.x) / (p2.x - p1.x);
        return p.y == p1.y && t_x >= 0 && t_x <= 1;
    }
    //-----Case 4----
    //      x = x1 + t(x2-x1)
    //      y = y1 + t(y2-y1)
    //---------------
    t_x = (p.x - p1.x) / (p2.x - p1.x);
    t_y = (p.y - p1.y) / (p2.y - p1.y);
    return t_x >= 0 && t_x <= 1 && t_y >= 0 && t_y <= 1;
};

// Converts numeric degrees to radians
const toRad = (value: number) => (value * Math.PI) / 180;
const distanceBetweenPoints = (point1: Coordinates, point2: Coordinates) => {
    var R = 6371; // km
    var dLat = toRad(point2.latitude - point1.latitude);
    var dLon = toRad(point2.longitude - point1.longitude);
    var lat1 = toRad(point1.latitude);
    var lat2 = toRad(point2.latitude);

    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
            Math.sin(dLon / 2) *
            Math.cos(lat1) *
            Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
};

export const getPrFromSegment = (
    point: Coordinates,
    segmentStart: Coordinates & { pr: number },
    segmentEnd: Coordinates
) => {
    const distanceSegment = distanceBetweenPoints(segmentStart, segmentEnd);
    const distancePoint = distanceBetweenPoints(segmentStart, point);

    return distanceSegment && distancePoint
        ? segmentStart.pr + distancePoint / distanceSegment
        : segmentStart.pr;
};
