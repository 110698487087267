import { DndContext, DragEndEvent, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveIcon } from "../../../assets/icons";
import DuplicatesModal from '../../../components/ModalDuplicates';
import LotModal from '../../../components/ModalSearchEntity/ModalSearchLot';
import EntityCard from "../../../components/entities/EntityCard";
import { IdentityBlockLot } from '../../../components/entities/IdentityBlock';
import RevisionCard from "../../../components/entities/RevisionCard";
import DatePickerInput from '../../../components/inputs/DatePicker';
import PositionPicker from '../../../components/inputs/PositionPicker';
import PrInput from "../../../components/inputs/PrInput";
import Select from '../../../components/inputs/Select';
import Switch from '../../../components/inputs/Switch';
import TextInput from '../../../components/inputs/TextInput';
import Button from '../../../components/ui/Button';
import Card from "../../../components/ui/Card";
import Header from '../../../components/ui/Header';
import IconLink from '../../../components/ui/IconLink';
import ScrollableContent from "../../../components/ui/ScrollableContent";
import { useOperationContext } from '../../../context/OperationProvider';
import useForm, { FormComparator } from '../../../hooks/useForm';
import useRequest from "../../../hooks/useRequest";
import { Lot } from "../../../models/lot";
import { Phase, PhaseLabel, RoadPosition, RoadPositionsList } from '../../../models/operation';
import { Controle, Controles, Layer, Sample, SampleLabels, SampleType } from '../../../models/sample';
import { RevisionStatus } from "../../../models/shared";
import LayerComponent, { LayerAction } from './Layer';
import './index.scss';

const DEFAULT_SAMPLE = { type: SampleType.CAROTTE, phase: Phase.ETUDE, controle: Controle.EXTERIEUR, active: true, problematic: false, way: 1, roadPosition: RoadPosition.AXE };
const DEFAULT_LAYER = { active: true, problematic: false, collage: true, thickness: 0, order: 0 };
const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }],
    date: [{ comparator: FormComparator.REQUIRED }],
    type: [{ comparator: FormComparator.REQUIRED }],
    phase: [{ comparator: FormComparator.REQUIRED }],
    diameter: [{ comparator: FormComparator.POSITIVE_NUMBER }]
};
const VALIDATION_TYPE_1 = {
    'location.way': [{ comparator: FormComparator.REQUIRED }],
    'location.road': [{ comparator: FormComparator.REQUIRED }]
};
const VALIDATION_TYPE_2 = {
    marker: [{ comparator: FormComparator.REQUIRED }],
    'marker.longitude': [{ comparator: FormComparator.REQUIRED }],
    'marker.latitude': [{ comparator: FormComparator.REQUIRED }],
    location: [{ comparator: FormComparator.REQUIRED }]
};

const SampleEdit = () => {
    const { operation, isRoadOperation, operationPermissions } = useOperationContext();
    const { operationId, page, sampleId, phase, sampleType, lotId } = useParams();
    const request = useRequest();
    const navigate = useNavigate();
    const [isLotModalVisible, setLotModalVisible] = useState<boolean>(false);
    const [possibleDuplicates, setPossibleDuplicates] = useState<Sample[] | null>(null);
    const { entity, attachInput, setEntity, validate, onChange, onChangeMultiple, errors, hasChanged, setChanged } = useForm<Sample>({});
    const sensors = useSensors(useSensor(PointerSensor));
    const prRules = useMemo(() => [
        { comparator: FormComparator.REQUIRED },
        { comparator: FormComparator.PR },
        { comparator: FormComparator.PR_INSIDE, compareTo: { min: entity?.lotPopulated?.zone?.prStart, max: entity?.lotPopulated?.zone?.prEnd }, message: 'Le PR est hors des limites du lot' },
    ], [entity.lotPopulated]);

    const handleLotChange = useCallback((lot: Lot) => {
        onChangeMultiple([
            { field: 'lot', value: lot._id },
            { field: 'lotPopulated', value: lot },
            { field: 'fullLot', value: lot.fullLot },
            { field: 'location', value: { ...entity.location, way: lot.way, road: lot.roads?.length ? lot.roads[0] : undefined } },
            { field: 'layers', value: [{ ...(entity.layers?.[0] ?? {}), material: lot.material, materialPopulated: lot.materialPopulated } as Layer] },
        ])
        setLotModalVisible(false);
    }, [setEntity, entity]);

    const handleWayChange = useCallback((way: number) => {
        onChangeMultiple([
            { field: 'location.way', value: way },
            { field: 'location.road', value: undefined },
        ])
        setLotModalVisible(false);
    }, [onChangeMultiple]);

    const save = useCallback(async (sample: Sample, close?: boolean) => {
        const dto = {
            ...sample,
            layers: sample.layers?.map((layer, index) => ({
                ...layer,
                order: index
            }))
        }
        setPossibleDuplicates([]);

        const create = !sample._id;
        const requestMethod = create ? request.post : request.put;

        requestMethod<Sample>('/sample', dto, {
            successMessage: create ? 'Echantillon créé avec succès' : 'Echantillon mis à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                    setEntity(data);
                }
            })
            .catch(() => null);
    }, [setEntity]);

    const handleSubmit = useCallback(async (close?: boolean) => {
        const validation = {
            ...VALIDATION,
            ...(isRoadOperation
                ? { ...VALIDATION_TYPE_1, 'location.pr': prRules }
                : VALIDATION_TYPE_2
            )
        };
        const entity = validate(validation);
        if (!entity) return;

        if (!entity?._id && isRoadOperation) {
            request.post<Sample[]>(`/sample/duplicates`, {
                ...entity,
                layers: [],
            }, { loader: true, errorMessage: 'Une erreur est survenue lors de la recherche de duplicatas.' })
                .then((samples) => {
                    if (!samples?.length) {
                        setPossibleDuplicates([]);
                        save(entity, close);
                    } else {
                        setPossibleDuplicates(samples);
                    }
                })
                .catch(() => setPossibleDuplicates([]));
        } else {
            save(entity, close);
        }
    }, [validate, isRoadOperation, prRules, save]);

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        if (!entity.layers?.length) return;

        const { active, over } = event;

        if (over !== null && active.id !== over.id) {
            const oldIndex = entity.layers.findIndex(l => l.order === active.id);
            const newIndex = entity.layers.findIndex(l => l.order === over.id);

            onChange('layers', arrayMove(entity.layers, oldIndex, newIndex))
        }
    }, [onChange, entity]);

    const handleActionLayer = useCallback((action: LayerAction, index: number) => {
        const _layers = [...entity.layers ?? []];
        switch (action) {
            case 'delete':
                _layers.splice(index, 1);
                if (!_layers.length) {
                    _layers.push({ ...DEFAULT_LAYER, order: 1 });
                }
                onChange('layers', _layers);
                return;
            case 'copy':
                _layers.splice(index + 1, 0, { ..._layers[index], order: Math.max(...(entity?.layers ?? []).map(l => l.order)) + 1, analysis: undefined, analysisPopulated: undefined, melange: undefined, comment: undefined, problematic: false, collage: true, fissure: undefined, fracture: undefined });
                onChange('layers', _layers);
                return;
            default:
        }
    }, [onChange, entity]);

    const handleChangeLayer = useCallback((layer: Partial<Layer>, index: number) => {
        if (index >= (entity?.layers?.length ?? 0)) {
            return;
        }

        const _layers = [...entity?.layers ?? []];
        _layers[index] = layer as Layer;
        onChange('layers', _layers);
    }, [onChange, entity]);

    const addLayer = useCallback(() => {
        onChange('layers', [...(entity?.layers ?? []), { ...DEFAULT_LAYER, order: Math.max(...(entity?.layers ?? []).map(l => l.order)) + 1 }]);
    }, [onChange, entity]);


    useEffect(() => {
        if (sampleId) {
            request.get<Sample>(`/sample/${sampleId}${page === 'dupliquer' ? '/duplicate' : ''}`, { errorMessage: 'Une erreur est survenue lors de la récupération de l\'échantillon.', loader: true })
                .then(setEntity)
                .catch(() => navigate(-1));
        } else if (lotId) {
            request.get<Lot>(`/lot/${lotId}`, { errorMessage: 'Une erreur est survenue lors de la récupération de l\'échantillon.', loader: true })
                .then((lot) => {
                    setEntity({
                        ...DEFAULT_SAMPLE,
                        status: operationPermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
                        type: sampleType as SampleType,
                        phase: Phase.TRAVAUX,
                        operation: operationId,
                        name: lot?.materialPopulated?.name ?? '',
                        date: lot?.date ?? '',
                        lot: lot._id,
                        lotPopulated: lot,
                        fullLot: lot?.fullLot,
                        location: {
                            way: lot?.way,
                            road: lot?.roads?.length === 1 ? lot.roads[0] : undefined,
                            roadPosition: RoadPosition.AXE,
                        },
                        layers: [{
                            ...DEFAULT_LAYER,
                            material: lot?.material,
                            thickness: sampleType === SampleType.CAROTTE ? lot?.thickness ?? 0 : 0,
                        }],
                        controle: operationPermissions.controles[0] ?? Controle.EXTERIEUR
                    });
                })
                .catch(() => navigate(-1));
        } else {
            setEntity({
                ...DEFAULT_SAMPLE,
                status: operationPermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
                operation: operation._id,
                layers: [DEFAULT_LAYER],
                type: sampleType as SampleType,
                phase: phase as Phase,
                controle: operationPermissions.controles[0] ?? Controle.EXTERIEUR
            });
        }
    }, []);

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: operation?.name, href: `/operation/${operation?._id}` },
                    { label: PhaseLabel[(phase ?? 'travaux') as Phase], href: `/operation/${operation?._id}/${phase ?? 'travaux'}/${entity?.type ?? sampleType}` },
                    ...(entity?.lotPopulated
                        ? [{ label: 'Lot ' + entity.lotPopulated.fullLot, href: `/operation/${operation?._id}/travaux/lot/${entity.lotPopulated._id}/detail/${entity?.type ?? sampleType}` }]
                        : []
                    ),
                    { label: entity?._id ? SampleLabels[entity.type ?? sampleType as SampleType].edit : SampleLabels[entity?.type ?? sampleType as SampleType]?.create ?? '' }
                ]}
            >
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={<SaveIcon />}
                            onClick={handleSubmit}
                        />
                        <Button
                            label="Enregistrer et fermer"
                            icon={<SaveIcon />}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            {entity?.phase === Phase.TRAVAUX && (
                <IdentityBlockLot
                    lot={entity.lotPopulated}
                    onClick={!lotId ? () => setLotModalVisible(true) : undefined}
                />
            )}
            <ScrollableContent
                side={
                    <EntityCard entity={entity} attachInput={attachInput}>
                        <RevisionCard<Sample> entity={entity} attachInput={attachInput} />
                    </EntityCard>
                }
            >
                <Card title="Informations générales">
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="name">Nom *</label>
                            <TextInput {...attachInput('name')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="date">Date *</label>
                            <DatePickerInput max={new Date()} withIcon {...attachInput('date')} />
                        </div>
                        {entity?.type === SampleType.CAROTTE && (
                            <div className="input-column">
                                <label htmlFor="diameter">Diametre carottage (mm)</label>
                                <TextInput {...attachInput('diameter')} />
                            </div>
                        )}
                    </div>
                </Card>
                <Card title="Localisation">
                    {isRoadOperation ? (
                        <Fragment>
                            <div className="row">
                                <div className="input-column">
                                    <label htmlFor="way">Sens *</label>
                                    <Select<number>
                                        id="way"
                                        value={entity.location?.way}
                                        items={operation.ways}
                                        disabled={!!entity?.lot}
                                        onChange={(way) => way ? handleWayChange(way) : null}
                                        errors={errors.way}
                                    />
                                </div>
                                <div className="input-column">
                                    <label htmlFor="road">Voie *</label>
                                    <Select
                                        items={entity.location?.way
                                            ? operation.roads.filter(r => r.way === entity.location!.way && (!entity.lotPopulated || entity.lotPopulated?.roads?.includes(r._id)))
                                            : []
                                        }
                                        disabled={!entity.location?.way}
                                        {...attachInput('location.road')}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-column">
                                    <label htmlFor="roadPosition">Emplacement *</label>
                                    <Select items={RoadPositionsList} {...attachInput('location.roadPosition')} />
                                </div>
                                <div className="input-column">
                                    <label htmlFor="pr">PR *</label>
                                    <PrInput onBlurValidationError={prRules} {...attachInput('location.pr')} />
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="input-column">
                            <label htmlFor="pr">Repère kilométrique</label>
                            <PrInput onBlurValidationError={prRules} {...attachInput('location.pr')} />
                        </div>
                    )}
                    <PositionPicker
                        type="location"
                        location={entity?.location?.location}
                        marker={entity?.location?.coordinates}
                        initialPosition={entity?.location?.coordinates ?? entity?.lotPopulated?.zone?.marker}
                        backgroundPolygon={entity?.lotPopulated?.zone?.polygon}
                        onChange={onChange}
                        errors={errors}
                    />
                </Card>
                <Card title="Analyse">
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="controle">Contrôle *</label>
                            <Switch
                                className="form-switch"
                                items={Controles.filter(c => operationPermissions.controles.includes(c.key))}
                                {...attachInput('controle')}
                            />
                        </div>
                        <div className="input-column">
                            <label htmlFor="laboratory">Laboratoire</label>
                            <TextInput {...attachInput('laboratory')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="problematic">Problématique</label>
                            <Switch<boolean>
                                disabled={entity.phase && [Phase.TRAVAUX, Phase.EXPERTISE].includes(entity.phase)}
                                items={[{ key: true, label: 'Oui' }, { key: false, label: 'Non' }]}
                                {...attachInput('problematic')}
                            />
                        </div>
                    </div>
                </Card>
                <Card title="Couches">
                    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext items={(entity.layers ?? [])?.map(l => l.order + 1)} strategy={verticalListSortingStrategy}>
                            {entity?.layers?.map((layer, index) => (
                                <LayerComponent
                                    key={`item-${index}`}
                                    layer={layer}
                                    sample={entity}
                                    onChange={(l) => handleChangeLayer(l, index)}
                                    onAction={(action) => handleActionLayer(action, index)}
                                />
                            ))}
                        </SortableContext>
                    </DndContext>
                    {entity.type !== SampleType.PRELEVEMENT && !entity?.lot && (
                        <IconLink type="add" label="Ajouter une couche" onClick={() => addLayer()} />
                    )}
                </Card>
            </ScrollableContent>
            {isLotModalVisible && (
                <LotModal
                    onSubmit={handleLotChange}
                    onClose={() => setLotModalVisible(false)}
                />
            )}
            {!!possibleDuplicates?.length && (
                <DuplicatesModal
                    onSubmit={() => save(entity as Sample)}
                    onClose={() => setPossibleDuplicates([])}
                    duplicates={possibleDuplicates}
                />
            )}
        </Fragment >
    );
}

export default SampleEdit;