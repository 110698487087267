import { Scatter } from 'react-chartjs-2';
import ProgressBar from '../../../../components/data/Statistics/ProgressBar';
import Expandable from '../../../../components/ui/Expandable';
import FakeData from '../../../../components/ui/FakeData';
import FakeDataTable from '../../../../components/ui/FakeData/FakeDataTable';
import Printable from '../../../../components/ui/Printable';
import { useOperationContext } from '../../../../context/OperationProvider';
import { MinMaxAverage } from '../../../../models/analysis';
import { Population, PopulationColor, PopulationTypeLabel } from '../../../../models/population';
import { ControleLabel, Controles } from '../../../../models/sample';
import { Permission } from '../../../../models/user';
import { formatNumberToFixedDecimal } from '../../../../utils/format';
import { floatToPrString } from '../../../../utils/pr';
import { Lot } from '../../../../models/lot';

interface PopulationStatisticsProps {
    population: Population;
    lot: Lot;
}

const PopulationStatistics = ({ population, lot }: PopulationStatisticsProps) => {
    const { operation, operationPermissions } = useOperationContext();

    return (
        <div className="lot-population-content" style={{ borderLeft: `${PopulationColor[population.type]} 4px solid` }}>
            <Printable
                title={PopulationTypeLabel[population.type]}
                subtitle={operation.name}
                filename={PopulationTypeLabel[population.type]}
                data={[
                    { label: 'Lot', value: population.fullLot },
                    { label: 'Matériau', value: lot.materialPopulated?.name ?? '-' },
                    { label: 'PR', value: `${floatToPrString(lot?.zone?.prStart)} - ${floatToPrString(lot?.zone?.prEnd)}` },
                    { label: 'Type de mesure', value: PopulationTypeLabel[population.type] },
                ]}
            >
                {operationPermissions.synoptiqueData ? (
                    <div className="data-table">
                        <h3>Représentation des valeurs</h3>
                        <div className="data-chart">
                            <Expandable>
                                <Scatter
                                    data={{
                                        datasets: [
                                            {
                                                label: 'Externe',
                                                data: population?.controle?.externe?.data?.map(d => ({ x: d.location.pr, y: d.value, problematic: d.problematic })) ?? [],
                                                backgroundColor: '#0c2c40',
                                                borderColor: '#0c2c40',
                                                pointRadius: 3,
                                                pointBackgroundColor: (context) => (context?.raw as any)?.problematic ? 'red' : '#0c2c40'
                                            },
                                            {
                                                label: 'Exterieur',
                                                data: population?.controle?.exterieur?.data?.map(d => ({ x: d.location.pr, y: d.value, problematic: d.problematic })) ?? [],
                                                backgroundColor: '#397d92',
                                                borderColor: '#397d92',
                                                pointRadius: 3,
                                                pointBackgroundColor: (context) => (context?.raw as any)?.problematic ? 'red' : '#397d92'
                                            },
                                            {
                                                label: 'Autre',
                                                data: population?.controle?.autre?.data?.map(d => ({ x: d.location.pr, y: d.value, problematic: d.problematic })) ?? [],
                                                backgroundColor: '#2c6070',
                                                borderColor: '#2c6070',
                                                pointRadius: 3,
                                                pointBackgroundColor: (context) => (context?.raw as any)?.problematic ? 'red' : '#2c6070'
                                            },
                                        ],
                                    }}
                                    options={{
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: 'bottom'
                                            },
                                            tooltip: {
                                                callbacks: {
                                                    label: (item) => `${item.dataset?.label} : ${item.formattedValue} (PR ${item.label})`
                                                }
                                            }
                                        },
                                        maintainAspectRatio: false,
                                        scales: {
                                            y: {
                                                min: 0,
                                            }
                                        }
                                    }}
                                />
                            </Expandable>
                        </div>
                        <h3>Conformité</h3>
                        <table>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>à 95%</td>
                                    <td>à 100%</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Controles.map(controle => (
                                    <tr key={controle.key}>
                                        <td>{ControleLabel[controle.key]}</td>
                                        <td>{population.controle?.[controle.key]?.compliance95 === false
                                            ? 'Non conforme'
                                            : population.controle?.[controle.key]?.compliance95 === true ? 'Conforme' : ''}
                                        </td>
                                        <td>{population.controle?.[controle.key]?.compliance100 === false
                                            ? 'Non conforme'
                                            : population.controle?.[controle.key]?.compliance100 === true ? 'Conforme' : ''}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <h3>Statistiques</h3>
                        <table>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Minimum</td>
                                    <td>Maximum</td>
                                    <td>Moyenne</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Controles.map(controle => (
                                    <tr key={controle.key} className="col">
                                        <td>{ControleLabel[controle.key]}</td>
                                        {['min', 'max', 'average'].map(field => (
                                            <td key={field}>
                                                <span className="data-value">{formatNumberToFixedDecimal(population.controle?.[controle.key]?.statistics?.[field as keyof MinMaxAverage])}</span>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                <tr>
                                    <td><strong>Global</strong></td>
                                    {['min', 'max', 'average'].map(field => (
                                        <td key={field}>
                                            <span className="data-value">{formatNumberToFixedDecimal(population.statistics?.[field as keyof MinMaxAverage])}</span>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                        <h3>Données brutes</h3>
                        <div className="row">
                            {Controles.map(controle => (
                                <div key={controle.key} className="col">
                                    <table>
                                        <thead>
                                            <tr><td colSpan={4}><strong>{ControleLabel[controle.key]}</strong></td></tr>
                                            <tr>
                                                <td>PR</td>
                                                <td>Valeur</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(population.controle?.[controle.key]?.data ?? []).sort((d1, d2) => (d1.location?.pr ?? 0) > (d2.location?.pr ?? 0) ? 1 : -1).map((datum, index) => (
                                                <tr key={index}>
                                                    <td>{floatToPrString(datum.location?.pr)}</td>
                                                    <td className={datum.problematic ? 'problematic' : ''}>
                                                        <ProgressBar value={datum.value} maximum={population.statistics?.max} minimum={population.statistics?.min} cursor={population.statistics?.average} />
                                                        {formatNumberToFixedDecimal(datum.value)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <FakeData permission={Permission.SYNOPTIQUE_DATA}><FakeDataTable /></FakeData>
                )}
            </Printable>
        </div>
    )
}

export default PopulationStatistics;