import { Fragment, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveIcon } from '../../assets/icons';
import EntityCard from '../../components/entities/EntityCard';
import Select from '../../components/inputs/Select';
import SelectAutocomplete from '../../components/inputs/SelectAutocomplete';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Header from '../../components/ui/Header';
import ScrollableContent from '../../components/ui/ScrollableContent';
import { useGlobalContext } from '../../context/GlobalProvider';
import useForm, { FormComparator } from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { Role, Roles, User } from '../../models/user';
import { getFullName } from '../../utils/format';

const VALIDATION = {
    firstName: [{ comparator: FormComparator.REQUIRED }],
    lastName: [{ comparator: FormComparator.REQUIRED }],
    email: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.EMAIL }],
    role: [{ comparator: FormComparator.REQUIRED }],
    business: [{ comparator: FormComparator.REQUIRED }],
};

interface UserEditProps {
}

const UserEdit = ({ }: UserEditProps) => {
    const { setToastError } = useGlobalContext();
    const request = useRequest();
    const navigate = useNavigate();
    const { id } = useParams();
    const { entity, attachInput, validate, setEntity, onChange, hasChanged, setChanged } = useForm<User>({ active: true, role: Role.USER });

    const handleSubmit = useCallback(async (close?: boolean) => {
        const entity = validate(VALIDATION)
        if (!entity) return;

        const create = !entity?._id;
        const requestMethod = create ? request.post : request.put;

        requestMethod<User>('/user', entity, {
            successMessage: create ? 'Utilisateur créé avec succès' : 'Utilisateur mis à jour avec succès',
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                    setEntity(data);
                }
            })
            .catch((e) => {
                if ((e as any)?.error?.status === 409) {
                    setToastError('Cet email est déjà utilisée par un utilisateur');
                } else {
                    setToastError('Une erreur est survenue lors de l\'enregistrement', e);
                }
            });

    }, [validate, setEntity]);

    useEffect(() => {
        if (!!id) {
            request.get<User>(`/user/${id}`, { loader: true, errorMessage: 'Une erreur est survenue lors de la récupération de l\'utilisateur' })
                .then(setEntity)
                .catch(() => navigate(`/utilisateurs`))
        }
    }, []);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Utilisateurs', href: '/utilisateurs' }, { label: entity?._id ? getFullName(entity as User) : 'Nouvel utilisateur' }]}>
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={<SaveIcon />}
                            onClick={handleSubmit}
                        />
                        <Button
                            label="Enregistrer et fermer"
                            icon={<SaveIcon />}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            <ScrollableContent side={<EntityCard entity={entity} attachInput={attachInput} />}>
                <Card title="Informations générales">
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="firstName">Prénom *</label>
                            <TextInput {...attachInput('firstName')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="lastName">Nom *</label>
                            <TextInput {...attachInput('lastName')} />
                        </div>
                    </div>
                    <div className="input-column">
                        <label htmlFor="email">Email *</label>
                        <TextInput {...attachInput('email')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="business">Entreprise *</label>
                        <SelectAutocomplete endpoint="/business/list" {...attachInput('business')} />
                    </div>
                </Card>
                <Card title="Permissions">
                    <div className="input-column">
                        <label htmlFor="role">Role *</label>
                        <Select<Role> items={Roles} {...attachInput('role')} />
                    </div>
                </Card>
            </ScrollableContent>
        </Fragment>
    )
}

export default UserEdit;