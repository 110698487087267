import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import PopulationDetail from '../../../components/PopulationDetail';
import SampleDetail from '../../../components/SampleDetail';
import List, { FilterType, ListColumns } from '../../../components/data/List';
import { IdentityBlockLot } from '../../../components/entities/IdentityBlock';
import Header from '../../../components/ui/Header';
import Navigation from '../../../components/ui/Navigation';
import Tag from '../../../components/ui/Tag';
import { useOperationContext } from '../../../context/OperationProvider';
import useRequest from '../../../hooks/useRequest';
import { Lot } from '../../../models/lot';
import { Phase, RoadPositionLabel, RoadPositionsList } from '../../../models/operation';
import { Population, PopulationTypeLabel, PopulationTypes } from '../../../models/population';
import { Controles, Sample, SampleLabels, SampleType } from '../../../models/sample';
import { RevisionStatus, RevisionStatusList } from '../../../models/shared';
import { formatDate, formatNumberToFixedDecimal } from '../../../utils/format';
import { floatToPrString } from '../../../utils/pr';
import SampleList from '../Sample/SampleList';
import PopulationList from '../Population/PopulationList';

const PAGES = [{ key: 'prelevement', label: 'Prélèvements' }, { key: 'carotte', label: 'Carottes' }, { key: 'population', label: 'Populations' }]

const LotView = () => {
    const { operation, isRoadOperation, operationPermissions } = useOperationContext();
    const request = useRequest();
    const { lotId } = useParams();
    const navigate = useNavigate();
    const [page, setPage] = useState<string | null>(null);
    const [lot, setLot] = useState<Lot | null>(null);

    useEffect(() => {
        request.get<Lot>(`/lot/${lotId}`, { loader: true })
            .then(setLot)
            .catch(() => navigate(-1));
    }, [lotId]);

    if (!lot) {
        return (null)
    }

    return (
        <Fragment>
            <Header breadcrumbs={[
                { label: operation?.name, href: `/operation/${operation?._id}` },
                { label: 'Travaux', href: `/operation/${operation?._id}/travaux/lot` },
                { label: `Lot ${lot?.fullLot}${lot?.materialPopulated?.name ? ' - ' + lot?.materialPopulated?.name : ''}` }
            ]} />
            <Navigation pages={PAGES} setPage={setPage} />
            {!!page && page !== 'population' && <SampleList type={page as SampleType} phase={Phase.TRAVAUX} lot={lot} />}
            {page === 'population' && <PopulationList lot={lot} />}
        </Fragment>
    )
}

export default LotView;