import { FormEvent, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Password from '../../components/inputs/Password';
import Button from '../../components/ui/Button';
import { useGlobalContext } from '../../context/GlobalProvider';
import useRequest from '../../hooks/useRequest';

const Renew = () => {
    const { setToastError, setToastSuccess } = useGlobalContext();
    const [password, setPassword] = useState<string>('');
    const [passwordRepeat, setPasswordRepeat] = useState<string>('');
    const params = useParams();
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    const request = useRequest();

    const handleRenew = async (e?: FormEvent) => {
        e?.preventDefault();
        if (!password || !passwordRepeat) {
            setErrors(['Les champs sont obligatoires']);
        } else
            if (password !== passwordRepeat) {
                setErrors(['Les mots de passes sont différents']);
            } else if (
                !password.match(/[A-Z]/)
                || !password.match(/[a-z]/)
                || !password.match(/[0-9]/)
                || !password.match(/.{8,32}/)
            ) {
                setErrors(['Le mot de passe n\'est pas au bon format']);
            } else {
                request.patch('/user/renew-password', { password, token: params.token }, { loader: true, successMessage: 'Le mot de passe a bien été modifié', errorMessage: 'Une erreur est survenue. Veuillez contacter l\'administrateur.' })
                    .then(() => navigate('/'))
                    .catch(() => null)
                    .finally(() => setErrors([]));
            }
    }

    return (
        <div id="login">
            <h4>Générer un nouveau mot de passe</h4>
            <form autoComplete="off" onSubmit={handleRenew}>
                <div className="form-group">
                    <label htmlFor="newPassword">Nouveau mot de passe *</label>
                    <Password
                        id="newPassword"
                        value={password ?? ''}
                        withProgressBar
                        withWarnings
                        onChange={(value) => setPassword(value ?? '')}
                        errors={errors}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="newPasswordRepeat">Répéter le mot de passe *</label>
                    <Password
                        id="newPasswordRepeat"
                        value={passwordRepeat ?? ''}
                        onChange={(value) => setPasswordRepeat(value ?? '')}
                        errors={errors}
                    />
                </div>
                <Link to="/"> Retour au formulaire de connexion</Link>
                <Button
                    label="Mettre à jour mon mot de passe"
                    type="submit"
                />
            </form>
        </div>
    );
}

export default Renew;
