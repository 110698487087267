import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import List, { FilterType } from '../../components/data/List';
import Header from '../../components/ui/Header';
import { Tags } from '../../components/ui/Tag';
import { useAuthContext } from '../../context/AuthProvider';
import { Business, BusinessTypes, ProducerTypes, ProvidedMaterials } from '../../models/business';
import { Role } from '../../models/user';
import { formatTimestamp } from '../../utils/format';

const Businesses = () => {
    const navigate = useNavigate();
    const { permissions, user } = useAuthContext();

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Annuaire' }]} />
            <List<Business>
                columns={[
                    { key: 'name', label: 'Nom' },
                    { key: 'types', label: 'Type', mapper: (e) => <Tags values={e.types} items={BusinessTypes} /> },
                    { key: 'postCode', label: 'Siège social', mapper: (element) => `${element.address ? element.address + ' ' : ''}${element.postCode ? element.postCode + ' ' : ''}${element.city ?? ' '}` },
                    { key: 'usersCount', label: 'Nombre d\'utilisateurs' },
                    { key: 'createdAt', label: 'Date de création', mapper: (element) => formatTimestamp(element.createdAt) },
                ]}
                dataEndpoint="/business"
                crudEndpoint="/business"
                baseUrl="/annuaire"
                actions={{
                    edit: permissions.administrate,
                    activate: permissions.superAdministrate,
                    delete: permissions.superAdministrate,
                }}
                warningBeforeDelete
                withSearch
                filters={[
                    { field: 'active', label: 'Actif' },
                    { field: 'types', label: 'Types', type: FilterType.MULTIPLE_SELECT, items: BusinessTypes },
                    { field: 'providedMaterials', label: 'Fourniture', type: FilterType.MULTIPLE_SELECT, items: ProvidedMaterials },
                    { field: 'producerType', label: 'Borne', type: FilterType.MULTIPLE_SELECT, items: ProducerTypes },
                ]}
                initialPagination={{ sortBy: 'name', sortDirection: 1 }}
                buttons={[{ label: "Nouvelle entreprise", onClick: () => navigate('/annuaire/creer') }]}
            />
        </Fragment>
    )
}

export default Businesses;