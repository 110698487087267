import { useEffect, useState } from 'react';
import { FormBaseProps } from '../../hooks/useForm';

interface NumberInputProps extends FormBaseProps<number> {
    placeholder?: string;
    autoComplete?: boolean;
}

const NumberInput = ({
    id,
    value,
    placeholder,
    disabled,
    onChange,
    errors,
    autoComplete
}: NumberInputProps) => {
    const [errorsToDisplay, setErrorsToDisplay] = useState<string[]>([]);
    const [warnings, setWarnings] = useState<string[]>([]);

    useEffect(() => {
        setErrorsToDisplay(errors ?? []);
    }, [errors]);

    return (
        <div className="form-input-text">
            <input
                autoComplete={autoComplete ? "on" : "off"}
                id={id}
                type="number"
                readOnly={!!disabled}
                onChange={(e) => onChange(e.target.value !== '' && !isNaN(Number(e.target.value)) ? Number(e.target.value) : undefined)}
                value={value ?? ''}
                placeholder={placeholder ?? ''}
                className={`border-input ${!!errorsToDisplay?.length ? 'input-error' : (!!warnings?.length ? 'input-warning' : '')}`}
            />
            <div className="form-error">{
                !!errorsToDisplay?.length
                    ? errorsToDisplay[0] + '. '
                    : <span className="form-warning">{!!warnings?.length ? warnings.join('. ') + '. ' : ''}</span>
            }
            </div>
        </div>
    );
}

export default NumberInput;