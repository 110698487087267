import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import MelangeDetail from '../../../components/MelangeDetail';
import List, { FilterType, ListColumns } from '../../../components/data/List';
import Header from '../../../components/ui/Header';
import Navigation from '../../../components/ui/Navigation';
import Tag from '../../../components/ui/Tag';
import { useOperationContext } from '../../../context/OperationProvider';
import { Melange, MelangeTypeLabel } from '../../../models/melange';
import { Phase, PhaseLabel } from '../../../models/operation';
import { Controles, SampleType } from '../../../models/sample';
import { RevisionStatus, RevisionStatusList } from '../../../models/shared';
import { formatDate } from '../../../utils/format';
import MarkerList from '../Map/MarkerList';
import SampleList from '../Sample/SampleList';

const PAGES = [
    { key: 'carotte', label: 'Carottes' },
    { key: 'sondage', label: 'Sondages' },
    { key: 'melange', label: 'Mélanges' },
    { key: 'point-d-interet', label: 'Point d\'intérêt' }
];

const StudyFollowUp = () => {
    const { operation, isRoadOperation, operationPermissions } = useOperationContext();
    const location = useLocation();
    const [page, setPage] = useState<string | null>(null);
    const [phase, setPhase] = useState<Phase | null>(null);
    const [selectedMelange, setSelectedMelange] = useState<Melange | null>(null);

    useEffect(() => {
        setPhase(location.pathname?.split('/')?.[3] as Phase);
    }, [location]);

    if (!phase) return null;

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: operation.name, href: `/operation/${operation._id}` },
                    { label: PhaseLabel[phase as Phase] }
                ]}
            />
            <Navigation pages={PAGES} setPage={setPage} />
            {page === 'point-d-interet' && <MarkerList phase={phase} />}
            {(page === 'carotte' || page === 'sondage') && <SampleList type={page as SampleType} phase={phase as Phase} />}
            {page === 'melange' && (
                <List<Melange>
                    columns={[
                        { key: 'name', label: 'Nom' },
                        { key: 'problematic', label: 'Prob.', mapper: (element) => element.problematic ? <WarningIcon /> : (null) },
                        { key: 'status', label: 'Statut', mapper: (element) => <Tag value={element.status} items={RevisionStatusList} /> },
                        { key: 'controle', label: 'Contrôle', mapper: (element) => <Tag value={element.controle} items={Controles} /> },
                        { key: 'type', label: 'Type', mapper: (element) => MelangeTypeLabel[element.type] ?? '' },
                        ...(isRoadOperation
                            ? [
                                { key: 'way', label: 'Sens', mapper: (element) => element?.way ? operation.waysObj[element?.way]?.label : '' },
                            ] : []) as ListColumns<Melange>[],
                        { key: 'date', label: 'Date', mapper: (element) => formatDate(element.date) },
                        { key: 'samples', label: 'Nombre de carottes', mapper: (element) => element.samples?.length ?? '' },
                    ]}
                    dataEndpoint={`/melange/operation/${operation._id}/${phase}`}
                    crudEndpoint="/melange"
                    withSearch
                    baseUrl={`/operation/${operation._id}/${phase}/melange`}
                    actions={{
                        comment: operationPermissions.write,
                        edit: (e) => operationPermissions.write && (operationPermissions.validate || e.status !== RevisionStatus.VALIDATED),
                        view: (e) => operationPermissions.write && (operationPermissions.validate || e.status !== RevisionStatus.VALIDATED),
                        activate: operationPermissions.administrate,
                        duplicate: operationPermissions.write,
                        delete: operationPermissions.administrate
                    }}
                    onView={operationPermissions.read ? setSelectedMelange : undefined}
                    initialPagination={{ sortBy: 'date', sortDirection: -1 }}
                    filters={[
                        { field: 'active', label: 'Actif' },
                        { field: 'status', label: 'Statut', type: FilterType.MULTIPLE_SELECT, items: RevisionStatusList },
                        { field: 'controle', label: 'Contrôle', type: FilterType.MULTIPLE_SELECT, items: Controles },
                        { field: 'analysis', label: 'Avec analyse', type: FilterType.SWITCH, items: [{ key: true, label: 'Oui' }, { key: false, label: 'Non' }] },
                        { field: 'problematic', label: 'Problématique', type: FilterType.SWITCH, items: [{ key: true, label: 'Oui' }, { key: false, label: 'Non' }] },
                    ]}
                />
            )}
            <MelangeDetail id={selectedMelange?._id ?? undefined} onClose={() => setSelectedMelange(null)} />
        </Fragment>
    )
}

export default StudyFollowUp;