import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import StatisticsPicker from '../../../components/data/Statistics/StatisticsPicker';
import { IdentityBlockMaterial } from '../../../components/entities/IdentityBlock';
import Card from '../../../components/ui/Card';
import Header from '../../../components/ui/Header';
import NoResult from '../../../components/ui/NoResult';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { useOperationContext } from '../../../context/OperationProvider';
import useRequest from '../../../hooks/useRequest';
import { AnalysisStatistics } from '../../../models/analysis';
import { Material, MaterialType, MaterialTypeLabel } from '../../../models/material';
import Granulometrie from './Granulometrie';
import { Permission } from '../../../models/user';

const ByMaterialResults = () => {
    const request = useRequest();
    const { operation, operationPermissions } = useOperationContext();
    const { materialId } = useParams();
    const navigate = useNavigate();
    const [material, setMaterial] = useState<Material | null>(null);
    const [statistics, setStatistics] = useState<AnalysisStatistics[] | null>(null);

    useEffect(() => {
        request.get<Material>(`/material/${materialId}`, { errorMessage: 'Une erreur est survenue lors de la récupération de l\'exigence.', loader: true })
            .then(setMaterial)
            .catch(() => navigate(-1));
        request.get<AnalysisStatistics[]>(`/analysis/material/${materialId}`, { errorMessage: 'Une erreur est survenue lors de la récupération des analyses.' })
            .then(setStatistics)
            .catch(() => setStatistics(null))
    }, [operation, materialId]);

    if (!material) {
        return (null);
    }

    return (
        <Fragment>
            <Header breadcrumbs={[
                { label: operation?.name, href: `/operation/${operation?._id}` },
                { label: 'Résultats matériaux', href: `/operation/${operation?._id}/resultats/materiaux/${material.type}` },
                { label: `${MaterialTypeLabel[material.type] ?? ''} ${material.name}` }
            ]} />
            <IdentityBlockMaterial
                material={material}
                onClick={() => navigate(`/operation/${operation._id}/exigence/${material.type}/${material._id}/editer`)}
            />
            <ScrollableContent>
                {statistics && !statistics.length
                    ? (
                        <NoResult
                            text="Aucun résultat pour ce matériau"
                        />
                    ) : (
                        <Fragment>
                            <Granulometrie material={material} />
                            {statistics && !!statistics.length && (
                                <Card title="Résultats">
                                    <StatisticsPicker
                                        statistics={statistics}
                                        materialType={material.type}
                                        subtitle={operation.name}
                                        columns={
                                            [MaterialType.ENROBE, MaterialType.SOL, MaterialType.BETON, MaterialType.EMULSION_BITUME].includes(material.type)
                                                ? ['date', 'pr', 'road', 'roadPosition', 'business', 'controle']
                                                : ['date', 'business', 'controle']
                                        }
                                        chartAxis="date"
                                        chartByOptions={['controle', 'business']}
                                        permission={Permission.MATERIAL_RESULTS}
                                        isAuthorized={operationPermissions.materialResults}
                                    />
                                </Card>
                            )}
                        </Fragment>
                    )}
            </ScrollableContent>
        </Fragment>
    )
}

export default ByMaterialResults;
