import { Fragment, useCallback, useRef } from 'react';
import { ReactComponent as RemoveIcon } from '../../assets/icons/close-o.svg';
import { Material, MaterialCharacteristic, MaterialCharacteristicElement } from '../../models/material';
import { TabSectionSubTitles, TabSectionTitles } from '../ui/TabSection';
import './index.scss';
import Card from '../ui/Card';

interface ComplianceMinMaxProps {
    id: string;
    specifications: MaterialCharacteristicElement;
    value?: any;
    onChange: (key: string, value: any) => void;
}

export const Compliance = ({
    id,
    specifications,
    value,
    onChange
}: ComplianceMinMaxProps) => (
    <div className="charac-block">
        <div className="charac-title">{specifications.label}{!!specifications.unit && <small> ({specifications.unit})</small>}</div>
        <div className="charac-content">
            <div className="charac-header">
                <span>95%: min</span>
                <span>95%: max</span>
                <span>100%: min</span>
                <span>100%: max</span>
            </div>
            <div className="charac-row">
                <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={value?.min95 ?? ''} onChange={(event) => onChange(id + '.min95', Number(event.target.value))} /></div>
                <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={value?.max95 ?? ''} onChange={(event) => onChange(id + '.max95', Number(event.target.value))} /></div>
                <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={value?.min100 ?? ''} onChange={(event) => onChange(id + '.min100', Number(event.target.value))} /></div>
                <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={value?.max100 ?? ''} onChange={(event) => onChange(id + '.max100', Number(event.target.value))} /></div>
            </div>
        </div>
    </div>
);

export const ObjectifMinMax = ({
    id,
    specifications,
    value,
    onChange
}: ComplianceMinMaxProps) => (
    <div className="charac-block">
        <div className="charac-title">{specifications.label}{!!specifications.unit && <small> ({specifications.unit})</small>}</div>
        <div className="charac-content">
            <div className="charac-header">
                {!!specifications.withValCons && <span>Nbr. valeurs consécutives NC</span>}
                <span>Objectif</span>
                <span>Min</span>
                {!specifications.onlyMin && <span>Max</span>}
            </div>
            <div className="charac-row">
                {!!specifications.withValCons && <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={value?.nombreValeursConsecutives ?? ''} onChange={(event) => onChange(id + '.nombreValeursConsecutives', Number(event.target.value))} /></div>}
                <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={value?.objectif ?? ''} onChange={(event) => onChange(id + '.objectif', Number(event.target.value))} /></div>
                <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={value?.min ?? ''} onChange={(event) => onChange(id + '.min', Number(event.target.value))} /></div>
                {!specifications.onlyMin && <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={value?.max ?? ''} onChange={(event) => onChange(id + '.max', Number(event.target.value))} /></div>}
            </div>
        </div>
    </div>
);

const SEUIL_INIT = { seuil: '', objectif: '', min: '', max: '' };

export const SeuilObjectifMinMax = ({
    id,
    specifications,
    value = [{ ...SEUIL_INIT }],
    onChange
}: ComplianceMinMaxProps) => {
    const handleChange = (key: string, fieldValue: number, fieldIndex: number) => {
        const _value = [...value];
        if (fieldIndex < (value?.length ?? 0)) {
            _value[fieldIndex][key] = fieldValue;
            onChange(id, _value);
        } else {
            onChange(id, [..._value, { ...SEUIL_INIT, [key]: fieldValue }]);
        }
    }

    const removeRow = (index: number) => {
        if (value?.length > 0) {
            const _value = [...value];
            _value.splice(index, 1);
            onChange(id, _value);
        }
    }

    return (
        <div className="charac-block">
            <div className="charac-title">{specifications.label}{!!specifications.unit && <small> ({specifications.unit})</small>}</div>
            <div className="charac-content">
                <div className="charac-header">
                    <span>{specifications.seuil ?? 'Seuil'}{specifications.seuilUnit && <small> ({specifications.seuilUnit})</small>}</span>
                    <span>Objectif</span>
                    <span>Min</span>
                    {!specifications.onlyMin && <span>Max</span>}
                </div>
                {[...(value ?? []), { ...SEUIL_INIT }].map((v, index) => (
                    <div className="charac-row" key={index}>
                        {index < value?.length && <RemoveIcon onClick={() => removeRow(index)} />}
                        <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={v?.seuil ?? ''} onChange={(event) => handleChange('seuil', Number(event.target.value), index)} /></div>
                        <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={v?.objectif ?? ''} onChange={(event) => handleChange('objectif', Number(event.target.value), index)} /></div>
                        <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={v?.min ?? ''} onChange={(event) => handleChange('min', Number(event.target.value), index)} /></div>
                        {!specifications.onlyMin && <div><input type="number" onWheel={(e) => e.currentTarget.blur()} value={v?.max ?? ''} onChange={(event) => handleChange('max', Number(event.target.value), index)} /></div>}
                    </div>
                ))}
            </div>
        </div>
    )
}

interface CharacProps {
    form: MaterialCharacteristic[];
    onChange?: (id: string, value: any) => void;
    material: Partial<Material>;
}

const Charac = ({
    form,
    onChange,
    material
}: CharacProps) => {
    const tabsRef = useRef<{ [k: string]: HTMLDivElement }>({});

    const goToRef = useCallback((id: string) => {
        if (tabsRef?.current?.[id]) {
            tabsRef.current[id].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, []);

    const CharacBlockComponent = useCallback(({ material, onChange, goToRef }: { material: Partial<Material>, onChange: (id: string, value: any) => void, goToRef: (id: string) => void }) =>
    (
        <Fragment>
            {form.map(group => (
                <Fragment key={group.key}>
                    <TabSectionTitles
                        ref={el => el ? tabsRef.current[group.key] = el : null}
                        tabs={form.map(c => ({ key: c.key, label: c.label }))}
                        current={group.key}
                        onClick={goToRef}
                    />
                    <TabSectionSubTitles
                        tabs={group.elements}
                        onClick={goToRef}
                    />
                    <div className="charac-container">
                        {group.elements.map(element => element.compliance ? (
                            <div className="charac-ref-block" key={element.key} ref={el => el ? tabsRef.current[element.key] = el : null}>
                                <Compliance
                                    specifications={element}
                                    value={material.characteristics?.[element.key]}
                                    id={element.key}
                                    onChange={onChange}
                                />
                            </div>
                        ) : element.seuil ? (
                            <div className="charac-ref-block" key={element.key} ref={el => el ? tabsRef.current[element.key] = el : null}>
                                <SeuilObjectifMinMax
                                    specifications={element}
                                    value={material.characteristics?.[element.key]}
                                    id={element.key}
                                    onChange={onChange}
                                />
                            </div>
                        ) : (
                            <div className="charac-ref-block" key={element.key} ref={el => el ? tabsRef.current[element.key] = el : null}>
                                <ObjectifMinMax
                                    specifications={element}
                                    value={material.characteristics?.[element.key]}
                                    id={element.key}
                                    onChange={onChange}
                                />
                            </div>
                        ))}
                    </div>
                </Fragment>
            ))}
        </Fragment>
    )

        , [form]);

    return (
        <Card title="Caractéristiques">
            <CharacBlockComponent material={material} onChange={onChange ?? (() => null)} goToRef={goToRef} />
        </Card>
    )
}

export default Charac