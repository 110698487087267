import { Polygon } from '@react-google-maps/api';
import { useCallback, useState } from 'react';
import Map, { MapAction, getCoordinatesFromPolygon } from '../../../components/data/Map/Map';
import { FormHookReturn } from '../../../hooks/useForm';
import { Coordinates } from '../../../models/location';
import { OperationDto } from '../../../models/operation';
import './index.scss';

interface ZoneProps extends Pick<FormHookReturn<OperationDto>, 'entity' | 'onChange' | 'attachInput'> { }

const Zone = ({
    entity,
    onChange,
    attachInput
}: ZoneProps) => {
    const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);

    const handlePolygonChange = useCallback((marker: Coordinates, polygon?: Coordinates[]) => {
        onChange('zone', { ...entity.zone, marker, polygon });
    }, [entity, onChange]);

    const handlePolygonEdit = useCallback((polygon: google.maps.Polygon) => {
        onChange('zone.polygon', getCoordinatesFromPolygon(polygon));
    }, [onChange]);

    return (
        <div className="container" id="operation-zone">
            <Map
                onActionComplete={(_, marker, polygon) => handlePolygonChange(marker, polygon)}
                availableActions={[MapAction.POLYGON]}
                center={entity.zone?.marker}
                withSearch
            >
                {entity.zone?.polygon && (
                    <Polygon
                        onLoad={setPolygon}
                        onMouseUp={() => polygon && handlePolygonEdit(polygon)}
                        paths={entity.zone.polygon.map(c => ({ lat: c.latitude, lng: c.longitude }))}
                        options={{
                            editable: true,
                            strokeWeight: 1,
                        }}
                    />
                )}
            </Map>
        </div>
    )
}

export default Zone;