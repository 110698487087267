import { Autocomplete, Circle, DrawingManager, GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { CrosshairIcon, PolygonIcon, TimelineIcon } from '../../../assets/icons';
import { ReactComponent as AddIcon } from '../../../assets/icons/map/marker.svg';
import { useOperationContext } from '../../../context/OperationProvider';
import { Coordinates } from '../../../models/location';
import { Phase, Phases } from '../../../models/operation';
import Menu, { MenuBar, MenuBarGrow, MenuItem } from '../../ui/Menu';
import Checkbox from '../../inputs/Checkbox';
import SearchInput from './SearchInput';
import './index.scss';

export enum MapAction {
    POSITION = 'position',
    MARKER = 'marker',
    POLYGON = 'polygon',
};

// eslint-disable-next-line no-undef
export const SCALED_SIZE = {
    width: 26,
    height: 35
} as google.maps.Size;
// eslint-disable-next-line no-undef
export const ANCHOR = {
    x: 13,
    y: 33
} as google.maps.Point;

const CENTER_DEFAULT = { lng: 5.426389023414817, lat: 43.526108460152955 };

const options = {
    mapTypeId: 'roadmap',
    controlSize: 24,
    clickableIcons: false,
    fullscreenControl: true,
    fullscreenControlOptions: {
        position: 9.0
    },
    mapTypeControlOptions: {
        position: 10.0
    },
};

const circleOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 10,
    zIndex: 1
}

const apiLoaderOptions = {
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY ?? 'AIzaSyDgjIT2ii00bCg3VZb_eMUHqZEznR67NAo',
    language: 'fr',
    region: 'fr',
    libraries: ['places', 'geometry', 'drawing', 'visualization']
};

export const getCoordinatesFromPolygon = (polygon: google.maps.Polygon): Coordinates[] => {
    if (!polygon?.getPath) return [];
    const polygonBounds = polygon.getPath();
    // Get each coordinates for path
    let coords: Coordinates[] = [];
    polygonBounds.forEach(p => {
        coords.push({
            latitude: p.lat(),
            longitude: p.lng()
        });
    });
    return coords;
}

interface MapProps {
    center?: Coordinates;
    initialAction?: MapAction;
    availableActions?: MapAction[];
    withSearch?: boolean;
    withUserPosition?: boolean;
    withZone?: boolean;
    onActionComplete?: (a: MapAction, marker: Coordinates, polygon?: Coordinates[]) => void;
    onMapLoaded?: (ref: google.maps.Map) => void;
    phases?: Phase[];
    onPhaseChange?: (p: Phase[]) => void;
    children: ReactNode;
}

const Map = ({
    center,
    initialAction,
    availableActions,
    withSearch,
    withUserPosition,
    withZone,
    onActionComplete,
    onMapLoaded,
    phases,
    onPhaseChange,
    children
}: MapProps) => {
    const { operation } = useOperationContext();
    const { isLoaded } = useJsApiLoader(apiLoaderOptions as any);
    const [userPosition, setUserPosition] = useState<Coordinates | null>(null);
    const [watcherId, setWatcherId] = useState<number | null>(null);
    const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({ ...CENTER_DEFAULT });
    const [mapZoom, setMapZoom] = useState(12);
    const [currentAction, setCurrentAction] = useState<MapAction | null>(initialAction ?? null);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

    const handleMapLoad = useCallback((map: google.maps.Map) => {
        setMap(map);

        if (onMapLoaded) onMapLoaded(map);
    }, [onMapLoaded]);

    const onPlaceChanged = () => {
        // Get place from search box
        const place = autocomplete?.getPlace();

        if (!place?.geometry) return;

        if (place.geometry.viewport) {
            // If place is zone
            map?.fitBounds(place.geometry.viewport);
        } else {
            // If place is marker
            if (place.geometry.location) {
                map?.setCenter(place.geometry.location);
                map?.setZoom(14);
            }
        }
    };

    const handlePolygonDone = (polygon: google.maps.Polygon) => {
        const coords = getCoordinatesFromPolygon(polygon);
        setCurrentAction(null);
        const center = map?.getCenter();
        if (onActionComplete && center) {
            onActionComplete(
                MapAction.POLYGON,
                {
                    longitude: center.lng(),
                    latitude: center.lat(),
                    zoom: map?.getZoom()
                },
                coords
            );
        }

        // Remove created polygon from map after draw
        polygon.setMap(null);
    }

    const handleMarkerDone = (marker: google.maps.Marker) => {
        const position = marker.getPosition();
        if (onActionComplete && position) {
            onActionComplete(MapAction.MARKER, {
                longitude: position.lng(),
                latitude: position.lat(),
                zoom: map?.getZoom()
            });
        }

        // Remove created marker from map after draw
        marker.setMap(null);
    }

    const handleCenterMyPosition = () => {
        if (userPosition) {
            setMapCenter({ lng: userPosition.longitude, lat: userPosition.latitude });
            setMapZoom(20);
        }
    }

    const handlePhaseChange = (phase: Phase) => {
        const _phases = [...phases ?? []];
        const index = _phases.indexOf(phase);
        if (index > -1) {
            _phases.splice(index, 1);
        } else {
            _phases.push(phase)
        }

        if (onPhaseChange) onPhaseChange(_phases);
    }

    useEffect(() => {
        if (center?.longitude && center?.latitude) {
            setMapCenter({ lng: center.longitude, lat: center.latitude });
        } else if (operation?.zone?.marker) {
            setMapCenter({ lng: operation.zone.marker.longitude, lat: operation.zone.marker.latitude });
        }

        if (center?.zoom) {
            setMapZoom(center.zoom);
        } else if (operation?.zone?.marker?.zoom) {
            setMapZoom(operation.zone.marker.zoom);
        }
    }, [center]);

    useEffect(() => {
        if (navigator.geolocation && withUserPosition && !watcherId) {
            const _watcherId = navigator.geolocation.watchPosition((position) => {
                setUserPosition({ latitude: position.coords.latitude, longitude: position.coords.longitude })
            });
            setWatcherId(_watcherId);
        }
        return () => {
            if (watcherId !== null) navigator.geolocation.clearWatch(watcherId);
        }
    }, []);

    return (
        <div className="map-container">
            <MenuBar>
                <MenuBarGrow>
                    {withSearch && isLoaded && (
                        <Autocomplete
                            onLoad={setAutocomplete}
                            onPlaceChanged={onPlaceChanged}
                        >
                            <SearchInput />
                        </Autocomplete>
                    )}
                    {!!onPhaseChange && (
                        <Menu label="Phases" icon={<TimelineIcon />}>
                            {Phases.map(p => (
                                <MenuItem key={p.key}>
                                    <Checkbox
                                        id="phases"
                                        value={phases?.includes(p.key)}
                                        label={p.label}
                                        onChange={() => handlePhaseChange(p.key)}
                                    />
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                </MenuBarGrow>
                {availableActions?.includes(MapAction.POLYGON) && (
                    <Menu
                        label="Tracer une zone"
                        icon={<PolygonIcon />}
                        onClick={() => setCurrentAction(currentAction === MapAction.POLYGON ? null : MapAction.POLYGON)}
                        active={currentAction === MapAction.POLYGON}
                    />
                )}
                {availableActions?.includes(MapAction.MARKER) && (
                    <Menu
                        label="Ajouter un élément"
                        icon={<AddIcon />}
                        onClick={() => setCurrentAction(currentAction === MapAction.MARKER ? null : MapAction.MARKER)}
                        active={currentAction === MapAction.MARKER}
                    />
                )}
                {availableActions?.includes(MapAction.POSITION) && (
                    <Menu
                        label="Centrer la carte"
                        icon={<CrosshairIcon />}
                        onClick={handleCenterMyPosition}
                    />
                )}
            </MenuBar>
            {!!isLoaded && (
                <GoogleMap
                    mapContainerClassName="map"
                    center={mapCenter}
                    options={options}
                    zoom={mapZoom}
                    onLoad={handleMapLoad}
                >
                    {withZone && operation?.zone?.polygon && (
                        <Polygon
                            paths={operation.zone.polygon.map(c => ({ lat: c.latitude, lng: c.longitude }))}
                            options={{ strokeWeight: 3, clickable: false, fillOpacity: 0 }}
                        />
                    )}
                    {withUserPosition && userPosition && (
                        <Circle
                            center={{ lng: userPosition.longitude, lat: userPosition.latitude }}
                            options={circleOptions}
                        />
                    )}
                    {(currentAction === MapAction.MARKER || currentAction === MapAction.POLYGON) && onActionComplete && (
                        <DrawingManager
                            drawingMode={currentAction as any}
                            options={{ drawingControl: false }}
                            onMarkerComplete={handleMarkerDone}
                            onPolygonComplete={handlePolygonDone}
                        />
                    )}
                    {children}
                </GoogleMap>
            )}
        </div>
    );
}

export default Map;