import { saveAs } from 'file-saver';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { AddRedIcon, DownloadIcon, InfoSquareIcon, OptionsIcon, SendIcon, TrashIcon } from '../../../assets/icons';
import MessageModal from '../../../components/MessageModal';
import Switch from '../../../components/inputs/Switch';
import Toggle from '../../../components/inputs/Toggle';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Header from '../../../components/ui/Header';
import Menu from '../../../components/ui/Menu';
import ModalDelete from '../../../components/ui/Modal/ModalDelete';
import NoResult from '../../../components/ui/NoResult';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { useAuthContext } from '../../../context/AuthProvider';
import { useGlobalContext } from '../../../context/GlobalProvider';
import { useOperationContext } from '../../../context/OperationProvider';
import useForm from '../../../hooks/useForm';
import useRequest from '../../../hooks/useRequest';
import { Message, MessageType } from '../../../models/message';
import { StorageFile } from '../../../models/storage';
import { formatTimestamp, getFullName } from '../../../utils/format';
import './index.scss';
import FileModal from '../../../components/FileModal';

interface MessageInterface {
    message: string;
    email: boolean;
    private: false;
    type: MessageType;
}

const DEFAULT_MESSAGE: MessageInterface = {
    message: '',
    email: false,
    private: false,
    type: MessageType.MESSAGE
}

const MailBox = () => {
    const { operation, operationPermissions } = useOperationContext();
    const { setToastError } = useGlobalContext();
    const { user } = useAuthContext();
    const request = useRequest();
    const [files, setFiles] = useState<StorageFile[]>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [messageType, setMessagesType] = useState<string | undefined>('public');
    const [isOptionsPanelVisible, setOptionsPanelVisible] = useState<boolean>(false);
    const [isFileModalVisible, setFileModalVisible] = useState<boolean>(false);
    const [fileForDelete, setFileForDelete] = useState<StorageFile | null>(null);
    const [fileToNotify, setFileForNotify] = useState<StorageFile | null>(null);
    const { attachInput, entity, onChange, setEntity } = useForm<MessageInterface>({ ...DEFAULT_MESSAGE });
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const messageRef = useRef<HTMLDivElement>(null);

    const getFiles = useCallback(async () => request
        .get<StorageFile[]>(`/operation/${operation._id}/files`, { loader: true, errorMessage: 'Une erreur est survenue lors de la récupération des documents.' })
        .then(setFiles)
        .catch(() => null), [operation]);

    const getMessages = useCallback(async () => request
        .get<Message[]>(`/operation/${operation._id}/messages/${messageType !== 'public' ? 'private' : ''}`, { loader: true, errorMessage: 'Une erreur est survenue lors de la récupération des messages.' })
        .then(setMessages)
        .catch(() => null), [operation, messageType]);

    const handleSendMessage = useCallback(() => {
        request.post<Message>(
            `/operation/${operation._id}/message`,
            { ...entity, operation: operation._id },
            { loader: true, errorMessage: 'Une erreur est survenue lors de l\'envoi du message', successMessage: 'Message envoyé avec succès' })
            .then(() => {
                getMessages();
                setEntity({ ...DEFAULT_MESSAGE });
            })
            .catch(() => null);
    }, [getMessages, entity]);

    const handleDelete = useCallback(async (file: StorageFile) => request
        .delete(`/operation/${operation._id}/file/${file._id}`, { loader: true, errorMessage: 'Une erreur est survenue lors de la suppression.', successMessage: 'Document supprimé avec succès' })
        .then(() => {
            getFiles();
            setFileForDelete(null);
        })
        .catch(() => null)
        , [operation, getFiles]);

    const handleDownload = useCallback(async (file: StorageFile) => request
        .get<string>(`/storage/${file._id}/download`, { responseType: 'blob', timeout: 30000, loader: true })
        .then((data) => {
            saveAs(data, file.originalFileName);
            getFiles();
        })
        .catch((e) => {
            if (e?.error?.status === 404) {
                setToastError('Le fichier n\'a pas été trouvé.', e)
            } else {
                setToastError('Une erreur est survenue lors du téléchargement.', e);
            }
        })
        , [getFiles]);

    const handleSubmit = useCallback(() => {
        setFileModalVisible(false);
        setFileForNotify(null);
        getFiles();
        getMessages();
    }, [getFiles, getMessages]);

    const getNotifiedDownloadCount = useCallback((file: StorageFile) => {
        if (!file.downloadedBy?.length || !file.notifyTo?.length) {
            return 0;
        } else {
            return file.downloadedBy.filter(db => file.notifyTo.some(v => v === db.user)).length;
        }
    }, []);

    useEffect(() => {
        setMessages([]);
        getMessages();
    }, [messageType]);

    useEffect(() => {
        if (messageRef.current) {
            const height = messageRef.current.getBoundingClientRect()?.height;
            const scrollHeight = messageRef.current.scrollHeight;
            messageRef.current.scrollTop = Math.ceil(scrollHeight - height);
        }
    }, [messages]);

    useEffect(() => {
        getFiles();
    }, []);

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: operation.name, href: `/operation/${operation._id}` },
                    { label: 'Boîte aux lettres' }
                ]}
            />
            <ScrollableContent
                id="mailbox"
                side={
                    <Card title="Fichiers" id="mailbox-files" actions={<Menu icon={<AddRedIcon />} label="Ajouter" onClick={() => setFileModalVisible(true)} />}>
                        {!files?.length && <NoResult text="Aucun document partagé" />}
                        {!!files?.length && files.map(file => (
                            <div key={file._id} className="mailbox-files-file">
                                <span className="mailbox-files-file-name">{file.name}</span>
                                {!!file.description && <span className="mailbox-files-file-description">{file.description}</span>}
                                <span className="mailbox-files-file-uploader">{getFullName(file.createdByPopulated)}, {formatTimestamp(file.createdAt, undefined, 'full')}</span>
                                <div>
                                    <Menu icon={<DownloadIcon />} label="Télécharger" onClick={() => handleDownload(file)} />
                                    <div className="mailbox-files-file-actions">
                                        {(operationPermissions.administrate || user._id === file.createdBy) && (
                                            <Fragment>
                                                <Menu icon={<TrashIcon />} onClick={() => setFileForDelete(file)} />
                                                <Menu icon={<SendIcon />} onClick={() => setFileForNotify(file)} />
                                            </Fragment>
                                        )}
                                        <Menu icon={<InfoSquareIcon />} childrenPosition="right">
                                            <div className="mailbox-files-file-download">
                                                <span>Téléchargements ({getNotifiedDownloadCount(file) ?? 0}/{file.notifyTo?.length ?? 0}) :</span>
                                                {(file.downloadedBy ?? []).map(download => (
                                                    <div key={download.timestamp}>
                                                        <span><strong>{getFullName(download.userPopulated)}</strong> ({file.notifyToPopulated?.some(u => u._id === download.user) ? 'Notifié' : 'Non notifié'})</span>
                                                        <span>Le {formatTimestamp(download.timestamp, undefined, 'full')}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Card>
                }
            >
                <Card
                    id="mailbox-messages"
                    title="Messages"
                    actions={<Switch id="messagesType" value={messageType} items={[{ key: 'public', label: 'Publics' }, { key: 'private', label: 'Privés' }]} onChange={setMessagesType} />}
                >
                    <span className="info sm-hide">
                        <InfoSquareIcon />La mise à jour des message n'est pas automatique. Merci de bien vouloir rafraichir la page régulièrement
                    </span>
                    <div id="mailbox-messages-container" ref={messageRef}>
                        {messages.map(m => (
                            <div key={m._id} className={`mailbox-messages-message ${m.createdBy === user._id ? 'mailbox-messages-message-by-user' : ''}`}>
                                <div className="mailbox-messages-message-sender">
                                    {getFullName(m.createdByPopulated)} <span>{formatTimestamp(m.createdAt, undefined, 'full')}</span>
                                </div>
                                {m.filePopulated && (<div className="mailbox-messages-message-file">Fichier concerné : <strong>{m.filePopulated.name}</strong></div>)}
                                <div className="mailbox-messages-message-content">
                                    {m.message}
                                </div>
                            </div>
                        ))}
                    </div>
                    {messageType === 'public' && (
                        <form id="mailbox-messages-form">
                            <div id="mailbox-messages-form-input">
                                <textarea
                                    id="message"
                                    rows={3}
                                    onChange={(e) => onChange('message', e.target.value)}
                                    value={entity.message}
                                    placeholder="Message..."
                                    ref={textareaRef}
                                />
                            </div>
                            <div id="mailbox-messages-form-actions">
                                <Button label="Envoyer" icon={<SendIcon />} disabled={!entity.message} onClick={handleSendMessage} />
                                <Button label="Options" icon={<OptionsIcon />} color="secondary" onClick={() => setOptionsPanelVisible(!isOptionsPanelVisible)} />
                            </div>
                            <div id="mailbox-messages-form-options" className={isOptionsPanelVisible ? 'visible' : ''}>
                                <Toggle label="Notifier par email :" {...attachInput('email')} />
                            </div>
                        </form>
                    )}
                </Card>
            </ScrollableContent>
            {isFileModalVisible && <FileModal onSubmit={handleSubmit} onClose={() => setFileModalVisible(false)} />}
            {fileToNotify && <MessageModal type={MessageType.FILE} associatedFile={fileToNotify} onSubmit={handleSubmit} onClose={() => setFileForNotify(null)} />}
            {fileForDelete && <ModalDelete onSubmit={() => handleDelete(fileForDelete)} onCancel={() => setFileForDelete(null)} />}
        </Fragment>
    )
}

export default MailBox;