import { AddRedIcon, ChartIcon, CopyIcon, EditIcon, EyeIcon, PositionIcon, TrashIcon } from '../../assets/icons';

interface IconLinkProps {
    type: 'add' | 'position' | 'copy' | 'trash' | 'edit' | 'view' | 'chart';
    label: string;
    onClick: () => void;
}

const IconLink = ({
    type,
    label,
    onClick
}: IconLinkProps) => (
    <div className="link" onClick={() => typeof onClick === 'function' ? onClick() : undefined}>
        {label && <span>{label}</span>}
        {type === 'add' && <AddRedIcon />}
        {type === 'position' && <PositionIcon />}
        {type === 'copy' && <CopyIcon />}
        {type === 'trash' && <TrashIcon />}
        {type === 'edit' && <EditIcon />}
        {type === 'view' && <EyeIcon />}
        {type === 'chart' && <ChartIcon />}
    </div>
)

export default IconLink;