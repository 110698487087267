import { useEffect, useMemo, useState } from "react";
import { useOperationContext } from "../../context/OperationProvider";
import useRequest from "../../hooks/useRequest";
import { Components, Material, MaterialType } from "../../models/material";
import { IdentityBlockMaterial } from "../entities/IdentityBlock";
import SearchInput from "../inputs/SearchInput";
import { Modal } from "../ui/Modal";
import './index.scss';
import Button from "../ui/Button";
import Menu from "../ui/Menu";
import { toggleInArray } from "../../utils/objects";

interface ModalSearchComponentProps {
    onSubmit: (material: Material) => void;
    onClose: () => void;
}

const ModalSearchComponent = ({ onSubmit, onClose }: ModalSearchComponentProps) => {
    const { operation } = useOperationContext();
    const request = useRequest();
    const [materials, setMaterials] = useState<Material[]>([]);
    const [selectedTypes, setSelectedTypes] = useState<MaterialType[]>([]);
    const [selectedMaterial, setSelectedMaterial] = useState<Material | null>(null);
    const [filter, setFilter] = useState<string | undefined>();

    const materialsFiltered = useMemo(() => {
        if (!filter && !selectedTypes.length) {
            return materials;
        } else {
            const regex = new RegExp('.*' + (filter ?? '').replace('+', ' ').split(' ').join('.*') + '.*', 'gi');
            return materials.filter(m => ((filter && regex.test(m.name)) || !filter) && ((selectedTypes.includes(m.type) || !selectedTypes.length)));
        }
    }, [materials, filter, selectedTypes]);

    useEffect(() => {
        request.get<Material[]>(`/material/operation/${operation._id}/components`, { loader: true })
            .then(setMaterials)
            .catch(() => onClose());
    }, []);

    return (
        <Modal
            title="Sélection de constituant"
            className="modal-search"
            size="medium"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', disabled: !selectedMaterial, onClick: selectedMaterial ? () => onSubmit(selectedMaterial) : undefined },
            ]}
        >
            <SearchInput
                id="component-search-input"
                placeholder="Rechercher un constituant..."
                value={filter}
                onChange={setFilter}
            />
            <div className="modal-search-buttons">
                {Components.map(c => <Menu key={c.key} label={c.label} active={selectedTypes.includes(c.key)} onClick={() => setSelectedTypes(toggleInArray(c.key, selectedTypes))} />)}
            </div>
            <div className="modal-search-list">
                {materialsFiltered?.map(m => (
                    <div key={m._id} className={m._id === selectedMaterial?._id ? 'active' : ''} onClick={() => setSelectedMaterial(m)}>
                        <IdentityBlockMaterial key={m._id} material={m} />
                    </div>
                ))}
            </div>
        </Modal>
    );
}

export default ModalSearchComponent;