import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from '../../components/data/List';
import Header from '../../components/ui/Header';
import Navigation from '../../components/ui/Navigation';
import { useAuthContext } from '../../context/AuthProvider';
import { Material, MaterialListParams, MaterialType, MaterialTypes } from '../../models/material';

const Catalogue = () => {
    const [material, setMaterial] = useState(MaterialType.GRANULAT);
    const navigate = useNavigate();
    const { permissions } = useAuthContext();

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Catalogue' }]} />
            <Navigation pages={MaterialTypes} setPage={(page) => setMaterial(page as MaterialType)} />
            {!!material && MaterialListParams[material] && (
                <List<Material>
                    columns={MaterialListParams[material].columns}
                    filters={MaterialListParams[material].filters}
                    actions={{
                        duplicate: permissions.superAdministrate,
                        edit: permissions.administrate,
                        activate: permissions.superAdministrate,
                    }}
                    dataEndpoint={`/material/standard/${material}`}
                    crudEndpoint="/material"
                    baseUrl={`/catalogue/${material}`}
                    buttons={[{ label: "Nouveau matériau", onClick: () => navigate(`/catalogue/${material}/creer`) }]}
                />
            )}
        </Fragment>
    )
}

export default Catalogue;
