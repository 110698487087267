import { ReactNode, useState } from 'react';
import { ArrowDownIcon } from '../../../assets/icons';
import './index.scss';

interface CollapseProps {
    header: ReactNode;
    children: ReactNode;
    collapsed?: boolean;
}

const Collapse = ({ header, children, collapsed }: CollapseProps) => {
    const [isCollapsed, setCollapsed] = useState<boolean>(collapsed !== undefined ? collapsed : true);

    return (
        <div className={`collapse ${isCollapsed ? 'collapsed' : ''}`}>
            <div className="collapse-header" onClick={() => setCollapsed(!isCollapsed)}>
                <ArrowDownIcon className="collapse-header-icon" />
                {header}
            </div>
            <div className="collapse-content">{children}</div>
        </div>
    )
}

export default Collapse;