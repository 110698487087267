import { useEffect, useMemo, useRef, useState } from 'react';
import { FormBaseProps } from '../../../hooks/useForm';
import { addToDate, getMonthInfo } from '../../../utils/format';
import { ArrowLeftIcon, ArrowRightIcon, CloseOIcon } from '../../../assets/icons';

interface DatePickerCalendarProps extends FormBaseProps<Date> {
    max?: Date;
    min?: Date;
}

const DatePickerCalendar = ({
    value,
    onChange,
    min,
    max
}: DatePickerCalendarProps) => {
    const [selectedDate, setSelectedDate] = useState<Date>(value ?? new Date());
    const ref = useRef<HTMLDivElement>(null);

    const displayedDays = useMemo(() => {
        let today = new Date().toLocaleString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' });
        let selectedDay = value?.toLocaleString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' });
        let prevMonth = getMonthInfo(selectedDate.getFullYear(), selectedDate.getMonth() - 1);
        let currentMonth = getMonthInfo(selectedDate.getFullYear(), selectedDate.getMonth());
        let nextMonth = getMonthInfo(selectedDate.getFullYear(), selectedDate.getMonth() + 1);

        let days = [];
        let day;
        let dayClass;

        for (let i = 0; i < currentMonth.firstDay; i++) {
            day = new Date(prevMonth.year, prevMonth.month, prevMonth.nbDays - i);
            dayClass = day.toLocaleString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' }) === today ? 'date-picker-other-month date-picker-today' : 'date-picker-other-month';
            const isDisabled = (min && day < min) || (max && day > max);
            days.push({ day: day, class: dayClass, disabled: isDisabled });
        }

        for (let i = 1; i <= currentMonth.nbDays; i++) {
            day = new Date(currentMonth.year, currentMonth.month, i);
            dayClass = day.toLocaleString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' }) === today ? 'today' : '';
            dayClass += day.toLocaleString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' }) === selectedDay ? ' date-picker-day-selected' : '';
            const isDisabled = (min && day < min) || (max && day > max);
            days.push({ day: day, class: dayClass, disabled: isDisabled });
        }

        let i = 1;
        while (days.length < 42) {
            day = new Date(nextMonth.year, nextMonth.month, i);
            dayClass = day.toLocaleString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' }) === today ? 'date-picker-other-month date-picker-today' : 'date-picker-other-month';
            const isDisabled = (min && day < min) || (max && day > max);
            days.push({ day: day, class: dayClass, disabled: isDisabled });
            i++;
        }

        let weeks = [];
        for (i = 0; i < days.length / 7; i++) {
            weeks.push(days.slice(i * 7, i * 7 + 7))
        }
        return weeks;
    }, [selectedDate, value, min, max]);

    useEffect(() => {
        if (ref.current) {
            const bound = ref.current.getBoundingClientRect();
            if (bound.left < bound.width) {
                ref.current.style.left = '0';
                ref.current.style.right = 'auto';
            } else {
                ref.current.style.left = 'auto';
                ref.current.style.right = '0';
            }
        }
    }, [ref]);

    return (
        <div className="date-picker" ref={ref}>
            <CloseOIcon className="lg-hide close-icon-form" />
            <div className="date-picker-header">
                <ArrowLeftIcon onClick={() => setSelectedDate(addToDate(selectedDate, 'year', -1))} />
                <span>{selectedDate.toLocaleString('fr', { year: 'numeric' })}</span>
                <ArrowRightIcon onClick={() => setSelectedDate(addToDate(selectedDate, 'year', 1))} />
            </div>
            <div className="date-picker-header">
                <ArrowLeftIcon onClick={() => setSelectedDate(addToDate(selectedDate, 'month', -1))} />
                <span>{selectedDate.toLocaleString('fr', { month: 'long' })}</span>
                <ArrowRightIcon onClick={() => setSelectedDate(addToDate(selectedDate, 'month', 1))} />
            </div>
            <div className="date-picker-calendar">
                <div className="date-picker-days-header">
                    <span>Lu</span>
                    <span>Ma</span>
                    <span>Me</span>
                    <span>Je</span>
                    <span>Ve</span>
                    <span>Sa</span>
                    <span>Di</span>
                </div>
                <div className="date-picker-days">
                    {displayedDays.map((w, i) => (
                        <div key={i} className="date-picker-week">
                            {w.map((d, j) => (
                                <div
                                    key={j}
                                    className={`date-picker-day ${d.class} ${d.disabled ? 'disabled' : ''}`}
                                    onClick={() => d.disabled ? undefined : onChange(d.day)}
                                >
                                    {d.day.getDate()}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DatePickerCalendar;