import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import useRequest from '../../hooks/useRequest';
import { Analysis } from '../../models/analysis';
import { Material, MaterialCharacteristicElementRequirement, getAnalysisCharac } from '../../models/material';
import { Controle, ControleLabel, Controles } from '../../models/sample';
import { ListItem, RevisionStatus, RevisionStatusLabel, RevisionStatusList } from '../../models/shared';
import { formatDate } from '../../utils/format';
import { changeFieldValue } from '../../utils/objects';
import DatePickerInput from '../inputs/DatePicker';
import AnalysisCell from './AnalysisCell';
import AnalysisHeader, { AnalysisMinMax } from './AnalysisHeader';
import './index.scss';
import { useOperationContext } from '../../context/OperationProvider';
import Button from '../ui/Button';
import { Component } from '../../models/component';
import ModalDelete from '../ui/Modal/ModalDelete';

const options = { date: true, status: true, controle: true, actions: true, provider: true, comment: true };

interface ComponentAnalysisProps {
    material: Material;
}

const ComponentAnalysis = ({
    material
}: ComponentAnalysisProps) => {
    const request = useRequest();
    const { operationPermissions, operation } = useOperationContext();
    const [characteristics, setCharacteristics] = useState<MaterialCharacteristicElementRequirement[]>([]);
    const [providers, setProviders] = useState<ListItem[]>([]);
    const [analysis, setAnalysis] = useState<Partial<Analysis & { isEditing: boolean, status: RevisionStatus }>[]>([]);
    const [toDelete, setToDelete] = useState<string | null>(null);

    const controles = useMemo(() => Controles.filter(c => operationPermissions.controles.includes(c.key)), [operationPermissions]);

    const handleChange = useCallback((index: number, field: string, value: any) => {
        setAnalysis(analysis => {
            if (index >= 0 && index < analysis?.length) {
                const _analysis = [...analysis];
                _analysis[index] = field !== 'analysis' ? changeFieldValue(_analysis[index], field, value) : value;
                return _analysis;
            }
            return analysis;
        });
    }, []);

    const getAnalysis = useCallback(async () => {
        request.get<Component[]>(`/component/operation/${operation._id}/material/${material._id}`, { loader: true, errorMessage: 'Une erreur est survenue lors de la récupération des analyses' })
            .then(data => setAnalysis([
                {
                    controle: operationPermissions.controles[0] ?? Controle.EXTERIEUR,
                    status: operationPermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
                    date: new Date(), business: material.business,
                    data: {},
                    isEditing: true
                },
                ...data.map(d => ({ status: d.status, ...d.analysisPopulated })).sort((a, b) => (a.date ?? 0) > (b.date ?? 0) ? -1 : 1)
            ]))
            .catch(() => null);
    }, [material]);

    const handleCopy = useCallback((index: number) => {
        setAnalysis(analysis => {
            if (index >= 0 && index < analysis?.length) {
                const _analysis = [...analysis];
                _analysis[0] = { ...analysis[index], data: undefined, isEditing: true, _id: undefined, status: operationPermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT };
                return _analysis;
            }
            return analysis;
        })
    }, []);

    const handleSave = useCallback(async (analysis: Partial<Analysis>) => {
        if (!analysis) return;

        const create = !analysis?._id;
        const requestMethod = create ? request.post : request.put;
        const dto = create ? {
            material: material?._id,
            type: material?.type,
            operation: material?.operation,
            ...analysis,
        } : analysis

        requestMethod('/component', dto, {
            successMessage: create ? 'Analyse créée avec succès' : 'Analyse mise à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then(() => getAnalysis())
            .catch(() => null);
    }, [getAnalysis, material]);

    const handleDelete = useCallback(async (_id: string) => {
        request.delete(`/component/${_id}`, { loader: true, successMessage: 'Analyse supprimée avec succès', errorMessage: 'Une erreur est survenue lors de la suppression' })
            .then(() => {
                getAnalysis();
                setToDelete(null);
            })
            .catch(() => null);
    }, [getAnalysis]);

    useEffect(() => {
        if (material) {
            setCharacteristics(getAnalysisCharac(material));
            getAnalysis();
        }
    }, [material]);

    useEffect(() => {
        request.get<ListItem[]>('/business/list/provider')
            .then(setProviders)
            .catch(() => null);
    }, []);

    if (!characteristics?.length) return null;

    return (
        <div className="table-container">
            <table>
                <AnalysisHeader
                    characteristics={characteristics}
                    options={options}
                />
                <tbody>
                    {!!analysis?.length && analysis.map((a, index) => (
                        <Fragment key={index}>
                            {!!a.isEditing && <AnalysisMinMax characteristics={characteristics} options={options} minOrMax="max" />}
                            <tr key={index} className={`${a.controle ?? ''}${a.isEditing ? ' editing' : ''}`}>
                                {!!a.isEditing
                                    ? (
                                        <Fragment>
                                            <td>
                                                <DatePickerInput
                                                    id="date"
                                                    value={a.date}
                                                    onChange={(value) => handleChange(index, 'date', value)}
                                                    hideIcon
                                                />
                                            </td>
                                            <td>
                                                <select className="input-long" disabled={!operationPermissions.validate} value={a.status ?? ''} onChange={(e) => handleChange(index, 'status', e.target.value)}>
                                                    {RevisionStatusList.map(s => <option key={s.key} value={s.key}>{s.label}</option>)}
                                                </select>
                                            </td>
                                            <td>
                                                <select className="input-long" value={a.controle ?? ''} onChange={(e) => handleChange(index, 'controle', e.target.value)}>
                                                    {controles.map(c => <option key={c.key} value={c.key}>{c.label}</option>)}
                                                </select>
                                            </td>
                                            <td>
                                                <select className="input-long" value={a.business ?? ''} onChange={(e) => handleChange(index, 'business', e.target.value)}>
                                                    <option value=""></option>
                                                    {providers.map(p => <option key={p.key} value={p.key}>{p.label}</option>)}
                                                </select>
                                            </td>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <td>{a.date ? formatDate(a.date) : ''}</td>
                                            <td>{a.status ? RevisionStatusLabel[a.status] : ''}</td>
                                            <td>{a?.controle ? ControleLabel[a?.controle] : ''}</td>
                                            <td>{providers.find(p => p.key === a.business)?.label ?? ''}</td>
                                        </Fragment>
                                    )
                                }
                                {characteristics.map(c => <AnalysisCell key={c.key} characteristic={c} analysis={a} onChange={(value) => handleChange(index, 'analysis', value)} isEditing={!!a.isEditing} />)}
                                {!!a.isEditing
                                    ? <td className="with-border"><input type="text" className="input-extra-long" value={a.comment ?? ''} onChange={(e) => handleChange(index, 'comment', e.target.value)} /></td>
                                    : <td className="with-border overflow">{a.comment}</td>
                                }
                                {!!a.isEditing && (
                                    <td className="sticky-menu">
                                        <div>
                                            <Button color="primary" icon={<SaveIcon />} onClick={() => handleSave(a)} />
                                        </div>
                                    </td>
                                )}
                                {!a.isEditing && (
                                    <td className="sticky-menu">
                                        <div>
                                            {operationPermissions.administrate && <Button color="primary" icon={<TrashIcon />} onClick={() => a._id ? setToDelete(a._id) : null} />}
                                            <Button color="primary" icon={<CopyIcon />} onClick={() => handleCopy(index)} />
                                            <Button color="primary" icon={<EditIcon />} onClick={() => handleChange(index, 'isEditing', true)} />
                                        </div>
                                    </td>
                                )}
                            </tr>
                            {!!a.isEditing && <AnalysisMinMax characteristics={characteristics} options={options} minOrMax="min" />}
                        </Fragment>
                    ))}
                </tbody>
            </table>
            {!!toDelete && (
                <ModalDelete onCancel={() => setToDelete(null)} onSubmit={() => handleDelete(toDelete)} />
            )}
        </div>
    );
}

export default ComponentAnalysis;