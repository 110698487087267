import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveIcon } from '../../assets/icons';
import EntityCard from '../../components/entities/EntityCard';
import { IdentityBlockOperation, IdentityBlockUser } from '../../components/entities/IdentityBlock';
import UserSearchModal from '../../components/entities/UserSearchModal';
import DatePicker from '../../components/inputs/DatePicker';
import MultipleSelect from '../../components/inputs/MultipleSelect';
import NumberInput from '../../components/inputs/NumberInput';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Header from '../../components/ui/Header';
import ScrollableContent from '../../components/ui/ScrollableContent';
import useForm, { FormComparator } from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { License } from '../../models/license';
import { Permission, Permissions, User } from '../../models/user';

const VALIDATION = {
    reference: [{ comparator: FormComparator.REQUIRED }],
    manager: [{ comparator: FormComparator.REQUIRED }],
    start: [{ comparator: FormComparator.REQUIRED }],
    end: [{ comparator: FormComparator.REQUIRED }],
    operationsQuota: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_INTEGER }],
    usersPerOperationQuota: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_INTEGER }],
};

const Edit = () => {
    const request = useRequest();
    const { id, page } = useParams();
    const navigate = useNavigate();
    const { entity, attachInput, validate, setEntity, setChanged, onChange, hasChanged, onChangeMultiple } = useForm<License>({ active: true });
    const [isSearchModalVisible, setSearchModalVisible] = useState<boolean>(false);

    const handleSubmit = useCallback(async (close?: boolean) => {
        const entity = validate(VALIDATION)
        if (!entity) return;

        const create = !entity?._id;
        const requestMethod = create ? request.post : request.put;

        requestMethod<License>('/license', entity, {
            successMessage: create ? 'Licence créée avec succès' : 'Licence mise à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                    setEntity(data);
                }
            })
            .catch(() => null);
    }, [validate, page, setEntity]);

    const handleUser = useCallback((users: User[]) => {
        if (!users.length) return;
        onChangeMultiple([{ field: 'manager', value: users[0]._id }, { field: 'managerPopulated', value: users[0] }]);
        setSearchModalVisible(false);
    }, [entity, onChangeMultiple]);

    useEffect(() => {
        if (id) {
            request.get<License>(`/license/${id}`, { errorMessage: 'Une erreur est survenue lors de la récupération de la license', loader: true })
                .then(setEntity)
                .catch(() => navigate(`/licences`))
        }
    }, []);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Licences', href: '/licences' }, { label: entity?._id ? entity.reference! : 'Nouvelle licence' }]}>
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={<SaveIcon />}
                            onClick={handleSubmit}
                        />
                        <Button
                            label="Enregistrer et fermer"
                            icon={<SaveIcon />}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            <ScrollableContent side={
                <Fragment>
                    <EntityCard entity={entity} attachInput={attachInput} />
                </Fragment>
            }>
                <Card title="Informations générales">
                    <div className="input-column">
                        <label htmlFor="reference">Référence *</label>
                        <TextInput {...attachInput('reference')} />
                    </div>
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="start">Date début *</label>
                            <DatePicker {...attachInput('start')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="start">Date fin *</label>
                            <DatePicker {...attachInput('end')} />
                        </div>
                    </div>
                </Card>
                <Card title="Utilisateur référent">
                    <IdentityBlockUser user={entity.managerPopulated} onClick={() => setSearchModalVisible(true)} />
                </Card>
                <Card title="Configuration">
                    <div className="input-column">
                        <label htmlFor="permissions">Permissions</label>
                        <MultipleSelect<Permission> items={Permissions} {...attachInput('permissions')} />
                    </div>
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="operationsQuota">Limite opérations</label>
                            <NumberInput {...attachInput('operationsQuota')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="usersPerOperationQuota">Limite utilisateurs par opération</label>
                            <NumberInput {...attachInput('usersPerOperationQuota')} />
                        </div>
                    </div>
                </Card>
                {!!entity.operationsPopulated?.length && (
                    <Card title="Opérations">
                        {entity.operationsPopulated?.map(o => <IdentityBlockOperation key={o._id} operation={o} onView={() => navigate(`/operation/${o._id}`)} />)}
                    </Card>
                )}
            </ScrollableContent>
            {isSearchModalVisible && (<UserSearchModal onClose={() => setSearchModalVisible(false)} onSubmit={handleUser} />)}
        </Fragment >
    )
}

export default Edit;