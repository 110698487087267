import { useCallback, useState } from 'react';
import { CloseIcon, SearchIcon } from '../../../assets/icons';
import { FormBaseProps } from '../../../hooks/useForm';
import TextInput from '../TextInput';
import './index.scss';
import useDebounce from '../../../hooks/useDebounce';

interface SearchInputProps extends FormBaseProps<string> {
    maxLength?: number,
    placeholder?: string,
}

const SearchInput = ({
    id,
    value: valueFromProps,
    maxLength,
    placeholder,
    disabled,
    onChange,
}: SearchInputProps) => {
    const [value, setValue] = useState<string | undefined>(valueFromProps);
    useDebounce(() => onChange(value), 500, [value]);

    return (
        <div className="search-input">
            <SearchIcon />
            <TextInput
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={setValue}
                maxLength={maxLength}
                disabled={disabled}
            />
            {!!value && <CloseIcon onClick={() => setValue(undefined)} />}
        </div>
    );
}

export default SearchInput;