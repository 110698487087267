import { useMemo } from 'react';
import { RouteObject, RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom';
import { GlobalPermissions, useAuthContext } from '../context/AuthProvider';
import { OperationPermissions, OperationProvider, useOperationContext } from '../context/OperationProvider';
import Businesses from './Business';
import BusinessEdit from './Business/BusinessEdit';
import Catalogue from './Catalogue';
import MaterialEdit from './Catalogue/Edit';
import Error from './Error';
import Home from './Home';
import Library from './Library';
import Licenses from './License';
import LicenseForUser from './License/License';
import LicenseEdit from './License/LicenseEdit';
import Login from './Login/Login';
import Recovery from './Login/Recovery';
import Renew from './Login/Renew';
import Network from './Network';
import ComponentEdit from './Operation/Components/ComponentEdit';
import Dashboard from './Operation/Dashboard';
import LotEdit from './Operation/Lot/LotEdit';
import LotView from './Operation/Lot/LotView';
import MailBox from './Operation/MailBox';
import OperationMap from './Operation/Map';
import MarkerForm from './Operation/Map/MarkerForm';
import OperationEdit from './Operation/OperationEdit';
import PopulationEdit from './Operation/Population/PopulationEdit';
import Requirement from './Operation/Requirement';
import ByLocation from './Operation/Results/ByLocation';
import ByMaterial from './Operation/Results/ByMaterial';
import ByMaterialResults from './Operation/Results/ByMaterialResults';
import Melanges from './Operation/Results/Melanges';
import WorkSummary from './Operation/Results/WorkSummary';
import SampleEdit from './Operation/Sample/SampleEdit';
import SampleEditMultiple from './Operation/Sample/SampleEditMultiple';
import StudyFollowUp from './Operation/StudyFollowUp';
import Melange from './Operation/StudyFollowUp/MelangeEdit';
import Synoptique from './Operation/Synoptique';
import WorkFollowUp from './Operation/WorkFollowUp';
import Private from './Private';
import Public from './Public';
import Users from './Users';
import Profile from './Users/Profile';
import UserEdit from './Users/UserEdit';

interface ProtectedRouteProps {
    children: JSX.Element;
    canAccess: (permissions: GlobalPermissions, operationPermissions: OperationPermissions) => boolean;
    alternative?: JSX.Element;
}

const ProtectedRoute = ({ children, canAccess, alternative }: ProtectedRouteProps) => {
    const { permissions } = useAuthContext();
    const { operationPermissions } = useOperationContext();
    const navigate = useNavigate();

    if (!canAccess(permissions, operationPermissions)) {
        if (alternative) return alternative;
        navigate('/erreur/401');
    }

    return children;
}

const App = () => {
    const { user } = useAuthContext();

    const routes: RouteObject[] = useMemo(() => !!user._id
        ? [
            {
                path: "/",
                element: <OperationProvider><Private /></OperationProvider>,
                children: [
                    { path: '*', element: <Error /> },
                    { path: 'erreur/:code?', element: <Error /> },
                    { path: 'accueil', element: <Home /> },
                    { path: 'mon-profil', element: <Profile /> },
                    { path: 'ma-licence', element: <LicenseForUser /> },
                    { path: 'operation/:operationId', element: <ProtectedRoute canAccess={(_, op) => op.read} alternative={<Dashboard />}><Synoptique /></ProtectedRoute> },
                    { path: 'operation/:operationId/synoptique', element: <ProtectedRoute canAccess={(_, op) => op.read}><Synoptique /></ProtectedRoute> },
                    { path: 'operation/:operationId/tableau-de-bord', element: <ProtectedRoute canAccess={(_, op) => op.write}><Dashboard /></ProtectedRoute> },
                    // Can administrate operations (admin and license owners)
                    { path: 'operation/creer/:type/:page?', element: <ProtectedRoute canAccess={(p) => p.administrate}><OperationEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/editer/:page?', element: <ProtectedRoute canAccess={(p) => p.administrate}><OperationEdit /></ProtectedRoute> },
                    // Can read operation
                    { path: 'operation/:operationId/carte', element: <ProtectedRoute canAccess={(_, op) => op.read}><OperationMap /></ProtectedRoute> },
                    { path: 'operation/:operationId/boite-aux-lettres', element: <ProtectedRoute canAccess={(_, op) => op.read}><MailBox /></ProtectedRoute> },
                    // Can access operation results
                    { path: 'operation/:operationId/resultats/localisation-et-phase', element: <ProtectedRoute canAccess={(_, op) => op.localizedResults}><ByLocation /></ProtectedRoute> },
                    { path: 'operation/:operationId/resultats/melanges', element: <ProtectedRoute canAccess={(_, op) => op.localizedResults}><Melanges /></ProtectedRoute> },
                    { path: 'operation/:operationId/resultats/materiaux/:page?', element: <ProtectedRoute canAccess={(_, op) => op.materialResults}><ByMaterial /></ProtectedRoute> },
                    { path: 'operation/:operationId/resultats/materiau/:materialId', element: <ProtectedRoute canAccess={(_, op) => op.materialResults}><ByMaterialResults /></ProtectedRoute> },
                    { path: 'operation/:operationId/resultats/bilan-travaux', element: <ProtectedRoute canAccess={(_, op) => op.read}><WorkSummary /></ProtectedRoute> },
                    // Can write in operation (write and subcontractors)
                    { path: 'operation/:operationId/travaux/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><WorkFollowUp /></ProtectedRoute> },
                    { path: 'operation/:operationId/etude/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><StudyFollowUp /></ProtectedRoute> },
                    { path: 'operation/:operationId/preparatoire/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><StudyFollowUp /></ProtectedRoute> },
                    { path: 'operation/:operationId/expertise/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><StudyFollowUp /></ProtectedRoute> },
                    { path: 'operation/:operationId/travaux/lot/:lotId/detail/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><LotView /></ProtectedRoute> },
                    { path: 'operation/:operationId/travaux/lot/creer/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><LotEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/travaux/lot/:lotId/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><LotEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/travaux/population/creer', element: <ProtectedRoute canAccess={(_, op) => op.write}><PopulationEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/travaux/population/:populationId/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><PopulationEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/travaux/lot/:lotId/population/creer', element: <ProtectedRoute canAccess={(_, op) => op.write}><PopulationEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/travaux/lot/:lotId/population/:populationId/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><PopulationEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/travaux/lot/:lotId/:sampleType/creer-en-masse', element: <ProtectedRoute canAccess={(_, op) => op.write}><SampleEditMultiple /></ProtectedRoute> },
                    { path: 'operation/:operationId/travaux/lot/:lotId/:sampleType/creer', element: <ProtectedRoute canAccess={(_, op) => op.write}><SampleEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/travaux/lot/:lotId/:sampleType/:sampleId/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><SampleEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/travaux/suivi-de-constituants/:materialId', element: <ProtectedRoute canAccess={(_, op) => op.write}><ComponentEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/:phase/point-d-interet/creer/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><MarkerForm /></ProtectedRoute> },
                    { path: 'operation/:operationId/:phase/point-d-interet/:markerId/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><MarkerForm /></ProtectedRoute> },
                    { path: 'operation/:operationId/:phase/melange/creer', element: <ProtectedRoute canAccess={(_, op) => op.write}><Melange /></ProtectedRoute> },
                    { path: 'operation/:operationId/:phase/melange/:melangeId/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><Melange /></ProtectedRoute> },
                    { path: 'operation/:operationId/:phase/:sampleType/creer', element: <ProtectedRoute canAccess={(_, op) => op.write}><SampleEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/:phase/:sampleType/:sampleId/:page?', element: <ProtectedRoute canAccess={(_, op) => op.write}><SampleEdit /></ProtectedRoute> },
                    // Can administrate operation
                    { path: 'operation/:operationId/exigences/:page?', element: <ProtectedRoute canAccess={(_, op) => op.administrate}><Requirement /></ProtectedRoute> },
                    { path: 'operation/:operationId/exigences/:materialType/creer/:standardId', element: <ProtectedRoute canAccess={(_, op) => op.administrate}><MaterialEdit /></ProtectedRoute> },
                    { path: 'operation/:operationId/exigences/:materialType/:materialId/:page?', element: <ProtectedRoute canAccess={(_, op) => op.administrate}><MaterialEdit /></ProtectedRoute> },
                    // Can access library
                    { path: 'bibliotheque/:page?', element: <ProtectedRoute canAccess={(p) => p.library}><Library /></ProtectedRoute> },
                    { path: 'cartographie-du-reseau', element: <ProtectedRoute canAccess={(p) => p.library}><Network /></ProtectedRoute> },
                    // Can administrate
                    { path: 'catalogue/:page?', element: <ProtectedRoute canAccess={(p) => p.administrate}><Catalogue /></ProtectedRoute> },
                    { path: 'catalogue/:materialType/creer', element: <ProtectedRoute canAccess={(p) => p.administrate}><MaterialEdit /></ProtectedRoute> },
                    { path: 'catalogue/:materialType/:standardId/:page?', element: <ProtectedRoute canAccess={(p) => p.administrate}><MaterialEdit /></ProtectedRoute> },
                    { path: 'annuaire', element: <ProtectedRoute canAccess={(p) => p.administrate}><Businesses /></ProtectedRoute> },
                    { path: 'annuaire/creer', element: <ProtectedRoute canAccess={(p) => p.administrate}><BusinessEdit /></ProtectedRoute> },
                    { path: 'annuaire/:id/:page?', element: <ProtectedRoute canAccess={(p) => p.administrate}><BusinessEdit /></ProtectedRoute> },
                    // Can super administrate
                    { path: 'utilisateurs', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><Users /></ProtectedRoute> },
                    { path: 'utilisateurs/creer', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><UserEdit /></ProtectedRoute> },
                    { path: 'utilisateurs/:id/:page?', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><UserEdit /></ProtectedRoute> },
                    { path: 'licences', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><Licenses /></ProtectedRoute> },
                    { path: 'licences/creer', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><LicenseEdit /></ProtectedRoute> },
                    { path: 'licences/:id/:page?', element: <ProtectedRoute canAccess={(p) => p.superAdministrate}><LicenseEdit /></ProtectedRoute> },
                    { path: '/', element: <Home /> },
                ],
            },
        ] : [{
            path: "/",
            element: <Public />,
            children: [
                { path: '/', element: <Login /> },
                { path: 'recuperation', element: <Recovery /> },
                { path: 'nouveau-mot-de-passe/:token', element: <Renew /> },
                { path: '/*', element: <Login /> },
            ],
        },
        ], [user]);

    return <RouterProvider router={createBrowserRouter(routes)} />;
}

export default App;
