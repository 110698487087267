import { ReactComponent as CarotteIcon } from '../../../../assets/icons/synoptique-bottom-panel/carotte.svg';
import { ReactComponent as MarkerIcon } from '../../../../assets/icons/synoptique-bottom-panel/marker.svg';
import { ReactComponent as PopulationIcon } from '../../../../assets/icons/synoptique-bottom-panel/population.svg';
import { ReactComponent as PrelevementIcon } from '../../../../assets/icons/synoptique-bottom-panel/prelevement.svg';
import { ReactComponent as SondageIcon } from '../../../../assets/icons/synoptique-bottom-panel/sondage.svg';
import Card from '../../../../components/ui/Card';
import { SampleLabels } from '../../../../models/sample';

const Legend = () => (
    <Card className="sm-hide" id="legend">
        <div className="legend-item"><CarotteIcon /><span>{SampleLabels.carotte.type}</span></div>
        <div className="legend-item"><SondageIcon /><span>{SampleLabels.sondage.type}</span></div>
        <div className="legend-item"><PrelevementIcon /><span>{SampleLabels.prelevement.type}</span></div>
        <div className="legend-item"><PopulationIcon /><span>{SampleLabels.population.type}</span></div>
        <div className="legend-item"><MarkerIcon /><span>{SampleLabels.marker.type}</span></div>
    </Card>
);

export default Legend;