import { Fragment, useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import Switch from '../../../components/inputs/Switch';
import Card from '../../../components/ui/Card';
import Expandable from '../../../components/ui/Expandable';
import FakeData from '../../../components/ui/FakeData';
import FakeGranulometrie from '../../../components/ui/FakeData/FakeGranulometrie';
import Printable from '../../../components/ui/Printable';
import { useOperationContext } from '../../../context/OperationProvider';
import useRequest from '../../../hooks/useRequest';
import { AnalysisSimplified, AnalysisStatistics } from '../../../models/analysis';
import { Material, MaterialType } from '../../../models/material';
import { RoadPositionLabel } from '../../../models/operation';
import { ControleChartColor, Controles } from '../../../models/sample';
import { Permission } from '../../../models/user';
import { LayerColors } from '../../../synoptique/Synoptique.class';
import { formatDate, formatNumberToFixedDecimal } from '../../../utils/format';
import { floatToPrString } from '../../../utils/pr';

const COMMON_DATASET_OPTIONS = {
    borderWidth: 1,
    pointRadius: 2,
    spanGaps: true,
}

const getDatasets = (statistics: AnalysisStatistics[], chartBy: string) => chartBy === 'global' ?
    [
        {
            ...COMMON_DATASET_OPTIONS,
            type: 'line',
            label: 'Minimum',
            data: statistics.map(s => ({ x: s.seuil, y: s.min })) ?? [],
            backgroundColor: LayerColors[1],
            borderColor: LayerColors[1]
        },
        {
            ...COMMON_DATASET_OPTIONS,
            type: 'line',
            label: 'Moyenne',
            data: statistics.map(s => ({ x: s.seuil, y: s.average })) ?? [],
            backgroundColor: LayerColors[0],
            borderColor: LayerColors[0]
        },
        {
            ...COMMON_DATASET_OPTIONS,
            type: 'line',
            label: 'Maximum',
            data: statistics.map(s => ({ x: s.seuil, y: s.max })) ?? [],
            backgroundColor: LayerColors[2],
            borderColor: LayerColors[2]
        }
    ] : Controles.map(c => ({
        ...COMMON_DATASET_OPTIONS,
        type: 'line',
        label: c.label,
        data: statistics.map(s => ({ x: s.seuil, y: s.controle?.[c.key]?.average })) ?? [],
        backgroundColor: ControleChartColor[c.key],
        borderColor: ControleChartColor[c.key]
    }));

const plugins = [{
    id: 'vertical-line',
    beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        const meta = chart.getDatasetMeta(0);
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;
        meta.data?.forEach((point: any) => {
            // draw vertical line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(point.x, topY);
            ctx.lineTo(point.x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#e5e5e5';
            ctx.stroke();

            ctx.fillStyle = "#666666";
            ctx.textAlign = 'center';
            ctx.fillText(point.$context.parsed.x, point.x, bottomY + 14);
            ctx.restore();
        });
    },
}];

interface GranulometrieProps {
    material: Material;
}

const Granulometrie = ({
    material,
}: GranulometrieProps) => {
    const { operation, operationPermissions } = useOperationContext();
    const request = useRequest();
    const [chartData, setChartData] = useState<{ min: number, max: number, datasets: any[], ticks: (number | undefined)[] } | null>(null);
    const [chartBy, setChartBy] = useState('global');
    const [analysis, setAnalysis] = useState<AnalysisSimplified[] | null>(null);
    const [statistics, setStatistics] = useState<AnalysisStatistics[] | null>(null);

    useEffect(() => {
        if (statistics?.length) {
            setChartData({
                min: 0,
                max: 100,
                datasets: getDatasets(statistics, chartBy),
                ticks: statistics.map(s => s.seuil)
            });
        } else {
            setChartData(null);
        }
    }, [statistics, chartBy]);

    useEffect(() => {
        request.get<{ analysis: AnalysisSimplified[]; granulometries?: AnalysisStatistics[]; }>(`/material/${material._id}/granulometries`, { loader: true })
            .then((data) => {
                setAnalysis(data?.analysis ?? []);
                setStatistics(data?.granulometries ?? []);
            })
            .catch(() => {
                setAnalysis(null);
                setStatistics(null);
            });
    }, [material]);

    if (chartData === null) {
        return (null);
    }

    return (
        <Card
            title="Granulométrie"
            opened={false}
        >
            <Printable
                title="Granulométrie"
                subtitle={material.name}
                filename="granulometrie"
            >
                {operationPermissions.materialResults ? (
                    <Fragment>
                        <div className="global-statistics">
                            <div className="global-statistics-chart">
                                <Switch
                                    className="no-print"
                                    id="chart-by"
                                    items={[{ key: 'global', label: 'Global' }, { key: 'controle', label: 'Par contrôle' }]}
                                    value={chartBy}
                                    onChange={(value) => value !== undefined ? setChartBy(value) : null}
                                />
                                <Expandable>
                                    <Chart
                                        plugins={plugins}
                                        type="line"
                                        data={{
                                            datasets: chartData?.datasets ?? [],
                                        }}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    display: true,
                                                    position: 'bottom',
                                                    labels: {
                                                        padding: 20
                                                    }
                                                },
                                            },
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    type: 'linear',
                                                    ticks: {
                                                        color: '#ffffff',
                                                        display: false
                                                    },
                                                    grid: {
                                                        display: false
                                                    }
                                                },
                                                y: {
                                                    max: chartData?.max,
                                                    min: chartData?.min,
                                                }

                                            }
                                        }}
                                    />
                                </Expandable>
                            </div>

                            <div className="data-table">
                                <h3>Statistiques</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Tamis</td>
                                            {chartBy === 'global'
                                                ? (
                                                    <Fragment>
                                                        <td>Minimum</td>
                                                        <td>Moyenne</td>
                                                        <td>Maximum</td>
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <td>Extérieur</td>
                                                        <td>Externe</td>
                                                        <td>Autre</td>
                                                    </Fragment>
                                                )
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {statistics?.map(s => (
                                            <tr key={s.seuil}>
                                                <td><strong>{s.seuil}</strong></td>
                                                {chartBy === 'global'
                                                    ? (
                                                        <Fragment>
                                                            <td>{formatNumberToFixedDecimal(s.min)}</td>
                                                            <td>{formatNumberToFixedDecimal(s.average)}</td>
                                                            <td>{formatNumberToFixedDecimal(s.max)}</td>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <td>{formatNumberToFixedDecimal(s.controle.exterieur?.average)}</td>
                                                            <td>{formatNumberToFixedDecimal(s.controle.externe?.average)}</td>
                                                            <td>{formatNumberToFixedDecimal(s.controle.autre?.average)}</td>
                                                        </Fragment>
                                                    )
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {!!analysis?.length && (
                            <div className="data-table">
                                <h3>Données brutes</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            {material.type !== MaterialType.GRANULAT && (
                                                <Fragment>
                                                    <td>PR</td>
                                                    <td>Voie</td>
                                                    <td>Position</td>
                                                </Fragment>
                                            )}
                                            {statistics?.map(s => <td key={s.seuil}>{s.seuil}</td>)}
                                            {material.type === MaterialType.GRANULAT && (
                                                <Fragment>
                                                    <td>Aplatissement</td>
                                                    <td>Los Angeles</td>
                                                    <td>Micro-Deval</td>
                                                </Fragment>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {analysis.map(a => (
                                            <tr key={a._id} className={a.controle}>
                                                <td>{formatDate(a.date)}</td>
                                                {material.type !== MaterialType.GRANULAT && (
                                                    <Fragment>
                                                        <td>{a.samplePopulated?.location?.pr ? floatToPrString(a.samplePopulated.location.pr) : ''}</td>
                                                        <td>{a.samplePopulated?.location?.road ? operation.roadsObj[a.samplePopulated?.location?.road]?.label ?? '' : ''}</td>
                                                        <td>{a.samplePopulated?.location?.roadPosition ? RoadPositionLabel[a.samplePopulated?.location?.roadPosition] ?? '' : ''}</td>
                                                    </Fragment>
                                                )}
                                                {statistics?.map(s => (
                                                    <td key={s.seuil} className={Array.isArray(a.data.granulometrie) && a.data.granulometrie?.find(g => g.seuil === s.seuil)?.problematic ? 'problematic' : ''}>
                                                        {formatNumberToFixedDecimal(Array.isArray(a.data.granulometrie) ? a.data.granulometrie?.find(g => g.seuil === s.seuil)?.value : 0)}
                                                    </td>
                                                ))}
                                                {material.type === MaterialType.GRANULAT && (
                                                    <Fragment>
                                                        <td className={!Array.isArray(a.data.coefAplatissementForme) && a.data.coefAplatissementForme?.problematic ? 'problematic' : ''}>
                                                            {formatNumberToFixedDecimal(!Array.isArray(a.data.coefAplatissementForme) ? a.data.coefAplatissementForme?.value : 0)}
                                                        </td>
                                                        <td className={!Array.isArray(a.data.losAngeles) && a.data.losAngeles?.problematic ? 'problematic' : ''}>
                                                            {formatNumberToFixedDecimal(!Array.isArray(a.data.losAngeles) ? a.data.losAngeles?.value : 0)}
                                                        </td>
                                                        <td className={!Array.isArray(a.data.microDeval) && a.data.microDeval?.problematic ? 'problematic' : ''}>
                                                            {formatNumberToFixedDecimal(!Array.isArray(a.data.microDeval) ? a.data.microDeval?.value : 0)}
                                                        </td>
                                                    </Fragment>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </Fragment>
                ) : (
                    <FakeData permission={Permission.MATERIAL_RESULTS}><FakeGranulometrie /></FakeData>
                )}
            </Printable>
        </Card>
    );
}

export default Granulometrie;