import { useCallback, useRef } from 'react';
import { CloseIcon, SearchIcon } from '../../../assets/icons';

const SearchInput = () => {
    const input = useRef<HTMLInputElement>(null);

    const clearInput = useCallback(() => {
        if (input.current) input.current.value = '';
    }, []);

    return (
        <div className="search-input">
            <input
                ref={input}
                type="text"
                onFocus={clearInput}
                placeholder="Entrer une localisation"
                id="map-search"
            />
            <SearchIcon id="search-icon" />
            <CloseIcon id="clear-icon" onClick={clearInput} />
        </div>
    );
}


export default SearchInput;