import { Fragment } from 'react';
import DatePickerInput from '../../../components/inputs/DatePicker';
import MultipleSelect from '../../../components/inputs/MultipleSelect';
import PositionPicker from '../../../components/inputs/PositionPicker';
import SelectAutocomplete from '../../../components/inputs/SelectAutocomplete';
import Switch from '../../../components/inputs/Switch';
import TextInput from '../../../components/inputs/TextInput';
import { Textarea } from '../../../components/inputs/Textarea';
import Card from '../../../components/ui/Card';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { FormHookReturn } from '../../../hooks/useForm';
import { OperationDto, Phases } from '../../../models/operation';

interface GeneralProps extends Pick<FormHookReturn<OperationDto>, 'entity' | 'onChange' | 'attachInput' | 'errors'> { }

const General = ({
    entity,
    attachInput,
    onChange,
    errors
}: GeneralProps) => {
    return (
        <ScrollableContent
            side={<Card>
                <Switch<boolean> items={[{ key: true, label: 'Actif' }, { key: false, label: 'Inactif' }]} {...attachInput('active')} />
                <div className="input-column">
                    <label htmlFor="country">Commentaire</label>
                    <Textarea {...attachInput('comment')} />
                </div>
            </Card>}
        >
            <Card title="Informations générales">
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="name">Nom *</label>
                        <TextInput placeholder="Nom de l'opération" {...attachInput('name')} />
                    </div>
                    {entity.synoptique === 1 && (
                        <Fragment>
                            <div className="input-column">
                                <label htmlFor="borneType">Type</label>
                                <Switch
                                    className="form-switch"
                                    items={[{ key: 'autoroute', label: 'Autoroute' }, { key: 'route', label: 'Route' }, { key: undefined, label: 'Autre' }]}
                                    {...attachInput('borneType')}
                                />
                            </div>
                            {entity.borneType && (
                                <div className="input-column">
                                    <label htmlFor="referential">Référentiel *</label>
                                    <SelectAutocomplete
                                        endpoint={`/borne/list/${entity.borneType}`}
                                        {...attachInput('referential')}
                                    />
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="postCode">Code postal</label>
                        <TextInput placeholder="Code postal de référence" {...attachInput('postCode')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="date">Date de lancement *</label>
                        <DatePickerInput withIcon {...attachInput('date')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="phase">Phases</label>
                        <MultipleSelect items={Phases} {...attachInput('phases')} />
                    </div>
                </div>
                <div className="input-column">
                    <label htmlFor="project">Dossier *</label>
                    <TextInput placeholder="Numéro de dossier" {...attachInput('project')} />
                </div>
                <div className="input-column">
                    <label htmlFor="businesses">Entreprise</label>
                    <MultipleSelect endpoint="/business/list" {...attachInput('businesses')} />
                </div>
            </Card>
            <Card title="Localisation">
                <PositionPicker
                    type="zone"
                    location={entity?.zone?.location}
                    marker={entity?.zone?.marker}
                    onChange={onChange}
                    errors={errors}
                />
            </Card>
        </ScrollableContent>
    )
}

export default General;