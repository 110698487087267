import { Fragment, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveIcon } from '../../assets/icons';
import EntityCard from '../../components/entities/EntityCard';
import MultipleSelect from '../../components/inputs/MultipleSelect';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import Header from '../../components/ui/Header';
import ScrollableContent from '../../components/ui/ScrollableContent';
import useForm, { FormComparator } from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { Business, BusinessType, BusinessTypes, ProducerTypes, ProvidedMaterials } from '../../models/business';

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }],
    postCode: [{ comparator: FormComparator.REQUIRED }],
};

const Edit = () => {
    const request = useRequest();
    const { id } = useParams();
    const navigate = useNavigate();
    const { entity, attachInput, validate, setEntity, hasChanged, setChanged, onChange } = useForm<Business>({ active: true });

    const handleSubmit = useCallback(async (close?: boolean) => {
        const entity = validate(VALIDATION)
        if (!entity) return;

        const create = !entity?._id;
        const requestMethod = create ? request.post : request.put;

        requestMethod<Business>('/business', entity, {
            successMessage: create ? 'Entreprise créée avec succès' : 'Entreprise mise à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                    setEntity(data);
                }
            })
            .catch(() => null);
    }, [validate, setEntity]);

    useEffect(() => {
        if (id) {
            request.get<Business>(`/business/${id}`, { errorMessage: 'Une erreur est survenue lors de la récupération de l\'entreprise', loader: true })
                .then(setEntity)
                .catch(() => navigate(`/annuaire`))
        }
    }, []);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Annuaire', href: '/annuaire' }, { label: entity?._id ? entity.name! : 'Nouvelle entreprise' }]}>
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={<SaveIcon />}
                            onClick={handleSubmit}
                        />
                        <Button
                            label="Enregistrer et fermer"
                            icon={<SaveIcon />}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            <ScrollableContent side={<EntityCard entity={entity} attachInput={attachInput} />}>
                <Card title="Informations générales">
                    <div className="input-column">
                        <label htmlFor="name">Raison sociale *</label>
                        <TextInput {...attachInput('name')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="types">Type d'entreprise</label>
                        <MultipleSelect items={BusinessTypes} {...attachInput('types')} />
                    </div>
                    {!!entity.types?.includes(BusinessType.PRODUCER) && (
                        <div className="input-column">
                            <label htmlFor="producerType">Type de borne</label>
                            <MultipleSelect items={ProducerTypes} {...attachInput('producerType')} />
                        </div>
                    )}
                    {!!entity.types?.includes(BusinessType.PROVIDER) && (
                        <div className="input-column">
                            <label htmlFor="providedMaterials">Type de fourniture(s)</label>
                            <MultipleSelect items={ProvidedMaterials} {...attachInput('providedMaterials')} />
                        </div>
                    )}
                </Card>
                <Card title="Localisation">
                    <div className="input-column">
                        <label htmlFor="address">Adresse</label>
                        <TextInput {...attachInput('address')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="city">Ville</label>
                        <TextInput {...attachInput('city')} />
                    </div>
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="name">Code postal *</label>
                            <TextInput {...attachInput('postCode')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="country">Pays</label>
                            <TextInput {...attachInput('country')} />
                        </div>
                    </div>
                </Card>
            </ScrollableContent>
        </Fragment >
    )
}

export default Edit;