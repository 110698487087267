import { Fragment, useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { WarningIcon } from '../../../assets/icons';
import ModalSearchComponent from '../../../components/ModalSearchEntity/ModalSearchComponent';
import List, { FilterType } from '../../../components/data/List';
import Tag from '../../../components/ui/Tag';
import { useOperationContext } from '../../../context/OperationProvider';
import { Component } from '../../../models/component';
import { MaterialTypeLabel } from '../../../models/material';
import { Controles } from '../../../models/sample';
import { RevisionStatusList } from '../../../models/shared';
import { formatDate, formatTimestamp } from '../../../utils/format';
import ComponentDetail from './ComponentDetail';

const ComponentsAnalysisList = () => {
    const { operation, operationPermissions } = useOperationContext();
    const [selectedComponent, setSelectedComponent] = useState<Component | null>(null);
    const [isComponentSelectModalVisible, setComponentSelectModalVisible] = useState<boolean>(false);
    const navigate = useNavigate();
    const [URLSearchParams, setURLSearchParams] = useSearchParams();

    const handleClose = useCallback((refresh?: boolean) => {
        setSelectedComponent(null);
        if (refresh) {
            const refreshParam = URLSearchParams.get('refresh_list');
            URLSearchParams.set('refresh_list', refreshParam ? String(Number(refreshParam) + 1) : '1');
            setURLSearchParams(URLSearchParams);
        }
    }, [URLSearchParams]);

    return (
        <Fragment>
            <List<Component>
                columns={[
                    { key: 'type', label: 'Type', mapper: (element) => element.materialPopulated?.type ? MaterialTypeLabel[element.materialPopulated?.type] : '' },
                    { key: 'material', label: 'Matériau', mapper: (element) => element.materialPopulated?.name ?? '' },
                    { key: 'problematic', label: 'Prob.', mapper: (element) => element.analysisPopulated?.problematic ? <WarningIcon /> : (null) },
                    { key: 'status', label: 'Statut', mapper: (element) => <Tag value={element.status} items={RevisionStatusList} /> },
                    { key: 'controle', label: 'Contrôle', mapper: (element) => element.analysisPopulated?.controle ? <Tag value={element.analysisPopulated?.controle} items={Controles} /> : '' },
                    { key: 'date', label: 'Date', mapper: (element) => element.analysisPopulated?.date ? formatDate(element.analysisPopulated?.date) : '' },
                ]}
                dataEndpoint={`/component/operation/${operation._id}`}
                crudEndpoint="/component"
                actions={{
                    comment: operationPermissions.write,
                    view: operationPermissions.validate,
                    delete: operationPermissions.administrate
                }}
                onView={operationPermissions.read ? setSelectedComponent : undefined}
                initialPagination={{ sortBy: 'createdAt', sortDirection: -1 }}
                filters={[
                    { field: 'problematic', label: 'Problématique', type: FilterType.SWITCH, items: [{ key: true, label: 'Oui' }, { key: false, label: 'Non' }] },
                    { field: 'status', label: 'Statut', type: FilterType.MULTIPLE_SELECT, items: RevisionStatusList },
                    { field: 'controle', label: 'Contrôle', type: FilterType.MULTIPLE_SELECT, items: Controles },
                    { field: 'material', label: 'Matériau', type: FilterType.MULTIPLE_SELECT, endpoint: `/material/list/component/${operation._id}` },
                ]}
                buttons={[{ color: 'primary', label: 'Editer les données', onClick: () => setComponentSelectModalVisible(true) }]}
            />
            {isComponentSelectModalVisible && (
                <ModalSearchComponent onClose={() => setComponentSelectModalVisible(false)} onSubmit={(m) => navigate(`/operation/${operation._id}/travaux/suivi-de-constituants/${m._id}`)} />
            )}
            <ComponentDetail id={selectedComponent?._id ?? undefined} onClose={handleClose} />
        </Fragment>
    )
}

export default ComponentsAnalysisList;