import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useRequest from "../hooks/useRequest";
import { Operation, OperationDto, OperationRole, Phase, Phases, operationDtoToOperation } from "../models/operation";
import { Controle } from "../models/sample";
import { Permission, Role } from "../models/user";
import { useAuthContext } from "./AuthProvider";
import { useGlobalContext } from "./GlobalProvider";

const DEFAULT_OPERATION: Operation = {} as Operation

type OperationProviderProps = {
    children: ReactNode;
};

export interface OperationPermissions {
    administrate: boolean;
    notify: boolean;
    upload: boolean;
    validate: boolean;
    write: boolean;
    read: boolean;
    localizedResults: boolean;
    materialResults: boolean;
    synoptiqueData: boolean;
    filter: boolean;
    controles: Controle[];
}

export interface OperationProtectedPage {
    canAccess: (p: OperationPermissions) => boolean;
}

const DEFAULT_PERMISSIONS = {
    administrate: false,
    notify: false,
    upload: false,
    validate: false,
    write: false,
    read: false,
    localizedResults: false,
    materialResults: false,
    synoptiqueData: false,
    filter: false,
    controles: [],
}

type OperationContextType = {
    operation: Operation;
    isRoadOperation: boolean;
    isOperationLoaded: boolean;
    hasTravaux: boolean;
    availablePhases: { key: Phase, label: string }[];
    getOperation: (_id: string) => void;
    operationPermissions: OperationPermissions;
};

export const OperationContext = createContext<OperationContextType>({
    operation: DEFAULT_OPERATION,
    isRoadOperation: false,
    isOperationLoaded: false,
    hasTravaux: false,
    availablePhases: [],
    getOperation: (_id: string) => null,
    operationPermissions: DEFAULT_PERMISSIONS
});

const OperationProvider = ({ children }: OperationProviderProps) => {
    const { setToastError } = useGlobalContext();
    const { user, getMe, permissions } = useAuthContext();
    const request = useRequest();
    const [operation, setOperation] = useState<Operation>(DEFAULT_OPERATION);
    const [operationPermissions, setOperationPermissions] = useState<OperationPermissions>(DEFAULT_PERMISSIONS);
    const navigate = useNavigate();
    const { operationId } = useParams();
    const [searchParams] = useSearchParams();

    const getOperation = useCallback(async (_id: string) => {
        request.get<OperationDto>(`/operation/${_id}`, { loader: true })
            .then((dto) => {
                setOperation(operationDtoToOperation(dto));

                if (user.role === Role.SUPER_ADMIN) {
                    setOperationPermissions({
                        administrate: true,
                        notify: true,
                        upload: true,
                        validate: true,
                        write: true,
                        read: true,
                        localizedResults: true,
                        materialResults: true,
                        synoptiqueData: true,
                        filter: true,
                        controles: [Controle.EXTERIEUR, Controle.EXTERNE, Controle.AUTRE]
                    });
                } else {
                    let operationRole = dto.users.find(ur => ur.user === user._id)?.role;

                    if (dto.licensePopulated?.manager === user._id) {
                        operationRole = OperationRole.ADMIN;
                    }

                    setOperationPermissions({
                        administrate: operationRole === OperationRole.ADMIN,
                        notify: operationRole === OperationRole.ADMIN,
                        upload: operationRole === OperationRole.ADMIN,
                        validate: operationRole === OperationRole.ADMIN || operationRole === OperationRole.VALIDATE,
                        write: !!operationRole && operationRole !== OperationRole.READ,
                        read: !!operationRole && [OperationRole.ADMIN, OperationRole.VALIDATE, OperationRole.READ, OperationRole.WRITE].includes(operationRole),
                        localizedResults: !!dto.licensePopulated ? !!dto.licensePopulated.permissions.includes(Permission.LOCALIZED_RESULTS) : permissions.localizedResults,
                        materialResults: !!dto.licensePopulated ? !!dto.licensePopulated.permissions.includes(Permission.MATERIAL_RESULTS) : permissions.materialResults,
                        synoptiqueData: !!dto.licensePopulated ? !!dto.licensePopulated.permissions.includes(Permission.SYNOPTIQUE_DATA) : permissions.synoptiqueData,
                        filter: !!dto.licensePopulated ? !!dto.licensePopulated.permissions.includes(Permission.FILTER) : permissions.filter,
                        controles: !!operationRole && [OperationRole.ADMIN, OperationRole.VALIDATE, OperationRole.WRITE].includes(operationRole)
                            ? [Controle.EXTERIEUR, Controle.EXTERNE, Controle.AUTRE]
                            : [
                                ...(operationRole === OperationRole.EXTERIEUR ? [Controle.EXTERIEUR] : []),
                                ...(operationRole === OperationRole.EXTERNE ? [Controle.EXTERNE] : []),
                                ...(operationRole === OperationRole.AUTRE ? [Controle.AUTRE] : []),
                            ]
                    });
                }
            })
            .catch((e) => {
                setOperation(DEFAULT_OPERATION);
                setOperationPermissions(DEFAULT_PERMISSIONS);
                if ((e as any)?.error?.status === 401) {
                    navigate('/erreur/401');
                } else {
                    setToastError('Une erreur est survenue. Veuillez contacter l\'administrateur.', e);
                    navigate('/accueil');
                }
            });
    }, [user, permissions]);

    useEffect(() => {
        if (operationId && (!operation?._id || operation._id !== operationId || !!searchParams.get('refresh_operation'))) {
            setOperation({ ...DEFAULT_OPERATION })
            getOperation(operationId);

            // Refresh user to refresh operationcount on license
            if (!!searchParams.get('refresh_operation') && user.managedLicense) {
                getMe();
            }
        }
    }, [operationId, searchParams]);

    return (
        <OperationContext.Provider
            value={{
                operation,
                isRoadOperation: operation?.synoptique === 1,
                hasTravaux: operation?.phases?.includes(Phase.TRAVAUX),
                availablePhases: Phases.filter(p => operation?.phases?.includes(p.key)) ?? [],
                getOperation,
                operationPermissions,
                isOperationLoaded: !operationId || operationId === operation?._id
            }}
        >
            {children}
        </OperationContext.Provider>
    );
};

const useOperationContext = () => useContext<OperationContextType>(OperationContext);

export { OperationProvider, useOperationContext };
export default OperationContext;
