import { Fragment, useCallback, useEffect, useState } from 'react';
import { SearchIcon } from '../../assets/icons';
import CharacAddRemove, { SelectedMaterialCharacteristicElement } from '../../components/CharacAddRemove';
import ModalStandard from '../../components/ModalStandard';
import StatisticsPicker from '../../components/data/Statistics/StatisticsPicker';
import { IdentityBlockMaterial } from '../../components/entities/IdentityBlock';
import DatePickerInput from '../../components/inputs/DatePicker';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import NoResult from '../../components/ui/NoResult';
import ScrollableContent from '../../components/ui/ScrollableContent';
import { useAuthContext } from '../../context/AuthProvider';
import useForm from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { AnalysisStatistics } from '../../models/analysis';
import { MATERIAL_FORM_PARAMETERS, Material, MaterialCharacteristic } from '../../models/material';
import { Permission } from '../../models/user';

type MaterialSearch = {
    from: Date;
    to: Date;
}

interface MaterialSearchResult {
    businessId?: string;
    businessName?: string;
    requirements: string[];
}

const FindMaterial = () => {
    const request = useRequest();
    const { permissions } = useAuthContext();
    const [standard, setStandard] = useState<Material | null>(null);
    const [modalStandardVisible, setModalStandardVisible] = useState<boolean>(false);
    const [availableCharacs, setAvailableCharacs] = useState<MaterialCharacteristic[]>([]);
    const [selectedCharacs, setSelectedCharacs] = useState<SelectedMaterialCharacteristicElement[]>([]);
    const [statistics, setStatistics] = useState<AnalysisStatistics[] | null>(null);
    const { entity, attachInput, validate, setEntity } = useForm<MaterialSearch>({});
    const [materials, setMaterials] = useState<MaterialSearchResult[] | null>(null);
    const [selectedBusiness, setSelectedBusiness] = useState<string | null>(null);

    const clear = useCallback(() => {
        setEntity({});
        setStatistics(null);
        setAvailableCharacs([]);
        setSelectedCharacs([]);
        setAvailableCharacs([]);
    }, []);

    const searchMaterial = useCallback(async (standard: Material, selectedCharacs: SelectedMaterialCharacteristicElement[]) => {
        const params = {
            ...(entity ?? {}),
            type: standard.type,
            material: standard._id,
            characteristics: selectedCharacs.map(charac => ({
                key: charac.key,
                min: charac.searchParams.min,
                max: charac.searchParams.max,
                seuil: charac.searchParams.seuil,
                tolerance: charac.searchParams.tolerance
            }))
        }
        request.get<MaterialSearchResult[]>('/analysis/material/search', { params, loader: true, errorMessage: 'Une erreur est survenue lors de la recherche.' })
            .then(setMaterials)
            .catch(() => {
                setMaterials(null);
            })
            .finally(() => {
                setStatistics(null);
                setSelectedBusiness(null);
            });

    }, []);

    const getStatistics = useCallback(async (standard: Material, search: Partial<MaterialSearch>, selectedBusiness: string) => {
        const params = { type: standard.type, material: standard._id, ...search, businesses: [selectedBusiness] };
        request.get<AnalysisStatistics[]>('/analysis/search', { params, loader: true })
            .then(setStatistics)
            .catch(() => setStatistics(null));
    }, []);

    useEffect(() => {
        setAvailableCharacs(standard?.type ? MATERIAL_FORM_PARAMETERS[standard.type]?.characteristics ?? [] : []);
        setSelectedCharacs([]);
    }, [standard]);

    useEffect(() => {
        if (selectedBusiness && standard) {
            getStatistics(standard, entity, selectedBusiness);
        } else {
            setStatistics(null);
        }
    }, [selectedBusiness]);

    return (
        <ScrollableContent
            side={<Fragment>
                <Card type="action">
                    <Button color="secondary" label="Réinitialiser" onClick={clear} />
                    <Button color="primary" icon={<SearchIcon />} label="Rechercher" onClick={() => standard ? searchMaterial(standard, selectedCharacs) : null} />
                </Card>
                <Card type="info">
                    Cet outil permet de filtrer les analyses des opérations suivies par OSO Numérique afin de trouver l'entreprise proposant un matériau donné
                    conforme aux exigences désirées.<br />
                    Après avoir renseigné le type de matériau, la liste des caractéristiques possibles apparaît. Elles peuvent être ensuite ajoutées au besoin,
                    avec un pourcentage de tolérance de résultat (afin d'éviter qu'un résultat soit refusé pour quelques valeurs aberrantes).
                    <div className="warn">
                        Attention : l'entreprise étant discriminante lors de la compilation des données, certains résultats peuvent ne pas apparaître
                        si celle-ci n'est pas renseignée lors de la saisie d'analyse.
                    </div>
                </Card>
            </Fragment>}
        >
            <Card title="Paramètres">
                <IdentityBlockMaterial
                    material={standard ?? undefined}
                    onClick={() => setModalStandardVisible(true)}
                />
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="from">Depuis</label>
                        <DatePickerInput withIcon {...attachInput('from')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="to">Jusqu'à</label>
                        <DatePickerInput withIcon {...attachInput('to')} />
                    </div>
                </div>
            </Card>
            {standard?.type && (
                <Card title="Caractéristiques">
                    <CharacAddRemove
                        availableCharacs={availableCharacs}
                        setAvailableCharacs={setAvailableCharacs}
                        selectedCharacs={selectedCharacs}
                        setSelectedCharacs={setSelectedCharacs}
                        withTolerance
                    />
                </Card>
            )}
            {materials && !!materials.length && (
                <Card title="Résultats">
                    <div className="material-results-container">
                        <h3>Matériaux répondants aux critères</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Entreprise</th>
                                    <th>Exigences</th>
                                </tr>
                            </thead>
                            <tbody>
                                {materials.map((material, index) => (
                                    <tr
                                        key={index}
                                        onClick={() => setSelectedBusiness(material.businessId ?? null)} className={selectedBusiness === material?.businessId ? 'selected' : ''}
                                    >
                                        <td>{material.businessName}</td>
                                        <td>{material.requirements.join(', ')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {statistics?.length && (
                        <StatisticsPicker
                            statistics={statistics}
                            materialType={standard?.type}
                            subtitle="Résultats de bibliothèque"
                            columns={['date', 'operation', 'material', 'business', 'controle']}
                            chartAxis="date"
                            chartByOptions={['controle', 'material']}
                            isAuthorized={permissions.library}
                            permission={Permission.LIBRARY}
                            isLibrary={true}
                        />
                    )}
                </Card>
            )}
            {
                materials && !materials.length && (
                    <NoResult
                        text="Aucun résultat pour ces paramètres"
                    />
                )
            }
            {modalStandardVisible && <ModalStandard onSubmit={setStandard} onClose={() => setModalStandardVisible(false)} />}
        </ScrollableContent >
    )
}

export default FindMaterial;