import ReactDOM from 'react-dom/client';
import './assets/styles/react-datasheet.css';
import { AuthProvider } from './context/AuthProvider';
import { GlobalProvider } from './context/GlobalProvider';
import './layout/index.scss';
import App from './pages/App';
import './styles/index.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <AuthProvider>
        <GlobalProvider>
            <App />
        </GlobalProvider>
    </AuthProvider>
);
