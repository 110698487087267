import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import List from "../../components/data/List";
import Header from "../../components/ui/Header";
import { License } from "../../models/license";
import { formatTimestamp, getFullName } from "../../utils/format";

interface LicensesProps {
}

const Licenses = ({ }: LicensesProps) => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: 'Licences' }]} />
            <List<License>
                columns={[
                    { key: 'reference', label: 'Référence' },
                    { key: 'manager', label: 'Gérant', mapper: (element) => getFullName(element.managerPopulated) },
                    { key: 'createdAt', label: 'Date de création', mapper: (element) => formatTimestamp(element.createdAt) },
                ]}
                dataEndpoint="/license"
                crudEndpoint="/license"
                baseUrl="/licences"
                actions={{
                    edit: true,
                    activate: true
                }}
                filters={[
                    { field: 'active', label: 'Actif' }
                ]}
                initialPagination={{ sortBy: 'name', sortDirection: 1 }}
                buttons={[{ label: "Nouvelle licence", onClick: () => navigate('/licences/creer') }]}
            />
        </Fragment>
    )
}

export default Licenses;