import { Outlet } from 'react-router-dom';
import '../pages/Login/index.scss';
import logo from '../assets/images/logo-big.png';
import background from '../assets/images/login-bg.webp';

const Public = () => (
    <div id="container-login">
        <div id="login-content">
            <img src={logo} id="oso-logo" alt="" />
            <h1>L'intelligence numérique<br />au service de l'expertise</h1>
            <Outlet />
        </div>
        <div id="image-container" className="md-hide">
            <img src={background} alt="" />
        </div>
    </div>
);

export default Public;
