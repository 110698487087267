import { Fragment, useCallback, useState } from 'react';
import { SearchIcon } from '../../assets/icons';
import ModalStandard from '../../components/ModalStandard';
import StatisticsPicker from '../../components/data/Statistics/StatisticsPicker';
import { IdentityBlockMaterial } from '../../components/entities/IdentityBlock';
import DatePickerInput from '../../components/inputs/DatePicker';
import MultipleSelect from '../../components/inputs/MultipleSelect';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import NoResult from '../../components/ui/NoResult';
import ScrollableContent from '../../components/ui/ScrollableContent';
import { useAuthContext } from '../../context/AuthProvider';
import useForm from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { AnalysisStatistics } from '../../models/analysis';
import { Material, MaterialsWithProducer } from '../../models/material';
import { Permission } from '../../models/user';

const INFO = "La recherche de caractéristique permet d'afficher les résultats d'analyse pour un matériau donné et une fourchette temporelle optionnelle, et ce sur l'ensemble des opérations suivies par OSO Numérique.\n\nLes résultats sont présentés par caractéristique, puis par entreprise (fournisseur ou producteur) et type de contrôle (extérieur, externe ou autre).";

const FindCharacteristic = () => {
    const request = useRequest();
    const { permissions } = useAuthContext();
    const [standard, setStandard] = useState<Material | null>(null);
    const [modalStandardVisible, setModalStandardVisible] = useState<boolean>(false);
    const [statistics, setStatistics] = useState<AnalysisStatistics[] | null>(null);
    const { entity, attachInput, setEntity } = useForm<{ businesses: string[], from: Date, to: Date }>({});

    const clear = () => {
        setEntity({});
        setStandard(null);
        setStatistics(null);
    }

    const handleSearch = useCallback(async () => {
        if (!standard) return;

        const params = { type: standard.type, material: standard._id, ...(entity ?? {}) };
        request.get<AnalysisStatistics[]>('/analysis/search', { params, loader: true, errorMessage: 'Une erreur est survenue lors de la recherche.' })
            .then(setStatistics)
            .catch(() => setStatistics(null));
    }, [standard]);


    return (
        <ScrollableContent
            side={<Fragment>
                <Card type="action">
                    <Button color="secondary" label="Réinitialiser" onClick={clear} />
                    <Button color="primary" icon={<SearchIcon />} label="Rechercher" onClick={handleSearch} />
                </Card>
                <Card type="info">{INFO}</Card>
            </Fragment>}
        >
            <Card title="Paramètres">
                <IdentityBlockMaterial
                    material={standard ?? undefined}
                    onClick={() => setModalStandardVisible(true)}
                />
                <div className="row">
                    {standard?.type && MaterialsWithProducer.includes(standard?.type) ? (
                        <div className="input-column">
                            <label htmlFor="businesses">Producteur</label>
                            <MultipleSelect endpoint="/business/list/producer" {...attachInput('businesses')} />
                        </div>
                    ) : (
                        <div className="input-column">
                            <label htmlFor="businesses">Fournisseur</label>
                            <MultipleSelect endpoint="/business/list/provider" {...attachInput('businesses')} />
                        </div>
                    )}
                    <div className="input-column">
                        <label htmlFor="from">Depuis</label>
                        <DatePickerInput withIcon {...attachInput('from')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="to">Jusqu'à</label>
                        <DatePickerInput withIcon {...attachInput('to')} />
                    </div>
                </div>
            </Card>
            {!!statistics?.length && (
                <Card title="Résultats">
                    <StatisticsPicker
                        statistics={statistics}
                        materialType={standard?.type}
                        subtitle="Résultats de bibliothèque"
                        columns={['date', 'operation', 'material', 'business', 'controle']}
                        chartAxis="date"
                        chartByOptions={['controle', 'business']}
                        isAuthorized={permissions.library}
                        permission={Permission.LIBRARY}
                        isLibrary={true}
                    />
                </Card>
            )}
            {statistics && !statistics.length && (
                <NoResult
                    text="Aucun résultat pour ces paramètres"
                />
            )}
            {modalStandardVisible && <ModalStandard onSubmit={setStandard} onClose={() => setModalStandardVisible(false)} />}
        </ScrollableContent>
    )
}

export default FindCharacteristic;