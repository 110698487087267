import { ReactNode } from "react";
import { RevisionEntity } from "../../../models/shared";
import Switch from "../../inputs/Switch";
import { Textarea } from "../../inputs/Textarea";
import Card from "../../ui/Card";
import './index.scss';

interface EntityCardProps<T extends RevisionEntity> {
    entity: Partial<T>;
    attachInput: (field: string) => any;
    children?: ReactNode;
}

const EntityCard = <T extends RevisionEntity>({ entity, attachInput, children }: EntityCardProps<T>) => {
    return (
        <Card className="entity-card">
            <Switch<boolean> items={[{ key: true, label: 'Actif' }, { key: false, label: 'Inactif' }]} {...attachInput('active')} />
            <div className="input-column">
                <label htmlFor="country">Commentaire</label>
                <Textarea {...attachInput('comment')} />
            </div>
            {children}
        </Card>
    )
}

export default EntityCard;