import { CloseCircleIcon, CompanyIcon, EditIcon, LayerIcon, LicenseIcon, OpenIcon, OperationIcon, RockIcon, SelectIcon, UserIcon } from '../../../assets/icons';
import { useOperationContext } from '../../../context/OperationProvider';
import { Business, BusinessTypeLabel } from '../../../models/business';
import { License } from '../../../models/license';
import { Lot } from '../../../models/lot';
import { MATERIAL_IDENTITY_FIELDS, Material } from '../../../models/material';
import { Operation, OperationSubType } from '../../../models/operation';
import { User } from '../../../models/user';
import { formatDate, getFullName } from '../../../utils/format';
import { getNestedField } from '../../../utils/objects';
import { floatToPrString } from '../../../utils/pr';
import Menu from '../../ui/Menu';
import './index.scss';

interface IdentityBlockUserProps {
    user?: User;
    onClick?: () => void;
}

export const IdentityBlockUser = ({
    user,
    onClick
}: IdentityBlockUserProps) => {
    return user?._id
        ? (
            <div className="identity-block">
                <div className="identity-block-name">
                    <UserIcon />
                    <span>{user.fullName}</span>
                    {!!onClick && <Menu label="Editer" icon={<EditIcon />} onClick={onClick} />}
                </div>
                <div className="identity-block-details">
                    <div className="identity-block-detail">
                        <label>Entreprise</label>
                        <div>{user?.businessPopulated?.name}</div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="identity-block select-entity" onClick={onClick}>
                <SelectIcon />
                <span>Cliquer ici pour sélectionner un utilisateur</span>
            </div>
        );
}

interface IdentityBlockLicenseProps {
    license: Partial<License>;
}

export const IdentityBlockLicense = ({
    license
}: IdentityBlockLicenseProps) => (
    <div className="identity-block">
        <div className="identity-block-name">
            <LicenseIcon />
            <span>{license.reference ?? ''}</span>
        </div>
        <div className="identity-block-details">
            <div className="identity-block-detail">
                <label>Gérant</label>
                <div>{getFullName(license?.managerPopulated)}</div>
            </div>
            <div className="identity-block-detail">
                <label>Date début</label>
                <div>{license.start ? formatDate(license.start) : ''}</div>
            </div>
            <div className="identity-block-detail">
                <label>Date fin</label>
                <div>{license.end ? formatDate(license.end) : ''}</div>
            </div>
        </div>
    </div>
);

interface IdentityBlockLotProps {
    lot?: Lot;
    onClick?: () => void;
}

export const IdentityBlockLot = ({
    lot,
    onClick
}: IdentityBlockLotProps) => {
    const { operation } = useOperationContext();

    return lot?._id
        ? (
            <div className="identity-block">
                <div className="identity-block-name">
                    <LayerIcon />
                    <span>Lot {lot.fullLot}</span>
                    {!!onClick && <Menu label="Editer" icon={<EditIcon />} onClick={onClick} />}
                </div>
                <div className="identity-block-details">
                    <div className="identity-block-detail">
                        <label>Sens</label>
                        <div>{lot.way}</div>
                    </div>
                    <div className="identity-block-detail">
                        <label>Voies</label>
                        <div>{lot.roads.map(roadId => operation.roadsObj[roadId]?.name ?? null).join(', ')}</div>
                    </div>
                    {lot?.zone?.prStart && lot?.zone?.prEnd
                        ? (
                            <div className="identity-block-detail">
                                <label>PR</label>
                                <span>{floatToPrString(lot?.zone?.prStart ?? '')} - {floatToPrString(lot?.zone?.prEnd ?? '')}</span>
                            </div>
                        ) : (
                            <div className="identity-block-detail">
                                <label>Localisation</label>
                                <span>{lot?.zone?.location}</span>
                            </div>
                        )
                    }
                    <div className="identity-block-detail">
                        <label>Matériau</label>
                        <div>{lot?.materialPopulated?.name ?? ''}</div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="identity-block select-entity" onClick={onClick}>
                <SelectIcon />
                <span>Cliquer ici pour sélectionner un lot</span>
            </div>
        );
}

const getField = (entity: any, field: string, labels: { [k: string]: string } = {}) => {
    const value = getNestedField(entity, field) ?? '';
    return labels[value] ?? value;
}

interface IdentityBlockMaterialProps {
    material?: Material;
    onClick?: () => void;
    onClose?: () => void;
}

export const IdentityBlockMaterial = ({
    material,
    onClick,
    onClose,
}: IdentityBlockMaterialProps) => (material?._id ? (
    <div className="identity-block">
        <div className="identity-block-name">
            <RockIcon />
            <span>{material.name}</span>
            {!!onClick && <Menu label="Editer" icon={<EditIcon />} onClick={onClick} />}
            {!!onClose && <Menu label="Fermer" icon={<CloseCircleIcon />} onClick={onClose} />}
        </div>
        <div className="identity-block-details">
            {(MATERIAL_IDENTITY_FIELDS[material.type] ?? []).map((item) => (
                <div className="identity-block-detail" key={item.field}>
                    <label>{!material.isStandard && item.labelRequirement ? item.labelRequirement : item.label}</label>
                    <span>{getField(material, item.field, item.enum)}</span>
                </div>
            ))}
        </div>
    </div>
) : (
    <div className="identity-block select-entity" onClick={onClick}>
        <SelectIcon />
        <span>Cliquer ici pour sélectionner une norme</span>
    </div>
));

interface IdentityBlockBusinessProps {
    business: Partial<Business>;
    onClick?: () => void;
}

export const IdentityBlockBusiness = ({
    business,
    onClick,
}: IdentityBlockBusinessProps) => (
    <div className="identity-block">
        <div className="identity-block-name">
            <CompanyIcon />
            <span>{business.name}</span>
            {!!onClick && <Menu label="Editer" icon={<EditIcon />} onClick={onClick} />}
        </div>
        <div className="identity-block-details">
            {business.types?.length && (
                <div className="identity-block-detail">
                    <label>Type</label>
                    <div>{business.types.map(t => BusinessTypeLabel[t]).join(', ')}</div>
                </div>
            )}
            <div className="identity-block-detail">
                <label>Adresse</label>
                <div>{business.address ?? ''}</div>
            </div>
            <div className="identity-block-detail">
                <label>Code postal</label>
                <div>{business.postCode ?? ''}</div>
            </div>
            <div className="identity-block-detail">
                <label>Ville</label>
                <div>{business.city ?? ''}</div>
            </div>
        </div>
    </div>
);

interface IdentityBlockOperationProps {
    operation: Operation;
    onClick?: () => void;
    onView?: () => void;
}

export const IdentityBlockOperation = ({
    operation,
    onView
}: IdentityBlockOperationProps) => (
    <div className="identity-block">
        <div className="identity-block-name">
            <OperationIcon className="stroke" />
            <span>{operation.name}</span>
            {!!onView && <Menu label="Voir" icon={<OpenIcon />} onClick={onView} />}
        </div>
        <div className="identity-block-details">
            <div className="identity-block-detail">
                <label>Type</label>
                <div>{operation.type ? OperationSubType[operation.type].label : ''}</div>
            </div>
            <div className="identity-block-detail">
                <label>Projet</label>
                <div>{operation.project ?? ''}</div>
            </div>
            <div className="identity-block-detail">
                <label>Date</label>
                <div>{formatDate(operation.date)}</div>
            </div>
        </div>
    </div>
);