import { useLocation } from 'react-router';
import { useGlobalContext } from '../../../context/GlobalProvider';
import { Permission, PermissionLabel } from '../../../models/user';
import AxiosInstance from '../../../services/AxiosInstance';
import './index.scss';
import { Fragment } from 'react';

interface AskAccessProps {
    permission: Permission
}
const AskAccess = ({
    permission
}: AskAccessProps) => {
    const { setToastError, setToastSuccess, setSpinnerVisible } = useGlobalContext();
    const location = useLocation();

    const handleAskAccess = async () => {
        try {
            setSpinnerVisible(true);
            await AxiosInstance.post('/message/ask-access', { permission: PermissionLabel[permission] ?? location.pathname });
            setToastSuccess('Demande d\'accès envoyée');
        } catch (e) {
            setToastError('Une erreur est survenue lors de la demande', e);
        } finally {
            setSpinnerVisible(false);
        }
    }

    return (
        <div className="ask-access">
            {permission === Permission.PRINT ? (
                <Fragment>
                    <div className="ask-access-line1">L'impression de documents ne vous est pas accessible</div>
                    <div className="ask-access-line2"><span onClick={handleAskAccess}>Contactez l'administrateur</span> pour débloquer cette fonctionnalité</div>
                </Fragment>
            ) : (
                permission === Permission.FILTER ? (
                    <Fragment>
                        <div className="ask-access-line1">Filtrer les éléments requiert une permission supplémentaire</div>
                        <div className="ask-access-line2"><span onClick={handleAskAccess}>Envoyez une demande</span> à l'administrateur pour obtenir plus d'options</div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="ask-access-line1">Vous n'avez pas la permission de visualiser les données</div>
                        <div className="ask-access-line2">Pour faire évoluer votre accès, contactez l'administrateur en <span onClick={handleAskAccess}>cliquant ici</span></div>
                    </Fragment>
                )
            )}
        </div>
    )
}

export default AskAccess;