import { Fragment, useCallback, useEffect, useState } from "react";
import { ReactComponent as AddIcon } from "../../assets/icons/add-black.svg";
import { ReactComponent as CollapseIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { FormBaseProps } from "../../hooks/useForm";
import useOutsideClick from "../../hooks/useOutsideClick";
import useRequest from "../../hooks/useRequest";
import { toggleInArray } from "../../utils/objects";
import Menu from "../ui/Menu";

const MAX_HEIGHT = 306;

type MultipleSelectItem<T> = { key: T, label: string };

interface MultipleSelectProps<T> extends FormBaseProps<T[]> {
    items?: MultipleSelectItem<T>[];
    endpoint?: string;
}

const MultipleSelect = <T,>({
    value,
    items,
    endpoint,
    disabled,
    onChange,
    errors,
}: MultipleSelectProps<T>) => {
    const request = useRequest();
    const [itemsOrData, setItemsOrData] = useState<MultipleSelectItem<T>[]>([]);
    const [isOpened, setOpened] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => setOpened(false), []));

    const toggleItem = useCallback((key: T) => {
        const _value = toggleInArray(key, value ?? []);

        onChange(_value);
    }, [onChange, itemsOrData, value]);

    useEffect(() => {
        if (ref.current) {
            const bound = ref.current.getBoundingClientRect();
            if (!isOpened) {
                ref.current.style.maxHeight = "100%";
            } else {
                if (bound.top + MAX_HEIGHT > window.innerHeight) {
                    ref.current.style.bottom = '0';
                    ref.current.style.top = "auto";
                } else {
                    ref.current.style.top = '0';
                    ref.current.style.bottom = "auto";
                }
                ref.current.style.maxHeight = MAX_HEIGHT + "px";
            }
        }
    }, [isOpened, ref]);

    useEffect(() => {
        if (endpoint) {
            request.get<MultipleSelectItem<T>[]>(endpoint).then(setItemsOrData).catch(() => setItemsOrData([]));
        } else {
            setItemsOrData(items ?? []);
        }
    }, [endpoint, items]);

    return (
        <div className="multiple-select">
            <div
                className={`dropdown border-input${isOpened ? " opened" : ""}${!!errors?.length ? " input-error" : ""}`}
                ref={ref}
            >
                <div className="input" onClick={() => setOpened(!isOpened)}>
                    {!!value?.length &&
                        value.map((v, index) => (
                            <Menu
                                key={index}
                                icon={<CloseIcon />}
                                label={
                                    itemsOrData?.find((item) => item.key === v)
                                        ?.label ?? ""
                                }
                                onClick={() => toggleItem(v)}
                            />
                        ))}
                </div>
                {!!errors?.length && (
                    <div className="form-error">{errors.join(". ")}</div>
                )}
                {!disabled && (
                    <Fragment>
                        <div className="icon-container">
                            {isOpened ? (
                                <CollapseIcon
                                    onClick={() => setOpened(false)}
                                />
                            ) : (
                                <AddIcon
                                    className="add-icon"
                                    onClick={() => setOpened(true)}
                                />
                            )}
                        </div>
                        {!!itemsOrData?.length && (
                            <div className="dropdown-scroll">
                                {isOpened && (
                                    <CloseIcon
                                        className="sm-show close-icon-form"
                                        onClick={() => setOpened(false)}
                                    />
                                )}
                                {itemsOrData.map((item) => {
                                    const active = !!value?.includes(item.key);

                                    return (
                                        <div
                                            key={String(item.key)}
                                            className={`select-item ${active ? 'active' : ''}`}
                                            onClick={() => toggleItem(item.key)}
                                        >
                                            {item.label}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
            {!!errors?.length && (
                <div className="form-error">{errors.join(". ")}</div>
            )}
        </div>
    );
};

export default MultipleSelect;