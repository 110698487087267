import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { FormComparator, validateAll } from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { Analysis } from '../../models/analysis';
import { Lot } from '../../models/lot';
import { MaterialCharacteristicElementRequirement, getAnalysisCharac } from '../../models/material';
import { Phase, Road, RoadPosition, RoadPositionLabel, RoadPositionsList } from '../../models/operation';
import { Controle, ControleLabel, Controles, Sample, SampleType } from '../../models/sample';
import { ListItem, RevisionStatus, RevisionStatusLabel, RevisionStatusList } from '../../models/shared';
import { formatDate, formatNumberToFixedDecimal } from '../../utils/format';
import { changeFieldValue } from '../../utils/objects';
import { floatToPrString, prToFloat } from '../../utils/pr';
import DatePicker from '../inputs/DatePicker';
import Tooltip from '../ui/Tooltip';
import AnalysisCell from './AnalysisCell';
import AnalysisHeader, { AnalysisMinMax } from './AnalysisHeader';
import './index.scss';
import { useOperationContext } from '../../context/OperationProvider';
import Button from '../ui/Button';

const OPTIONS = {
    date: true,
    status: true,
    controle: true,
    actions: true,
    producer: true,
    minMax: true,
    thickness: true,
    road: true,
    roadPosition: true,
    pr: true,
};

interface SampleSingleLayer {
    _id?: string;
    name: string;
    diameter?: number;
    date: Date;
    controle: Controle;
    status: RevisionStatus;
    road?: string;
    roadPosition?: RoadPosition;
    thickness?: number;
    collage?: string;
    pr?: string;
    analysis?: Partial<Analysis>;
    isEditing: boolean;
}

interface SampleMasseAnalysisProps {
    lot: Lot;
    type: SampleType;
    roads: Road[];
}

const SampleMasseAnalysis = ({
    lot,
    roads,
    type
}: SampleMasseAnalysisProps) => {
    const request = useRequest();
    const { operationPermissions } = useOperationContext();
    const [characteristics, setCharacteristics] = useState<MaterialCharacteristicElementRequirement[]>([]);
    const [producers, setProducers] = useState<ListItem[]>([]);
    const [samples, setSamples] = useState<SampleSingleLayer[]>([{
        name: lot?.materialPopulated?.name ?? '',
        date: lot?.date ?? new Date().toISOString(),
        thickness: type === SampleType.CAROTTE ? lot?.thickness ?? 0 : 0,
        controle: operationPermissions.controles[0] ?? Controle.EXTERIEUR,
        status: operationPermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
        road: roads.length ? roads[0]._id : undefined,
        roadPosition: RoadPosition.AXE,
        isEditing: true
    }]);
    const [errors, setErrors] = useState<{ [index: number]: { [key: string]: string[] } } | null>(null);
    const options = type === SampleType.CAROTTE ? { ...OPTIONS, diameter: true, collage: true } : OPTIONS;

    const controles = useMemo(() => Controles.filter(c => operationPermissions.controles.includes(c.key)), [operationPermissions]);

    const handleChange = useCallback((index: number, field: string, value: any) => {
        setSamples(samples => {
            if (index >= 0 && index < samples?.length) {
                const _samples = [...samples];
                _samples[index] = changeFieldValue(_samples[index], field, value);
                return _samples;
            }
            return samples;
        });
    }, []);

    const handleCopy = useCallback((index: number) => {
        setSamples(samples => {
            if (index >= 0 && index < samples?.length) {
                const _samples = [...samples];
                _samples[0] = {
                    ...samples[index],
                    analysis: { ...samples[index].analysis, data: undefined },
                    isEditing: true,
                    _id: undefined
                };
                return _samples;
            }
            return samples;
        });
    }, []);

    const handleSave = useCallback(async (index: number) => {
        const sample = samples[index];

        const dto = {
            _id: sample._id,
            name: sample.name,
            type: type,
            phase: Phase.TRAVAUX,
            operation: lot.operation,
            diameter: sample.diameter,
            status: sample.status,
            lot: lot._id,
            fullLot: lot.fullLot,
            date: sample.date,
            controle: sample.controle,
            location: {
                roadPosition: sample.roadPosition,
                road: sample.road,
                way: lot.way,
                pr: sample.pr !== undefined ? prToFloat(sample.pr) : 0,
            },
            layers: [{
                thickness: sample.thickness ?? 0,
                collage: sample.collage !== "no",
                order: 0,
                active: true,
                material: lot.materialPopulated?._id,
                analysisPopulated: {
                    date: sample.date,
                    controle: sample.controle,
                    type: lot.materialPopulated?.type,
                    business: sample.analysis?.business,
                    material: lot.materialPopulated?._id,
                    operation: lot.operation,
                    data: sample.analysis?.data,
                    order: 1,
                    active: true
                }
            }],
            active: true
        }

        const [_errors, hasError] = validateAll(dto, {
            name: [{ comparator: FormComparator.REQUIRED }],
            'location.pr': [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.POSITIVE_NUMBER },
                { comparator: FormComparator.PR },
                { comparator: FormComparator.PR_INSIDE, compareTo: { min: lot?.zone?.prStart, max: lot?.zone?.prEnd }, message: 'Le PR est hors des limites du lot' },
            ],
        });

        if (hasError) {
            setErrors({ [index]: _errors });
            return;
        } else {
            setErrors(null);
        }

        const create = !sample._id;
        const requestMethod = create ? request.post : request.put;

        requestMethod<Sample>('/sample', dto, {
            successMessage: create ? 'Echantillon créé avec succès' : 'Echantillon mis à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                const _samples = [...samples];
                _samples[index] = {
                    _id: data._id,
                    name: data.name,
                    controle: data.controle,
                    status: data.status,
                    date: data.date,
                    road: data.location.road,
                    roadPosition: data.location.roadPosition,
                    diameter: data.diameter,
                    pr: floatToPrString(data.location.pr),
                    analysis: data.layers?.length ? data.layers[0].analysisPopulated : undefined,
                    collage: data.layers?.[0]?.collage ? "yes" : "no",
                    thickness: data.layers?.[0]?.thickness ?? 0,
                    isEditing: false
                }
                setSamples(index === 0
                    ? [{
                        name: lot?.materialPopulated?.name ?? '',
                        date: lot?.date ?? new Date().toISOString(),
                        thickness: type === SampleType.CAROTTE ? lot?.thickness ?? 0 : 0,
                        controle: operationPermissions.controles[0] ?? Controle.EXTERIEUR,
                        status: operationPermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
                        road: roads.length ? roads[0]._id : undefined,
                        roadPosition: RoadPosition.AXE,
                        isEditing: true
                    },
                    ..._samples]
                    : _samples
                );
            })
            .catch(() => null);

    }, [samples]);

    const handleDelete = useCallback(async (_id: string) => {
        request.delete(`/sample/${_id}`, { loader: true, errorMessage: 'Une erreur est survenue lors de la suppression.', successMessage: 'Echantillon supprimé avec succès' })
            .then(() => {
                setSamples(samples.filter(s => s._id !== _id));
            })
            .catch(() => null);
    }, [samples]);

    useEffect(() => {
        if (lot?.materialPopulated) {
            setCharacteristics(getAnalysisCharac(lot.materialPopulated));
        }
    }, [lot]);

    useEffect(() => {
        request.get<ListItem[]>('/business/list/producer')
            .then(setProducers)
            .catch(() => null);
    }, []);

    if (!characteristics?.length) {
        return (null);
    }

    return (
        <div className="table-container">
            <table>
                <AnalysisHeader
                    characteristics={characteristics}
                    options={options}
                />
                <tbody>
                    {!!samples?.length && samples.map((sample, index) => !!sample.isEditing
                        ? (
                            <Fragment key={index}>
                                <AnalysisMinMax characteristics={characteristics} options={options} minOrMax="max" />
                                <tr className={`editing ${sample.controle ?? ''}`}>
                                    <td>
                                        <Tooltip text={(errors?.[index]?.name ?? []).join('. ')}>
                                            <input
                                                className={`input-long ${errors?.[index]?.name?.length ? 'error' : ''}`}
                                                type="text" value={sample?.name ?? ''}
                                                onChange={(e) => handleChange(index, 'name', e.target.value)}
                                            />
                                        </Tooltip>
                                    </td>
                                    <td>
                                        <DatePicker
                                            id="date"
                                            value={sample.date}
                                            max={new Date()}
                                            onChange={(value) => handleChange(index, 'date', value)}
                                            hideIcon
                                        />
                                    </td>
                                    <td>
                                        <select className="input-long" disabled={!operationPermissions.validate} value={sample.status ?? ''} onChange={(e) => handleChange(index, 'status', e.target.value)}>
                                            {RevisionStatusList.map(s => <option key={s.key} value={s.key}>{s.label}</option>)}
                                        </select>
                                    </td>
                                    <td>
                                        <select className="input-long" value={sample.controle ?? ''} onChange={(e) => handleChange(index, 'controle', e.target.value)}>
                                            {controles.map(c => <option key={c.key} value={c.key}>{c.label}</option>)}
                                        </select>
                                    </td>
                                    <td>
                                        <select className="input-long" value={sample.road ?? ''} onChange={(e) => handleChange(index, 'road', e.target.value)}>
                                            {roads.map(c => <option key={c.key} value={c.key}>{c.name}</option>)}
                                        </select>
                                    </td>
                                    <td>
                                        <select className="input-long" value={sample.roadPosition ?? ''} onChange={(e) => handleChange(index, 'roadPosition', e.target.value)}>
                                            {RoadPositionsList.map(c => <option key={c.key} value={c.key}>{c.label}</option>)}
                                        </select>
                                    </td>
                                    <td>
                                        <Tooltip text={(errors?.[index]?.pr ?? []).join('. ')}>
                                            <input
                                                className={`input-long ${errors?.[index]?.['location.pr']?.length ? 'error' : ''}`}
                                                type="text" value={sample?.pr ?? ''}
                                                onChange={(e) => handleChange(index, 'pr', e.target.value)}
                                            />
                                        </Tooltip>
                                    </td>
                                    <td>
                                        <select className="input-long" value={sample.analysis?.business ?? ''} onChange={(e) => handleChange(index, 'analysis.business', e.target.value)}>
                                            <option value=""></option>
                                            {producers.map(p => <option key={p.key} value={p.key}>{p.label}</option>)}
                                        </select>
                                    </td>
                                    <td>
                                        <input type="number" onWheel={(e) => e.currentTarget.blur()} value={sample.thickness ?? ''} onChange={(e) => handleChange(index, 'thickness', e.target.value)} />
                                    </td>
                                    {type === SampleType.CAROTTE && (
                                        <Fragment>
                                            <td>
                                                <select value={sample.collage ?? ''} onChange={(e) => handleChange(index, 'collage', e.target.value)}>
                                                    <option value="yes">Oui</option>
                                                    <option value="no">Non</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="number" onWheel={(e) => e.currentTarget.blur()} value={sample.diameter ?? ''} onChange={(e) => handleChange(index, 'diameter', e.target.value)} />
                                            </td>
                                        </Fragment>
                                    )}
                                    {characteristics.map(c => <AnalysisCell key={c.key} characteristic={c} analysis={sample.analysis ?? {}} onChange={(analysis) => handleChange(index, 'analysis', analysis)} isEditing={true} />)}
                                    <td className="sticky-menu"><div><Button color="primary" icon={<SaveIcon />} onClick={() => handleSave(index)} /></div></td>
                                </tr>
                                <AnalysisMinMax characteristics={characteristics} options={options} minOrMax="min" />
                            </Fragment>
                        ) : (
                            <tr key={index} className={sample.controle ?? ''}>
                                <td>{sample.name}</td>
                                <td>{formatDate(sample.date)}</td>
                                <td>{sample.status ? RevisionStatusLabel[sample.status] : ''}</td>
                                <td>{ControleLabel[sample?.controle]}</td>
                                <td>{roads.find(r => r._id === sample.road)?.name ?? ''}</td>
                                <td>{sample.roadPosition ? RoadPositionLabel[sample.roadPosition] ?? '' : ''}</td>
                                <td>{sample.pr}</td>
                                <td>{producers.find(p => p.key === sample.analysis?.business)?.label ?? ''}</td>
                                <td>{formatNumberToFixedDecimal(sample.thickness)}</td>
                                {type === SampleType.CAROTTE && (
                                    <Fragment>
                                        <td>{sample.collage !== 'no' ? 'Oui' : 'Non'}</td>
                                        <td>{sample.diameter ? formatNumberToFixedDecimal(sample.diameter, 0) + 'mm' : ''}</td>
                                    </Fragment>
                                )}
                                {characteristics.map(c => <AnalysisCell key={c.key} characteristic={c} analysis={sample.analysis ?? {}} onChange={(analysis) => handleChange(index, 'analysis', analysis)} isEditing={false} />)}
                                <td className="sticky-menu">
                                    <div>
                                        {operationPermissions.administrate && <Button color="primary" icon={<TrashIcon />} onClick={() => sample._id ? handleDelete(sample._id) : null} />}
                                        <Button color="primary" icon={<CopyIcon />} onClick={() => handleCopy(index)} />
                                        <Button color="primary" icon={<EditIcon />} onClick={() => handleChange(index, 'isEditing', true)} />
                                    </div>
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default SampleMasseAnalysis;