import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OpenIcon, SendIcon } from "../../assets/icons";
import CommentModal from "../../components/CommentModal";
import Button from "../../components/ui/Button";
import Card from "../../components/ui/Card";
import Header from "../../components/ui/Header";
import Menu from "../../components/ui/Menu";
import ScrollableContent from "../../components/ui/ScrollableContent";
import { useAuthContext } from "../../context/AuthProvider";
import { useOperationContext } from "../../context/OperationProvider";
import useRequest from "../../hooks/useRequest";
import { Phase, Phases } from "../../models/operation";
import { SampleType, SampleTypeLabel } from "../../models/sample";
import { RevisionStatusList } from "../../models/shared";
import './index.scss';

const INFO_WRITING = "Le tableau de bord résume l'état des données rédigées par l'utilisateur en cours pour chaque étape de l'opération.\n\nEn fin de saisie, utiliser le bouton de notification pour faire avancer les données dans le processus de validation et notifier les validateurs des nouvelles données à valider.\n\nAttention : seules les données créées ou modifiées par l'utilisateur en cours sont comptabilisées, et seules celles-ci seront prises en compte lors de la notification";

const INFO_VALIDATION = "Le tableau de bord résume l'état des données de l'opération. Utiliser les liens associés à chaque ligne pour voir le détail";

type OperationDashboardCount = { [status: string]: number };

interface OperationPhaseDashboard {
    lot?: OperationDashboardCount;
    prelevement?: OperationDashboardCount;
    carotte?: OperationDashboardCount;
    population?: OperationDashboardCount;
    sondage?: OperationDashboardCount;
    melange?: OperationDashboardCount;
    marker?: OperationDashboardCount;
    component?: OperationDashboardCount;
}

interface OperationDashboard {
    etude?: OperationPhaseDashboard;
    preparatoire?: OperationPhaseDashboard;
    travaux?: OperationPhaseDashboard;
    expertise?: OperationPhaseDashboard;
}

interface DashboardProps {

}

const Dashboard = ({ }: DashboardProps) => {
    const { operation, operationPermissions } = useOperationContext();
    const [isNotifyModalVisible, setNotifyModalVisible] = useState<boolean>(false);
    const [dashboard, setDashboard] = useState<OperationDashboard | null>(null);
    const request = useRequest();
    const navigate = useNavigate();

    const getDashboard = useCallback(() => {
        request.get<any>(`/operation/${operation._id}/dashboard`, { loader: true, errorMessage: 'Une erreur est survenue pendant la récupération des informations' })
            .then(setDashboard)
            .catch(() => null);
    }, [operation]);

    const handleNotify = useCallback((comment: string | undefined) => {
        request.post(`/operation/${operation._id}/notify-new-data`, { comment }, { loader: true, errorMessage: 'Une erreur est survenue pendant l\'envoi de la notification', successMessage: 'Notification envoyée' })
            .then(() => {
                getDashboard();
                setNotifyModalVisible(false);
            })
            .catch(() => null);
    }, [getDashboard]);

    useEffect(() => {
        getDashboard();
    }, [operation]);

    return (
        <Fragment>
            <Header breadcrumbs={[
                { label: operation.name, href: `/operation/${operation._id}` },
                { label: 'Tableau de bord' }
            ]}>
                {operationPermissions.write && !operationPermissions.validate && (
                    <Button
                        color="primary"
                        label="Notifier la saisie"
                        icon={<SendIcon />}
                        onClick={() => setNotifyModalVisible(true)}
                    />
                )}
            </Header>
            <ScrollableContent
                side={<Card type="info">{operationPermissions.validate ? INFO_VALIDATION : INFO_WRITING}</Card>}
            >
                {Phases.filter(p => operation.phases.includes(p.key)).map(p => (
                    <Card key={p.key} title={p.label} actions={<Menu icon={<OpenIcon />} label="Accéder à la saisie" onClick={() => navigate(`/operation/${operation._id}/${p.key}`)} />}>
                        <table className="simple-table dashboard-table">
                            <thead>
                                <tr>
                                    <th />
                                    {RevisionStatusList.map(r => (<th key={r.key}>{r.label}</th>))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{SampleTypeLabel[SampleType.CAROTTE]}</td>
                                    {RevisionStatusList.map(r => (
                                        <th key={r.key} style={{ color: r.color }}>
                                            {dashboard?.[p.key]?.carotte?.[r.key]
                                                ? <span>{dashboard?.[p.key]?.carotte?.[r.key]}<Link to={`/operation/${operation._id}/${p.key}/carotte?status=${r.key}`}><OpenIcon /></Link></span>
                                                : '--'
                                            }
                                        </th>
                                    ))}
                                </tr>
                                {p.key !== Phase.TRAVAUX ? (
                                    <tr>
                                        <td>{SampleTypeLabel[SampleType.SONDAGE]}</td>
                                        {RevisionStatusList.map(r => (
                                            <th key={r.key} style={{ color: r.color }}>
                                                {dashboard?.[p.key]?.sondage?.[r.key]
                                                    ? <span>{dashboard?.[p.key]?.sondage?.[r.key]}<Link to={`/operation/${operation._id}/${p.key}/sondage?status=${r.key}`}><OpenIcon /></Link></span>
                                                    : '--'
                                                }
                                            </th>
                                        ))}
                                    </tr>
                                ) : (
                                    <Fragment>
                                        <tr>
                                            <td>{SampleTypeLabel[SampleType.PRELEVEMENT]}</td>
                                            {RevisionStatusList.map(r => (
                                                <th key={r.key} style={{ color: r.color }}>
                                                    {dashboard?.[p.key]?.prelevement?.[r.key]
                                                        ? <span>{dashboard?.[p.key]?.prelevement?.[r.key]}<Link to={`/operation/${operation._id}/${p.key}/prelevement?status=${r.key}`}><OpenIcon /></Link></span>
                                                        : '--'
                                                    }
                                                </th>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>Populations</td>
                                            {RevisionStatusList.map(r => (
                                                <th key={r.key} style={{ color: r.color }}>
                                                    {dashboard?.[p.key]?.population?.[r.key]
                                                        ? <span>{dashboard?.[p.key]?.population?.[r.key]}<Link to={`/operation/${operation._id}/${p.key}/population?status=${r.key}`}><OpenIcon /></Link></span>
                                                        : '--'
                                                    }
                                                </th>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>Lots</td>
                                            {RevisionStatusList.map(r => (
                                                <th key={r.key} style={{ color: r.color }}>
                                                    {dashboard?.[p.key]?.lot?.[r.key]
                                                        ? <span>{dashboard?.[p.key]?.lot?.[r.key]}<Link to={`/operation/${operation._id}/${p.key}/lot?status=${r.key}`}><OpenIcon /></Link></span>
                                                        : '--'
                                                    }
                                                </th>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>Suivi de constituants</td>
                                            {RevisionStatusList.map(r => (
                                                <th key={r.key} style={{ color: r.color }}>
                                                    {dashboard?.[p.key]?.component?.[r.key]
                                                        ? <span>{dashboard?.[p.key]?.component?.[r.key]}<Link to={`/operation/${operation._id}/${p.key}/suivi-de-constituants?status=${r.key}`}><OpenIcon /></Link></span>
                                                        : '--'
                                                    }
                                                </th>
                                            ))}
                                        </tr>
                                    </Fragment>
                                )}
                                <tr>
                                    <td>Points d'intérêt</td>
                                    {RevisionStatusList.map(r => (
                                        <th key={r.key} style={{ color: r.color }}>
                                            {dashboard?.[p.key]?.marker?.[r.key]
                                                ? <span>{dashboard?.[p.key]?.marker?.[r.key]}<Link to={`/operation/${operation._id}/${p.key}/point-d-interet?status=${r.key}`}><OpenIcon /></Link></span>
                                                : '--'
                                            }
                                        </th>))}
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                ))}
            </ScrollableContent>
            {isNotifyModalVisible && (
                <CommentModal
                    title="Commentaire"
                    placeholder="Détailler le contenu de la saisie, les points à vérifier, etc..."
                    allowEmpty
                    onClose={() => setNotifyModalVisible(false)}
                    onSubmit={handleNotify}
                />

            )}
        </Fragment>
    )
}

export default Dashboard;