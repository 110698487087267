import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditIcon } from '../../assets/icons';
import { useOperationContext } from '../../context/OperationProvider';
import useRequest from '../../hooks/useRequest';
import { Melange } from '../../models/melange';
import { RoadPositionLabel } from '../../models/operation';
import { Controles } from '../../models/sample';
import { formatNumberToFixedDecimal } from '../../utils/format';
import { floatToPrString } from '../../utils/pr';
import SampleAnalysisColumn from '../Analysis/SampleAnalysisColumn';
import SampleIcon from '../SampleDetail/SampleIcon';
import ValidationCard from '../entities/ValidationCard';
import Card from '../ui/Card';
import Menu from '../ui/Menu';
import { ModalRight } from '../ui/Modal/ModalRight';
import Tag from '../ui/Tag';
import './index.scss';

interface MelangeDetailProps {
    id?: string;
    melange?: Melange;
    onClose: (refresh?: boolean) => void;
}

const MelangeDetail = ({
    id,
    melange: melangeFromProps,
    onClose
}: MelangeDetailProps) => {
    const { operation, isRoadOperation, operationPermissions } = useOperationContext();
    const request = useRequest();
    const [melange, setMelange] = useState<Melange | null>(melangeFromProps ?? null);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            request.get<Melange>(`/melange/${id}`, { errorMessage: 'Une erreur est survenue lors de la récupération du mélange' })
                .then(setMelange)
                .catch(() => onClose());
        } else if (melangeFromProps) {
            setMelange(melangeFromProps);
        } else {
            setMelange(null);
            onClose();
        }
    }, [id, melangeFromProps]);

    if (!melange) {
        return (null);
    }

    return (
        <ModalRight
            visible={!!melange}
            actions={[{ label: 'Fermer', color: 'secondary', onClick: onClose }]}
            title={melange.name}
            icon={operationPermissions.write ? <Menu label="Editer" icon={<EditIcon />} onClick={() => navigate(`/operation/${operation?._id}/etude/melange/${melange._id}/editer`)} /> : undefined}
        >
            <div id="melange-detail">
                <div id="melange-detail-header">
                    <Tag value={melange.controle} items={Controles} />
                </div>
                {!!melange.comment && <span><strong>Commentaire : </strong>{melange.comment}</span>}
                <ValidationCard
                    entity={melange}
                    canValidate={operationPermissions.validate}
                    endpoint="/melange"
                    onSubmit={() => onClose(true)}
                />
                {melange?.analysisPopulated?.data && (
                    <Card title="Analyse">
                        <SampleAnalysisColumn analysis={melange.analysisPopulated} />
                    </Card>
                )}
                <Card title="Carottes utilisées" className="melange-detail-samples">
                    {melange.samplesPopulated?.map(sample => (
                        <div key={sample._id}>
                            <SampleIcon sample={sample} selectedLayers={sample.layers.filter(l => l.melange === melange._id).map(l => l.order)} />
                            <div className="melange-sample">
                                <div className="melange-sample-title">
                                    <span>{sample.name}</span>
                                    <Tag value={sample.controle} items={Controles} />
                                </div>
                                {isRoadOperation
                                    ? (
                                        <div className="melange-sample-position">
                                            <span>Sens {sample.location.way},</span>
                                            <span>{sample.location?.road ? operation.roadsObj?.[sample.location?.road]?.name : ''},</span>
                                            <span>{floatToPrString(sample.location?.pr) ?? ''},</span>
                                            <span>{sample.location?.roadPosition ? RoadPositionLabel[sample.location?.roadPosition] : ''}</span>
                                        </div>
                                    ) : (
                                        <div className="melange-sample-position">
                                            <span>{sample.location.location}</span>
                                        </div>
                                    )
                                }
                                <div className="melange-sample-selected-layers">
                                    <h5>Couche(s) utilisée(s) :</h5>
                                    {sample.layers.filter(l => l.melange === melange._id).map(layer => (
                                        <span key={layer.order}>
                                            {layer.order + 1} - {layer.materialPopulated ? layer.materialPopulated.name : layer.supposedMaterial ?? '(Couche sans nom)'}
                                            {!!layer.thickness ? ` (${formatNumberToFixedDecimal(layer.thickness)} cm)` : ''}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>
        </ModalRight>
    );
}

export default MelangeDetail;