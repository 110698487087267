import Card from '../../../components/ui/Card';
import Select from '../../../components/inputs/Select';
import TextInput from '../../../components/inputs/TextInput';
import { ClasseConsistances, ClasseEtalements } from '../../../models/material';
import { MaterialSpecificationsFormProps } from '../EditComponent';
import NumberInput from '../../../components/inputs/NumberInput';

const BetonEdit = ({
    attachInput
}: MaterialSpecificationsFormProps) => (
    <Card title="Classification et spécifications">
        <div className="row">
            <div className="input-column">
                <label htmlFor="specifications.classeBeton">Classe *</label>
                <TextInput {...attachInput('specifications.classeBeton')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.classeEtalement">Classe d'étalement - Abrams *</label>
                <Select
                    items={ClasseEtalements}
                    {...attachInput('specifications.classeEtalement')}
                />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.classeConsistance">Classe de consistance (slump) *</label>
                <Select
                    items={ClasseConsistances}
                    {...attachInput('specifications.classeConsistance')}
                />
            </div>
        </div>
        <div className="row">
            <div className="input-column">
                <label htmlFor="specifications.resistanceCompression">Résistance en compression *</label>
                <TextInput {...attachInput('specifications.resistanceCompression')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.ciment">Ciment</label>
                <TextInput {...attachInput('specifications.ciment')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.chlorureMax">Chlorure max *</label>
                <NumberInput {...attachInput('specifications.chlorureMax')} />
            </div>
            <div className="input-column">
                <label htmlFor="specifications.dMax">dMax *</label>
                <NumberInput {...attachInput('specifications.dMax')} />
            </div>
        </div>
    </Card>
)

export default BetonEdit;