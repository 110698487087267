import { FormEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Password from '../../components/inputs/Password';
import TextInput from '../../components/inputs/TextInput';
import Button from '../../components/ui/Button';
import { useAuthContext } from '../../context/AuthProvider';
import useForm, { FormComparator } from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { User, UserLogin } from '../../models/user';

const VALIDATION = {
    email: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.EMAIL }],
    password: [{ comparator: FormComparator.REQUIRED }],
};

const Login = () => {
    const { login } = useAuthContext();
    const request = useRequest();
    const { attachInput, validate } = useForm<UserLogin>({});

    const handleSubmit = useCallback(async (e?: FormEvent) => {
        e?.preventDefault();
        const entity = validate(VALIDATION);
        if (!entity) return;

        request.post<{ token: string }>('/user/login', entity, { errorMessage: 'Les identifiants sont incorrects', loader: true })
            .then((data) => login(data.token))
            .catch(() => null);
    }, [validate, login]);

    return (
        <div id="recuperation">
            <h4>Se connecter</h4>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <TextInput {...attachInput('email')} autoComplete />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Mot de passe</label>
                    <Password {...attachInput('password')} />
                </div>
                <Link to="/recuperation"> Mot de passe oublié ?</Link>
                <Button
                    label="Se connecter"
                    type="submit"
                />
            </form>
        </div>
    );
}

export default Login;
