import { Polyline } from '@react-google-maps/api';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-circle.svg';
import Breadcrumbs from '../../components/ui/Breadcrumb';
import { IdentityBlockMaterial } from '../../components/entities/IdentityBlock';
import { IdentityCardOperation } from '../../components/entities/IdentityCard';
import Map from '../../components/data/Map/Map';
import { ModalBottom } from '../../components/ui/Modal/ModalBottom';
import ModalMaterial from '../../components/ModalMaterial';
import ModalStandard from '../../components/ModalStandard';
import useRequest from '../../hooks/useRequest';
import { Lot } from '../../models/lot';
import { Material } from '../../models/material';
import { Operation, OperationNetwork, Phase, PhaseMapColor } from '../../models/operation';
import AxiosInstance from '../../services/AxiosInstance';
import { Direction } from '../../synoptique/Synoptique.class';
import { getPrFromSegment, isPointOnSegment } from '../../utils/maths';
import { floatToPrString } from '../../utils/pr';
import LotCanvas from '../Operation/Synoptique/components/LotCanvas';
import './index.scss';
import Header from '../../components/ui/Header';
import IconLink from '../../components/ui/IconLink';

interface MapFilters {
    standard?: Material;
    phases?: Phase[];
}

const CENTER = {
    latitude: 46.7121344755452,
    longitude: 2.3162206006157593,
    zoom: 6,
};

const OPTIONS = {
    strokeWeight: 3,
    strokeColor: '#FF0000',
    fillColor: '#FF0000',
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
    icons: {
        path: 2.0,
        fillOpacity: 1,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: 'white',
        strokeColor: 'orange',
        scale: 5
    }
};

const getPhaseColor = (phases: Phase[]) => {
    if (phases.includes(Phase.EXPERTISE)) {
        return PhaseMapColor[Phase.EXPERTISE];
    }
    if (phases.includes(Phase.TRAVAUX)) {
        return PhaseMapColor[Phase.TRAVAUX];
    }
    if (phases.includes(Phase.PREPARATOIRE)) {
        return PhaseMapColor[Phase.PREPARATOIRE];
    }
    if (phases.includes(Phase.ETUDE)) {
        return PhaseMapColor[Phase.ETUDE];
    }
}

const FILTERS_LOCALSTORAGE_KEY = 'filters-network-local-storage';

const getFiltersInLocalStorage = () => JSON.parse(localStorage.getItem(FILTERS_LOCALSTORAGE_KEY) || "{}");

const setFiltersInLocalStorage = (filters: MapFilters) => {
    localStorage.setItem(FILTERS_LOCALSTORAGE_KEY, JSON.stringify(filters));
}

const Network = () => {
    const request = useRequest();
    const [operationNetworks, setOperationNetworks] = useState<OperationNetwork[]>([]);
    const [modalStandardVisible, setModalStandardVisible] = useState(false);
    const [filters, setFilters] = useState<MapFilters | null>(null);
    const [layers, setLayers] = useState<{ way1: Lot[]; way2: Lot[] } | null>(null);
    const [materials, setMaterials] = useState<Material[] | null>(null);
    const [hoverOperation, setHoverOperation] = useState<Operation | null>(null);
    const [selectedPr, setSelectedPr] = useState<string | null>(null);
    const [selectedOperation, setSelectedOperation] = useState<Operation | null>(null);
    const [selectedMaterial, setSelectedMaterial] = useState<Material | null>(null);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const navigate = useNavigate();

    const reset = () => {
        setSelectedPr(null);
        setLayers(null);
        setMaterials(null);
        setSelectedOperation(null);
    }

    const getMaterialsFromLayers = (layers: { way1: Lot[]; way2: Lot[] }) => {
        const materials: Material[] = [];
        for (const layer of [...(layers?.way1 ?? []), ...(layers?.way2 ?? [])]) {
            if (!layer.materialPopulated?._id) {
                continue;
            }

            if (!materials.find(m => m._id === layer.materialPopulated?._id)) {
                materials.push(layer.materialPopulated);
            }
        }

        return materials;
    }

    const getGpsData = async (operation: Operation, longitude: number | null, latitude: number | null) => {
        for (let i = 1; i < operation.line.length; i++) {
            if (!map || !longitude || !latitude) continue;
            const isOnSegment = isPointOnSegment(
                map,
                { lng: operation.line[i - 1].longitude, lat: operation.line[i - 1].latitude },
                { lng: operation.line[i].longitude, lat: operation.line[i].latitude },
                { lng: longitude, lat: latitude },
            );

            if (isOnSegment && longitude && latitude) {
                const pr = getPrFromSegment({ longitude, latitude }, operation.line[i - 1], operation.line[i]);

                try {
                    const { data } = await AxiosInstance.get<{ way1: Lot[]; way2: Lot[] }>(`/operation/${operation._id}/get-pr-data`, { params: { pr } });
                    setLayers(data);
                    setMaterials(getMaterialsFromLayers(data));
                    setSelectedPr(floatToPrString(pr));
                    setSelectedOperation(operation);
                } catch (e) {
                    reset();
                } finally {
                    break;
                }
            }
        }
    }

    const onFilterChange = (key: string, data: any) => {
        const _filters = { ...(filters ?? {}), [key]: data };
        setFilters(_filters);
        setFiltersInLocalStorage(_filters);
    }

    useEffect(() => {
        const params = {
            material: filters?.standard?._id,
            type: filters?.standard?.type,
            phases: filters?.phases,
        }
        request.get<OperationNetwork[]>('/operation/network', { params, errorMessage: 'Une erreur est survenue lors de la récupération des données.', loader: true })
            .then(setOperationNetworks)
            .catch(() => setOperationNetworks([]))

    }, [filters]);

    useEffect(() => {
        setFilters(getFiltersInLocalStorage());
    }, []);

    return (
        <Fragment>
            <Header breadcrumbs={[{ label: "Cartographie du réseau" }]} />
            <IdentityBlockMaterial
                material={filters?.standard}
                onClick={() => setModalStandardVisible(true)}
                onClose={() => onFilterChange('standard', undefined)}
            />
            <div className="container" id="network-map">
                <Map
                    center={CENTER}
                    availableActions={[]}
                    onMapLoaded={setMap}
                    onPhaseChange={(phases) => onFilterChange('phases', phases)}
                    phases={filters?.phases}
                >
                    {operationNetworks.map(o => (
                        <Fragment>
                            {o.lines.map(lines => (
                                <Polyline
                                    key={o.operation._id}
                                    path={lines.map(coordinates => ({
                                        lat: coordinates.latitude,
                                        lng: coordinates.longitude,
                                    }))}
                                    onClick={(data) => getGpsData(o.operation, data.latLng?.lng() ?? null, data.latLng?.lat() ?? null)}
                                    onMouseOver={() => setHoverOperation(o.operation)}
                                    onMouseOut={() => setHoverOperation(null)}
                                    options={{
                                        ...(OPTIONS as any),
                                        strokeColor: getPhaseColor(o.operation.phases)
                                    }}
                                />
                            ))}
                        </Fragment>
                    ))}
                </Map>
                {hoverOperation && (
                    <div id="hover-elements-modal" className="md-hide">
                        <IdentityCardOperation entity={hoverOperation} />
                    </div>
                )}
                <ModalBottom
                    visible={!!selectedOperation}
                    onClose={reset}
                    title={selectedOperation?.name ?? ''}
                >
                    <div id='lot-view-container'>
                        <IconLink type="view" label="Se rendre à l'opération" onClick={() => navigate(`/operation/${selectedOperation?._id}/synoptique`)} />
                        {!!materials?.length && (
                            <Fragment>
                                <h4>Matériaux utilisées</h4>
                                <table className="simple-table">
                                    <thead>
                                        <tr>
                                            <th>Enrobé</th>
                                            <th>Liants</th>
                                            <th>Granulats</th>
                                            <th>Additifs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {materials?.map(material => (
                                            <tr key={material._id}>
                                                <td><span onClick={() => setSelectedMaterial(material)}>{material.name}</span></td>
                                                <td>{material.composition?.liantsPopulated?.map(liant => <div onClick={() => setSelectedMaterial(liant)}>{liant.name}</div>)}</td>
                                                <td>{material.composition?.granulatsPopulated?.map(granulat => <div onClick={() => setSelectedMaterial(granulat)}>{granulat.name}</div>)}</td>
                                                <td>{material.composition?.additifsPopulated?.map(additif => <div onClick={() => setSelectedMaterial(additif)}>{additif.name}</div>)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Fragment>
                        )}
                        {(!!layers?.way1?.length || !!layers?.way2?.length) && <h4>Structure au point sélectionné : {selectedPr ?? ''}</h4>}
                        {!!layers?.way1?.length && (
                            <Fragment>
                                <div className="lot-view-way">Sens 1 : {selectedOperation?.way1?.name}</div>
                                <LotCanvas layers={layers.way1} currentDirection={Direction.BOTTOM} onSelectLayer={(lot) => navigate(`/operation/${selectedOperation?._id}/travaux/lot/${lot._id}/detail`)} />
                            </Fragment>
                        )}
                        {!!layers?.way2?.length && (
                            <Fragment>
                                <div className="lot-view-way">Sens 2 : {selectedOperation?.way2?.name}</div>
                                <LotCanvas layers={layers.way2} currentDirection={Direction.BOTTOM} onSelectLayer={(lot) => navigate(`/operation/${selectedOperation?._id}/travaux/lot/${lot._id}/detail`)} />
                            </Fragment>
                        )}
                    </div>
                </ModalBottom>
                {modalStandardVisible && <ModalStandard onSubmit={(standard) => onFilterChange('standard', standard)} onClose={() => setModalStandardVisible(false)} />}
                {selectedMaterial && <ModalMaterial material={selectedMaterial} onClose={() => setSelectedMaterial(null)} />}
            </div>
        </Fragment>
    )
}

export default Network;