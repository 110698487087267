import { Fragment, useCallback, useMemo, useState } from 'react';
import { AddBlackIcon, CloseIcon } from '../../../assets/icons';
import UserSearchModal from '../../../components/entities/UserSearchModal';
import NativeSelect from '../../../components/inputs/NativeSelect';
import Card from '../../../components/ui/Card';
import Menu from '../../../components/ui/Menu';
import { useGlobalContext } from '../../../context/GlobalProvider';
import { Operation, OperationRole, OperationRoles, OperationRolesSubcontractors, OperationUser } from '../../../models/operation';
import { Role, User } from '../../../models/user';
import { getFullName } from '../../../utils/format';


interface UsersPanelProps {
    operation: Partial<Operation>;
    users?: OperationUser[];
    onChange: (u: OperationUser[]) => void;
}

const UsersPanel = ({
    operation,
    onChange
}: UsersPanelProps) => {
    const { setToastError } = useGlobalContext();
    const [isUserModalVisible, setUserModalVisible] = useState<string | null>(null);

    const users = useMemo(() => operation.users?.filter(u => [OperationRole.ADMIN, OperationRole.READ, OperationRole.VALIDATE, OperationRole.WRITE].includes(u.role))
        ?? []
        , [operation]);

    const subcontractors = useMemo(() => operation.users?.filter(u => ![OperationRole.ADMIN, OperationRole.READ, OperationRole.VALIDATE, OperationRole.WRITE].includes(u.role))
        ?? []
        , [operation]);

    const handleDelete = useCallback((user: string) => {
        const _users = [...(operation.users ?? [])];
        const index = _users.findIndex(ur => ur.user === user);

        if (index === -1) return;

        _users.splice(index, 1);

        onChange(_users);
    }, [operation, onChange]);

    const handleChange = useCallback((user: string, role: OperationRole) => {
        const _users = [...(operation.users ?? [])];
        const index = _users.findIndex(ur => ur.user === user);

        if (index === -1) return;

        _users[index].role = role;

        onChange(_users);
    }, [operation, onChange]);

    const handleAdd = useCallback((usersToAdd: User[]) => {
        const _users = [...(operation.users ?? [])];

        for (const user of usersToAdd) {
            if (_users.some(u => u.user === user._id)) continue;

            if (user.role !== Role.SUBCONTRACTOR && operation.licensePopulated && users.length >= operation.licensePopulated.usersPerOperationQuota) {
                setToastError('La limite du nombre d\'utilisateurs par opération a été atteint');
                break;
            }

            _users.push({ user: user._id, userPopulated: user, role: user.role === Role.SUBCONTRACTOR ? OperationRole.EXTERIEUR : OperationRole.READ });
        }

        setUserModalVisible(null);
        onChange(_users);
    }, [operation, onChange, users]);

    return (
        <Fragment>
            <Card
                title="Utilisateurs"
                actions={<Menu icon={<AddBlackIcon />} label="Ajouter" onClick={() => setUserModalVisible('user')} />}
            >
                <div id="operation-users-list">
                    {users.map(u => (
                        <div key={u.user}>
                            <div>
                                <span>{getFullName(u.userPopulated)}</span>
                                {u.userPopulated?.businessPopulated && <span>{u.userPopulated?.businessPopulated.name}</span>}
                            </div>
                            <NativeSelect id={u.user} value={u.role} items={OperationRoles} onChange={(role) => role ? handleChange(u.user, role) : null} />
                            <CloseIcon onClick={() => handleDelete(u.user)} />
                        </div>
                    ))}
                </div>
            </Card>
            <Card
                title="Prestataires"
                actions={<Menu icon={<AddBlackIcon />} label="Ajouter" onClick={() => setUserModalVisible('subcontractor')} />}
            >
                <div id="operation-users-list">
                    {subcontractors.map(u => (
                        <div key={u.user}>
                            <div>
                                <span>{getFullName(u.userPopulated)}</span>
                                {u.userPopulated?.businessPopulated && <span>{u.userPopulated?.businessPopulated.name}</span>}
                            </div>
                            <NativeSelect id={u.user} value={u.role} items={OperationRolesSubcontractors} onChange={(role) => role ? handleChange(u.user, role) : null} />
                            <CloseIcon onClick={() => handleDelete(u.user)} />
                        </div>
                    ))}
                </div>
            </Card>
            {!!isUserModalVisible && <UserSearchModal mode={isUserModalVisible === 'user' ? 'user' : 'subcontractor'} onClose={() => setUserModalVisible(null)} onSubmit={handleAdd} />}
        </Fragment>
    )
}

export default UsersPanel;