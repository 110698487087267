import { useCallback, useEffect, useState } from 'react';
import { SynoptiqueAdvancedFilters, SynoptiqueFilters } from '..';
import { CylinderIcon, LayerIcon, MelangeIcon, TimelineIcon, TubeIcon, ZoomIcon, ZoomMinusIcon, ZoomPlusIcon } from '../../../../assets/icons';
import { ReactComponent as PrintIcon } from '../../../../assets/icons/print2.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as SelectIcon } from '../../../../assets/icons/select.svg';
import Checkbox from '../../../../components/inputs/Checkbox';
import Button from '../../../../components/ui/Button';
import Menu, { MenuBar, MenuBarGrow, MenuItem } from '../../../../components/ui/Menu';
import { useOperationContext } from '../../../../context/OperationProvider';
import { UnknownMaterialCharacteristics } from '../../../../models/material';
import { Phase } from '../../../../models/operation';
import { PopulationTypes } from '../../../../models/population';
import { SynoptiqueAction } from '../../../../synoptique/Synoptique.class';
import { changeFieldValue, toggleInArray } from '../../../../utils/objects';
import { prToFloat } from '../../../../utils/pr';

interface MenuProps {
    currentAction?: SynoptiqueAction;
    onActionChange: (a: SynoptiqueAction) => void;
    onGoToPr: (pr: number) => void;
    filters: SynoptiqueFilters;
    onChange: (f: SynoptiqueFilters) => void;
    advancedFilters: SynoptiqueAdvancedFilters;
    onAdvancedFiltersSubmit: (f: SynoptiqueAdvancedFilters) => void;
}

const SynoptiqueMenu = ({
    currentAction,
    onActionChange,
    onChange,
    onGoToPr,
    filters,
    advancedFilters: advancedFiltersFromProps,
    onAdvancedFiltersSubmit
}: MenuProps) => {
    const { availablePhases, isRoadOperation } = useOperationContext();
    const [pr, setPr] = useState('');
    const [advancedFilters, setAdvancedFilters] = useState<SynoptiqueAdvancedFilters>(advancedFiltersFromProps);

    const handleAdvancedFiltersChange = (id: string, value: any) => setAdvancedFilters(changeFieldValue(advancedFilters, id, value));

    const handleGoToPr = (event: any) => {
        if (event?.preventDefault) {
            event.preventDefault();
        }
        onGoToPr(prToFloat(pr));
    }

    const handleChange = useCallback((phase: Phase) => {
        onChange({ ...filters, phase: toggleInArray(phase, filters.phase ?? []) });
    }, [filters]);

    useEffect(() => {
        setAdvancedFilters(advancedFiltersFromProps);
    }, [advancedFiltersFromProps]);

    return (
        <MenuBar>
            <MenuBarGrow>
                {isRoadOperation && (
                    <Menu label="Aller au PR" icon={<SearchIcon />}>
                        <form id="goto-input" onSubmit={handleGoToPr}>
                            <input type="number" placeholder="PR" onWheel={(e) => e.currentTarget.blur()} onChange={(e) => setPr(e.target.value)} />
                            <SearchIcon onClick={handleGoToPr} onTouchEnd={handleGoToPr} />
                        </form>
                    </Menu>
                )}
                <Menu label="Phases" icon={<TimelineIcon />}>
                    {(availablePhases ?? []).map(p => (
                        <MenuItem key={p.key}>
                            <Checkbox
                                id="phases"
                                value={!!filters.phase?.includes(p.key)}
                                label={p.label}
                                onChange={() => handleChange(p.key)}
                            />
                        </MenuItem>
                    ))}
                </Menu>
                <Menu className="sm-hide" label="Filtres avancés" icon={<TubeIcon />}>
                    <MenuItem className="advanced-filter">
                        <label>Echantillons</label>
                        <select value={advancedFilters?.sampleValue?.key ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.key', e.target.value)}>
                            <option value=""></option>
                            {UnknownMaterialCharacteristics.map(c => <option key={c.key} value={c.key}>{c.label}</option>)}
                        </select>
                        <div>
                            <span>entre</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleValue?.min ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.min', e.target.value)} />
                            <span>et</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleValue?.max ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.max', e.target.value)} />
                            <span>sur la couche</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleValue?.layer ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleValue.layer', e.target.value)} />
                        </div>
                    </MenuItem>
                    <MenuItem className="advanced-filter">
                        <label>Epaisseur</label>
                        <div>
                            <span>entre</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleThickness?.min ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleThickness.min', e.target.value)} />
                            <span>et</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleThickness?.max ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleThickness.max', e.target.value)} />
                            <span>sur la couche</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.sampleThickness?.layer ?? ''} onChange={(e) => handleAdvancedFiltersChange('sampleThickness.layer', e.target.value)} />
                        </div>
                    </MenuItem>
                    <MenuItem className="advanced-filter">
                        <label>Populations</label>
                        <select value={advancedFilters?.populationValue?.key ?? ''} onChange={(e) => handleAdvancedFiltersChange('populationValue.key', e.target.value)}>
                            <option value=""></option>
                            {PopulationTypes.map(p => <option key={p.key} value={p.key}>{p.label}</option>)}
                        </select>
                        <div>
                            <span>entre</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.populationValue?.min ?? ''} onChange={(e) => handleAdvancedFiltersChange('populationValue.min', e.target.value)} />
                            <span>et</span>
                            <input type="number" onWheel={(e) => e.currentTarget.blur()} value={advancedFilters?.populationValue?.max ?? ''} onChange={(e) => handleAdvancedFiltersChange('populationValue.max', e.target.value)} />
                        </div>
                    </MenuItem>
                    <MenuItem>
                        <div className="advanced-filter-actions">
                            <Button color="secondary" label="Effacer" onClick={() => onAdvancedFiltersSubmit({})} />
                            <Button color="primary" label="Valider" onClick={() => onAdvancedFiltersSubmit(advancedFilters)} />
                        </div>
                    </MenuItem>
                </Menu>
            </MenuBarGrow>
            <Menu
                label="Mode de sélection"
                childrenPosition="right"
                icon={
                    currentAction === SynoptiqueAction.SELECT_LAYER
                        ? <LayerIcon className="stroke" /> :
                        currentAction === SynoptiqueAction.SELECT_ONE
                            ? <CylinderIcon className="stroke" /> :
                            currentAction === SynoptiqueAction.SELECT_ONE_BY_ONE
                                ? <CylinderIcon className="stroke" /> :
                                currentAction === SynoptiqueAction.SELECT_MULTIPLE
                                    ? <SelectIcon /> :
                                    currentAction === SynoptiqueAction.SELECT_MELANGE
                                        ? <MelangeIcon className="stroke" /> : undefined
                }
            >
                <MenuItem label="Couche" icon={<LayerIcon />} onClick={() => onActionChange(SynoptiqueAction.SELECT_LAYER)} />
                <MenuItem label="Echantillon" icon={<CylinderIcon />} onClick={() => onActionChange(SynoptiqueAction.SELECT_ONE)} />
                <MenuItem label="Echantillons un par un" icon={<CylinderIcon />} onClick={() => onActionChange(SynoptiqueAction.SELECT_ONE_BY_ONE)} />
                <MenuItem label="Plusieurs échantillons" icon={<SelectIcon />} onClick={() => onActionChange(SynoptiqueAction.SELECT_MULTIPLE)} />
                <MenuItem label="Mélange" icon={<MelangeIcon />} onClick={() => onActionChange(SynoptiqueAction.SELECT_MELANGE)} />
            </Menu>
            <Menu childrenPosition="right" label="Zoom" icon={<ZoomIcon className="stroke" />}>
                <MenuItem label="Zoom avant" icon={<ZoomPlusIcon />} onClick={() => onActionChange(SynoptiqueAction.ZOOM_IN)} />
                <MenuItem label="Zoom arrière" icon={<ZoomMinusIcon />} onClick={() => onActionChange(SynoptiqueAction.ZOOM_OUT)} />
            </Menu>
            <Menu className="sm-hide" label="Imprimer" icon={<PrintIcon />} onClick={() => onActionChange(SynoptiqueAction.PRINT)} />
        </MenuBar >
    );
}

export default SynoptiqueMenu;