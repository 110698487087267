import { Fragment, useEffect, useMemo, useState } from 'react';
import Charac from '../../components/Characteristics';
import { IdentityBlockMaterial } from '../../components/entities/IdentityBlock';
import CommentPopup from '../../components/inputs/CommentPopup';
import MultipleSelect from '../../components/inputs/MultipleSelect';
import Select from '../../components/inputs/Select';
import TextInput from '../../components/inputs/TextInput';
import Toggle from '../../components/inputs/Toggle';
import { EntityFormRules, FormHookReturn } from '../../hooks/useForm';
import { MATERIAL_FORM_PARAMETERS, Material, MaterialCharacteristic, MaterialType, MaterialsWithProducer } from '../../models/material';
import AdditifStandardEdit from './Standards/Additif';
import BetonStandardEdit from './Standards/Beton';
import EmulsionBitumeStandardEdit from './Standards/EmulsionBitume';
import EnrobeStandardEdit from './Standards/Enrobe';
import GranulatStandardEdit from './Standards/Granulat';
import LiantHydrauliqueStandardEdit from './Standards/LiantHydraulique';
import LiantHydrocarboneStandardEdit from './Standards/LiantHydrocarbone';
import SolStandardEdit from './Standards/Sol';
import Card from '../../components/ui/Card';
import Switch from '../../components/inputs/Switch';
import { Textarea } from '../../components/inputs/Textarea';
import ScrollableContent from '../../components/ui/ScrollableContent';
import NumberInput from '../../components/inputs/NumberInput';

export interface MaterialSpecificationsFormProps extends Pick<FormHookReturn<Material>, 'attachInput'> { }

const MaterialEditComponent = ({ entity, attachInput, onChange }: Pick<FormHookReturn<Material>, 'entity' | 'attachInput' | 'onChange'>) => {
    // Parameters for the form
    const [formParameters, setFormParameters] = useState<{
        validationCatalogue: EntityFormRules;
        characteristics: MaterialCharacteristic[];
    } | null>(null);

    useEffect(() => {
        if (entity.type && MATERIAL_FORM_PARAMETERS[entity.type]) {
            setFormParameters(MATERIAL_FORM_PARAMETERS[entity.type]);
        }
    }, [entity]);

    const MaterialSpecificationsForm = useMemo(() => {
        switch (entity.type) {
            case MaterialType.ADDITIF:
                return AdditifStandardEdit;
            case MaterialType.BETON:
                return BetonStandardEdit;
            case MaterialType.EMULSION_BITUME:
                return EmulsionBitumeStandardEdit;
            case MaterialType.GRANULAT:
                return GranulatStandardEdit;
            case MaterialType.LIANT_HYDRAULIQUE:
                return LiantHydrauliqueStandardEdit;
            case MaterialType.LIANT_HYDROCARBONE:
                return LiantHydrocarboneStandardEdit;
            case MaterialType.SOL:
                return SolStandardEdit;
            default:
                return EnrobeStandardEdit;
        }
    }, [entity.type]);

    return (
        <ScrollableContent
            side={
                <Card>
                    <Switch<boolean> items={[{ key: true, label: 'Actif' }, { key: false, label: 'Inactif' }]} {...attachInput('active')} />
                    <div className="input-column">
                        <label htmlFor="country">Commentaire</label>
                        <Textarea {...attachInput('comment')} />
                    </div>
                </Card>
            }
        >
            <Card title="Informations générales">
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="name">Nom *</label>
                        <TextInput {...attachInput('name')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="englishName">Nom international</label>
                        <TextInput {...attachInput('englishName')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="year">Année de la norme *</label>
                        <NumberInput {...attachInput('year')} />
                    </div>
                </div>
                {entity?.standard && (
                    <div className="input-column">
                        <label>{entity.type && MaterialsWithProducer.includes(entity.type) ? 'Producteur' : 'Fournisseur'}</label>
                        <Select
                            endpoint={entity.type && MaterialsWithProducer.includes(entity.type)
                                ? '/business/list/producer'
                                : '/business/list/provider'
                            }
                            {...attachInput('business')}
                        />
                    </div>
                )}
            </Card>
            {entity?.standard && entity.type && MaterialsWithProducer.includes(entity.type) && (
                <Card title="Composition">
                    <div className="input-column">
                        <label htmlFor="composition.liants">Liants</label>
                        <MultipleSelect
                            endpoint={`/material/list/${MaterialType.BETON === entity.type ? MaterialType.LIANT_HYDRAULIQUE : MaterialType.LIANT_HYDROCARBONE}/${entity.operation}`}
                            {...attachInput('composition.liants')}
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor="composition.granulats">Granulats</label>
                        <MultipleSelect
                            endpoint={`/material/list/granulat/${entity.operation}`}
                            {...attachInput('composition.granulats')}
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor="composition.additifs">Additifs</label>
                        <MultipleSelect
                            endpoint={`/material/list/additifs/${entity.operation}`}
                            {...attachInput('composition.additifs')}
                        />
                    </div>
                </Card>
            )}
            <MaterialSpecificationsForm attachInput={attachInput} />
            {formParameters?.characteristics && (
                <Charac
                    material={entity}
                    form={formParameters.characteristics}
                    onChange={(id, value) => onChange('characteristics.' + id, value)}
                />
            )}
        </ScrollableContent>
    )
}

export default MaterialEditComponent;
